"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var api_1 = require("@react-google-maps/api");
var MapControl = function (props) {
    var latitude = props.latitude, longitude = props.longitude, getCordinates = props.getCordinates, setCordinates = props.setCordinates, onChange = props.onChange;
    var _a = (0, react_1.useState)(11), zoomLevel = _a[0], setZoomLevel = _a[1];
    var polygonRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(null), polygon = _b[0], setPolygon = _b[1];
    var isLoaded = (0, api_1.useJsApiLoader)({
        id: "google-map-script",
        googleMapsApiKey: process.env.GOOGLE_API_KEY,
    }).isLoaded;
    var containerStyle = {
        width: "100%",
        height: "400px",
    };
    var center = {
        lat: latitude,
        lng: longitude,
    };
    var _c = (0, react_1.useState)(null), map = _c[0], setMap = _c[1];
    var onLoad = (0, react_1.useCallback)(function (map) {
        console.log("What is the callBack map-onLoad", map);
        var bounds = new window.google.maps.LatLngBounds(center);
        console.log("What is the callBack fitbounds-onLoad", bounds);
        map.fitBounds(bounds);
        setMap(map);
        setTimeout(function () {
            setZoomLevel(zoomLevel + 1);
        }, 1000);
    }, []);
    var onUnmount = (0, react_1.useCallback)(function (map) {
        setMap(null);
    }, []);
    // Call setPath with new edited path
    var onPolygonEdit = (0, react_1.useCallback)(function () {
        if (polygon) {
            console.log("What is the polygoon data-onpolyedit", polygon);
            var nextPath = polygon
                .getPath()
                .getArray()
                .map(function (latLng) {
                return {
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                };
            });
            //  getCordinates(nextPath);
            getCordinates({
                nextPath: nextPath,
            });
        }
    }, [polygon]);
    var onPolygonLoad = (0, react_1.useCallback)(function (polygon) {
        console.log("What is the poly data-onpolyLoad", polygon);
        setPolygon(polygon);
    }, [onPolygonEdit]);
    return isLoaded ? ((0, jsx_runtime_1.jsxs)(api_1.GoogleMap, __assign({ mapContainerStyle: containerStyle, center: center, 
        // position={center}
        zoom: zoomLevel, onLoad: onLoad, onUnmount: onUnmount, options: {
            gestureHandling: "greedy",
        } }, { children: [(0, jsx_runtime_1.jsx)(api_1.Marker, { icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png", position: center }), (0, jsx_runtime_1.jsx)(api_1.Polygon, { editable: true, draggable: true, path: setCordinates, onMouseUp: onPolygonEdit, onDragEnd: onPolygonEdit, onLoad: onPolygonLoad }), (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: " " })] }))) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}));
};
exports.default = (0, react_1.memo)(MapControl);
