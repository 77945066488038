"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpgradeModalComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("../constants/constants");
var main_1 = require("../reducers/slices/main");
var billingComponent_1 = require("./billingComponent");
var client_user_selector_1 = require("../selectors/client_user_selector");
var UpgradeModalComponent = function (_a) {
    var message = _a.message;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var isUnsubscribed = (0, react_redux_1.useSelector)(client_user_selector_1.clientSubsciptionStatusSelector).isUnsubscribed;
    var _showPlans = function () {
        dispatch((0, main_1.setContentModal)(null));
        if (!isUnsubscribed)
            return window.open(process.env.STRIPE_BILLING_URL, "_blank");
        setTimeout(function () {
            dispatch((0, main_1.setContentModal)({
                title: constants_1.UPGRADE_MODAL_TITLE,
                content: (0, jsx_runtime_1.jsx)(billingComponent_1.BillingComponent, {}),
            }));
        }, 300);
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: message !== null && message !== void 0 ? message : "Please upgrade your subsciption to access this feature" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-row justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Yes, Continue", size: "small", onClick: function () {
                            _showPlans();
                        } })] }))] })));
};
exports.UpgradeModalComponent = UpgradeModalComponent;
