"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_redux_1 = require("react-redux");
var add_category_thunk_1 = require("../../../../../../reducers/slices/categories/thunks/add_category_thunk");
var upload_file_1 = require("../../../../../../api/calls/media/upload_file");
var main_1 = require("../../../../../../reducers/slices/main");
var sanitiseObject_1 = require("../../../../../../helpers/sanitiseObject");
var update_category_thunk_1 = require("../../../../../../reducers/slices/categories/thunks/update_category_thunk");
var AddCategoryComponent = function (props) {
    var _a, _b, _c;
    var dispatch = (0, react_redux_1.useDispatch)();
    var category = props.category, isEditing = props.isEditing;
    var _d = (0, react_1.useState)(false), isImageLoading = _d[0], setIsImageLoading = _d[1];
    var SUB_CATEGORY = { name: "", image_url: "", description: "" };
    var _e = (0, react_1.useState)((_a = category === null || category === void 0 ? void 0 : category.is_online) !== null && _a !== void 0 ? _a : false), isChecked = _e[0], setIsChecked = _e[1];
    var _f = (0, react_1.useState)({
        name: "",
        image_list: [],
        image_url: "",
        description: "",
        sub_categories: [],
        is_online: null,
        order_number: (_b = category === null || category === void 0 ? void 0 : category.order_number) !== null && _b !== void 0 ? _b : null,
    }), values = _f[0], setValues = _f[1];
    var _g = (0, react_1.useState)(false), showFileInputControl = _g[0], setShowFileInputControl = _g[1];
    var _h = (0, react_1.useState)(false), subCategoryFileInputControl = _h[0], setSubCategoryFileInputControl = _h[1];
    (0, react_1.useEffect)(function () {
        if (category) {
            setValues(__assign({}, category));
        }
    }, []);
    var updateValues = function (prop, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = value, _a)));
    };
    var updateSubCategory = function (index, updatedSubCategory) {
        setValues(function (prevValues) {
            var updatedSubCategories = __spreadArray([], prevValues.sub_categories, true);
            updatedSubCategories[index] = updatedSubCategory;
            return __assign(__assign({}, prevValues), { sub_categories: updatedSubCategories });
        });
    };
    var uploadImage = function (file, file_name, index) {
        setIsImageLoading(true);
        (0, upload_file_1.upload_file)({
            file: file,
            file_name: file_name,
            description: "Letstrade product category images",
            type: "IMAGE",
            category: "PRODUCTS CATEGORY",
        }).then(function (res) {
            var _a;
            console.log(res);
            if (res.success) {
                setIsImageLoading(false);
                if (index === null) {
                    updateValues("image_url", res.content.location);
                }
                else {
                    //not sure why we have this here...
                    // values.sub_categories[index]?.image_list?.push({
                    //   image_url: res.content.location,
                    // });
                    var updatedSubcategories = (_a = values === null || values === void 0 ? void 0 : values.sub_categories) === null || _a === void 0 ? void 0 : _a.map(function (element, idx) {
                        var tempObj = {};
                        for (var i in element) {
                            tempObj[i] = element[i];
                        }
                        element = tempObj;
                        if (index === idx) {
                            element.image_url = res.content.location;
                        }
                        return element;
                    });
                    setValues(__assign(__assign({}, values), { sub_categories: updatedSubcategories }));
                    setSubCategoryFileInputControl(false);
                }
            }
            else {
                setIsImageLoading(false);
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when trying to upload your Image.", messages: ["Please try again..."] }));
            }
        });
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 overflow-y-auto max-h-[600px]" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4 mb-4 p-2 bg-borders rounded-md" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Product Category Name ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Product Category Image ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Product Category Description ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] }))] })), isImageLoading && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "xsmall" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 gap-2 items-end" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex-1 flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.SwitchControl, { disabled: false, label: isChecked ? "Online" : "Offline", checked: isChecked, onChange: function (checked) {
                                    updateValues("is_online", checked);
                                    setIsChecked(checked);
                                }, colors: {
                                    thumbColor: isChecked ? "bg-blue-500" : "bg-gray-500",
                                    titleColor: "text-blue-500",
                                    labelColor: "text-black",
                                    errorTextColor: "text-red-500",
                                } }) })) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Order Number", type: "number", size: "small", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.order_number, errorText: "", onChange: function (v) {
                            updateValues("order_number", v);
                        } })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 gap-2 items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Name", type: "text", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.name, errorText: "", size: "medium", onChange: function (v) {
                            updateValues("name", v);
                        } }), (showFileInputControl || !(category === null || category === void 0 ? void 0 : category.image_url)) && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.FileInputControl, { startIcon: pro_light_svg_icons_1.faFileArrowUp, previewSide: "right", colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, size: "medium", previewFile: true, label: "Upload Image", onChange: function (f) {
                            uploadImage(f, f.name, null);
                        } })), !showFileInputControl && (category === null || category === void 0 ? void 0 : category._id) && (category === null || category === void 0 ? void 0 : category.image_url) && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " }, { children: [(0, jsx_runtime_1.jsx)("img", { src: category === null || category === void 0 ? void 0 : category.image_url, alt: "Category Image", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "absolute right-3 bg-gray-600 rounded-full text-white" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faPencil, size: "xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                                        setShowFileInputControl(true);
                                        values.image_url = "";
                                    } }) }))] }))), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Description", type: "text", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.description, errorText: "", size: "medium", onChange: function (v) {
                            updateValues("description", v);
                        } })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4 mb-4 p-2 bg-borders rounded-md" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Sub-Category Name ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Sub-Category Image ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Sub-Category Description ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] }))] })), (_c = values === null || values === void 0 ? void 0 : values.sub_categories) === null || _c === void 0 ? void 0 : _c.map(function (x, i) {
                var _a, _b, _c, _d, _e;
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 gap-2 items-center justify-between" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Name", type: "text", borderColor: "border-borders", value: (_a = values === null || values === void 0 ? void 0 : values.sub_categories[i]) === null || _a === void 0 ? void 0 : _a.name, errorText: "", onChange: function (v) {
                                var updatedSubCategory = __assign(__assign({}, values.sub_categories[i]), { name: v });
                                updateSubCategory(i, updatedSubCategory);
                            } }), !((_b = values === null || values === void 0 ? void 0 : values.sub_categories[i]) === null || _b === void 0 ? void 0 : _b.image_url) && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.FileInputControl, { startIcon: pro_light_svg_icons_1.faFileArrowUp, previewSide: "right", colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, previewFile: true, label: "Upload Image", size: "medium", onChange: function (f) {
                                uploadImage(f, f.name, i);
                            } })), ((_c = values === null || values === void 0 ? void 0 : values.sub_categories[i]) === null || _c === void 0 ? void 0 : _c.image_url) && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " }, { children: (0, jsx_runtime_1.jsx)("img", { src: (_d = values === null || values === void 0 ? void 0 : values.sub_categories[i]) === null || _d === void 0 ? void 0 : _d.image_url, alt: "Category Image", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }) }))), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Description", type: "text", borderColor: "border-borders", value: (_e = values === null || values === void 0 ? void 0 : values.sub_categories[i]) === null || _e === void 0 ? void 0 : _e.description, errorText: "", onChange: function (v) {
                                var updatedSubCategory = __assign(__assign({}, values.sub_categories[i]), { description: v });
                                updateSubCategory(i, updatedSubCategory);
                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { iconColor: "text-black", backgroundColor: "bg-gray-100", size: "xsmall", onClick: function () {
                                var _a;
                                var _subCategories = (_a = values === null || values === void 0 ? void 0 : values.sub_categories) === null || _a === void 0 ? void 0 : _a.filter(function (sub, subIndex) { return subIndex !== i; });
                                setValues(__assign(__assign({}, values), { sub_categories: _subCategories }));
                            }, icon: pro_light_svg_icons_1.faMinus })] }), i));
            }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-right" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: function () {
                        var _a;
                        var subCategory = SUB_CATEGORY;
                        setValues(__assign(__assign({}, values), { sub_categories: __spreadArray(__spreadArray([], ((_a = values === null || values === void 0 ? void 0 : values.sub_categories) !== null && _a !== void 0 ? _a : []), true), [subCategory], false) }));
                    }, icon: pro_light_svg_icons_1.faPlus }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { startIcon: !isEditing ? pro_light_svg_icons_1.faPlus : pro_solid_svg_icons_1.faSave, iconColor: "text-white", label: !isEditing ? "Add Product Category" : "Update Product Category", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var params, _id, name_1, description, image_url, image_list, sub_categories, is_online;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!!isEditing) return [3 /*break*/, 2];
                                    return [4 /*yield*/, dispatch((0, add_category_thunk_1.add_category_thunk)(values))];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 4];
                                case 2:
                                    params = (0, sanitiseObject_1.sanitiseObject)(__assign({}, values));
                                    _id = params._id, name_1 = params.name, description = params.description, image_url = params.image_url, image_list = params.image_list, sub_categories = params.sub_categories, is_online = params.is_online;
                                    return [4 /*yield*/, dispatch((0, update_category_thunk_1.update_category_thunk)({
                                            _id: _id,
                                            name: name_1,
                                            image_url: image_url,
                                            description: description,
                                            image_list: image_list,
                                            sub_categories: sub_categories,
                                            is_online: is_online,
                                            order_number: (values === null || values === void 0 ? void 0 : values.order_number) ? String(values === null || values === void 0 ? void 0 : values.order_number) : null,
                                        }))];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); } }) }))] })));
};
exports.default = AddCategoryComponent;
