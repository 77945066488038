"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.branch_working_hours_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var update_branch_hours_1 = require("../../../../../api/calls/branch/update/update_branch_hours");
var main_1 = require("../../../main");
var branch_1 = require("../../branch");
exports.branch_working_hours_thunk = (0, toolkit_1.createAsyncThunk)("branch/branch_working_hours_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, branchState, _b, _id, working_hours, response;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    state = getState();
                    branchState = state.branchState;
                    _b = branchState.selectedBranch, _id = _b._id, working_hours = _b.working_hours;
                    return [4 /*yield*/, dispatch((0, branch_1.setIsLoadingBranch)(true))];
                case 1:
                    _c.sent();
                    return [4 /*yield*/, (0, update_branch_hours_1.branch_working_hours)({
                            _id: _id,
                            working_hours: working_hours.map(function (workingHour) {
                                var _a, _b;
                                return {
                                    closed: workingHour.closed == false ? undefined : workingHour.closed,
                                    day: workingHour.day,
                                    from: (_a = workingHour.from) !== null && _a !== void 0 ? _a : new Date().toISOString(),
                                    to: (_b = workingHour.to) !== null && _b !== void 0 ? _b : new Date().toISOString(),
                                };
                            }),
                        })];
                case 2:
                    response = _c.sent();
                    return [4 /*yield*/, dispatch((0, branch_1.setIsLoadingBranch)(false))];
                case 3:
                    _c.sent();
                    // Handle the response.
                    if (response.success) {
                        dispatch((0, branch_1.setSelectedBranch)(response.content));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
