"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductListComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var create_parcel_1 = require("../../../../../api/calls/order/post/create_parcel");
var approve_loan_1 = require("../../../../../api/calls/order/update/approve_loan");
var cancel_order_1 = require("../../../../../api/calls/order/update/cancel_order");
var pick_products_1 = require("../../../../../api/calls/parcel/products/pick_products");
var constants_1 = require("../../../../../constants/constants");
var main_1 = require("../../../../../reducers/slices/main");
var get_order_thunk_1 = require("../../../../../reducers/slices/orders/thunks/get_order_thunk");
var client_user_selector_1 = require("../../../../../selectors/client_user_selector");
var constants_2 = require("../constants");
var applied_discounts_1 = require("./applied_discounts");
var constants_3 = require("./parcels/constants");
var product_ref_1 = require("./product_ref");
var variations_1 = require("./variations");
var sumVariations_1 = require("../../../../../helpers/sumVariations");
var ProductListComponent = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _g = (0, react_1.useState)(false), isLoadingButton = _g[0], setIsLoadingButton = _g[1];
    var selectedOrder = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }).selectedOrder;
    var parcels = (0, react_redux_1.useSelector)(function (state) { return state.parcelState; }).parcels;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var isManager = (0, react_redux_1.useSelector)(client_user_selector_1.isManagerSelector).isManager;
    var isAdmin = (0, react_redux_1.useSelector)(client_user_selector_1.isAdminSelector);
    var canCancelOrders = isAdmin || (isManager && ((_a = client === null || client === void 0 ? void 0 : client.branch_manager) === null || _a === void 0 ? void 0 : _a.can_cancel_orders));
    var _h = (0, react_1.useState)([]), selectedProducts = _h[0], setSelectedProducts = _h[1];
    var isAwaitingApproval = selectedOrder.status === constants_2.StatusEnum.WAITING_LOAN_APPROVAL;
    var canManageParcels = !isAwaitingApproval;
    if (selectedOrder.status === constants_2.StatusEnum.POS_ORDERED) {
        canManageParcels = (_b = client === null || client === void 0 ? void 0 : client.order_manager) === null || _b === void 0 ? void 0 : _b.is_pos_fulfillment_enabled;
    }
    var _onViewProductDiscount = function (product) {
        return dispatch((0, main_1.setContentModal)({
            title: "Order ".concat(selectedOrder.code),
            content: (0, jsx_runtime_1.jsx)(applied_discounts_1.AppliedDiscounts, { product: product }),
        }));
    };
    var _onViewVariations = function (product) {
        return dispatch((0, main_1.setContentModal)({
            title: "Order ".concat(selectedOrder.code),
            content: (0, jsx_runtime_1.jsx)(variations_1.Variations, { product: product }),
        }));
    };
    var productList = ((_c = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.product_list) !== null && _c !== void 0 ? _c : []).map(function (product) {
        var _a;
        return (__assign(__assign({}, product), { estimated_delivery_time: doshx_controls_web_1.Utilities.formatDateTime(product.estimated_delivery_time), type: (_a = product === null || product === void 0 ? void 0 : product.type) === null || _a === void 0 ? void 0 : _a.name }));
    });
    var branchId = (_e = (_d = selectedOrder.branch) === null || _d === void 0 ? void 0 : _d._id) !== null && _e !== void 0 ? _e : (_f = selectedOrder.store) === null || _f === void 0 ? void 0 : _f.store_id;
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var createParcel = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user_id, order_id, delivery, method, response;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    user_id = selectedOrder.user_id, order_id = selectedOrder._id, delivery = selectedOrder.delivery, method = selectedOrder.method;
                    return [4 /*yield*/, (0, create_parcel_1.create_parcel)({
                            user_id: user_id,
                            order_id: order_id,
                            branch: { _id: branchId },
                            collection_address: {
                                platform: constants_1.PLATFORM,
                                lon: (_c = (_b = (_a = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.branch) === null || _a === void 0 ? void 0 : _a.address) === null || _b === void 0 ? void 0 : _b.longitude) !== null && _c !== void 0 ? _c : " ",
                                lat: (_f = (_e = (_d = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.branch) === null || _d === void 0 ? void 0 : _d.address) === null || _e === void 0 ? void 0 : _e.latitude) !== null && _f !== void 0 ? _f : " ",
                                full_address: (_j = (_h = (_g = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.branch) === null || _g === void 0 ? void 0 : _g.address) === null || _h === void 0 ? void 0 : _h.full_address) !== null && _j !== void 0 ? _j : " ",
                            },
                            method: method,
                            product_list: __spreadArray([], selectedProducts, true),
                            parcel_date: new Date().toISOString(),
                        })];
                case 1:
                    response = _k.sent();
                    if (response.success) {
                        setIsLoadingButton(false);
                        dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id }));
                    }
                    else
                        dispatch((0, main_1.setMessageModal)({ title: constants_1.REQUEST_ERROR_TITLE, messages: [response.message] }));
                    return [2 /*return*/];
            }
        });
    }); };
    var cancelOrder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var order_id, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    order_id = selectedOrder._id;
                    return [4 /*yield*/, (0, cancel_order_1.cancel_order)({
                            order_id: order_id,
                        })];
                case 1:
                    response = _a.sent();
                    dispatch((0, main_1.setContentModal)(null));
                    if (response.success) {
                        dispatch((0, main_1.setMessageModal)({ title: "Success", messages: [response.message] }));
                        dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id }));
                    }
                    else
                        dispatch((0, main_1.setMessageModal)({ title: constants_1.REQUEST_ERROR_TITLE, messages: [response.message] }));
                    return [2 /*return*/];
            }
        });
    }); };
    var approveLoan = function () { return __awaiter(void 0, void 0, void 0, function () {
        var order_id, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    order_id = selectedOrder._id;
                    return [4 /*yield*/, (0, approve_loan_1.approve_loan)({
                            order_id: order_id,
                        })];
                case 1:
                    response = _a.sent();
                    dispatch((0, main_1.setContentModal)(null));
                    if (response.success) {
                        dispatch((0, main_1.setMessageModal)({ title: "Success", messages: [response.message] }));
                        dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id }));
                    }
                    else
                        dispatch((0, main_1.setMessageModal)({ title: constants_1.REQUEST_ERROR_TITLE, messages: [response.message] }));
                    return [2 /*return*/];
            }
        });
    }); };
    var onCancelOrder = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to cancel order ".concat(selectedOrder.code, "?"), size: "medium", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-2 flex-row" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Yes, Continue", size: "small", onClick: cancelOrder })] }))] }))),
        }));
    };
    var onApproveLoan = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to approve loan on this order", size: "medium", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-2 flex-row" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Yes, Continue", size: "small", onClick: approveLoan })] }))] }))),
        }));
    };
    var pickToParcel = function (parcel_id) { return __awaiter(void 0, void 0, void 0, function () {
        var productIds, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    productIds = selectedProducts.map(function (product) { return product._id; });
                    return [4 /*yield*/, (0, pick_products_1.pick_products)({
                            _id: parcel_id,
                            product_id_list: productIds,
                        })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id }));
                        dispatch((0, main_1.setContentModal)(null));
                        setSelectedProducts([]);
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
                    return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Products", size: "small", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-13 gap-4 w-full mt-3" }, { children: [constants_3.productTableColumns.map(function (_a, index) {
                        var label = _a.label, width = _a.width;
                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " flex items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere", size: "xsmall", textColor: "text-black", bold: true }) }), index));
                    }), (0, jsx_runtime_1.jsx)("div", { className: "col-span-2" })] })), productList === null || productList === void 0 ? void 0 : productList.map(function (product, index) {
                var arrived = product.arrived, reference_number = product.reference_number;
                var isReordered = product.status === constants_2.StatusEnum.RE_ORDERED || product.status === constants_2.StatusEnum.ORDERED;
                var isSelected = selectedProducts.some(function (x) { return x._id === product._id; });
                var handleCheckboxChange = function (value) {
                    var updatedProducts = selectedProducts.filter(function (i) { return i._id !== product._id; });
                    if (value && !isSelected) {
                        setSelectedProducts(__spreadArray(__spreadArray([], selectedProducts, true), [product], false));
                    }
                    else {
                        setSelectedProducts(updatedProducts);
                    }
                };
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid w-full gap-4 mt-2 items-center grid-cols-13" }, { children: [constants_3.productTableColumns.map(function (_a, i) {
                            var _b, _c;
                            var displayKey = _a.displayKey, width = _a.width;
                            var splitKey = displayKey.split(".");
                            var keyToAccess = splitKey.reduce(function (obj, key) {
                                if (key === "price" && obj["on_promotion"] === true) {
                                    return obj === null || obj === void 0 ? void 0 : obj["promotion_price"];
                                }
                                return obj === null || obj === void 0 ? void 0 : obj[key];
                            }, product);
                            if (displayKey === "title") {
                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product.title, size: "xsmall", textColor: "text-black" }) }), i));
                            }
                            if (displayKey === "price") {
                                var totalProductPrice = (0, sumVariations_1.calculateTotalProductPrice)(product === null || product === void 0 ? void 0 : product.price, product === null || product === void 0 ? void 0 : product.variations);
                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({
                                            symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                            value: (_b = String(totalProductPrice)) !== null && _b !== void 0 ? _b : "0",
                                            symbolSeparation: true,
                                        })), size: "xsmall", textColor: "text-black" }) }), i));
                            }
                            if (displayKey === "discount") {
                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-600", label: "View", onClick: function () { return _onViewProductDiscount(product); }, size: "xsmall" }) }), i));
                            }
                            if (displayKey === "variations")
                                return ((_c = product === null || product === void 0 ? void 0 : product.variations) === null || _c === void 0 ? void 0 : _c.length) > 0 ? ((0, jsx_runtime_1.jsx)("div", __assign({ className: "items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-600", label: "View", onClick: function () { return _onViewVariations(product); }, size: "xsmall" }) }), i)) : ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "N/A", size: "xsmall", textColor: "text-black" }));
                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: keyToAccess, size: "xsmall", textColor: "text-black" }) }), i));
                        }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-1" }, { children: arrived ? ((0, jsx_runtime_1.jsx)("div", __assign({ className: "", title: "Reference Number: ".concat(reference_number) }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", iconColor: "text-green-500", icon: pro_solid_svg_icons_1.faCheckCircle, onClick: function () { }, size: "large" }) }))) : canManageParcels ? ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faCheckToSlot, tooltip: {
                                    label: "Enter Reference Number",
                                    placement: "right",
                                    colors: {
                                        backgroundColor: "bg-dark",
                                        textColor: "text-white",
                                    },
                                }, onClick: function () {
                                    dispatch((0, main_1.setContentModal)({
                                        title: "Enter Reference Number",
                                        content: (0, jsx_runtime_1.jsx)(product_ref_1.ProductRefComponent, { product: product }),
                                    }));
                                }, size: "large" })) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {})) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-1 items-center" }, { children: isReordered && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "", value: isSelected, onChange: handleCheckboxChange, size: "large", textColor: "text-black" }) })), (product === null || product === void 0 ? void 0 : product.reference_number) && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { size: "xsmall", label: "Ref: ".concat(product === null || product === void 0 ? void 0 : product.reference_number) }) })))] }), index));
            }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center mt-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex-1" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "", size: "small", textColor: "text-black", bold: true }) })), selectedOrder.status !== constants_2.StatusEnum.CANCELLED && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [isAwaitingApproval && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Approve Loan", size: "xsmall", textColor: "text-white", backgroundColor: "bg-secondary", onClick: onApproveLoan })), canCancelOrders && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel Order", size: "xsmall", textColor: "text-white", backgroundColor: "bg-secondary", onClick: onCancelOrder })), canManageParcels && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Create Parcel", size: "xsmall", textColor: "text-white", backgroundColor: "bg-secondary", loading: isLoadingButton, onClick: function () {
                                    setIsLoadingButton(true);
                                    createParcel();
                                } })), canManageParcels && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Pack to Parcel", size: "xsmall", textColor: "text-white", backgroundColor: "bg-secondary", onClick: function () {
                                    dispatch((0, main_1.setContentModal)({
                                        title: "Choose Parcel",
                                        content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-wrap gap-4" }, { children: selectedProducts.length > 0 ? (parcels === null || parcels === void 0 ? void 0 : parcels.map(function (parcel, index) {
                                                var _a;
                                                var parcelStatus = parcel === null || parcel === void 0 ? void 0 : parcel.status;
                                                if (parcelStatus === constants_2.StatusEnum.OPEN) {
                                                    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "".concat(parcel.code, " (").concat((_a = parcel.product_list) === null || _a === void 0 ? void 0 : _a.length, ")"), size: "xsmall", textColor: "text-white", loading: isLoadingButton, onClick: function () { return pickToParcel(parcel._id); } }));
                                                }
                                                else {
                                                    return null;
                                                }
                                            })) : ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Please add at least 1 product to your parcel", size: "small", textColor: "text-black", bold: true }) })) }))),
                                    }));
                                } }))] })))] }))] })));
};
exports.ProductListComponent = ProductListComponent;
