"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFrontDetails = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var sanitiseObject_1 = require("../../../../../helpers/sanitiseObject");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
var store_front_1 = require("../../../../../reducers/slices/storeFront/store_front");
var create_store_front_thunk_1 = require("../../../../../reducers/slices/storeFront/thunks/create_store_front_thunk");
var publish_store_front_thunk_1 = require("../../../../../reducers/slices/storeFront/thunks/publish_store_front_thunk");
var update_store_front_thunk_1 = require("../../../../../reducers/slices/storeFront/thunks/update_store_front_thunk");
var pages_1 = require("../../../../../constants/pages");
var StoreFrontDetails = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState; }), isEdited = _a.isEdited, isLoading = _a.isLoading, errors = _a.errors, selectedEnvironment = _a.selectedEnvironment, store_front = __rest(_a, ["isEdited", "isLoading", "errors", "selectedEnvironment"]);
    var developmentLabel = "Development";
    var productionLabel = "Production";
    var environmentOptions = [
        { label: developmentLabel, value: "development" },
        { label: productionLabel, value: "production" },
    ];
    var onSave = function () {
        var isValid = validate();
        if (!isValid)
            return;
        if (store_front._id) {
            dispatch((0, update_store_front_thunk_1.update_store_front_thunk)(store_front));
        }
        else {
            dispatch((0, create_store_front_thunk_1.create_store_front_thunk)(store_front));
        }
    };
    var onPublish = function () { return dispatch((0, publish_store_front_thunk_1.publish_store_front_thunk)(selectedEnvironment)); };
    var validate = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        var contact_details_errors = (0, validateForm_1.validateForm)(store_front.contact_details).errors;
        var contact_us_recipient_errors = (0, validateForm_1.validateForm)((_a = store_front.contact_us) === null || _a === void 0 ? void 0 : _a.recipient).errors;
        var errors = {
            about_us: {
                description: ((_b = store_front.about_us) === null || _b === void 0 ? void 0 : _b.description) ? "" : "required",
            },
            return_policy: {
                description: ((_c = store_front.return_policy) === null || _c === void 0 ? void 0 : _c.description) ? "" : "required",
            },
            terms_of_service: {
                description: ((_d = store_front.terms_of_service) === null || _d === void 0 ? void 0 : _d.description) ? "" : "required",
            },
            privacy_policy: {
                description: ((_e = store_front.privacy_policy) === null || _e === void 0 ? void 0 : _e.description) ? "" : "required",
            },
            newsletter: {
                description: ((_f = store_front.newsletter) === null || _f === void 0 ? void 0 : _f.description) ? "" : "required",
                sender: {
                    email: ((_h = (_g = store_front.newsletter) === null || _g === void 0 ? void 0 : _g.sender) === null || _h === void 0 ? void 0 : _h.email) ? "" : "required",
                },
            },
            images: {
                header_logo: {
                    url: ((_k = (_j = store_front.images) === null || _j === void 0 ? void 0 : _j.header_logo) === null || _k === void 0 ? void 0 : _k.url) ? "" : "required",
                },
                footer_logo: {
                    url: ((_m = (_l = store_front.images) === null || _l === void 0 ? void 0 : _l.footer_logo) === null || _m === void 0 ? void 0 : _m.url) ? "" : "required",
                },
                favicon: {
                    url: ((_p = (_o = store_front.images) === null || _o === void 0 ? void 0 : _o.favicon) === null || _p === void 0 ? void 0 : _p.url) ? "" : "required",
                },
            },
            contact_details: __assign({}, contact_details_errors),
            contact_us: {
                recipient: contact_us_recipient_errors,
            },
        };
        var sanitisedObject = (0, sanitiseObject_1.sanitiseObjectRecursively)(errors);
        if (Object.keys(sanitisedObject).length !== 0) {
            dispatch((0, store_front_1.setErrors)(errors));
            return false;
        }
        return true;
    };
    var _setSelectedEnvironment = function (env) {
        dispatch((0, store_front_1.setSelectedEnvironment)(env));
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "w-md" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row justify-between items-center h-[30px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Storefront Details", size: "large", textColor: "text-black" }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, {}), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Environment", size: "large", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.RadioButtonsControl, { colors: pages_1.radioButtonColorConfig, options: environmentOptions, selectedValue: selectedEnvironment, onChange: _setSelectedEnvironment }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row justify-end gap-4 pt-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", enabled: isEdited, loading: isLoading, startIcon: pro_light_svg_icons_1.faSave, onClick: onSave }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Publish", size: "small", startIcon: pro_light_svg_icons_1.faUpload, enabled: Boolean(selectedEnvironment), onClick: onPublish })] }))] })));
};
exports.StoreFrontDetails = StoreFrontDetails;
