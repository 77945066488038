"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var AddD365Organization = function () {
    var _a = (0, react_1.useState)(""), url = _a[0], setUrl = _a[1];
    var _b = (0, react_1.useState)(""), errorText = _b[0], setErrorText = _b[1];
    (0, react_1.useEffect)(function () { }, [open]);
    var validate = function () {
        var messageCount = 0;
        setErrorText("");
        if (!url) {
            messageCount++;
            setErrorText("Please enter url");
        }
        return messageCount ? false : true;
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-8" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "eg. https://orgc889c88f.crm4.dynamics.com", size: "xsmall" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "URL", type: "text", borderColor: "border-borders", value: url, 
                            // errorText={validate().url}
                            errorText: errorText, onChange: function (v) {
                                setUrl(v);
                            } }) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-40" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Connect", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                            if (validate()) {
                                var stateString = doshx_controls_web_1.Utilities.randomAlphanumeric();
                                var _scope = "".concat(url, "/user_impersonation");
                                var redirectTo = "".concat(process.env.LETS_TRADE_WEB_URL);
                                location.href = "https://login.microsoftonline.com/".concat(process.env.D365_DIRECTORY_ID, "/oauth2/v2.0/authorize?client_id=").concat(process.env.D365_CLIENT_ID, "&response_type=code&redirect_uri=").concat(redirectTo, "&response_mode=query&scope=").concat(_scope, "&state=").concat(stateString);
                            }
                        } }) })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "px-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "By dynamic 365 your Shopify account, you agree that your products will be visible and manageable on the Letstrade platform.", size: "xsmall", center: true }) }))] })));
};
exports.default = AddD365Organization;
