"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BillingComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var BillingComponent = function () {
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _a.user, isLoading = _a.isLoading;
    var integration_settings = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client.integration_settings;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-auto" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col lg:w-[1350px] overflow-y-scroll" }, { children: (0, jsx_runtime_1.jsx)("stripe-pricing-table", { "pricing-table-id": process.env.STRIPE_PRICING_TABLE_ID, "publishable-key": process.env.STRIPE_PUBLISHABLE_KEY, "client-reference-id": integration_settings === null || integration_settings === void 0 ? void 0 : integration_settings.payment_engine_id, "customer-email": user === null || user === void 0 ? void 0 : user.email }) })) })));
};
exports.BillingComponent = BillingComponent;
