"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductListPage = exports.PRODUCT_AVAILABILITY = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var remove_product_1 = require("../../../../api/calls/product/remove_product");
var channelsCellComponent_1 = require("../../../../components/channelsCellComponent");
var pages_1 = require("../../../../constants/pages");
var routes_1 = require("../../../../constants/routes");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var console_1 = require("../../../../reducers/slices/console");
var main_1 = require("../../../../reducers/slices/main");
var product_1 = require("../../../../reducers/slices/products/product");
var get_products_shopify_thunk_1 = require("../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var constants_1 = require("../../constants");
var action_selected_1 = require("./components/product_details/action_selected");
var importCSV_1 = require("./components/product_details/importCSV");
exports.PRODUCT_AVAILABILITY = [
    { label: "Offline Products", value: "OFFLINE" },
    { label: "Online Products", value: "ONLINE" },
];
var ProductListPage = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), search = _a[0], setSearch = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _b.products, pagination = _b.pagination, loading = _b.loading;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _c.client_token, user = _c.user;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _d = (0, react_1.useState)(false), showFilters = _d[0], setShowFilters = _d[1];
    var _e = (0, react_1.useState)(""), selectedAvailabilityType = _e[0], setSelectedAvailabilityType = _e[1];
    var _f = (0, react_1.useState)(1), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = (0, react_1.useState)(10), rowsPerPage = _g[0], setRowsPerPage = _g[1];
    var _h = (0, react_1.useState)([]), selectedRows = _h[0], setSelectedRows = _h[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Product Catalog"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token && !loading) {
            dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    (0, react_1.useEffect)(function () {
        dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign(__assign({}, pagination), { availability: selectedAvailabilityType })));
    }, [selectedAvailabilityType]);
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getProducts(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (search === "") {
            setRowsPerPage(rowsPerPage);
            return getProducts(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getProducts(__assign(__assign({}, pagination), { page: page, search_string: search }));
        }
    };
    var onRowsPerPageChange = function (size) {
        setRowsPerPage(size);
        return getProducts(__assign(__assign({}, pagination), { size: size }));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 relative" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-start gap-4 items-center" }, { children: [(user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faSlidersSimple, size: "small", backgroundColor: "bg-primary", iconColor: "text-white", onClick: function () { return setShowFilters(!showFilters); } })), showFilters && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-44 rounded-md bg-white" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Product Types", size: "small", borderColor: "border-white", options: exports.PRODUCT_AVAILABILITY === null || exports.PRODUCT_AVAILABILITY === void 0 ? void 0 : exports.PRODUCT_AVAILABILITY.map(function (type) {
                                        return {
                                            label: type.label,
                                            value: type === null || type === void 0 ? void 0 : type.value,
                                        };
                                    }), value: selectedAvailabilityType, onClear: function () { }, onChange: function (v) {
                                        setSelectedAvailabilityType(v);
                                    } }) })))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "type", endIcon: pro_light_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-white", textColor: "text-black", iconColor: "text-black", onClick: function () {
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_TYPES_ROUTE));
                                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Categories", endIcon: pro_light_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-white", textColor: "text-black", iconColor: "text-black", onClick: function () {
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_CATEGORIES_ROUTE));
                                } }), (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Import CSV", endIcon: pro_light_svg_icons_1.faFileCsv, size: "small", backgroundColor: "bg-white", textColor: "text-black", iconColor: "text-black", onClick: function () {
                                    dispatch((0, main_1.setContentModal)({
                                        title: "Import Products",
                                        content: (0, jsx_runtime_1.jsx)(importCSV_1.ImportCSVComponent, {}),
                                    }));
                                } })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Add Product", endIcon: pro_light_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_DETAILS_ROUTE));
                                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, dispatch((0, product_1.setTablePagination)(pages_1.initialPaginationValues))];
                                            case 1:
                                                _a.sent();
                                                setSearch(e);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })] }))] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "image",
                        label: "Image",
                        formatter: function (product) {
                            if (product) {
                                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: product === null || product === void 0 ? void 0 : product.image_url });
                            }
                            else {
                                return "";
                            }
                        },
                    },
                    {
                        displayKey: "sku",
                        label: "SKU",
                    },
                    {
                        displayKey: "pid",
                        label: "PID",
                    },
                    {
                        displayKey: "title",
                        label: "Title",
                    },
                    ,
                    {
                        displayKey: "category.name",
                        label: "Category",
                        formatter: function (product) {
                            var _a;
                            if (product === null || product === void 0 ? void 0 : product.category) {
                                return (_a = product === null || product === void 0 ? void 0 : product.category) === null || _a === void 0 ? void 0 : _a.name;
                            }
                            else {
                                return "";
                            }
                        },
                    },
                    {
                        displayKey: "price",
                        label: "Price",
                        formatter: function (product) {
                            var _a, _b;
                            if (product) {
                                return doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_b = (_a = product === null || product === void 0 ? void 0 : product.price) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "0",
                                    symbolSeparation: true,
                                });
                            }
                        },
                    },
                    {
                        displayKey: "on_sale",
                        label: "On Sale",
                        formatter: function (product) {
                            if (product === null || product === void 0 ? void 0 : product.on_promotion) {
                                return "Yes";
                            }
                            else {
                                return "No";
                            }
                        },
                    },
                    {
                        displayKey: "on_sale_price",
                        label: "On Sale Price",
                        formatter: function (product) {
                            if (product === null || product === void 0 ? void 0 : product.on_promotion) {
                                return doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: product === null || product === void 0 ? void 0 : product.promotion_price,
                                    symbolSeparation: true,
                                });
                            }
                            else {
                                return doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: "0",
                                    symbolSeparation: true,
                                });
                            }
                        },
                    },
                    {
                        displayKey: "channels",
                        label: "Channel",
                        formatter: function (product) {
                            return (0, channelsCellComponent_1.ChannelsCellComponent)({ channels: product === null || product === void 0 ? void 0 : product.channels });
                        },
                    },
                    {
                        displayKey: "status",
                        label: "Status",
                        formatter: function (x) {
                            if (x) {
                                return (0, constants_1.TABLE_STATUSES)(x.status);
                            }
                        },
                    },
                ], rowSelections: selectedRows, onSelectionChange: function (selectedRows) {
                    // setSelectedAllProducts(false);
                    setSelectedRows(selectedRows);
                }, data: products, onRowClicked: function (data) {
                    setSearch(null);
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_DETAILS_ROUTE, "/").concat(data === null || data === void 0 ? void 0 : data._id));
                }, rowActions: [
                    {
                        label: "Edit",
                        onClick: function (data) {
                            setSearch(null);
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_DETAILS_ROUTE, "/").concat(data === null || data === void 0 ? void 0 : data._id));
                        },
                    },
                    {
                        label: "Delete",
                        onClick: function (data) {
                            dispatch((0, main_1.setContentModal)({
                                title: "Confirm deletion of ".concat(data === null || data === void 0 ? void 0 : data.title, " product"),
                                content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this product?", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () {
                                                        (0, remove_product_1.remove_product)({
                                                            _id: data === null || data === void 0 ? void 0 : data._id,
                                                        }).then(function (response) {
                                                            if (response.success) {
                                                                dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                                dispatch((0, main_1.setContentModal)(null));
                                                                dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign({}, pagination)));
                                                            }
                                                            else {
                                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when deleting the product!", messages: [response.message] }));
                                                            }
                                                        });
                                                    } })] }))] }))),
                            }));
                        },
                    },
                ], pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: currentPage,
                    rowsPerPage: rowsPerPage,
                    totalRecords: pagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, hasShadow: true, colors: pages_1.tableColorConfig }), selectedRows && selectedRows.length > 0 && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 fixed bottom-8 right-8" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel Selected", endIcon: pro_solid_svg_icons_1.faTimes, size: "small", backgroundColor: "bg-borders", textColor: "text-black", iconColor: "text-black", onClick: function () {
                            setSelectedRows([]);
                            // dispatch(setSelectedProducts([]));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Action Selected", endIcon: pro_solid_svg_icons_1.faTasks, size: "small", backgroundColor: "bg-secondary", textColor: "text-white", iconColor: "text-white", onClick: function () {
                            dispatch((0, product_1.setSelectedProducts)(selectedRows));
                            dispatch((0, main_1.setContentModal)({
                                title: "Action Selected Products",
                                content: (0, jsx_runtime_1.jsx)(action_selected_1.ActionSelected, {}),
                            }));
                        } })] })))] })));
};
exports.ProductListPage = ProductListPage;
