"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var get_branch_parcels_thunk_1 = require("../../../../../reducers/slices/parcels/thunks/get_branch_parcels_thunk");
var ParcelsComponent = function (props) {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var setSelectedParcels = props.setSelectedParcels, selectedParcels = props.selectedParcels;
    var _c = (0, react_1.useState)(1), currentPage = _c[0], setCurrentPage = _c[1];
    (0, react_1.useEffect)(function () {
        console.log("branchParcels  ===>", branchParcels);
    }, []);
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.parcelState; }), branchParcels = _d.branchParcels, pagination = _d.pagination;
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var readyParcels = (_a = branchParcels === null || branchParcels === void 0 ? void 0 : branchParcels.filter(function (x) { return x.status === "READY" && x.method == "DELIVERY"; })) !== null && _a !== void 0 ? _a : [];
    var filters = {
        floating_fleet: client.floating_fleet
    };
    (0, react_1.useEffect)(function () { }, [branchParcels]);
    var parcelMode = function (x) {
        switch (x.parcel_mode) {
            case "PASSED_COLLECTION_TIME":
                return "text-red-500";
            case "WITHIN_30_MIN":
                return "text-orange-500";
            default:
                return "text-black";
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)("hr", { className: "mt-4" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Total", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat((_b = readyParcels === null || readyParcels === void 0 ? void 0 : readyParcels.length) !== null && _b !== void 0 ? _b : 0) })] })), (0, jsx_runtime_1.jsx)("hr", {}), readyParcels.map(function (x) {
                var _a, _b, _c, _d;
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex-1" }, { children: client.floating_fleet ?
                                        (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: x.code }) :
                                        (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: x.code, textColor: parcelMode(x), value: selectedParcels.find(function (parcel) { return parcel._id === x._id; }) ? true : false, onChange: function (v) {
                                                if (v === true) {
                                                    setSelectedParcels(__spreadArray([x], selectedParcels, true));
                                                }
                                                else {
                                                    var _selectedParcels = selectedParcels.filter(function (parcel) { return parcel._id !== x._id; });
                                                    setSelectedParcels(_selectedParcels);
                                                }
                                            } }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Pick up Location:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_a = x === null || x === void 0 ? void 0 : x.collection_address) === null || _a === void 0 ? void 0 : _a.full_address })] })), ((_b = x === null || x === void 0 ? void 0 : x.collection_time) === null || _b === void 0 ? void 0 : _b.actual) && (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Pick up Time:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_c = x === null || x === void 0 ? void 0 : x.collection_time) === null || _c === void 0 ? void 0 : _c.actual })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Drop Off:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_d = x === null || x === void 0 ? void 0 : x.delivery_address) === null || _d === void 0 ? void 0 : _d.full_address })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Status:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: x.status })] }))] }))] })), (0, jsx_runtime_1.jsx)("hr", {})] }), x._id));
            }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.PaginationControl, { currentPage: pagination.page, totalRecords: pagination.countTotal, recordsPerPage: 10, onClick: function (page) {
                        // console.log(page);
                        setCurrentPage(page);
                        dispatch((0, get_branch_parcels_thunk_1.get_branch_parcels_thunk)(__assign(__assign({}, filters), { branch_id: selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id, status: "READY", page: page })));
                    } }) }))] })));
};
exports.default = ParcelsComponent;
