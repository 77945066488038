"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var CondimentListView = function (props) {
    var _a;
    var product = props.product;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    if (((_a = product === null || product === void 0 ? void 0 : product.product) === null || _a === void 0 ? void 0 : _a.condiments.length) === 0) {
        return ((0, jsx_runtime_1.jsxs)("p", __assign({ className: "text-center text-gray-500 flex items-center justify-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faInfoCircle, className: "bg-blue-500 text-white rounded-full p-1" }), "No condiments were selected for the ", product === null || product === void 0 ? void 0 : product.title, " product!"] })));
    }
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ className: "p-4 overflow-y-auto max-h-[600px]" }, { children: product === null || product === void 0 ? void 0 : product.condiments.map(function (condiment, index) {
            return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "mb-6" }, { children: [(0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-xl font-bold mb-4" }, { children: condiment.title })), condiment.product_list.map(function (product, idx) {
                        var _a;
                        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center mb-2 gap-10 justify-between p-2 border rounded-lg shadow-sm" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product.title, size: "small", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_a = product === null || product === void 0 ? void 0 : product.price) !== null && _a !== void 0 ? _a : "0",
                                        symbolSeparation: true,
                                    }), size: "small", textColor: "text-black" })] }), idx));
                    })] }), index));
        }) })));
};
exports.default = CondimentListView;
