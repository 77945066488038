"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.trigger_stock_replacement_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var stock_replacement_1 = require("../stock_replacement");
var main_1 = require("../../main");
var trigger_webhook_1 = require("../../../../api/calls/webhook/trigger_webhook");
var get_scheduled_webhook_1 = require("../../../../api/calls/webhook/get_scheduled_webhook");
var get_order_1 = require("../../../../api/calls/order/get/get_order");
var orders_1 = require("../../orders/orders");
exports.trigger_stock_replacement_thunk = (0, toolkit_1.createAsyncThunk)("stock/trigger_stock_replacement_thunk", function (args, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var selectedOrder, isTriggered, response, _response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    selectedOrder = getState().orderState.selectedOrder;
                    isTriggered = getState().stockReplacementState.isTriggered;
                    if (isTriggered) {
                        return [2 /*return*/, { success: false, message: "This stock replacement has already been triggered!" }];
                    }
                    dispatch((0, stock_replacement_1.setIsLoading)(true));
                    return [4 /*yield*/, (0, trigger_webhook_1.trigger_webhook)(args)];
                case 1:
                    response = _b.sent();
                    if (!response.success) return [3 /*break*/, 4];
                    if (!args.data._id) return [3 /*break*/, 3];
                    return [4 /*yield*/, (0, get_scheduled_webhook_1.get_scheduled_webhook)({ topic: args.topic, process: args.process, "data._id": args.data._id })];
                case 2:
                    _response = _b.sent();
                    if (_response.success) {
                        dispatch((0, stock_replacement_1.setSelectedScheduledWebhook)(__assign({}, _response.content)));
                        (0, get_order_1.get_order)({ _id: selectedOrder._id }).then(function (order) {
                            dispatch((0, orders_1.setSelectedOrder)(order.content));
                        });
                        dispatch((0, stock_replacement_1.setIsTriggered)(true));
                    }
                    else {
                        dispatch((0, stock_replacement_1.setSelectedScheduledWebhook)(null));
                    }
                    _b.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong triggering this stock replacement!", messages: [response.message] }));
                    _b.label = 5;
                case 5:
                    dispatch((0, stock_replacement_1.setIsLoading)(false));
                    return [2 /*return*/, response];
            }
        });
    });
});
