"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkflowsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../../reducers/slices/console");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var routes_1 = require("../../../../../constants/routes");
var react_router_dom_1 = require("react-router-dom");
var constants_1 = require("./constants");
var main_1 = require("../../../../../reducers/slices/main");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var get_workflows_thunk_1 = require("../../../../../reducers/slices/workflow/thunks/get_workflows_thunk");
var delete_workflow_thunk_1 = require("../../../../../reducers/slices/workflow/thunks/delete_workflow_thunk");
var pages_1 = require("../../../../../constants/pages");
var WorkflowsPage = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), search = _a[0], setSearch = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.workflowState; }), workflows = _b.workflows, pagination = _b.pagination, isLoading = _b.isLoading;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Workflows"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_workflows_thunk_1.get_workflows_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearchWorkflows(search);
    }, [search]);
    var getWorkflows = function (query) { return dispatch((0, get_workflows_thunk_1.get_workflows_thunk)(query)); };
    var onSearchWorkflows = function (search_string) {
        getWorkflows(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })));
    };
    var onPageChange = function (page) {
        return getWorkflows(__assign(__assign({}, pagination), { page: page }));
    };
    var onRowsPerPageChange = function (size) {
        return getWorkflows(__assign(__assign({}, pagination), { size: size }));
    };
    var rowActions = [
        {
            label: "View",
            onClick: function (workflow) {
                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.WORKFLOW_DETAILS_ROUTE, "/").concat(workflow === null || workflow === void 0 ? void 0 : workflow._id));
            },
        },
        {
            label: "Delete",
            onClick: function (workflow) {
                dispatch((0, main_1.setContentModal)({
                    title: "Confirm deletion of '".concat(workflow === null || workflow === void 0 ? void 0 : workflow.name, "' workflow"),
                    content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Deleting this workflow will stop any existing and future runs of this flow.", textColor: "text-inputText", center: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you wish to continue?", textColor: "text-inputText", center: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row pt-4 gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () {
                                            dispatch((0, delete_workflow_thunk_1.delete_workflow_thunk)(workflow === null || workflow === void 0 ? void 0 : workflow._id));
                                        } })] }))] }))),
                }));
            },
        },
    ];
    var onRowClicked = function (workflow) {
        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.WORKFLOW_DETAILS_ROUTE, "/").concat(workflow === null || workflow === void 0 ? void 0 : workflow._id));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-end items-center gap-4 " }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Add Workflow", endIcon: pro_solid_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.WORKFLOW_DETAILS_ROUTE));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) {
                            setSearch(e);
                        } })] })), isLoading ? ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: constants_1.tableColumns, data: workflows, onRowClicked: onRowClicked, rowActions: rowActions, hasShadow: true, pagination: {
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig }))] })));
};
exports.WorkflowsPage = WorkflowsPage;
