"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReturnedProducts = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var update_product_status_1 = require("../../../../../api/calls/parcel/products/update/update_product_status");
var constants_1 = require("../../../../../constants/constants");
var main_1 = require("../../../../../reducers/slices/main");
var get_order_thunk_1 = require("../../../../../reducers/slices/orders/thunks/get_order_thunk");
var confirmation_modal_1 = require("./parcels/components/confirmation_modal");
var constants_2 = require("./parcels/constants");
var constants_3 = require("../constants");
var ReturnedProducts = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedOrder = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }).selectedOrder;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.parcelState; }), isLoadingParcels = _a.isLoadingParcels, parcels = _a.parcels;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var handleResponse = function (response) {
        if (response.success) {
            dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id, showLoader: false }));
            dispatch((0, main_1.setContentModal)(null));
        }
        else
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
    };
    var handleProductStatusUpdate = function (parcelId, product, status) {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: ((0, jsx_runtime_1.jsx)(confirmation_modal_1.ConfirmationModal, { message: "Are you sure you want to update product ".concat(product.title, " status to ").concat(status.toLocaleLowerCase(), "?"), callback: function (notes) { return updateProductStatus(parcelId, product._id, status, notes); } })),
        }));
    };
    var updateProductStatus = function (parcel_id, product_id, status, notes) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    return [4 /*yield*/, (0, update_product_status_1.update_product_status)({
                            product_id: product_id,
                            parcel_id: parcel_id,
                            status: status,
                            notes: notes,
                        })];
                case 1:
                    response = _a.sent();
                    handleResponse(response);
                    return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Returned Products", size: "small", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-9 w-full gap-4 mt-3" }, { children: [constants_2.returnedProductTableColumns.map(function (_a, index) {
                        var label = _a.label, width = _a.width;
                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, size: "small", textColor: "text-black", bold: true }) }), index));
                    }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 flex items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Actions", size: "small", textColor: "text-black", bold: true }) }))] })), parcels === null || parcels === void 0 ? void 0 : parcels.map(function (parcel, index) {
                var _a;
                return ((0, jsx_runtime_1.jsx)("div", { children: (_a = parcel.returned_product_list) === null || _a === void 0 ? void 0 : _a.map(function (product, index) {
                        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-9 w-full gap-4 mt-3 items-center" }, { children: [constants_2.returnedProductTableColumns.map(function (_a, i) {
                                    var _b, _c;
                                    var displayKey = _a.displayKey, width = _a.width;
                                    var label = displayKey === "code" ? parcel.code : (_b = product[displayKey]) !== null && _b !== void 0 ? _b : "-";
                                    if (displayKey === "price") {
                                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({
                                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                                    value: (_c = product === null || product === void 0 ? void 0 : product.price) !== null && _c !== void 0 ? _c : "0",
                                                    symbolSeparation: true,
                                                })), size: "xsmall", textColor: "text-black" }) }), i));
                                    }
                                    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, size: "xsmall", textColor: "text-black" }) }), i));
                                }), selectedOrder.status !== constants_3.StatusEnum.CANCELLED && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-2 flex items-center gap-4" }, { children: [(product === null || product === void 0 ? void 0 : product.status) === constants_3.StatusEnum.RETURNED && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faHandHoldingDollar, onClick: function () { return handleProductStatusUpdate(parcel === null || parcel === void 0 ? void 0 : parcel._id, product, constants_3.StatusEnum.REFUNDED); }, size: "xsmall", iconColor: "text-blue-600", backgroundColor: "bg-blue-200", shape: "circle", tooltip: {
                                                        label: "Refund Product",
                                                        placement: "right",
                                                        colors: {
                                                            backgroundColor: "bg-dark",
                                                            textColor: "text-white",
                                                        },
                                                    } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faReply, onClick: function () { return handleProductStatusUpdate(parcel === null || parcel === void 0 ? void 0 : parcel._id, product, constants_3.StatusEnum.REPLACED); }, size: "xsmall", iconColor: "text-green-600", backgroundColor: "bg-green-200", shape: "circle", tooltip: {
                                                        label: "Replace Product",
                                                        placement: "right",
                                                        colors: {
                                                            backgroundColor: "bg-dark",
                                                            textColor: "text-white",
                                                        },
                                                    } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faRepeat1Alt, onClick: function () { return handleProductStatusUpdate(parcel === null || parcel === void 0 ? void 0 : parcel._id, product, constants_3.StatusEnum.RE_ORDERED); }, size: "xsmall", iconColor: "text-yellow-600", backgroundColor: "bg-yellow-200", shape: "circle", tooltip: {
                                                        label: "Reorder Product",
                                                        placement: "right",
                                                        colors: {
                                                            backgroundColor: "bg-dark",
                                                            textColor: "text-white",
                                                        },
                                                    } })] })), ((product === null || product === void 0 ? void 0 : product.status) === constants_3.StatusEnum.REFUNDED || (product === null || product === void 0 ? void 0 : product.status) === constants_3.StatusEnum.RE_ORDERED || (product === null || product === void 0 ? void 0 : product.status) === constants_3.StatusEnum.REPLACED) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faInventory, onClick: function () { return handleProductStatusUpdate(parcel === null || parcel === void 0 ? void 0 : parcel._id, product, constants_3.StatusEnum.STOCK); }, size: "xsmall", iconColor: "text-blue-600", backgroundColor: "bg-blue-200", shape: "circle", tooltip: {
                                                        label: "Send to Stock",
                                                        placement: "right",
                                                        colors: {
                                                            backgroundColor: "bg-dark",
                                                            textColor: "text-white",
                                                        },
                                                    } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faTruck, onClick: function () { return handleProductStatusUpdate(parcel === null || parcel === void 0 ? void 0 : parcel._id, product, constants_3.StatusEnum.SUPPLIER); }, size: "xsmall", iconColor: "text-yellow-600", backgroundColor: "bg-yellow-200", shape: "circle", tooltip: {
                                                        label: "Send to Supplier",
                                                        placement: "right",
                                                        colors: {
                                                            backgroundColor: "bg-dark",
                                                            textColor: "text-white",
                                                        },
                                                    } })] }))] })))] }), index));
                    }) }, index));
            })] })));
};
exports.ReturnedProducts = ReturnedProducts;
