"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketPayfastGateway = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var doshx_controls_web_1 = require("doshx_controls_web");
var get_client_1 = require("../../../../api/calls/client/get_client");
var main_1 = require("../../../../reducers/slices/main");
var pay_with_payfast_1 = require("../../../../api/calls/payments/payment_engine/pay_with_payfast");
var routes_1 = require("../../../../constants/routes");
var BasketPayfastGateway = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var payFastFormRef = (0, react_1.useRef)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var _a = (0, react_1.useState)(), formData = _a[0], setFormData = _a[1];
    var clientID = params.clientID, basketID = params.basketID, userID = params.userID, name = params.name, surname = params.surname, cellNumber = params.cellNumber, amount = params.amount, itemName = params.itemName;
    var searchParams = (0, react_router_dom_1.useSearchParams)()[0];
    console.log(searchParams.get("type"));
    (0, react_1.useEffect)(function () {
        console.log("clientID  ==>", clientID);
        (0, get_client_1.get_client)(clientID).then(function (clientRes) {
            if (clientRes.success) {
                var _a = clientRes.content, token = _a.token, integration_settings = _a.integration_settings;
                localStorage.setItem("paymentEngineToken", integration_settings === null || integration_settings === void 0 ? void 0 : integration_settings.payment_engine_token);
                localStorage.setItem("clientToken", token);
                (0, pay_with_payfast_1.pay_with_payfast)({
                    integration_id: userID,
                    return_url: "".concat(process.env.LETS_TRADE_WEB_URL, "#").concat(routes_1.SUCCESS_BASKET_ROUTE, "/").concat(basketID, "/").concat(userID),
                    cancel_url: "".concat(process.env.LETS_TRADE_WEB_URL, "#").concat(routes_1.FAILED_BASKET_ROUTE, "/").concat(basketID, "/").concat(userID),
                    name_first: name,
                    name_last: surname,
                    cell_number: cellNumber,
                    amount: amount,
                    item_name: itemName,
                }, integration_settings === null || integration_settings === void 0 ? void 0 : integration_settings.payment_engine_token).then(function (payfastRes) {
                    if (payfastRes.success) {
                        var result = payfastRes.content.result;
                        setFormData(result);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [payfastRes.message] }));
                    }
                });
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [clientRes.message] }));
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        if (formData) {
            payFastFormRef.current.submit();
        }
    }, [formData]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "container mx-auto p-4 flex flex-col gap-4 " }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: formData && ((0, jsx_runtime_1.jsx)("form", __assign({ ref: payFastFormRef, action: process.env.PAYFAST_FORM_URL, method: "post" //
                    , className: "hidden" }, { children: Object.keys(formData).map(function (key, index) {
                        return (0, jsx_runtime_1.jsx)("input", { type: "hidden", name: key, value: formData[key] }, "form-hidden-field-".concat(index));
                    }) }))) }))] })));
};
exports.BasketPayfastGateway = BasketPayfastGateway;
