"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchesPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var console_1 = require("../../../../../reducers/slices/console");
var branch_1 = require("./branch");
var branches_table_1 = require("./branches_table");
var BranchesPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Branches"));
    }, []);
    return ((0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(branches_table_1.BranchesTablePage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.BRANCH_DETAILS_ROUTE, "/:branchId?"), element: (0, jsx_runtime_1.jsx)(branch_1.BranchDetailsPage, {}) })] }));
};
exports.BranchesPage = BranchesPage;
