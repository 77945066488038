"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("./constants");
var react_redux_1 = require("react-redux");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var main_1 = require("../../../../../reducers/slices/main");
var styles_tw_1 = require("../../../../../constants/styles_tw");
var MainProductVariationsComponent = function (_a) {
    var product = _a.product, params = _a.params, setParams = _a.setParams, onSave = _a.onSave;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(product === null || product === void 0 ? void 0 : product.title, " Variant"), bold: true, textColor: "text-black", size: "medium" }), product === null || product === void 0 ? void 0 : product.variations.filter(function (variation) { return (variation === null || variation === void 0 ? void 0 : variation.selected) === true; }).map(function (selectedVariation, idx) {
                var _a, _b;
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col rounded-md" }, { children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(styles_tw_1.styles_tw === null || styles_tw_1.styles_tw === void 0 ? void 0 : styles_tw_1.styles_tw.table, " items-center") }, { children: constants_1.productTableColumns === null || constants_1.productTableColumns === void 0 ? void 0 : constants_1.productTableColumns.map(function (_a, i) {
                                    var _b, _c;
                                    var displayKey = _a.displayKey;
                                    switch (displayKey) {
                                        case "category":
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "break-words items-start" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat((_b = product === null || product === void 0 ? void 0 : product.category) === null || _b === void 0 ? void 0 : _b.name), size: "xsmall", textColor: "text-black", className: "break-words" }) }), i));
                                        case "image":
                                            return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: selectedVariation === null || selectedVariation === void 0 ? void 0 : selectedVariation.image_url }, i);
                                        case "stock":
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "break-words items-start" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "YES", size: "xsmall", textColor: "text-black", className: "break-words" }) }), i));
                                        case "barcode":
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "break-words items-start" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(product === null || product === void 0 ? void 0 : product.barcode), size: "xsmall", textColor: "text-black", className: "break-words" }) }), i));
                                        case "quantity":
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "items-center" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-16 h-10" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: (_c = selectedVariation.quantity) !== null && _c !== void 0 ? _c : 1, size: "small", type: "number", onChange: function (v) {
                                                            var updatedVariation = __assign(__assign({}, selectedVariation), { quantity: v });
                                                            var updatedVariations = product === null || product === void 0 ? void 0 : product.variations.map(function (item) {
                                                                if (item._id === selectedVariation._id) {
                                                                    return updatedVariation;
                                                                }
                                                                return item;
                                                            });
                                                            var updatedProducts = params === null || params === void 0 ? void 0 : params.product_list.map(function (item) {
                                                                if (item._id === product._id) {
                                                                    return __assign(__assign({}, item), { variations: updatedVariations });
                                                                }
                                                                return item;
                                                            });
                                                            setParams(__assign(__assign({}, params), { product_list: __spreadArray([], updatedProducts, true) }));
                                                        } }) })) }), i));
                                        case "price":
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "break-words items-start" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                                        value: ((selectedVariation === null || selectedVariation === void 0 ? void 0 : selectedVariation.quantity)
                                                            ? parseFloat(selectedVariation.price) * parseInt(selectedVariation.quantity)
                                                            : parseFloat(selectedVariation.price)).toString(),
                                                        symbolSeparation: true,
                                                    }), size: "xsmall", textColor: "text-black", className: "break-words" }) }), i));
                                        default:
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "break-words items-start" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: selectedVariation[displayKey], size: "xsmall", textColor: "text-black", className: "break-words" }) }), i));
                                    }
                                }) })) }), ((_a = selectedVariation === null || selectedVariation === void 0 ? void 0 : selectedVariation.selected_add_ons) === null || _a === void 0 ? void 0 : _a.length) > 0 && (
                        // <div className="border-2 border-rose-500 rounded-md">
                        (0, jsx_runtime_1.jsx)("div", __assign({ className: "rounded-md" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.AccordionControl, { addHeaderPadding: false, addContentPadding: false, arrowSide: "right", collapsePreviousItems: true, items: [
                                    {
                                        header: (0, jsx_runtime_1.jsx)("div", __assign({ className: "bg-black text-white bold pl-2" }, { children: "Variation Add Ons" })),
                                        content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "divide-y" }, { children: (_b = selectedVariation === null || selectedVariation === void 0 ? void 0 : selectedVariation.selected_add_ons) === null || _b === void 0 ? void 0 : _b.map(function (addon, idx) {
                                                var _a, _b;
                                                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "".concat(styles_tw_1.styles_tw === null || styles_tw_1.styles_tw === void 0 ? void 0 : styles_tw_1.styles_tw.table, " items-center py-1") }, { children: [(0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: addon === null || addon === void 0 ? void 0 : addon.image_url }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: addon === null || addon === void 0 ? void 0 : addon.title, size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_a = addon === null || addon === void 0 ? void 0 : addon.category) === null || _a === void 0 ? void 0 : _a.name, size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: addon === null || addon === void 0 ? void 0 : addon.barcode, size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "YES", size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-16" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: (_b = addon.quantity) !== null && _b !== void 0 ? _b : 1, size: "small", type: "number", onChange: function (v) {
                                                                    var quantity = 1;
                                                                    if (v === "") {
                                                                        quantity = Math.max(parseInt(v), 1); // Ensure the quantity is at least 1
                                                                    }
                                                                    quantity = Math.max(parseInt(v), 1); // Ensure the quantity is at least 1
                                                                    // update the quantity of the addon.
                                                                    var updatedAddOn = __assign(__assign({}, addon), { quantity: String(quantity) });
                                                                    var updatedAddOns = selectedVariation.selected_add_ons.map(function (item) {
                                                                        if (item._id === addon._id) {
                                                                            return updatedAddOn;
                                                                        }
                                                                        return item;
                                                                    });
                                                                    var updatedVariations = product === null || product === void 0 ? void 0 : product.variations.map(function (variation) {
                                                                        if (variation._id === selectedVariation._id) {
                                                                            return __assign(__assign({}, variation), { selected_add_ons: updatedAddOns });
                                                                        }
                                                                        return variation;
                                                                    });
                                                                    var updatedProducts = params === null || params === void 0 ? void 0 : params.product_list.map(function (item) {
                                                                        if (item._id === product._id) {
                                                                            return __assign(__assign({}, item), { variations: updatedVariations });
                                                                        }
                                                                        return item;
                                                                    });
                                                                    setParams(__assign(__assign({}, params), { product_list: updatedProducts }));
                                                                    onSave(updatedProducts);
                                                                } }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { size: "xsmall", textColor: "text-black", className: "break-words", label: doshx_controls_web_1.Utilities.formatMoney({
                                                                symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                                                value: (addon === null || addon === void 0 ? void 0 : addon.quantity) ? (addon === null || addon === void 0 ? void 0 : addon.price) * (addon === null || addon === void 0 ? void 0 : addon.quantity) : addon === null || addon === void 0 ? void 0 : addon.price,
                                                                symbolSeparation: true,
                                                            }) }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTrash, className: "text-white border-transparent p-1 rounded-md", onClick: function () {
                                                                    dispatch((0, main_1.setContentModal)({
                                                                        title: "Confirm",
                                                                        content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to remove ".concat(addon === null || addon === void 0 ? void 0 : addon.title, " variant add on ?"), textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () {
                                                                                                var updatedVariations = product === null || product === void 0 ? void 0 : product.variations.map(function (variation) {
                                                                                                    if (variation._id === selectedVariation._id) {
                                                                                                        var updatedAddOns = variation.selected_add_ons.filter(function (item) { return item._id !== addon._id; });
                                                                                                        return __assign(__assign({}, variation), { selected_add_ons: updatedAddOns });
                                                                                                    }
                                                                                                    return variation;
                                                                                                });
                                                                                                var updatedProducts = params === null || params === void 0 ? void 0 : params.product_list.map(function (item) {
                                                                                                    if (item._id === product._id) {
                                                                                                        return __assign(__assign({}, item), { variations: updatedVariations });
                                                                                                    }
                                                                                                    return item;
                                                                                                });
                                                                                                setParams(__assign(__assign({}, params), { product_list: updatedProducts }));
                                                                                                dispatch((0, main_1.setContentModal)(null));
                                                                                            } })] }))] }))),
                                                                    }));
                                                                } }) }))] }), idx));
                                            }) }))),
                                        expanded: true,
                                    },
                                ], colors: {
                                    borderColor: "border-transparent",
                                    titleTextColor: "text-red-500",
                                    titleBackgroundColor: "bg-white",
                                    contentTextColor: "text-orange-500",
                                    contentBackgroundColor: "bg-white",
                                } }) })))] }), idx));
            })] }));
};
exports.default = MainProductVariationsComponent;
