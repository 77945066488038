"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsLoading = exports.setTermStorage = exports.setSearches = exports.setOverview = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var _devMode = process.env.NODE_ENV !== "production";
var initialState = {
    overview: {},
    searches: [],
    termStorage: [],
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "searchTerms",
    initialState: initialState,
    reducers: {
        setOverview: function (state, action) {
            state.overview = action.payload;
        },
        setSearches: function (state, action) {
            state.searches = action.payload;
        },
        setTermStorage: function (state, action) {
            state.termStorage = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
    },
});
exports.setOverview = (_a = slice.actions, _a.setOverview), exports.setSearches = _a.setSearches, exports.setTermStorage = _a.setTermStorage, exports.setIsLoading = _a.setIsLoading;
exports.default = slice.reducer;
