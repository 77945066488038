"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomersPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../reducers/slices/console");
var routes_1 = require("../../../../constants/routes");
var customers_table_1 = require("./customers_table");
var customer_profile_1 = require("./customer_profile");
var CustomersPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)(); //<AppDispatch>
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Customers"));
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(customers_table_1.CustomersTablePage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.CUSTOMER_PROFILE_ROUTE, "/:id"), element: (0, jsx_runtime_1.jsx)(customer_profile_1.CustomerProfilePage, {}) })] }) }));
};
exports.CustomersPage = CustomersPage;
