"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.daysOfTheWeek = void 0;
exports.daysOfTheWeek = [
    {
        id: 0,
        day: "Sunday",
    },
    {
        id: 1,
        day: "Monday",
    },
    {
        id: 2,
        day: "Tuesday",
    },
    {
        id: 3,
        day: "Wednesday",
    },
    {
        id: 4,
        day: "Thursday",
    },
    {
        id: 5,
        day: "Friday",
    },
    {
        id: 6,
        day: "Saturday",
    },
    {
        id: 7,
        day: "Public Holidays",
    },
];
// TODO: Add more constants here as needed.
