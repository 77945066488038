"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.onConnect = exports.onEdgesChange = exports.onNodesChange = exports.setErrors = exports.setPagination = exports.setIsEditing = exports.setIsLoading = exports.setNewNode = exports.setNode = exports.setWorkflows = exports.setEdges = exports.setNodes = exports.clearSelectedWorkflow = exports.setSelectedWorkflow = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var reactflow_1 = require("reactflow");
var initialState = {
    selectedWorkflow: { name: "", status: "DRAFT" },
    workflows: [],
    newNode: null,
    pagination: pages_1.initialPaginationValues,
    isLoading: false,
    isEditing: false,
    isEdited: null,
    nodes: [],
    edges: [],
    errors: {},
};
var slice = (0, toolkit_1.createSlice)({
    name: "workflowState",
    initialState: initialState,
    reducers: {
        setSelectedWorkflow: function (state, action) {
            var _a = action.payload, nodes = _a.nodes, edges = _a.edges, workflow = __rest(_a, ["nodes", "edges"]);
            state.selectedWorkflow = workflow;
            nodes.forEach(function (node) {
                node.data.sources = edges.filter(function (edge) { return edge.target === node.id; }).map(function (edge) { return edge.source; });
                node.data.targets = edges.filter(function (edge) { return edge.source === node.id; }).map(function (edge) { return edge.target; });
            });
            state.nodes = nodes;
            state.edges = edges;
        },
        clearSelectedWorkflow: function (state) {
            state.selectedWorkflow = { name: "", status: "DRAFT" };
            state.isEdited = false;
            state.nodes = [];
            state.edges = [];
        },
        setNodes: function (state, action) {
            state.nodes = action.payload;
        },
        setEdges: function (state, action) {
            state.edges = action.payload;
        },
        onNodesChange: function (state, action) {
            state.nodes = (0, reactflow_1.applyNodeChanges)(action.payload, state.nodes);
            if (action.payload.some(function (node) { return ["remove", "add", "reset"].includes(node.type); })) {
                state.isEdited = true;
            }
        },
        onEdgesChange: function (state, action) {
            var removed_edge_changes = action.payload.filter(function (edge) { return edge.type === "remove"; }).map(function (edge) { return edge.id; });
            if (removed_edge_changes.length > 0) {
                var removed_edges_1 = state.edges.filter(function (edge) { return removed_edge_changes.includes(edge.id); });
                state.nodes.forEach(function (node) {
                    var _a, _b;
                    node.data.sources = (_a = node.data.sources) === null || _a === void 0 ? void 0 : _a.filter(function (source) { return !removed_edges_1.some(function (edge) { return edge.source === source; }); });
                    node.data.targets = (_b = node.data.targets) === null || _b === void 0 ? void 0 : _b.filter(function (target) { return !removed_edges_1.some(function (edge) { return edge.target === target; }); });
                });
            }
            state.edges = (0, reactflow_1.applyEdgeChanges)(action.payload, state.edges);
            if (action.payload.some(function (edge) { return ["remove", "add", "reset"].includes(edge.type); })) {
                state.isEdited = true;
            }
        },
        onConnect: function (state, action) {
            var _a, _b, _c, _d, _e, _f;
            var _g, _h;
            state.edges = (0, reactflow_1.addEdge)(action.payload, state.edges);
            var sourceNode = state.nodes.find(function (node) { return node.id === action.payload.source; });
            var targetNode = state.nodes.find(function (node) { return node.id === action.payload.target; });
            if (sourceNode && !((_b = (_a = sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data) === null || _a === void 0 ? void 0 : _a.targets) === null || _b === void 0 ? void 0 : _b.includes(action.payload.target))) {
                ((_c = (_g = sourceNode.data).targets) !== null && _c !== void 0 ? _c : (_g.targets = [])).push(action.payload.target);
            }
            if (targetNode && !((_e = (_d = targetNode === null || targetNode === void 0 ? void 0 : targetNode.data) === null || _d === void 0 ? void 0 : _d.sources) === null || _e === void 0 ? void 0 : _e.includes(action.payload.source))) {
                ((_f = (_h = targetNode.data).sources) !== null && _f !== void 0 ? _f : (_h.sources = [])).push(action.payload.source);
            }
        },
        setNode: function (state, action) {
            var _a = action.payload, id = _a.id, data = _a.data, errors = _a.errors, edit = _a.edit;
            var node = state.nodes.find(function (node) { return node.id === id; });
            if (node) {
                node.data = data;
                if (errors)
                    node.errors = errors;
            }
            if (edit !== false) {
                state.isEdited = true;
            }
        },
        setWorkflows: function (state, action) {
            state.workflows = action.payload;
        },
        setNewNode: function (state, action) {
            state.newNode = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEditing: function (state, action) {
            state.isEditing = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setErrors: function (state, action) {
            var _a = action.payload, id = _a.id, errors = _a.errors;
            state.errors[id] = errors;
        },
    },
});
exports.setSelectedWorkflow = (_a = slice.actions, _a.setSelectedWorkflow), exports.clearSelectedWorkflow = _a.clearSelectedWorkflow, exports.setNodes = _a.setNodes, exports.setEdges = _a.setEdges, exports.setWorkflows = _a.setWorkflows, exports.setNode = _a.setNode, exports.setNewNode = _a.setNewNode, exports.setIsLoading = _a.setIsLoading, exports.setIsEditing = _a.setIsEditing, exports.setPagination = _a.setPagination, exports.setErrors = _a.setErrors, exports.onNodesChange = _a.onNodesChange, exports.onEdgesChange = _a.onEdgesChange, exports.onConnect = _a.onConnect;
exports.default = slice.reducer;
