"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var create_trip_thunk_1 = require("../../../../../reducers/slices/trips/thunks/create_trip_thunk");
var CreateTripComponent = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var setSelectedParcels = props.setSelectedParcels, setSelectedAvailableDriver = props.setSelectedAvailableDriver, selectedParcels = props.selectedParcels, selectedAvailableDriver = props.selectedAvailableDriver;
    (0, react_1.useEffect)(function () { }, []);
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "shadow-md flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Selected Parcels", bold: true, textColor: "text-black" }), selectedParcels.map(function (x) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between p-4 rounded-md border" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: x.code })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-primary ml-8" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: function () {
                                                    var _selectedParcels = selectedParcels.filter(function (parcel) { return parcel._id !== x._id; });
                                                    setSelectedParcels(_selectedParcels);
                                                }, icon: pro_light_svg_icons_1.faTrash }) })) }))] }))); })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Selected Driver", bold: true, textColor: "text-black" }), selectedAvailableDriver.name && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Driver:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(selectedAvailableDriver === null || selectedAvailableDriver === void 0 ? void 0 : selectedAvailableDriver.name, " ").concat(selectedAvailableDriver === null || selectedAvailableDriver === void 0 ? void 0 : selectedAvailableDriver.surname) })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Mobile:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: selectedAvailableDriver === null || selectedAvailableDriver === void 0 ? void 0 : selectedAvailableDriver.cell_number })] }))] })))] }))] })), selectedParcels.length > 0 && selectedAvailableDriver.name && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Create trip", 
                    //  endIcon={faPlus}
                    size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Confirm!",
                            messages: ["Please confirm to conclude assigning the trip to the driver"],
                            buttons: [
                                {
                                    label: "Cancel",
                                    backgroundColor: "bg-white",
                                    textColor: "text-primary",
                                    borderColor: "border-primary",
                                    onClick: function () {
                                        dispatch((0, main_1.setMessageModal)(null));
                                    },
                                },
                                {
                                    label: "Confirm",
                                    loading: false,
                                    onClick: function () {
                                        dispatch((0, create_trip_thunk_1.create_trip_thunk)({
                                            driver_id: selectedAvailableDriver._id,
                                            parcel_id_list: selectedParcels.map(function (x) { return x._id; }),
                                        }));
                                        setSelectedParcels([]);
                                        setSelectedAvailableDriver({});
                                    },
                                },
                            ],
                        }));
                    } }) })))] })));
};
exports.default = CreateTripComponent;
