"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchDetailsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var get_branch_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/get_branch_thunk");
var console_1 = require("../../../../../reducers/slices/console");
var branch_trading_times_1 = require("./components/branch_trading_times");
var delivery_settings_1 = require("./components/delivery_settings");
var general_details_1 = require("./components/general_details");
var stock_management_1 = require("./components/stock_management");
var constants_1 = require("../../../../../constants/constants");
var BranchDetailsPage = function () {
    var _a;
    var params = (0, react_router_dom_1.useParams)();
    var branchId = params.branchId;
    var tabRef = (0, react_1.useRef)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_1.useState)(0), tabIndex = _b[0], setTabIndex = _b[1];
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Branch Details"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (branchId) {
            dispatch((0, get_branch_thunk_1.get_branch_thunk)(branchId));
        }
    }, [branchId]);
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-white" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ ref: tabRef, className: "grid grid-cols-1" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex pb-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "xsmall", startIcon: pro_light_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BRANCHES_ROUTE));
                        } }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TabControl, { selectedTabIndex: tabIndex, colors: {
                        backgroundColor: "bg-transparent",
                        tabs: {
                            indicatorColor: "bg-primary",
                            textColor: "text-black",
                            backgroundColor: "bg-borders",
                            selectedBackgroundColor: "bg-tableBackgroundColor",
                            unselectedBackgroundColor: "bg-borders",
                        },
                    }, tabs: [
                        {
                            title: "General Details",
                            content: (0, jsx_runtime_1.jsx)(general_details_1.GeneralDetailsPage, { tabRef: tabRef, setTabIndex: setTabIndex }),
                            hidden: false,
                        },
                        {
                            title: "Trading Hours & Extra Data",
                            content: (0, jsx_runtime_1.jsx)(branch_trading_times_1.BranchTradingTimesPage, {}),
                            hidden: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id) ? false : true,
                        },
                        {
                            title: "Delivery Settings",
                            content: (0, jsx_runtime_1.jsx)(delivery_settings_1.DeliverySettingsPage, {}),
                            hidden: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id) ? false : true,
                        },
                        ((_a = client === null || client === void 0 ? void 0 : client.stock_manager) === null || _a === void 0 ? void 0 : _a.method) === constants_1.BRANCH && {
                            title: "Stock Management",
                            content: (0, jsx_runtime_1.jsx)(stock_management_1.StockManagementPage, {}),
                            hidden: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id) ? false : true,
                        },
                    ].filter(Boolean) })] })) })));
};
exports.BranchDetailsPage = BranchDetailsPage;
