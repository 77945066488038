"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CHANNELS = exports.STATUS = exports.PRODUCT_AVAILABILITY = exports.columns = exports.INITIAL_VISIBLE_COLUMNS = exports.statusColorMap = exports.statusOptions = void 0;
var danger_circle_1 = require("../../../../../components/icons/danger-circle");
var default_circle_1 = require("../../../../../components/icons/default-circle");
var success_circle_1 = require("../../../../../components/icons/success-circle");
var warning_circle_1 = require("../../../../../components/icons/warning-circle");
exports.statusOptions = [
    { label: "Active", value: "ACTIVE" },
    { label: "Completed", value: "COMPLETED" },
    { label: "Fulfilled", value: "FULFILLED" },
    { label: "Processed", value: "PROCESSED" },
    { label: "Ordered", value: "ORDERED" },
    { label: "Ready", value: "READY" },
    { label: "Arrived", value: "ARRIVED" },
    { label: "Delivered", value: "DELIVERED" },
    { label: "Online", value: "ONLINE" },
    { label: "Available", value: "AVAILABLE" },
    { label: "Complete", value: "COMPLETE" },
    { label: "Success", value: "SUCCESS" },
    { label: "Accepted", value: "ACCEPTED" },
];
exports.statusColorMap = {
    ACTIVE: default_circle_1.DefaultCircleSvg,
    COMPLETED: default_circle_1.DefaultCircleSvg,
    FULFILLED: success_circle_1.SuccessCircleSvg,
    PROCESSED: warning_circle_1.WarningCircleSvg,
    ORDERED: success_circle_1.SuccessCircleSvg,
    READY: default_circle_1.DefaultCircleSvg,
    ARRIVED: success_circle_1.SuccessCircleSvg,
    DELIVERED: success_circle_1.SuccessCircleSvg,
    ONLINE: success_circle_1.SuccessCircleSvg,
    AVAILABLE: default_circle_1.DefaultCircleSvg,
    COMPLETE: danger_circle_1.DangerCircleSvg,
    SUCCESS: success_circle_1.SuccessCircleSvg,
    ACCEPTED: success_circle_1.SuccessCircleSvg,
};
exports.INITIAL_VISIBLE_COLUMNS = [
    "status",
    "channels",
    "on_sale_price",
    "on_sale",
    "price",
    "category",
    "title",
    "pid",
    "sku",
    "image_url",
    "actions"
];
exports.columns = [
    { name: "Image", uid: "image_url" },
    { name: "SKU", uid: "sku" },
    { name: "PID", uid: "pid" },
    { name: "Title", uid: "title" },
    { name: "Price", uid: "price" },
    { name: "Category", uid: "category" },
    { name: "On Sale Price", uid: "on_sale_price" },
    { name: "On Sale", uid: "on_sale" },
    { name: "Channels", uid: "channels" },
    { name: "Status", uid: "status" },
    { name: "Actions", uid: "actions" }
];
exports.PRODUCT_AVAILABILITY = [
    { label: "Offline Products", value: "OFFLINE" },
    { label: "Online Products", value: "ONLINE" },
];
exports.STATUS = [
    { label: "Active", value: "ACTIVE" },
    { label: "Available", value: "AVAILABLE" },
    { label: "Ready", value: "READY" },
    { label: "Online", value: "ONLINE" },
    { label: "Draft", value: "DRAFT" },
];
exports.CHANNELS = [
    { label: "Arch Store", value: "ARCH_STORE" },
    { label: "Shopify", value: "SHOPIFY" },
];
