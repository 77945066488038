"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("../constants");
var react_redux_1 = require("react-redux");
var get_all_scan_scripts_thunk_1 = require("../../../../../../reducers/slices/scanScript/thunks/get_all_scan_scripts_thunk");
var pages_1 = require("../../../../../../constants/pages");
var branch_list_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
function TableFiltersComponent(props) {
    var filters = props.filters, setFilters = props.setFilters;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), branches = _a.branches, branchPagination = _a.pagination;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _getBranchList = function (params) {
        if (params === void 0) { params = {}; }
        return dispatch((0, branch_list_thunk_1.branch_list_thunk)(__assign(__assign({}, branchPagination), params)));
    };
    var _onSearchBranches = function (search_string) { return _getBranchList(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string }))); };
    var _branches = branches.filter(function (x) {
        var _a;
        if (user.role === "ADMIN") {
            return true;
        }
        else {
            return (_a = user.manager_of) === null || _a === void 0 ? void 0 : _a.includes(x._id);
        }
    });
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 " }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-32 bg-white rounded-md" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Status", required: true, options: constants_1.DROPDOWN_STATUSES, labelColor: "text-black", textColor: "text-black", borderColor: "border-white", iconColor: "text-black", size: "small", value: filters.status, errorText: "", onChange: function (v) {
                        setFilters(__assign(__assign({}, filters), { status: v }));
                        var filter = __assign(__assign({}, filters), { status: v });
                        dispatch((0, get_all_scan_scripts_thunk_1.get_all_scan_scripts_thunk)(filter));
                    } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-32 bg-white rounded-md" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { label: "Branch", required: true, options: __spreadArray(__spreadArray([], _branches.map(function (x) {
                        return __assign({ label: x.display_name, value: x._id }, x);
                    }), true), [
                        constants_1.ALL,
                    ], false), renderDisplay: function (item) { return item.label; }, colors: {
                        labelColor: "text-black",
                        textColor: "text-black",
                        borderColor: "border-white",
                        iconColor: "text-black",
                    }, size: "small", value: branches === null || branches === void 0 ? void 0 : branches.find(function (x) { return x._id === filters.branch_id; }), errorText: "", onSearchChanged: _onSearchBranches, onChange: function (data) {
                        console.log("v ===>", data);
                        if (data.value === "") {
                            setFilters(__assign(__assign({}, filters), { branch_id_list: user.manager_of, branch_id: null }));
                            var filter = __assign(__assign({}, filters), { branch_id_list: user.manager_of, branch_id: null });
                            dispatch((0, get_all_scan_scripts_thunk_1.get_all_scan_scripts_thunk)(filter));
                        }
                        else {
                            setFilters(__assign(__assign({}, filters), { branch_id: data.value, branch_id_list: null }));
                            var filter = __assign(__assign({}, filters), { branch_id: data.value, branch_id_list: null });
                            dispatch((0, get_all_scan_scripts_thunk_1.get_all_scan_scripts_thunk)(filter));
                        }
                    } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-32 bg-white rounded-md py-2 text-center cursor-pointer" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.PopoverControl, __assign({ content: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 bg-white border-borders rounded-md" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { label: "From Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: new Date(filters.from_date), onChange: function (v) {
                                    setFilters(__assign(__assign({}, filters), { from_date: v.toISOString().split("T")[0] }));
                                }, size: "small" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { label: "To Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: new Date(filters.to_date), onChange: function (v) {
                                    setFilters(__assign(__assign({}, filters), { to_date: v.toISOString().split("T")[0] }));
                                }, size: "small" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { label: "apply", size: "xsmall", onClick: function () {
                                        dispatch((0, get_all_scan_scripts_thunk_1.get_all_scan_scripts_thunk)(filters));
                                    } }) }))] })), behavior: "click" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Date Filter" }) })) }))] })));
}
exports.default = TableFiltersComponent;
