"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmationModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../../reducers/slices/main");
var ConfirmationModal = function (_a) {
    var message = _a.message, callback = _a.callback;
    var _b = (0, react_1.useState)(""), notes = _b[0], setNotes = _b[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: message, size: "medium", textColor: "text-inputText" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-auto" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Attach a note (optional)", value: notes, onChange: setNotes, backgroundColor: "bg-gray-200", borderColor: "border-transparent", lines: 3, size: "small" }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-2 flex-row" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Yes, Continue", size: "small", onClick: function () { return callback(notes); } })] }))] })));
};
exports.ConfirmationModal = ConfirmationModal;
