"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTrip = exports.removeTripParcel = exports.setTripPagination = exports.setLoading = exports.deleteTrip = exports.editTrip = exports.updateTrips = exports.setTrips = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    trips: [],
    trip: {},
    loading: false,
    tripPagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "trip",
    initialState: initialState,
    reducers: {
        setTrips: function (state, action) {
            state.trips = action.payload;
        },
        setTrip: function (state, action) {
            state.trip = action.payload;
        },
        updateTrips: function (state, action) {
            state.trips.unshift(action.payload);
        },
        editTrip: function (state, action) {
            state.trips = state.trips.map(function (x) {
                if (x._id === action.payload._id) {
                    return __assign(__assign({}, x), action.payload);
                }
                return x;
            });
        },
        removeTripParcel: function (state, action) {
            state.trips = state.trips.map(function (x) {
                if (x._id === action.payload._id && action.payload.parcel_id_list.length > 0) {
                    return __assign(__assign({}, x), { parcel_list: x.parcel_list.filter(function (x) { return x._id !== action.payload.parcel_id_list[0]; }), parcel_id_list: x.parcel_id_list.filter(function (x) { return x !== action.payload.parcel_id_list[0]; }) });
                }
                return x;
            });
        },
        deleteTrip: function (state, action) {
            state.trips.filter(function (x) { return x._id !== action.payload; });
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setTripPagination: function (state, action) {
            state.tripPagination = action.payload;
        },
    },
});
exports.setTrips = (_a = slice.actions, _a.setTrips), exports.updateTrips = _a.updateTrips, exports.editTrip = _a.editTrip, exports.deleteTrip = _a.deleteTrip, exports.setLoading = _a.setLoading, exports.setTripPagination = _a.setTripPagination, exports.removeTripParcel = _a.removeTripParcel, exports.setTrip = _a.setTrip;
exports.default = slice.reducer;
