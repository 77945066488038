"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFrontDeploymentDetails = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var store_front_build_1 = require("./store_front_build");
var store_front_details_1 = require("./store_front_details");
var store_front_domain_1 = require("./store_front_domain");
var StoreFrontDeploymentDetails = function () {
    var _a;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState; }), selectedEnvironment = _b.selectedEnvironment, environments = _b.environments;
    var productionIsReady = !!((_a = environments === null || environments === void 0 ? void 0 : environments.production) === null || _a === void 0 ? void 0 : _a.url);
    var environment = environments[selectedEnvironment];
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [productionIsReady && (0, jsx_runtime_1.jsx)(store_front_domain_1.StoreFrontDomain, {}), selectedEnvironment && (0, jsx_runtime_1.jsx)(store_front_build_1.StoreFrontBuild, { name: selectedEnvironment, environment: environment }), (0, jsx_runtime_1.jsx)(store_front_details_1.StoreFrontDetails, {})] }));
};
exports.StoreFrontDeploymentDetails = StoreFrontDeploymentDetails;
