"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var upload_file_1 = require("../../../../../api/calls/media/upload_file");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var console_1 = require("../../../../../reducers/slices/console");
var gift_1 = require("../../../../../reducers/slices/gift/gift");
var add_gift_categories_thunk_1 = require("../../../../../reducers/slices/gift/thunks/add_gift_categories_thunk");
var get_gift_categories_thunk_1 = require("../../../../../reducers/slices/gift/thunks/get_gift_categories_thunk");
var get_gift_category_thunk_1 = require("../../../../../reducers/slices/gift/thunks/get_gift_category_thunk");
var update_gift_categories_thunk_1 = require("../../../../../reducers/slices/gift/thunks/update_gift_categories_thunk");
var main_1 = require("../../../../../reducers/slices/main");
var constants_1 = require("./constants");
var GiftCategoryPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var giftCategoryId = (0, react_router_dom_1.useParams)().giftCategoryId;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), searchGiftCategories = _a[0], setSearchGiftCategories = _a[1];
    var _b = react_1.default.useState(false), isGiftCardImageLoading = _b[0], setIsGiftCardImageLoading = _b[1];
    var _c = react_1.default.useState(0), giftCardImageIndex = _c[0], setGiftCardImageIndex = _c[1];
    var _d = react_1.default.useState(false), isInteracted = _d[0], setIsInteracted = _d[1];
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.giftState; }), giftCategories = _e.giftCategories, giftCategoriesPagination = _e.giftCategoriesPagination, selectedGiftCategory = _e.selectedGiftCategory, isLoading = _e.isLoading;
    var _f = (0, validateForm_1.validateForm)(selectedGiftCategory, {
        validationConfig: constants_1.validationConfig,
        initialValues: gift_1.initialGiftCategoryValues,
        enabled: isInteracted,
    }), errors = _f.errors, isValid = _f.isValid;
    var handleSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, _id, gift_card_images, name_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(selectedGiftCategory === null || selectedGiftCategory === void 0 ? void 0 : selectedGiftCategory._id)) return [3 /*break*/, 1];
                    _id = selectedGiftCategory._id, gift_card_images = selectedGiftCategory.gift_card_images, name_1 = selectedGiftCategory.name;
                    response = dispatch((0, update_gift_categories_thunk_1.update_gift_categories_thunk)({ _id: _id, gift_card_images: gift_card_images, name: name_1 }));
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, dispatch((0, add_gift_categories_thunk_1.add_gift_categories_thunk)(selectedGiftCategory))];
                case 2:
                    response = _c.sent();
                    _c.label = 3;
                case 3:
                    if ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.success)
                        navigate(-1);
                    else
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong.", messages: [(_b = response.payload) === null || _b === void 0 ? void 0 : _b.message] }));
                    return [2 /*return*/];
            }
        });
    }); };
    var _setGiftCategory = function (value) {
        dispatch((0, gift_1.setSelectedGiftCategory)(__assign(__assign({}, selectedGiftCategory), value)));
        if (!isInteracted)
            setIsInteracted(true);
    };
    var uploadImage = function (file, file_name) {
        setIsGiftCardImageLoading(true);
        (0, upload_file_1.upload_file)({
            file: file,
            file_name: file_name,
            description: "Letstrade Gift Category Image",
            type: "IMAGE",
            category: "GIFT-CATEGORY",
        }).then(function (res) {
            if (res.success)
                _setGiftCategory({
                    gift_card_images: __spreadArray(__spreadArray([], selectedGiftCategory.gift_card_images, true), [{ url: res.content.location, etag: res.content.etag }], false)
                });
        });
        setIsGiftCardImageLoading(false);
    };
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Gift Category Details"));
    }, []);
    (0, react_1.useEffect)(function () {
        onSearchCategories(searchGiftCategories);
    }, [searchGiftCategories]);
    (0, react_1.useEffect)(function () {
        if (user._id && giftCategoryId) {
            dispatch((0, get_gift_category_thunk_1.get_gift_category_thunk)({
                _id: giftCategoryId,
            }));
        }
    }, [user]);
    var getGiftCategories = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_gift_categories_thunk_1.get_gift_categories_thunk)(query))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSearchCategories = function (search_string) {
        getGiftCategories(__assign(__assign({}, giftCategoriesPagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ borderColor: "border-transparent", addPadding: false, className: "flex flex-col gap-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back to Gift Categories", size: "xsmall", startIcon: pro_solid_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () { return navigate(-1); } }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "bg-tableBackgroundColor rounded-md py-2 px-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end mb-2" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: giftCategoryId ? "Update Gift Category" : "Save Goft Category", size: "medium", enabled: isValid, backgroundColor: "bg-primary", textColor: "text-white", loading: isLoading, onClick: handleSave }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-4 pb-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Category Name", size: "medium", borderColor: "border-borders", errorText: errors.name, value: selectedGiftCategory === null || selectedGiftCategory === void 0 ? void 0 : selectedGiftCategory.name, onChange: function (name) { return _setGiftCategory({ name: name }); } }), ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.FileInputControl, { previewSide: "right", startIcon: pro_solid_svg_icons_1.faFileArrowUp, colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, size: "medium", previewFile: false, label: "Upload Image", onChange: function (f) {
                                        uploadImage(f, f === null || f === void 0 ? void 0 : f.name);
                                    } }) }))] })), (selectedGiftCategory === null || selectedGiftCategory === void 0 ? void 0 : selectedGiftCategory.gift_card_images.length) > 0 && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Gift Cards", bold: true, textColor: "text-black", className: "pb-2" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row gap-4 justify-between" }, { children: selectedGiftCategory === null || selectedGiftCategory === void 0 ? void 0 : selectedGiftCategory.gift_card_images.map(function (item, index) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " }, { children: [(0, jsx_runtime_1.jsx)("img", { src: item === null || item === void 0 ? void 0 : item.url, alt: "Image", className: "w-80 h-80 transition-all duration-300 rounded-lg cursor-pointer" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "absolute right-2 bg-gray-600 rounded-full text-white" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { backgroundColor: "bg-primary", borderColor: "border-transparent", iconColor: "text-white", icon: pro_solid_svg_icons_1.faTimes, size: "xsmall", shape: "circle", onClick: function () {
                                            _setGiftCategory({
                                                gift_card_images: selectedGiftCategory === null || selectedGiftCategory === void 0 ? void 0 : selectedGiftCategory.gift_card_images.filter(function (_, i) { return i !== index; })
                                            });
                                        } }) }))] }), index)); }) }))] }))] })));
};
exports.default = GiftCategoryPage;
