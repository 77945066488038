"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiRadiiDelivery = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var pages_1 = require("../../../../../../constants/pages");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var branch_1 = require("../../../../../../reducers/slices/branch/branch");
var MultiRadiiDelivery = function (props) {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var _c = (0, react_1.useState)(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = (0, react_1.useState)(10), rowsPerPage = _d[0], setRowsPerPage = _d[1];
    var _e = (0, react_1.useState)({ delivery_distance_km: "", delivery_fee: "" }), multipleDeliveryRadii = _e[0], setMultipleDeliveryRadii = _e[1];
    var _f = (0, validateForm_1.validateForm)(multipleDeliveryRadii), deliveryRadiiErrors = _f.errors, deliveryRadiiIsValid = _f.isValid;
    var onMultipleDeliveryRadiiChange = function (name, value) {
        var _a;
        setMultipleDeliveryRadii(__assign(__assign({}, multipleDeliveryRadii), (_a = {}, _a[name] = value, _a)));
    };
    var _onSubmit = function () {
        var _a, _b, _c, _d, _e, _f;
        var deliveryRadiiExists = ((_b = (_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.delivery) === null || _a === void 0 ? void 0 : _a.delivery_radii) !== null && _b !== void 0 ? _b : []).find(function (delivery) { return delivery.delivery_distance_km == (multipleDeliveryRadii === null || multipleDeliveryRadii === void 0 ? void 0 : multipleDeliveryRadii.delivery_distance_km); });
        if (deliveryRadiiExists) {
            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { delivery: __assign(__assign({}, selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.delivery), { delivery_radii: __spreadArray([], (_d = (_c = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.delivery) === null || _c === void 0 ? void 0 : _c.delivery_radii) === null || _d === void 0 ? void 0 : _d.map(function (deliveryRadii) {
                        if ((deliveryRadii === null || deliveryRadii === void 0 ? void 0 : deliveryRadii.delivery_distance_km) === (multipleDeliveryRadii === null || multipleDeliveryRadii === void 0 ? void 0 : multipleDeliveryRadii.delivery_distance_km)) {
                            return multipleDeliveryRadii;
                        }
                        else {
                            return deliveryRadii;
                        }
                    }), true) }) })));
        }
        else {
            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { delivery: __assign(__assign({}, selectedBranch.delivery), { delivery_radii: __spreadArray(__spreadArray([], ((_f = (_e = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.delivery) === null || _e === void 0 ? void 0 : _e.delivery_radii) !== null && _f !== void 0 ? _f : []), true), [multipleDeliveryRadii], false) }) })));
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-1 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { title: "Multiple Delivery Radii", 
                // currentPage={currentPage}
                // rowsPerPage={rowsPerPage}
                // totalRecords={2}
                columns: [
                    {
                        displayKey: "delivery_fee",
                        label: "Delivery Fee",
                    },
                    {
                        displayKey: "delivery_distance_km",
                        label: "Delivery Distance (KM)",
                    },
                ], data: (_b = (_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.delivery) === null || _a === void 0 ? void 0 : _a.delivery_radii) !== null && _b !== void 0 ? _b : [], rowActions: [
                    {
                        label: "Delete",
                        onClick: function (data) { },
                    },
                ], onSearch: function (searchText) { }, hasShadow: true, pagination: {
                    currentPage: currentPage,
                    rowsPerPage: rowsPerPage,
                    totalRecords: 2,
                    onPageChange: function (page) {
                        setCurrentPage(page);
                    },
                    onRowsPerPageChange: function (rows) {
                        setRowsPerPage(rows);
                    },
                }, colors: pages_1.tableColorConfig }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-1 lg:grid-cols-3 gap-4 pt-4 items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Delivery Distance (KM)", size: "medium", type: "number", value: multipleDeliveryRadii === null || multipleDeliveryRadii === void 0 ? void 0 : multipleDeliveryRadii.delivery_distance_km, onChange: function (v) { return onMultipleDeliveryRadiiChange("delivery_distance_km", v); }, iconColor: "text-error", errorText: deliveryRadiiErrors === null || deliveryRadiiErrors === void 0 ? void 0 : deliveryRadiiErrors.delivery_distance_km }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Delivery Fee", size: "medium", type: "number", value: multipleDeliveryRadii === null || multipleDeliveryRadii === void 0 ? void 0 : multipleDeliveryRadii.delivery_fee, onChange: function (v) { return onMultipleDeliveryRadiiChange("delivery_fee", v); }, iconColor: "text-error", errorText: deliveryRadiiErrors === null || deliveryRadiiErrors === void 0 ? void 0 : deliveryRadiiErrors.delivery_fee }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Add", size: "small", backgroundColor: "bg-primary", endIcon: pro_light_svg_icons_1.faPlus, enabled: deliveryRadiiIsValid, onClick: _onSubmit })] }))] })));
};
exports.MultiRadiiDelivery = MultiRadiiDelivery;
