"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuConceptDetailsPage = exports.columns = exports.INITIAL_VISIBLE_COLUMNS = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pages_1 = require("../../../../constants/pages");
var product_list_1 = require("../../../../api/calls/product/product_list");
var main_1 = require("../../../../reducers/slices/main");
var routes_1 = require("../../../../constants/routes");
var react_router_dom_1 = require("react-router-dom");
var get_products_shopify_thunk_1 = require("../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var product_1 = require("../../../../reducers/slices/products/product");
var menu_concepts_1 = require("../../../../reducers/slices/menuConcepts/menu_concepts");
var sanitiseObject_1 = require("../../../../helpers/sanitiseObject");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var create_menu_concept_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/create_menu_concept_thunk");
var update_menu_concept_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/update_menu_concept_thunk");
var get_menu_concept_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/get_menu_concept_thunk");
var set_menu_concept_products_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/set_menu_concept_products_thunk");
var react_2 = require("@nextui-org/react");
var get_category_thunk_1 = require("../../../../reducers/slices/products/categories/thunks/get_category_thunk");
var table_top_bar_1 = require("../../../../components/table_top_bar");
var table_bottom_content_1 = require("../../../../components/table_bottom_content");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var tabel_cell_1 = require("../../../../components/tabel_cell");
var local_storage_names_1 = require("../../../../constants/local_storage_names");
var copy_text_1 = require("../../../../components/copy_text");
var update_menu_concept_categories_1 = require("../../../../api/calls/menuConcept/update_menu_concept_categories");
exports.INITIAL_VISIBLE_COLUMNS = ["image_url", "title", "sku", "category", "description"];
exports.columns = [
    { name: "Image", uid: "image_url" },
    { name: "Title", uid: "title" },
    { name: "SKU", uid: "sku" },
    { name: "Category", uid: "category" },
    { name: "Description", uid: "description" },
];
var MenuConceptDetailsPage = function () {
    var _a, _b;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var menu_concept_id = (0, react_router_dom_1.useParams)().menu_concept_id;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _c.client_token, user = _c.user;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), menuConceptProducts = _d.menuConceptProducts, menuConceptProductsPagination = _d.menuConceptProductsPagination, isMenuConceptProductsLoading = _d.isMenuConceptProductsLoading;
    var pageTitle = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).pageTitle;
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.menuConceptState; }), selectedMenuConcept = _e.selectedMenuConcept, errors = _e.errors, isEdited = _e.isEdited, productSelectionEdited = _e.productSelectionEdited;
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.categoryState; }), categories = _f.categories, categoriesPagination = _f.pagination;
    var _g = (0, useSearchDebounce_1.useSearchDebounce)(), searchProducts = _g[0], setSearchProducts = _g[1];
    var _h = (0, useSearchDebounce_1.useSearchDebounce)(), searchCategories = _h[0], setSearchCategories = _h[1];
    var _j = (0, react_1.useState)([]), selectedProducts = _j[0], setSelectedProducts = _j[1];
    var _k = (0, react_1.useState)([]), selectedCategories = _k[0], setSelectedCategories = _k[1];
    var _l = (0, react_1.useState)(1), currentPage = _l[0], setCurrentPage = _l[1];
    var _m = (0, react_1.useState)(10), rowsPerPage = _m[0], setRowsPerPage = _m[1];
    var _o = (0, react_1.useState)([]), productOptions = _o[0], setProductOptions = _o[1];
    var _p = (0, react_1.useState)(new Set([])), selectedKeys = _p[0], setSelectedKeys = _p[1];
    var tableColumnConfig = localStorage.getItem(local_storage_names_1.TABLE_COLUMN_CONFIG);
    var savedVisibleColumns = (_a = JSON.parse(tableColumnConfig)) !== null && _a !== void 0 ? _a : {};
    var initialVisibleColumns = (_b = savedVisibleColumns["productOptions"]) !== null && _b !== void 0 ? _b : exports.INITIAL_VISIBLE_COLUMNS;
    var _q = (0, react_1.useState)(new Set(initialVisibleColumns)), visibleColumns = _q[0], setVisibleColumns = _q[1];
    var _r = (0, react_1.useState)({
        column: "createdAt",
        direction: "ascending",
    }), sortDescriptor = _r[0], setSortDescriptor = _r[1];
    var selectedCategoryIDs = (0, react_1.useMemo)(function () { return selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.map(function (category) { return category === null || category === void 0 ? void 0 : category._id; }); }, [selectedCategories !== null && selectedCategories !== void 0 ? selectedCategories : []]);
    var onChange = function (values) {
        dispatch((0, menu_concepts_1.setSelectedMenuConcept)(__assign(__assign({}, selectedMenuConcept), values)));
        dispatch((0, menu_concepts_1.setIsEdited)(true));
    };
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Menu Concept Details"));
        return function () {
            dispatch((0, menu_concepts_1.setSelectedMenuConcept)(menu_concepts_1.initialMenuConceptState.selectedMenuConcept));
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (!client_token) {
            return;
        }
        if (menu_concept_id) {
            getProductsByCategory(currentPage);
            dispatch((0, get_menu_concept_thunk_1.get_menu_concept_thunk)({ _id: menu_concept_id, client_id: user === null || user === void 0 ? void 0 : user.client_id }));
        }
    }, [client_token, menu_concept_id]);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_category_thunk_1.get_category_thunk)(__assign({}, categoriesPagination)));
        }
    }, [client_token, selectedCategories]);
    // useEffect(() => {
    //   if (menu_concept_id) {
    //     get_product_list({ menu_concept_id, size: 0 }).then((response) => {
    //       if (response.success) {
    //         debugger;
    //         setSelectedProducts(response.content.product_list);
    //       }
    //     });
    //   }
    // }, [menu_concept_id]);
    // useEffect(() => {
    //   setCategoryOptions(categories.map((category) => ({ label: category.name, value: category._id })));
    // }, [categories]);
    // useEffect(() => {
    //   // for (const product of selectedProducts) {
    //   console.log("selectedCategories", selectedCategories);
    //   // for category
    //   dispatch(get_products_thunk({ ...pagination, category}));
    // }, [selectedCategories]);
    (0, react_1.useEffect)(function () {
        setProductOptions(menuConceptProducts.map(function (product) { return ({
            _id: product._id,
            title: product.title,
            sku: product.sku,
            pid: product.pid,
            image_url: product.image_url,
            description: product.description,
            category: product.category,
        }); }));
    }, [menuConceptProducts]);
    (0, react_1.useEffect)(function () {
        if (selectedMenuConcept._id) {
            setSelectedCategories(selectedMenuConcept.category_id_list);
        }
    }, [selectedMenuConcept._id]);
    (0, react_1.useEffect)(function () {
        getProductsByCategory(currentPage);
    }, [selectedCategories]);
    var getProductsByCategory = function (page) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_menu_concept_products_thunk)({
                        category_id_list: selectedCategories.map(function (category) { return category._id; }),
                        page: String(page),
                        size: String(rowsPerPage),
                    }))];
                case 1:
                    response = _d.sent();
                    if ((_a = response.payload) === null || _a === void 0 ? void 0 : _a.success) {
                        setProductOptions((_c = (_b = response.payload) === null || _b === void 0 ? void 0 : _b.content.product_list) === null || _c === void 0 ? void 0 : _c.map(function (product) { return ({
                            _id: product._id,
                            title: product.title,
                            sku: product.sku,
                            pid: product.pid,
                            image_url: product.image_url,
                            description: product.description,
                            category: product.category,
                        }); }));
                    }
                    return [2 /*return*/, response];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        onSearchCategories(searchCategories);
    }, [searchCategories]);
    (0, react_1.useEffect)(function () {
        onSearchProducts(searchProducts);
    }, [searchProducts]);
    (0, react_1.useEffect)(function () {
        getSelectedCategoryProducts(__assign(__assign({}, menuConceptProductsPagination), { page: String(currentPage), size: String(rowsPerPage) }));
        getSelectedProducts();
        setCurrentPage(1);
    }, [selectedCategories]);
    var getSelectedCategoryProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_menu_concept_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var getCategories = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_category_thunk_1.get_category_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchProducts = function (search_string) {
        getSelectedCategoryProducts(__assign(__assign({}, menuConceptProductsPagination), { category_id_list: selectedCategories.map(function (category) { return category._id; }), search_string: search_string !== null && search_string !== void 0 ? search_string : null }));
    };
    var getSelectedProducts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, (0, product_list_1.get_product_list)({ menu_concept_id: menu_concept_id, size: 0 })];
                case 1:
                    response = _b.sent();
                    if (response.success) {
                        setSelectedProducts((_a = response.content.product_list) === null || _a === void 0 ? void 0 : _a.map(function (product) { return ({
                            _id: product._id,
                            title: product.title,
                            sku: product.sku,
                            pid: product.pid,
                            image_url: product.image_url,
                        }); }));
                    }
                    return [2 /*return*/, response];
            }
        });
    }); };
    var onSearchCategories = function (search_string) {
        getCategories(__assign(__assign({}, categoriesPagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        if (page < 1)
            return;
        setCurrentPage(page);
        if (searchProducts === "") {
            return getSelectedCategoryProducts(__assign(__assign({}, menuConceptProductsPagination), { page: String(page), size: String(rowsPerPage), category_id_list: selectedCategories.map(function (category) { return category._id; }) }));
        }
        else {
            return getSelectedCategoryProducts(__assign(__assign({}, menuConceptProductsPagination), { page: String(page), size: String(rowsPerPage), category_id_list: selectedCategories.map(function (category) { return category._id; }), search_string: searchProducts }));
        }
    };
    var onCategoriesPageChange = function (page) {
        if (!searchCategories) {
            return getCategories(__assign(__assign({}, categoriesPagination), { page: page }));
        }
        else {
            return getCategories(__assign(__assign({}, categoriesPagination), { page: page, search_string: searchCategories }));
        }
    };
    var saveMenuConcept = function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, payload, payload;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    params = (0, sanitiseObject_1.sanitiseObject)({
                        _id: selectedMenuConcept._id,
                        name: selectedMenuConcept.name,
                        branch_group_id_list: selectedMenuConcept.branch_group_id_list,
                        branch_id_list: selectedMenuConcept.branch_id_list,
                        category_id_list: selectedCategories,
                    });
                    if (!!selectedMenuConcept._id) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch((0, create_menu_concept_thunk_1.create_menu_concept_thunk)(params))];
                case 1:
                    payload = (_b.sent()).payload;
                    if (!(payload === null || payload === void 0 ? void 0 : payload.success) && (payload === null || payload === void 0 ? void 0 : payload.content)) {
                        return [2 /*return*/];
                    }
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_DETAILS_ROUTE, "/").concat((_a = payload === null || payload === void 0 ? void 0 : payload.content) === null || _a === void 0 ? void 0 : _a._id));
                    return [2 /*return*/, payload];
                case 2: return [4 /*yield*/, dispatch((0, update_menu_concept_thunk_1.update_menu_concept_thunk)(params))];
                case 3:
                    payload = (_b.sent()).payload;
                    if (!(payload === null || payload === void 0 ? void 0 : payload.success) && (payload === null || payload === void 0 ? void 0 : payload.content)) {
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, payload];
            }
        });
    }); };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, id, payload;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!isEdited) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveMenuConcept()];
                case 1:
                    result = _c.sent();
                    if ((result === null || result === void 0 ? void 0 : result.success) === false) {
                        return [2 /*return*/];
                    }
                    _c.label = 2;
                case 2:
                    id = (_a = selectedMenuConcept._id) !== null && _a !== void 0 ? _a : (_b = result === null || result === void 0 ? void 0 : result.content) === null || _b === void 0 ? void 0 : _b._id;
                    if (!(productSelectionEdited && id)) return [3 /*break*/, 5];
                    if (isEdited && (result === null || result === void 0 ? void 0 : result.success) === false) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch((0, set_menu_concept_products_thunk_1.set_menu_concept_products_thunk)({
                            product_id_list: selectedProducts.map(function (product) { return product._id; }),
                            menu_concept_id_list: [id],
                        }))];
                case 3:
                    payload = (_c.sent()).payload;
                    return [4 /*yield*/, (0, update_menu_concept_categories_1.update_menu_concept_categories)({
                            menu_concept_id: selectedMenuConcept._id,
                            category_id_list: selectedCategories,
                        })];
                case 4:
                    _c.sent();
                    getProductsByCategory(currentPage);
                    result = payload;
                    _c.label = 5;
                case 5:
                    if (result === null || result === void 0 ? void 0 : result.success) {
                        dispatch((0, menu_concepts_1.setIsEdited)(false));
                        dispatch((0, menu_concepts_1.setProductSelectionEdited)(false));
                        dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Your menu concept has been saved!"] }));
                        setTimeout(function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_LIST_ROUTE));
                        }, 1500);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onProductSelectionChange = function (newSelection) {
        var selectedIds = Array.from(newSelection);
        var newSelectedProducts = __spreadArray(__spreadArray([], selectedProducts, true), menuConceptProducts === null || menuConceptProducts === void 0 ? void 0 : menuConceptProducts.filter(function (product) { return selectedIds.includes(product._id) && !selectedProducts.some(function (p) { return p._id === product._id; }); }), true);
        var unselectedProducts = selectedProducts.filter(function (product) { return !selectedIds.includes(product._id); });
        var newSelectedProductsFiltered = newSelectedProducts.filter(function (product) { return !unselectedProducts.some(function (p) { return p._id === product._id; }); });
        setSelectedProducts(newSelectedProductsFiltered);
        setSelectedKeys(newSelection);
        if (!productSelectionEdited) {
            dispatch((0, menu_concepts_1.setProductSelectionEdited)(true));
        }
    };
    var filterSelectedKeys = (0, react_1.useMemo)(function () { return new Set(selectedProducts.map(function (product) { return product._id; })); }, [selectedProducts]);
    var totalPages = (0, react_1.useMemo)(function () {
        return (menuConceptProductsPagination === null || menuConceptProductsPagination === void 0 ? void 0 : menuConceptProductsPagination.countTotal) ? Math.ceil((menuConceptProductsPagination === null || menuConceptProductsPagination === void 0 ? void 0 : menuConceptProductsPagination.countTotal) / rowsPerPage) : 0;
    }, [menuConceptProductsPagination === null || menuConceptProductsPagination === void 0 ? void 0 : menuConceptProductsPagination.countTotal, rowsPerPage]);
    var totalCategoryPages = (0, react_1.useMemo)(function () {
        return (categoriesPagination === null || categoriesPagination === void 0 ? void 0 : categoriesPagination.countTotal) ? Math.ceil((categoriesPagination === null || categoriesPagination === void 0 ? void 0 : categoriesPagination.countTotal) / 10) : 0;
    }, [categoriesPagination === null || categoriesPagination === void 0 ? void 0 : categoriesPagination.countTotal, 10]);
    var headerColumns = (0, react_1.useMemo)(function () {
        if (visibleColumns === "all")
            return exports.columns;
        return exports.columns
            .map(function (item) {
            if (item.uid === sortDescriptor.column) {
                return __assign(__assign({}, item), { sortDirection: sortDescriptor.direction });
            }
            return item;
        })
            .filter(function (column) { return Array.from(visibleColumns).includes(column.uid); });
    }, [visibleColumns, sortDescriptor]);
    var renderCellViews = function (cellValue) {
        var _a;
        return [
            {
                key: "image_url",
                content: ((0, jsx_runtime_1.jsx)(react_2.User, { avatarProps: { radius: "lg", src: cellValue }, classNames: {
                        name: "text-default-foreground",
                        description: "text-default-500",
                    }, name: "" })),
            },
            {
                key: "title",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })),
            },
            {
                key: "sku",
                content: (0, jsx_runtime_1.jsx)(copy_text_1.CopyText, __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })),
            },
            {
                key: "description",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-wrap text-small capitalize text-default-foreground truncate" }, { children: cellValue })),
            },
            {
                key: "category",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-small capitalize text-default-foreground" }, { children: (_a = cellValue === null || cellValue === void 0 ? void 0 : cellValue.name) !== null && _a !== void 0 ? _a : "N/A" })),
            },
        ];
    };
    var selectedProductIds = selectedProducts.map(function (product) { return product._id; });
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2 !overflow-x-hidden !overflow-y-hidden" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back To Menu Concepts", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_LIST_ROUTE));
                            } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[100px]" }, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isDisabled: !isEdited && !productSelectionEdited, color: "primary", onClick: onSave, className: "text-white bg-primary", size: "md" }, { children: "Save" })) }))] })), (0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "flex gap-6 justify-between" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Menu Concept Name", type: "text", size: "small", borderColor: "border-borders", value: selectedMenuConcept.name, errorText: errors.name, onChange: function (name) { return onChange({ name: name }); } }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row gap-2" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "gap-2 w-[280px] bg-white grid" }, { children: (0, jsx_runtime_1.jsxs)(react_2.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ className: "text-wrap h-auto" }, { children: (selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.length) === 0
                                                ? "Select Categories"
                                                : (selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.length) > 4
                                                    ? "".concat(selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.length, " Categories Selected")
                                                    : selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.map(function (category) { return "".concat(category.name); }).join(", ") })) }), (0, jsx_runtime_1.jsxs)(react_2.DropdownMenu, __assign({ selectedKeys: selectedCategoryIDs !== null && selectedCategoryIDs !== void 0 ? selectedCategoryIDs : [], closeOnSelect: false, onAction: function (key) {
                                            var selectedCategory = categories.find(function (category) { return category._id === key; });
                                            if (selectedCategories.find(function (category) { return category._id === (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory._id); })) {
                                                setSelectedCategories(selectedCategories.filter(function (category) { return category._id !== (selectedCategory === null || selectedCategory === void 0 ? void 0 : selectedCategory._id); }));
                                            }
                                            else {
                                                setSelectedCategories(__spreadArray(__spreadArray([], selectedCategories, true), [
                                                    {
                                                        _id: selectedCategory._id,
                                                        name: selectedCategory.name,
                                                    },
                                                ], false));
                                            }
                                        }, selectionMode: "single" }, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownSection, __assign({ hideSelectedIcon: true }, { children: (0, jsx_runtime_1.jsx)(react_2.DropdownItem, { isReadOnly: true, textValue: "SelectedItem" }, "Search") })), (0, jsx_runtime_1.jsx)(react_2.DropdownSection, __assign({ items: categories }, { children: function (item) { return ((0, jsx_runtime_1.jsxs)(react_2.DropdownItem, __assign({ textValue: "Customer" }, { children: [item.name, " ", item.surname] }), item._id)); } })), (0, jsx_runtime_1.jsx)(react_2.DropdownSection, __assign({ hideSelectedIcon: true }, { children: (0, jsx_runtime_1.jsx)(react_2.DropdownItem, __assign({ isReadOnly: true, textValue: "Pagination" }, { children: (0, jsx_runtime_1.jsx)(react_2.Pagination, { total: totalCategoryPages, initialPage: 1, onChange: function (page) { return onCategoriesPageChange(page); } }) }), "move") }))] }))] }) })) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 relative" }, { children: [(0, jsx_runtime_1.jsx)("div", { className: "flex justify-between gap-4 items-end" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "!overflow-x-hidden !overflow-y-hidden" }, { children: [(0, table_top_bar_1.tableTopBar)({
                                pageTitle: pageTitle,
                                recordCount: menuConceptProductsPagination === null || menuConceptProductsPagination === void 0 ? void 0 : menuConceptProductsPagination.countTotal,
                                actionButtons: [].filter(Boolean),
                            }), (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ isHeaderSticky: true, "aria-label": "Customer Segments", bottomContent: (0, table_bottom_content_1.tableBottomContent)({
                                    filterSelectedKeys: filterSelectedKeys,
                                    page: currentPage,
                                    pages: totalPages,
                                    filteredItems: selectedProducts,
                                    onPreviousPage: function () { return onPageChange(currentPage - 1); },
                                    onNextPage: function () { return onPageChange(currentPage + 1); },
                                    setPage: onPageChange,
                                }), bottomContentPlacement: "outside", classNames: {
                                    td: "before:bg-transparent",
                                }, selectedKeys: selectedProductIds, onSelectionChange: onProductSelectionChange, selectionMode: "multiple", sortDescriptor: sortDescriptor, topContent: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[250px]" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { className: "min-w-[200px]", endContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faSearch, className: "text-default-400", width: 16 }), placeholder: "Search", size: "sm", onValueChange: function (search_string) { return __awaiter(void 0, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        dispatch((0, product_1.setTablePagination)(pages_1.initialPaginationValues));
                                                        setSearchProducts(search_string);
                                                        return [2 /*return*/];
                                                    });
                                                }); } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "font-bold pl-60" }, { children: "Select products to include in this menu concept" }))] })), topContentPlacement: "outside" }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_2.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_2.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: isMenuConceptProductsLoading, emptyContent: "This table is empty.", items: productOptions, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) {
                                            return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                                        } }))] }))] }))] })), selectedCategories && (selectedCategories === null || selectedCategories === void 0 ? void 0 : selectedCategories.length) > 0 && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4 fixed bottom-8 right-8" }, { children: (0, jsx_runtime_1.jsxs)(react_2.Button, __assign({ size: "md", className: "bg-danger text-white", onClick: function () {
                        setSelectedProducts([]);
                    } }, { children: ["Clear ".concat(selectedProducts === null || selectedProducts === void 0 ? void 0 : selectedProducts.length, " Selected Products"), (0, jsx_runtime_1.jsx)("span", __assign({ style: { marginLeft: "8px", fontStyle: "normal", fontWeight: "bold", animation: "ease-in-out" } }, { children: "X" }))] })) })))] })));
};
exports.MenuConceptDetailsPage = MenuConceptDetailsPage;
