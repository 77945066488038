"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productTableColumns = exports.ORDER_TYPE_OPTIONS = exports.STATUSES = void 0;
exports.STATUSES = [
    { label: "Draft", value: "DRAFT" },
    { label: "Sent", value: "SENT" },
    { label: "Viewed", value: "VIEWED" },
    { label: "Accepted", value: "ACCEPTED" },
    { label: "Declined", value: "DECLINED" },
    { label: "Expired", value: "EXPIRED" },
];
exports.ORDER_TYPE_OPTIONS = [
    { label: "Delivery", value: "delivery" },
    { label: "Collection", value: "collection" },
];
exports.productTableColumns = [
    {
        displayKey: "image",
        label: "Image",
    },
    {
        displayKey: "title",
        label: "Products/Service",
    },
    {
        displayKey: "category",
        label: "Category",
    },
    {
        displayKey: "barcode",
        label: "Barcode",
    },
    {
        displayKey: "stock",
        label: "In Stock",
    },
    {
        displayKey: "quantity",
        label: "Quantity",
    },
    {
        displayKey: "price",
        label: "Total",
    },
];
