"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogInPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../constants/routes");
var sign_in_thunk_1 = require("../../../reducers/slices/user/thunks/sign_in_thunk");
var user_1 = require("../../../reducers/slices/user/user");
var button_1 = require("@nextui-org/button");
var link_1 = require("@nextui-org/link");
var react_2 = require("@nextui-org/react");
var LogInPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _b.user, token = _b.token, isLoading = _b.isLoading;
    var _c = (0, react_1.useState)(""), emailMessage = _c[0], setEmailMessage = _c[1];
    var _d = (0, react_1.useState)(""), passwordMessage = _d[0], setPasswordMessage = _d[1];
    (0, react_1.useEffect)(function () {
        if ((user === null || user === void 0 ? void 0 : user._id) && token) {
            navigate(routes_1.CONSOLE_ROUTE);
        }
    }, [user]);
    var onControlChanged = function (name, value) {
        var _a;
        dispatch((0, user_1.setUser)(__assign(__assign({}, user), (_a = {}, _a[name] = value, _a))));
    };
    var validate = function () {
        var messageCount = 0;
        setEmailMessage("");
        setPasswordMessage("");
        if (!user.email) {
            messageCount++;
            setEmailMessage("Email is required.");
        }
        if (!user.password) {
            messageCount++;
            setPasswordMessage("Password is required.");
        }
        return messageCount ? false : true;
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-y-4 h-full justify-center w-full lg:w-[700px] py-4 lg:py-0 px-4 lg:px-8" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "text-3xl font-bold" }, { children: "Sign In" })), (0, jsx_runtime_1.jsx)("div", { className: "w-10 h-[1px] bg-black" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-md font-light mb-10" }, { children: "Sign in using your email address" })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full flex flex-col gap-y-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid lg:flex gap-4 w-full" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { type: "email", label: "Email", placeholder: "Enter your email", value: user.email, onChange: function (event) {
                                        onControlChanged("email", event.target.value);
                                    } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { type: "password", label: "Password", placeholder: "Enter your password", value: user.password, onChange: function (event) {
                                        onControlChanged("password", event.target.value);
                                    } }) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row justify-between items-center mt-10" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Checkbox, __assign({ value: (_a = user.remember_me) === null || _a === void 0 ? void 0 : _a.toString(), onChange: function (event) {
                                    onControlChanged("remember_me", event.target.checked);
                                } }, { children: "Remember me?" })), (0, jsx_runtime_1.jsx)(link_1.Link, __assign({ href: "#".concat(routes_1.WELCOME_ROUTE).concat(routes_1.FORGOT_PASSWORD_ROUTE), size: "md" }, { children: "Forgot password?" }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[200px]" }, { children: (0, jsx_runtime_1.jsx)(button_1.Button, __assign({ color: "primary", className: "bg-secondary", size: "lg", isLoading: isLoading, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (validate()) {
                                        dispatch((0, sign_in_thunk_1.sign_in_thunk)());
                                    }
                                    return [2 /*return*/];
                                });
                            }); } }, { children: "Sign in" })) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-1 pt-4 items-center justify-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "text-black text-md" }, { children: "Don't have an account?" })), (0, jsx_runtime_1.jsx)(link_1.Link, __assign({ href: "#".concat(routes_1.WELCOME_ROUTE).concat(routes_1.CREATE_CLIENT_ROUTE), className: "text-secondary" }, { children: "Create one now" }))] }))] })));
};
exports.LogInPage = LogInPage;
