"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuConceptsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../reducers/slices/console");
var routes_1 = require("../../../../constants/routes");
var main_1 = require("../../../../reducers/slices/main");
var pages_1 = require("../../../../constants/pages");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var menu_concepts_1 = require("../../../../reducers/slices/menuConcepts/menu_concepts");
var get_menu_concepts_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/get_menu_concepts_thunk");
var delete_menu_concept_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/delete_menu_concept_thunk");
var react_2 = require("@nextui-org/react");
var tabel_cell_1 = require("../../../../components/tabel_cell");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var table_top_bar_1 = require("../../../../components/table_top_bar");
var table_bottom_content_1 = require("../../../../components/table_bottom_content");
var copy_text_1 = require("../../../../components/copy_text");
var local_storage_names_1 = require("../../../../constants/local_storage_names");
var INITIAL_VISIBLE_COLUMNS = ["name", "branch_id_list", "actions"];
var columns = [
    { name: "Menu Name", uid: "name" },
    { name: "Number of Branches", uid: "branch_id_list" },
    { name: "Actions", uid: "actions" },
];
var MenuConceptsPage = function () {
    var _a, _b;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), search = _c[0], setSearch = _c[1];
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.menuConceptState; }), menuConcepts = _d.menuConcepts, isLoading = _d.isLoading, pagination = _d.pagination;
    var pageTitle = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).pageTitle;
    var _e = (0, react_1.useState)(1), currentPage = _e[0], setCurrentPage = _e[1];
    var _f = (0, react_1.useState)(10), rowsPerPage = _f[0], setRowsPerPage = _f[1];
    var _g = (0, react_1.useState)([]), selectedRows = _g[0], setSelectedRows = _g[1];
    var tableColumnConfig = localStorage.getItem(local_storage_names_1.TABLE_COLUMN_CONFIG);
    var savedVisibleColumns = (_a = JSON.parse(tableColumnConfig)) !== null && _a !== void 0 ? _a : {};
    var initialVisibleColumns = (_b = savedVisibleColumns["productOptions"]) !== null && _b !== void 0 ? _b : INITIAL_VISIBLE_COLUMNS;
    var _h = (0, react_1.useState)(new Set(initialVisibleColumns)), visibleColumns = _h[0], setVisibleColumns = _h[1];
    var _j = (0, react_1.useState)({
        column: "createdAt",
        direction: "ascending",
    }), sortDescriptor = _j[0], setSortDescriptor = _j[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Menu Concepts"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token && !isLoading) {
            dispatch((0, get_menu_concepts_thunk_1.get_menu_concepts_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    var getMenuConcepts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_menu_concepts_thunk_1.get_menu_concepts_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getMenuConcepts(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (search === "") {
            setRowsPerPage(rowsPerPage);
            return getMenuConcepts(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getMenuConcepts(__assign(__assign({}, pagination), { page: page, search_string: search }));
        }
    };
    var onRowsPerPageChange = function (size) {
        setRowsPerPage(size);
        return getMenuConcepts(__assign(__assign({}, pagination), { size: size }));
    };
    var deleteMenuConcept = function (menu_concept) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, delete_menu_concept_thunk_1.delete_menu_concept_thunk)({ _id: menu_concept._id }))];
                case 1:
                    _a.sent();
                    dispatch((0, main_1.setContentModal)(null));
                    dispatch((0, get_menu_concepts_thunk_1.get_menu_concepts_thunk)(__assign({}, pagination)));
                    return [2 /*return*/];
            }
        });
    }); };
    var handleEdit = function (menu) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_DETAILS_ROUTE, "/").concat(menu._id));
            return [2 /*return*/];
        });
    }); };
    var handleDelete = function (menu) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch((0, main_1.setContentModal)({
                title: "Confirm deletion of ".concat(menu === null || menu === void 0 ? void 0 : menu.name, " menu concept"),
                content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col items-center" }, { children: [(0, jsx_runtime_1.jsx)("p", { children: "Are you sure you want to delete this menu concept?" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ className: "bg-primary text-white", onClick: function () {
                                        deleteMenuConcept(menu);
                                    } }, { children: "Continue" }))] }))] }))),
            }));
            return [2 /*return*/];
        });
    }); };
    var headerColumns = (0, react_1.useMemo)(function () {
        if (visibleColumns === "all")
            return columns;
        return columns
            .map(function (item) {
            if (item.uid === sortDescriptor.column) {
                return __assign(__assign({}, item), { sortDirection: sortDescriptor.direction });
            }
            return item;
        })
            .filter(function (column) { return Array.from(visibleColumns).includes(column.uid); });
    }, [visibleColumns, sortDescriptor]);
    var rowActions = function (cellValue) { return [
        {
            key: "view",
            onClick: function () { return handleEdit(cellValue); },
        },
        {
            key: "edit",
            onClick: function () { return handleEdit(cellValue); },
        },
        {
            key: "delete",
            onClick: function () { return handleDelete(cellValue); },
        },
    ]; };
    var renderCellViews = function (cellValue) {
        var _a;
        return [
            {
                key: "name",
                content: (0, jsx_runtime_1.jsx)(copy_text_1.CopyText, __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })),
            },
            {
                key: "branch_id_list",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: (_a = cellValue === null || cellValue === void 0 ? void 0 : cellValue.length) !== null && _a !== void 0 ? _a : 0 })),
            },
        ];
    };
    var filterSelectedKeys = (0, react_1.useMemo)(function () { return new Set(selectedRows.map(function (menu) { return menu._id; })); }, [selectedRows]);
    var selectedMenuIds = selectedRows.map(function (menu) { return menu === null || menu === void 0 ? void 0 : menu._id; });
    var totalPages = (0, react_1.useMemo)(function () {
        return (pagination === null || pagination === void 0 ? void 0 : pagination.countTotal) ? Math.ceil((pagination === null || pagination === void 0 ? void 0 : pagination.countTotal) / rowsPerPage) : 0;
    }, [pagination === null || pagination === void 0 ? void 0 : pagination.countTotal, rowsPerPage]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 relative" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "!overflow-x-hidden !overflow-y-hidden" }, { children: [(0, table_top_bar_1.tableTopBar)({
                        pageTitle: pageTitle,
                        recordCount: pagination === null || pagination === void 0 ? void 0 : pagination.countTotal,
                        actionButtons: [
                            {
                                label: "Add Menu Concept",
                                iconClassname: "solar:add-circle-bold",
                                onClick: function () {
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_DETAILS_ROUTE));
                                },
                            },
                        ].filter(Boolean),
                    }), (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ isHeaderSticky: true, "aria-label": "Customer Segments", bottomContent: (0, table_bottom_content_1.tableBottomContent)({
                            filterSelectedKeys: filterSelectedKeys,
                            page: currentPage,
                            pages: totalPages,
                            filteredItems: selectedRows,
                            onPreviousPage: function () { return onPageChange(currentPage - 1); },
                            onNextPage: function () { return onPageChange(currentPage + 1); },
                            setPage: onPageChange,
                        }), bottomContentPlacement: "outside", classNames: {
                            td: "before:bg-transparent",
                        }, selectedKeys: selectedMenuIds, onSelectionChange: function (selectedRows) {
                            // setSearch(null);
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_DETAILS_ROUTE, "/").concat(selectedRows === null || selectedRows === void 0 ? void 0 : selectedRows.currentKey));
                        }, selectionMode: "single", topContent: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row items-center" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[250px]" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { className: "min-w-[200px]", endContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faSearch, className: "text-default-400", width: 16 }), placeholder: "Search", size: "sm", onValueChange: function (search_string) { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            dispatch((0, menu_concepts_1.setTablePagination)(pages_1.initialPaginationValues));
                                            setSearch(search_string);
                                            return [2 /*return*/];
                                        });
                                    }); } }) })) })), topContentPlacement: "outside" }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_2.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_2.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: isLoading, emptyContent: "This table is empty.", items: menuConcepts, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) {
                                    return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                                } }))] }))] })), selectedRows && selectedRows.length > 0 && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4 fixed bottom-8 right-8" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel Selected", endIcon: pro_solid_svg_icons_1.faTimes, size: "small", backgroundColor: "bg-borders", textColor: "text-black", iconColor: "text-black", onClick: function () {
                        setSelectedRows([]);
                    } }) })))] })));
};
exports.MenuConceptsPage = MenuConceptsPage;
