"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPagination = exports.setLoading = exports.setRedeemedVoucherUsers = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    redeemed_voucher_users: [],
    loading: false,
    pagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "userVouchers",
    initialState: initialState,
    reducers: {
        setRedeemedVoucherUsers: function (state, action) {
            state.redeemed_voucher_users = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
    },
});
exports.setRedeemedVoucherUsers = (_a = slice.actions, _a.setRedeemedVoucherUsers), exports.setLoading = _a.setLoading, exports.setPagination = _a.setPagination;
exports.default = slice.reducer;
