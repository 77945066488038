"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TABLE_STATUSES = exports.AWAITING_SUBSCRIPTION_PAYMENT = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
exports.AWAITING_SUBSCRIPTION_PAYMENT = "AWAITING-SUBSCRIPTION-PAYMENT";
var TABLE_STATUSES = function (statusString) {
    if (!statusString)
        return "";
    var status = statusString === null || statusString === void 0 ? void 0 : statusString.toUpperCase();
    var _colors = {
        background: "bg-primary",
        text: "text-white",
    };
    if ([
        "ACTIVE",
        "COMPLETED",
        "FUlFILLED",
        "PROCESSED",
        "ORDERED",
        "READY",
        "ARRIVED",
        "DELIVERED",
        "ONLINE",
        "AVAILABLE",
        "COMPLETE",
        "SUCCESS",
        "ACCEPTED",
    ].includes(status)) {
        _colors = {
            background: "bg-success",
            text: "text-white",
        };
    }
    if (["INACTIVE", "CANCELLED", "OFFLINE"].includes(status)) {
        _colors = {
            background: "bg-borders",
            text: "text-black",
        };
    }
    if (["UPDATED", "OPEN", "RETURNED", "PACKED", "VIEWED"].includes(status)) {
        _colors = {
            background: "bg-secondary",
            text: "text-white",
        };
    }
    if (["SUSPENDED", "ABANDONED", "RETURNED-BY-CUSTOMER", "DECLINED", "EXPIRED"].includes(status)) {
        _colors = {
            background: "bg-error",
            text: "text-white",
        };
    }
    if ([
        "AWAITING-SIGNUP",
        "PROCESSING",
        "CHECKED-OUT",
        "POS-ORDERED",
        "COLLECTED",
        "DELIVERY-READY",
        "PARTIALLY-DELIVERED",
        "AWAITING-EMAIL-CONFIRMATION",
        "SENT",
        "PENDING",
    ].includes(status)) {
        _colors = {
            background: "bg-yellow",
            text: "text-black",
        };
    }
    return (0, jsx_runtime_1.jsx)("div", __assign({ className: "p-2 ".concat(_colors.background, " ").concat(_colors.text, " text-center text-xs rounded-md w-32") }, { children: status }));
};
exports.TABLE_STATUSES = TABLE_STATUSES;
