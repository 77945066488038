"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedUserGift = exports.setUserGiftsPagination = exports.setUserGifts = exports.setGiftCategoriesPagination = exports.setPagination = exports.setSelectedGiftCategory = exports.setGiftCategories = exports.setLoading = exports.initialGiftCategoryValues = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
exports.initialGiftCategoryValues = {
    name: "",
    gift_card_images: [],
};
var initialState = {
    giftCategories: [],
    userGifts: [],
    selectedUserGift: {},
    selectedGiftCategory: exports.initialGiftCategoryValues,
    pagination: pages_1.initialPaginationValues,
    giftCategoriesPagination: pages_1.initialPaginationValues,
    userGiftsPagination: pages_1.initialPaginationValues,
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "gift",
    initialState: initialState,
    reducers: {
        setLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setGiftCategories: function (state, action) {
            state.giftCategories = action.payload;
        },
        setSelectedGiftCategory: function (state, action) {
            state.selectedGiftCategory = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setGiftCategoriesPagination: function (state, action) {
            state.giftCategoriesPagination = action.payload;
        },
        setUserGifts: function (state, action) {
            state.userGifts = action.payload;
        },
        setUserGiftsPagination: function (state, action) {
            state.userGiftsPagination = action.payload;
        },
        setSelectedUserGift: function (state, action) {
            state.selectedUserGift = action.payload;
        },
    },
});
exports.setLoading = (_a = slice.actions, _a.setLoading), exports.setGiftCategories = _a.setGiftCategories, exports.setSelectedGiftCategory = _a.setSelectedGiftCategory, exports.setPagination = _a.setPagination, exports.setGiftCategoriesPagination = _a.setGiftCategoriesPagination, exports.setUserGifts = _a.setUserGifts, exports.setUserGiftsPagination = _a.setUserGiftsPagination, exports.setSelectedUserGift = _a.setSelectedUserGift;
exports.default = slice.reducer;
