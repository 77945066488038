"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setBasketStatusGrouping = exports.setParcelStatusGrouping = exports.setOrderStatusGrouping = exports.setOrderCount = exports.setOrderRevenue = exports.setDashboardBasketDataLoading = exports.setDashboardOrderDataLoading = exports.setBranchDashboard = exports.setUsersDashboard = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    usersDashboard: {},
    branchDashboard: {},
    orderRevenue: {},
    orderCount: {},
    orderStatusGrouping: [],
    parcelStatusGrouping: [],
    basketStatusGrouping: [],
    dashboardOrderDataLoading: false,
    dashboardBasketDataLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        setUsersDashboard: function (state, action) {
            state.usersDashboard = action.payload;
        },
        setBranchDashboard: function (state, action) {
            state.branchDashboard = action.payload;
        },
        setDashboardOrderDataLoading: function (state, action) {
            state.dashboardOrderDataLoading = action.payload;
        },
        setOrderRevenue: function (state, action) {
            state.orderRevenue = action.payload;
        },
        setOrderCount: function (state, action) {
            state.orderCount = action.payload;
        },
        setOrderStatusGrouping: function (state, action) {
            state.orderStatusGrouping = action.payload;
        },
        setParcelStatusGrouping: function (state, action) {
            state.parcelStatusGrouping = action.payload;
        },
        setBasketStatusGrouping: function (state, action) {
            state.basketStatusGrouping = action.payload;
        },
        setDashboardBasketDataLoading: function (state, action) {
            state.dashboardBasketDataLoading = action.payload;
        },
    },
});
exports.setUsersDashboard = (_a = slice.actions, _a.setUsersDashboard), exports.setBranchDashboard = _a.setBranchDashboard, exports.setDashboardOrderDataLoading = _a.setDashboardOrderDataLoading, exports.setDashboardBasketDataLoading = _a.setDashboardBasketDataLoading, exports.setOrderRevenue = _a.setOrderRevenue, exports.setOrderCount = _a.setOrderCount, exports.setOrderStatusGrouping = _a.setOrderStatusGrouping, exports.setParcelStatusGrouping = _a.setParcelStatusGrouping, exports.setBasketStatusGrouping = _a.setBasketStatusGrouping;
exports.default = slice.reducer;
