"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.get_client_filtered_deal_branches_thunk = exports.deal_branch_list_thunk = exports.user_branch_list_thunk = exports.branch_list_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var client_branches_1 = require("../../../../../api/calls/branch/get/client_branches");
var main_1 = require("../../../main");
var branch_1 = require("../../branch");
var get_client_user_branches_1 = require("../../../../../api/calls/client/get_client_user_branches");
var get_filtered_client_branches_1 = require("../../../../../api/calls/branch/get/get_filtered_client_branches");
exports.branch_list_thunk = (0, toolkit_1.createAsyncThunk)("branch/branch_list_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, user, pagination, _c, showLoader, countTotal, query, response, page, size;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _b = getState(), user = _b.userState.user, pagination = _b.branchState.pagination;
                    _c = arg !== null && arg !== void 0 ? arg : pagination, showLoader = _c.showLoader, countTotal = _c.countTotal, query = __rest(_c, ["showLoader", "countTotal"]);
                    if ((user === null || user === void 0 ? void 0 : user.role) === "BRANCH_MANAGER") {
                        query['branch_id_list'] = user === null || user === void 0 ? void 0 : user.manager_of;
                    }
                    dispatch((0, branch_1.setIsLoadingBranch)(showLoader !== null && showLoader !== void 0 ? showLoader : true));
                    return [4 /*yield*/, (0, client_branches_1.get_client_branches)(query)];
                case 1:
                    response = _d.sent();
                    dispatch((0, branch_1.setIsLoadingBranch)(false));
                    page = query.page, size = query.size;
                    if (response.success) {
                        dispatch((0, branch_1.setBranchList)(response.content.branch_list));
                        dispatch((0, branch_1.setTablePagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                        return [2 /*return*/, response];
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                    }
                    return [2 /*return*/];
            }
        });
    });
});
exports.user_branch_list_thunk = (0, toolkit_1.createAsyncThunk)("branch/user_branch_list_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var dashboardBranchPagination, _b, countTotal, query, response, page, size;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    dashboardBranchPagination = getState().branchState.dashboardBranchPagination;
                    _b = arg !== null && arg !== void 0 ? arg : dashboardBranchPagination, countTotal = _b.countTotal, query = __rest(_b, ["countTotal"]);
                    dispatch((0, branch_1.setIsLoadingBranch)(true));
                    return [4 /*yield*/, (0, client_branches_1.get_client_branches)(query)];
                case 1:
                    response = _c.sent();
                    dispatch((0, branch_1.setIsLoadingBranch)(false));
                    page = query.page, size = query.size;
                    if (response.success) {
                        dispatch((0, branch_1.setDashboardBranchList)(response.content.branch_list));
                        dispatch((0, branch_1.setDashboardBranchPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                        return [2 /*return*/, response];
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching dashboard branch list!", messages: [response.message] }));
                    }
                    return [2 /*return*/];
            }
        });
    });
});
exports.deal_branch_list_thunk = (0, toolkit_1.createAsyncThunk)("branch/deal_branch_list_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var user, countTotal, query, response, page, size;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    user = getState().userState.user;
                    countTotal = arg.countTotal, query = __rest(arg, ["countTotal"]);
                    dispatch((0, branch_1.setIsLoadingBranch)(true));
                    if (!((user === null || user === void 0 ? void 0 : user.role) === "ADMIN")) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, client_branches_1.get_client_branches)(query)];
                case 1:
                    response = _c.sent();
                    dispatch((0, branch_1.setIsLoadingBranch)(false));
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, (0, get_client_user_branches_1.get_logged_in_client_user_branches)({
                        page: String(query === null || query === void 0 ? void 0 : query.page),
                        size: String(query === null || query === void 0 ? void 0 : query.size),
                        branch_id_list: user === null || user === void 0 ? void 0 : user.manager_of,
                    })];
                case 3:
                    response = _c.sent();
                    _c.label = 4;
                case 4:
                    page = query.page, size = query.size;
                    if (response.success) {
                        dispatch((0, branch_1.setDealBranchList)((_b = response.content.branch_list) !== null && _b !== void 0 ? _b : []));
                        dispatch((0, branch_1.setDealBranchPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                        return [2 /*return*/, response];
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching deals branch list!", messages: [response.message] }));
                    }
                    return [2 /*return*/];
            }
        });
    });
});
exports.get_client_filtered_deal_branches_thunk = (0, toolkit_1.createAsyncThunk)("product/get_client_filtered_deal_branches_thunk", function (query, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var user, countTotal, newQuery, response, page, size;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    user = getState().userState.user;
                    countTotal = query.countTotal, newQuery = __rest(query, ["countTotal"]);
                    dispatch((0, branch_1.setIsLoadingBranch)(true));
                    if (!((user === null || user === void 0 ? void 0 : user.role) === "ADMIN")) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, get_filtered_client_branches_1.get_client_filtered_branches)({
                            page: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.page),
                            size: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.size),
                            branch_exception_list: newQuery === null || newQuery === void 0 ? void 0 : newQuery.branch_exception_list,
                        })];
                case 1:
                    response = _d.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, (0, get_client_user_branches_1.get_logged_in_client_user_branches)({
                        page: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.page),
                        size: String(newQuery === null || newQuery === void 0 ? void 0 : newQuery.size),
                        branch_id_list: user === null || user === void 0 ? void 0 : user.manager_of,
                    })];
                case 3:
                    response = _d.sent();
                    _d.label = 4;
                case 4:
                    dispatch((0, branch_1.setIsLoadingBranch)(false));
                    page = query.page, size = query.size;
                    if (response.success) {
                        dispatch((0, branch_1.setClientFilteredDealBranches)((_c = (_b = response.content) === null || _b === void 0 ? void 0 : _b.branch_list) !== null && _c !== void 0 ? _c : []));
                        dispatch((0, branch_1.setClientFilteredDealBranchesPagination)({
                            page: page,
                            size: size,
                            countTotal: response.content.count,
                        }));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong fetching filtered deal branches!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
