"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPagination = exports.setIsLoading = exports.setSelectedCustomerSegment = exports.setCustomerSegments = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    customer_segments: [],
    isLoading: false,
    pagination: pages_1.initialPaginationValues,
    selectedCustomerSegment: {
        title: "",
        description: "",
        discount_type: "",
        discount: "",
        customers: [],
        apply_to_products_on_promotion: false,
        segment_rule: "",
    },
};
var slice = (0, toolkit_1.createSlice)({
    name: "customer_segments",
    initialState: initialState,
    reducers: {
        setCustomerSegments: function (state, action) {
            state.customer_segments = action.payload;
        },
        setSelectedCustomerSegment: function (state, action) {
            state.selectedCustomerSegment = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
    },
});
exports.setCustomerSegments = (_a = slice.actions, _a.setCustomerSegments), exports.setSelectedCustomerSegment = _a.setSelectedCustomerSegment, exports.setIsLoading = _a.setIsLoading, exports.setPagination = _a.setPagination;
exports.default = slice.reducer;
