"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLoading = exports.deleteChannel = exports.editChannel = exports.addChannel = exports.setChannels = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    channels: [],
    loading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "channel",
    initialState: initialState,
    reducers: {
        setChannels: function (state, action) {
            state.channels = action.payload;
        },
        addChannel: function (state, action) {
            state.channels.push(action.payload);
        },
        editChannel: function (state, action) {
            state.channels = state.channels.map(function (x) {
                if (x._id === action.payload._id) {
                    return action.payload;
                }
                return x;
            });
        },
        deleteChannel: function (state, action) {
            state.channels.filter(function (x) { return x._id !== action.payload; });
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
    },
});
exports.setChannels = (_a = slice.actions, _a.setChannels), exports.addChannel = _a.addChannel, exports.editChannel = _a.editChannel, exports.deleteChannel = _a.deleteChannel, exports.setLoading = _a.setLoading;
exports.default = slice.reducer;
