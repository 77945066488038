"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchFilterControl = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var BranchFilterControl = function (props) {
    var title = props.title, value = props.value, onButtonClick = props.onButtonClick;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center justify-center w-32 bg-white rounded-md" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: title, value: value, size: "medium", textColor: "text-textInput", onChange: function (v) { return onButtonClick(v); } }) })));
};
exports.BranchFilterControl = BranchFilterControl;
