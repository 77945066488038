"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_redux_1 = require("react-redux");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var get_client_condiments_thunk_1 = require("../../../../../../reducers/slices/condiments/thunks/get_client_condiments_thunk");
var main_1 = require("../../../../../../reducers/slices/main");
var sanitiseObject_1 = require("../../../../../../helpers/sanitiseObject");
var update_product_1 = require("../../../../../../api/calls/product/update_product");
var unit_constants_1 = require("./unit_constants");
var CondimentGroupsComponent = function (props) {
    var _a, _b;
    var values = props.values, setValues = props.setValues;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), searchCondiments = _c[0], setSearchCondiments = _c[1];
    var _d = (0, useSearchDebounce_1.useSearchDebounce)(), searchAddOnProducts = _d[0], setSearchAddOnProducts = _d[1];
    var _e = (0, react_1.useState)(""), selectedCondiment = _e[0], setSelectedCondiment = _e[1];
    var _f = (0, react_1.useState)(false), showSaveChangesLabel = _f[0], setShowSaveChangesLabel = _f[1];
    var _g = (0, react_1.useState)([]), condimentItems = _g[0], setCondimentsItems = _g[1];
    var _h = (0, react_1.useState)(1), currentPage = _h[0], setCurrentPage = _h[1];
    var _j = (0, react_1.useState)(10), rowsPerPage = _j[0], setRowsPerPage = _j[1];
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _k = (0, react_redux_1.useSelector)(function (state) { return state.condimentState; }), condiments = _k.condiments, pagination = _k.pagination, loading = _k.loading;
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_client_condiments_thunk_1.get_condiments_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(searchCondiments);
    }, [searchCondiments]);
    var handleDropdownChange = function (v) {
        setSelectedCondiment(v);
        var group = condiments === null || condiments === void 0 ? void 0 : condiments.find(function (selectedProduct) { return (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct._id) === (v === null || v === void 0 ? void 0 : v.value); });
        var groupExists = values.condiments.some(function (item) { return item._id === (group === null || group === void 0 ? void 0 : group._id); });
        if (!groupExists) {
            var updatedCondiments = __spreadArray(__spreadArray([], values.condiments, true), [group], false);
            setValues(__assign(__assign({}, values), { condiments: updatedCondiments }));
            setShowSaveChangesLabel(true);
        }
        else {
            dispatch((0, main_1.setMessageModal)({ title: "Condiment Group already exists!", messages: ["Please select a different condiment group."] }));
        }
    };
    var getCondiments = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_client_condiments_thunk_1.get_condiments_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getCondiments(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChangeCondiments = function (page) {
        setCurrentPage(page);
        if (searchCondiments === "") {
            return getCondiments(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            return getCondiments(__assign(__assign({}, pagination), { page: page, search_string: searchCondiments }));
        }
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "mb-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Condiment Groups", bold: true, textColor: "text-black" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4 mb-2" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-80" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) { return v.label; }, label: "Select Condiment Group(s)", required: true, options: (_a = condiments === null || condiments === void 0 ? void 0 : condiments.map(function (group, i) {
                            return { label: group.type, value: group === null || group === void 0 ? void 0 : group._id };
                        })) !== null && _a !== void 0 ? _a : [], colors: {
                            labelColor: "text-black",
                            textColor: "text-black",
                            borderColor: "border-borders",
                            iconColor: "text-black",
                        }, size: "small", value: selectedCondiment, errorText: "", onChange: handleDropdownChange, onSearchChanged: function (search_string) {
                            setTimeout(function () {
                                setSearchCondiments(search_string);
                            }, 2000);
                        }, pagination: {
                            currentPage: currentPage,
                            recordsPerPage: rowsPerPage,
                            totalRecords: pagination === null || pagination === void 0 ? void 0 : pagination.countTotal,
                            position: "center",
                            onClick: function (page) {
                                onPageChangeCondiments(page);
                            },
                        } }) })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-10 gap-2 bg-black max-w-none rounded p-2 mb-2 overflow-auto" }, { children: unit_constants_1.condimentsTableColumns.map(function (_a, index) {
                    var label = _a.label;
                    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 flex items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere text-white", size: "small", textColor: "text-black", bold: true }) }), index));
                }) })), (_b = values === null || values === void 0 ? void 0 : values.condiments) === null || _b === void 0 ? void 0 : _b.map(function (group, index) {
                var _a;
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "p-2 bg-white shadow-md rounded-lg mb-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-10 w-full gap-2 items-center pb-2" }, { children: unit_constants_1.condimentsTableColumns.map(function (_a, i) {
                                var displayKey = _a.displayKey;
                                switch (displayKey) {
                                    case "minimum_quantity":
                                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 flex flex-row gap-2 items-center" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-14" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: group.minimum_quantity, size: "small", type: "number", onChange: function (v) {
                                                        var updatedGroup = __assign(__assign({}, group), { minimum_quantity: v });
                                                        var updatedData = values === null || values === void 0 ? void 0 : values.condiments.map(function (item) {
                                                            if (item._id === group._id) {
                                                                return updatedGroup;
                                                            }
                                                            return item;
                                                        });
                                                        setValues(__assign(__assign({}, values), { condiments: updatedData }));
                                                    } }) })) }), i));
                                    case "maximum_quantity":
                                        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-2 flex flex-row gap-2 items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-14" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: group.maximum_quantity, size: "small", type: "number", onChange: function (v) {
                                                            var updatedGroup = __assign(__assign({}, group), { maximum_quantity: v });
                                                            var updatedData = values === null || values === void 0 ? void 0 : values.condiments.map(function (item) {
                                                                if (item._id === group._id) {
                                                                    return updatedGroup;
                                                                }
                                                                return item;
                                                            });
                                                            setValues(__assign(__assign({}, values), { condiments: updatedData }));
                                                        } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "pl-8" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { size: "2xsmall", icon: pro_light_svg_icons_1.faTrash, backgroundColor: "bg-red-400", iconColor: "text-white", borderColor: "border-transparent", onClick: function () {
                                                            var _a;
                                                            var filteredGroups = (_a = values === null || values === void 0 ? void 0 : values.condiments) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return (item === null || item === void 0 ? void 0 : item._id) !== (group === null || group === void 0 ? void 0 : group._id); });
                                                            setValues(__assign(__assign({}, values), { condiments: __spreadArray([], filteredGroups, true) }));
                                                            setShowSaveChangesLabel(true);
                                                        } }) }))] }), i));
                                    case "required":
                                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: group === null || group === void 0 ? void 0 : group.required, onChange: function (v) {
                                                    var updatedGroup = __assign(__assign({}, group), { required: v });
                                                    var updatedData = values === null || values === void 0 ? void 0 : values.condiments.map(function (item) {
                                                        if (item._id === group._id) {
                                                            return updatedGroup;
                                                        }
                                                        return item;
                                                    });
                                                    setValues(__assign(__assign({}, values), { condiments: updatedData }));
                                                    setShowSaveChangesLabel(true);
                                                } }) }), i));
                                    case "multiple":
                                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: group === null || group === void 0 ? void 0 : group.multiple, onChange: function (v) {
                                                    var updatedGroup = __assign(__assign({}, group), { multiple: v });
                                                    var updatedData = values === null || values === void 0 ? void 0 : values.condiments.map(function (item) {
                                                        if (item._id === group._id) {
                                                            return updatedGroup;
                                                        }
                                                        return item;
                                                    });
                                                    setValues(__assign(__assign({}, values), { condiments: updatedData }));
                                                    setShowSaveChangesLabel(true);
                                                } }) }), i));
                                    default:
                                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: group[displayKey], size: "small", textColor: "text-black", bold: true }) }), i));
                                }
                            }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-10 gap-2 w-full mb-2 p-2 bg-gray-400 rounded-lg text-white" }, { children: unit_constants_1.condimentGroupProductTableColumns.map(function (_a, index) {
                                var label = _a.label;
                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 flex items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere text-white", size: "small", textColor: "text-black", bold: true }) }), index));
                            }) })), (_a = group === null || group === void 0 ? void 0 : group.product_list) === null || _a === void 0 ? void 0 : _a.map(function (product, index) {
                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-10 w-full gap-2 items-center pb-2" }, { children: unit_constants_1.condimentGroupProductTableColumns.map(function (_a, i) {
                                    var displayKey = _a.displayKey;
                                    switch (displayKey) {
                                        case "required":
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: product === null || product === void 0 ? void 0 : product.required, onChange: function (v) {
                                                        var _a;
                                                        var updatedProduct = __assign(__assign({}, product), { required: v });
                                                        var updatedData = (_a = values === null || values === void 0 ? void 0 : values.condiments) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                                                            if (item._id === group._id) {
                                                                var updatedProductList = item.product_list.map(function (productItem) {
                                                                    if (productItem._id === product._id) {
                                                                        return updatedProduct;
                                                                    }
                                                                    return productItem;
                                                                });
                                                                return __assign(__assign({}, item), { product_list: updatedProductList });
                                                            }
                                                            return item;
                                                        });
                                                        setValues(__assign(__assign({}, values), { condiments: __spreadArray([], updatedData, true) }));
                                                        setShowSaveChangesLabel(true);
                                                    } }) }), i));
                                        case "default":
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: product === null || product === void 0 ? void 0 : product.default, onChange: function (v) {
                                                        var _a;
                                                        var updatedProduct = __assign(__assign({}, product), { default: v });
                                                        var updatedData = (_a = values === null || values === void 0 ? void 0 : values.condiments) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                                                            if (item._id === group._id) {
                                                                var updatedProductList = item.product_list.map(function (productItem) {
                                                                    if (productItem._id === product._id) {
                                                                        return updatedProduct;
                                                                    }
                                                                    return productItem;
                                                                });
                                                                return __assign(__assign({}, item), { product_list: updatedProductList });
                                                            }
                                                            return item;
                                                        });
                                                        setValues(__assign(__assign({}, values), { condiments: __spreadArray([], updatedData, true) }));
                                                        setShowSaveChangesLabel(true);
                                                    } }) }), i));
                                        default:
                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product[displayKey], size: "xsmall", textColor: "text-black" }) }), i));
                                    }
                                }) }), index));
                        })] }), index));
            }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-end" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px] mt-2 pb-1" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Save Groups", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var params, transformedCondiments, response;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            params = (0, sanitiseObject_1.sanitiseObject)(__assign({}, values));
                                            transformedCondiments = (_a = params === null || params === void 0 ? void 0 : params.condiments) === null || _a === void 0 ? void 0 : _a.map(function (condiment) {
                                                var defaultProduct = condiment.product_list.find(function (product) { return product.default; });
                                                var requiredProducts = condiment.product_list.filter(function (product) { return product.required; }).map(function (product) { return product._id; });
                                                return {
                                                    condiment_id: condiment._id,
                                                    default_product_id: defaultProduct ? defaultProduct._id : null,
                                                    required_products: requiredProducts,
                                                };
                                            });
                                            params.condiments = transformedCondiments;
                                            return [4 /*yield*/, (0, update_product_1.update_product)(params)];
                                        case 1:
                                            response = _b.sent();
                                            if (response.success) {
                                                setShowSaveChangesLabel(false);
                                                dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                            }
                                            else {
                                                setShowSaveChangesLabel(false);
                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when updating product details!", messages: [response.message] }));
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }) })), showSaveChangesLabel && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Save Changes...", size: "xsmall", textColor: "text-red-600" }) }))] }))] })));
};
exports.default = CondimentGroupsComponent;
