"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filters = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var get_category_thunk_1 = require("../../../../../reducers/slices/products/categories/thunks/get_category_thunk");
var Filters = function (_a) {
    var _b;
    var onSubmit = _a.onSubmit;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.categoryState; }), categories = _c.categories, pagination = _c.pagination;
    var _d = (0, react_1.useState)(""), fromDate = _d[0], setFromDate = _d[1];
    var _e = (0, react_1.useState)(""), toDate = _e[0], setToDate = _e[1];
    var _f = (0, react_1.useState)(""), selectedCategoryType = _f[0], setSelectedCategoryType = _f[1];
    var _g = (0, react_1.useState)(""), searchCategories = _g[0], setSearchCategories = _g[1];
    var _h = (0, react_1.useState)([]), subCategories = _h[0], setSubCategories = _h[1];
    var _j = (0, react_1.useState)(""), selectedSubCategoryType = _j[0], setSelectedSubCategoryType = _j[1];
    var getCategories = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_category_thunk_1.get_category_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getCategories(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var handleSubmit = function () {
        var _a;
        onSubmit(__assign(__assign(__assign({}, (fromDate &&
            toDate && {
            createdAt: {
                $gte: new Date(fromDate),
                $lte: new Date(toDate),
            },
        })), (selectedSubCategoryType && { "product_list.category.sub_categories._id": selectedSubCategoryType === null || selectedSubCategoryType === void 0 ? void 0 : selectedSubCategoryType._id })), (selectedCategoryType && !((_a = selectedCategoryType === null || selectedCategoryType === void 0 ? void 0 : selectedCategoryType.sub_categories) === null || _a === void 0 ? void 0 : _a.length) && { "product_list.category._id": selectedCategoryType === null || selectedCategoryType === void 0 ? void 0 : selectedCategoryType._id })));
    };
    (0, react_1.useEffect)(function () {
        onSearch(searchCategories);
    }, [searchCategories]);
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if ((_a = selectedCategoryType === null || selectedCategoryType === void 0 ? void 0 : selectedCategoryType.sub_categories) === null || _a === void 0 ? void 0 : _a.length) {
            setSubCategories((_b = selectedCategoryType === null || selectedCategoryType === void 0 ? void 0 : selectedCategoryType.sub_categories) === null || _b === void 0 ? void 0 : _b.map(function (subCategory) {
                var name = subCategory.name, _id = subCategory._id;
                return __assign({ label: name, value: _id }, subCategory);
            }));
        }
    }, [selectedCategoryType]);
    (0, react_1.useEffect)(function () {
        var _a;
        if ((selectedCategoryType && !((_a = selectedCategoryType === null || selectedCategoryType === void 0 ? void 0 : selectedCategoryType.sub_categories) === null || _a === void 0 ? void 0 : _a.length)) || selectedSubCategoryType) {
            handleSubmit();
        }
    }, [selectedCategoryType, selectedSubCategoryType]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row justify-between items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-auto bg-white p-4 text-center cursor-pointer rounded-lg" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.PopoverControl, __assign({ content: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 bg-white border-borders" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { label: "From Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: fromDate, onChange: function (v) {
                                    setFromDate(doshx_controls_web_1.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                }, size: "small" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { label: "To Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: toDate, onChange: function (v) {
                                    setToDate(doshx_controls_web_1.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                }, size: "small" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { label: "apply", size: "xsmall", onClick: handleSubmit }) }))] })), behavior: "click" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: " ".concat(fromDate && toDate ? "Period: " + fromDate + " - " + toDate : "Date Filter") }) })) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-2 justify-between items-center " }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-64 bg-white p-2 text-center cursor-pointer rounded-lg" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) {
                                return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: v.name });
                            }, label: "Product Category", required: true, options: categories !== null && categories !== void 0 ? categories : [], colors: {
                                labelColor: "text-black",
                                textColor: "text-black",
                                borderColor: "border-borders",
                                iconColor: "text-black",
                            }, size: "small", value: selectedCategoryType, errorText: "", onChange: function (x) {
                                setSelectedCategoryType(x);
                                setSearchCategories("");
                            }, onSearchChanged: function (search_string) {
                                setSearchCategories(search_string);
                            } }) })), ((_b = selectedCategoryType === null || selectedCategoryType === void 0 ? void 0 : selectedCategoryType.sub_categories) === null || _b === void 0 ? void 0 : _b.length) > 0 && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-64 bg-white p-2 text-center cursor-pointer rounded-lg" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.NewDropdownControl, { label: "Sub Categories", required: true, options: subCategories, renderDisplay: function (item) {
                                return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: item.label });
                            }, colors: {
                                textColor: "text-black",
                                labelColor: "text-black",
                                borderColor: "border-borders",
                                iconColor: "text-primary",
                            }, size: "small", value: selectedSubCategoryType, onChange: function (v) {
                                setSelectedSubCategoryType(v);
                            }, errorText: "" }) })))] }))] })));
};
exports.Filters = Filters;
