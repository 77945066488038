"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var ChannelComponent = function (props) {
    var logo = props.logo, label = props.label, learnMoreLink = props.learnMoreLink, onClick = props.onClick, title = props.title, isIntegratedMessage = props.isIntegratedMessage, optionalButton = props.optionalButton, optionalButtonProps = props.optionalButtonProps;
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "flex flex-col justify-between items-center text-center h-full gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col justify-center items-center gap-4" }, { children: [logo && (0, jsx_runtime_1.jsx)("img", { src: logo, className: "flex max-h-[75px] max-w-[70%]" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: title }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, center: true, size: "xsmall" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)("a", __assign({ href: learnMoreLink, className: "text-blue-600" }, { children: "Learn more" })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "".concat(optionalButton ? "flex gap-4" : "") }, { children: [isIntegratedMessage ? ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: isIntegratedMessage, center: true })) : ((0, jsx_runtime_1.jsx)("div", __assign({ className: "mt-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { startIcon: pro_light_svg_icons_1.faLink, label: "Edit Connection", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return onClick(); } }) }))), optionalButton && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "mt-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { loading: optionalButtonProps === null || optionalButtonProps === void 0 ? void 0 : optionalButtonProps.loading, label: optionalButtonProps === null || optionalButtonProps === void 0 ? void 0 : optionalButtonProps.label, size: "small", backgroundColor: "bg-borders", textColor: "text-black", onClick: function () { return optionalButtonProps === null || optionalButtonProps === void 0 ? void 0 : optionalButtonProps.onClick(); } }) })))] }))] }))] })));
};
exports.default = ChannelComponent;
