"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var lets_trade_utils_1 = require("lets-trade-utils");
var constants_1 = require("../screens/console/pages/configurations/pim/constants");
exports.Status = (0, react_1.memo)((0, react_1.forwardRef)(function (props, forwardedRef) {
    var className = props.className, status = props.status;
    var statusColor = constants_1.statusColorMap[status];
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ ref: forwardedRef, className: (0, react_2.cn)("flex w-fit items-center gap-[2px] rounded-lg bg-default-100 px-2 py-1", className) }, { children: [statusColor, (0, jsx_runtime_1.jsx)("span", __assign({ className: "px-1 text-default-800" }, { children: lets_trade_utils_1.Text.capitalizeFirst(status) }))] })));
}));
exports.Status.displayName = "Status";
