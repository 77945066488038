"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketService = void 0;
var socket_io_client_1 = require("socket.io-client");
var managerOptions = {
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    transports: ["websocket"],
};
var SocketService = /** @class */ (function () {
    function SocketService(socketData, token) {
        this.handlers = {};
        this.socketData = socketData;
        this.sockets = socketData.map(function (_a) {
            var url = _a.url;
            var socketOptions = __assign({ auth: {
                    token: token,
                } }, managerOptions);
            var manager = new socket_io_client_1.Manager(url, managerOptions);
            return manager.socket("/", socketOptions);
        });
    }
    SocketService.prototype.startListening = function () {
        for (var _i = 0, _a = this.socketData; _i < _a.length; _i++) {
            var _b = _a[_i], eventName = _b.eventName, callback = _b.callback;
            this.handlers[eventName] = callback;
            for (var _c = 0, _d = this.sockets; _c < _d.length; _c++) {
                var socket = _d[_c];
                socket.on(eventName, callback);
            }
        }
    };
    SocketService.prototype.stopListening = function () {
        for (var _i = 0, _a = this.socketData; _i < _a.length; _i++) {
            var eventName = _a[_i].eventName;
            var callback = this.handlers[eventName];
            if (callback) {
                for (var _b = 0, _c = this.sockets; _b < _c.length; _b++) {
                    var socket = _c[_b];
                    socket.off(eventName, callback);
                }
                delete this.handlers[eventName];
            }
        }
    };
    SocketService.prototype.emitEvent = function (eventName, data) {
        for (var _i = 0, _a = this.sockets; _i < _a.length; _i++) {
            var socket = _a[_i];
            socket.emit(eventName, data);
        }
    };
    SocketService.prototype.close = function () {
        for (var _i = 0, _a = this.sockets; _i < _a.length; _i++) {
            var socket = _a[_i];
            socket.disconnect();
        }
    };
    return SocketService;
}());
exports.SocketService = SocketService;
