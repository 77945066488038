"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentPlanPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var entry_1 = require("../../../../../reducers/slices/entry");
var sign_out_thunk_1 = require("../../../../../reducers/slices/user/thunks/sign_out_thunk");
var PaymentPlanPage = function (_a) {
    var _b = _a.hasBackButton, hasBackButton = _b === void 0 ? true : _b;
    var domain = window.location.host;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _c.user, isLoading = _c.isLoading;
    var integration_settings = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client.integration_settings;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full  lg:w-[1000px] pt-8" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "large", label: "Please select your subscription", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "medium", label: "pick the perfect subscription to your suit your buisness ." }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-10" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-black", addPadding: true }) })), (0, jsx_runtime_1.jsx)("stripe-pricing-table", { "pricing-table-id": process.env.STRIPE_PRICING_TABLE_ID, "publishable-key": process.env.STRIPE_PUBLISHABLE_KEY, "client-reference-id": integration_settings === null || integration_settings === void 0 ? void 0 : integration_settings.payment_engine_id, "customer-email": user === null || user === void 0 ? void 0 : user.email }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[200px] pt-8" }, { children: hasBackButton ? ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Back", backgroundColor: "bg-black", loading: isLoading, onClick: function () {
                        dispatch((0, entry_1.setCurrentStep)(2));
                    } })) : ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Log out", backgroundColor: entryTheme.config.colors.buttons.bgPrimary, loading: isLoading, onClick: function () {
                        dispatch((0, sign_out_thunk_1.sign_out_thunk)()).then(function () {
                            navigate(routes_1.WELCOME_ROUTE);
                        });
                    } })) }))] })));
};
exports.PaymentPlanPage = PaymentPlanPage;
