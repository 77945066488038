"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPagination = exports.setLoading = exports.setDeals = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    deals: [],
    loading: false,
    pagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "deals",
    initialState: initialState,
    reducers: {
        setDeals: function (state, action) {
            state.deals = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
    },
});
exports.setDeals = (_a = slice.actions, _a.setDeals), exports.setLoading = _a.setLoading, exports.setPagination = _a.setPagination;
exports.default = slice.reducer;
