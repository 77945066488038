"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressSearch = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var react_2 = require("react");
var AddressSearch = function (props) {
    var _a = props.letsTradeAPIUrl, letsTradeAPIUrl = _a === void 0 ? process.env.LETS_TRADE_CLIENT_URL : _a, fullAddress = props.fullAddress, onSelectionChange = props.onSelectionChange, _props = __rest(props, ["letsTradeAPIUrl", "fullAddress", "onSelectionChange"]);
    var _b = (0, react_2.useState)([]), predictions = _b[0], setPredictions = _b[1];
    var _c = (0, react_2.useState)(""), addressInput = _c[0], setAddressInput = _c[1];
    var _d = (0, react_2.useState)(null), selectedKey = _d[0], setSelectedKey = _d[1];
    (0, react_2.useEffect)(function () {
        setSelectedKey(fullAddress);
    }, [fullAddress]);
    (0, react_2.useEffect)(function () {
        if (addressInput) {
            var timeout_1 = setTimeout(function () {
                fetch("".concat(letsTradeAPIUrl, "/v2/google/autocomplete?input=").concat(addressInput), {
                    method: "GET",
                })
                    .then(function (response) { return response.json(); })
                    .then(function (data) {
                    if (data.success) {
                        // console.log("response1", data.content);
                        setPredictions(data.content);
                    }
                    else {
                        console.log("Address Search ERROR", data.message);
                    }
                });
            }, 1000);
            return function () { return clearTimeout(timeout_1); };
        }
    }, [addressInput]);
    return ((0, jsx_runtime_1.jsx)(react_1.Autocomplete, __assign({ label: "Address", placeholder: "Enter address", items: predictions, inputValue: addressInput, selectedKey: selectedKey, onInputChange: setAddressInput, onSelectionChange: function (key) {
            var address = predictions.find(function (item) { return item.description === key; });
            if (!address) {
                console.log("Address Select ERROR on find", key);
                return;
            }
            var place_id = address.place_id;
            fetch("".concat(letsTradeAPIUrl, "/v2/google/placeiddetails?place_id=").concat(place_id), {
                method: "GET",
            })
                .then(function (response) { return response.json(); })
                .then(function (data) {
                var _a, _b, _c, _d, _e, _f, _g;
                if (!data.success) {
                    console.log("Address Select ERROR", data.message);
                    return;
                }
                // console.log("response1", data.content);
                var _h = data.content, geometry = _h.geometry, address_components = _h.address_components;
                var lat = geometry.location.lat.toString();
                var lng = geometry.location.lng.toString();
                var obj = {
                    fullAddress: address.description,
                    coordinates: {
                        lat: lat.toString(),
                        lng: lng.toString(),
                    },
                    street_number: (_a = address_components.find(function (c) { return c.types.includes("street_number"); })) === null || _a === void 0 ? void 0 : _a.long_name,
                    street_name: (_b = address_components.find(function (c) { return c.types.includes("route"); })) === null || _b === void 0 ? void 0 : _b.long_name,
                    suburb: (_c = address_components.find(function (c) { return c.types.includes("sublocality"); })) === null || _c === void 0 ? void 0 : _c.long_name,
                    city: (_d = address_components.find(function (c) { return c.types.includes("locality"); })) === null || _d === void 0 ? void 0 : _d.long_name,
                    province: (_e = address_components.find(function (c) { return c.types.includes("administrative_area_level_1"); })) === null || _e === void 0 ? void 0 : _e.long_name,
                    country: (_f = address_components.find(function (c) { return c.types.includes("country"); })) === null || _f === void 0 ? void 0 : _f.long_name,
                    postal_code: (_g = address_components.find(function (c) { return c.types.includes("postal_code"); })) === null || _g === void 0 ? void 0 : _g.long_name,
                };
                setSelectedKey(address.description);
                onSelectionChange && onSelectionChange(obj);
            });
        } }, _props, { children: function (item) { return (0, jsx_runtime_1.jsx)(react_1.AutocompleteItem, { children: item.description }, item.description); } })));
};
exports.AddressSearch = AddressSearch;
