"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateClientPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var company_details_1 = require("./tabs/company_details");
var company_information_1 = require("./tabs/company_information");
var client_details_1 = require("./tabs/client_details");
var offering_list_1 = require("../../../../api/calls/client/offering_list");
var registration_1 = require("../../../../reducers/slices/registration");
var CreateClientPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var currentStep = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).currentStep;
    (0, react_1.useEffect)(function () {
        (0, offering_list_1.offering_list)().then(function (response) {
            dispatch((0, registration_1.setOfferings)(response.content[0]));
        });
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full flex flex-col h-full justify-center overflow-y-auto " }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "overflow-y-auto overflow-x-hidden  px-8" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.StepperControl
            // showDefaultButtons={true}
            , { 
                // showDefaultButtons={true}
                orientation: "horizontal", currentStep: currentStep, stepPillBackgroundColor: entryTheme.config.colors.buttons.bgPrimary, steps: [
                    // { label: "Test Stripe", component: <PaymentPlanPage /> },
                    { label: "Create Account", component: (0, jsx_runtime_1.jsx)(client_details_1.ClientDetailsPage, {}) },
                    { label: "Company Details", component: (0, jsx_runtime_1.jsx)(company_details_1.CompanyDetailsPage, {}) },
                    { label: "Company Information", component: (0, jsx_runtime_1.jsx)(company_information_1.CompanyInformationPage, {}) },
                    // { label: "Payment Plan", component: <PaymentPlanPage /> },
                ] }) })) })));
};
exports.CreateClientPage = CreateClientPage;
