"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchTermPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var overview_1 = require("./components/overview");
var searches_1 = require("./components/searches");
var search_without_results_1 = require("./components/search_without_results");
var searches_without_clicks_1 = require("./components/searches_without_clicks");
var term_storage_1 = require("./components/term_storage");
var SearchTermPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _a = (0, react_1.useState)(0), selectedTabIndex = _a[0], setSelectedTabIndex = _a[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Search Term"));
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "rounded-md p-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TabControl, { selectedTabIndex: selectedTabIndex, tabs: [
                { title: "Overview", content: (0, jsx_runtime_1.jsx)(overview_1.OverviewComponent, { selectedTabIndex: selectedTabIndex }) },
                {
                    title: "Searches",
                    content: (0, jsx_runtime_1.jsx)(searches_1.SearchesComponent, {}),
                },
                { title: "Searches without results", content: (0, jsx_runtime_1.jsx)(search_without_results_1.SearchesWithoutResultsComponent, { selectedTabIndex: selectedTabIndex }) },
                { title: "Searches without click", content: (0, jsx_runtime_1.jsx)(searches_without_clicks_1.SearchesWithoutClicksComponent, { selectedTabIndex: selectedTabIndex }) },
                { title: "Term Storage", content: (0, jsx_runtime_1.jsx)(term_storage_1.TermStorageComponent, {}) },
            ], colors: {
                // borderColor: "border-white",
                tabs: {
                    textColor: "text-gray-800",
                    indicatorColor: "bg-primary",
                    backgroundColor: "bg-white",
                    unselectedBackgroundColor: "bg-white",
                    selectedBackgroundColor: "bg-white",
                },
            } }) })));
};
exports.SearchTermPage = SearchTermPage;
