"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var user_1 = require("./user");
var users_table_1 = require("./users_table");
var UsersPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)(); //<AppDispatch>
    (0, react_1.useEffect)(function () { }, []);
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(users_table_1.UsersTablePage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.USER_ROUTE, "/:userId?"), element: (0, jsx_runtime_1.jsx)(user_1.UserPage, {}) })] }) }));
};
exports.UsersPage = UsersPage;
