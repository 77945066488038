"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.create_branch_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var create_branch_1 = require("../../../../api/calls/branch/create_branch");
var main_1 = require("../../main");
var branch_1 = require("../branch");
var branch_list_thunk_1 = require("./get/branch_list_thunk");
exports.create_branch_thunk = (0, toolkit_1.createAsyncThunk)("branch/create_branch_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, branchState, selectedBranch, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    state = getState();
                    console.log("create_branch_thunk STATE : ", state);
                    branchState = state.branchState;
                    selectedBranch = branchState.selectedBranch;
                    // Indicate that the API is being called.
                    return [4 /*yield*/, dispatch((0, branch_1.setIsLoadingBranch)(true))];
                case 1:
                    // Indicate that the API is being called.
                    _b.sent();
                    return [4 /*yield*/, (0, create_branch_1.create_branch)({
                            franchise: selectedBranch.franchise,
                            display_name: selectedBranch.display_name,
                            branch_number: selectedBranch.branch_number,
                            email: selectedBranch.email,
                            offline: true,
                            image_url: selectedBranch.image_url,
                            registered_name: selectedBranch.registered_name,
                            registration_number: selectedBranch.registration_number,
                            vat_registered: selectedBranch.vat_registered,
                            vat_number: selectedBranch.vat_number,
                            address: {
                                full_address: selectedBranch.address.full_address,
                                latitude: selectedBranch.address.latitude,
                                longitude: selectedBranch.address.longitude,
                                town: selectedBranch.address.town,
                                city: selectedBranch.address.city,
                                province: selectedBranch.address.province,
                                country: selectedBranch.address.country,
                                postal_code: selectedBranch.address.postal_code,
                            },
                            contact_number: selectedBranch.contact_number,
                            country_code: selectedBranch.country_code,
                            country_code_extension: selectedBranch.country_code_extension,
                            banking_details: selectedBranch.franchise
                                ? {
                                    account_number: selectedBranch.banking_details.account_number,
                                    account_type: selectedBranch.banking_details.account_type,
                                    branch_code: selectedBranch.banking_details.branch_code,
                                    name: selectedBranch.banking_details.name,
                                }
                                : undefined,
                        })];
                case 2:
                    response = _b.sent();
                    // Indicate that the API is done being called.
                    return [4 /*yield*/, dispatch((0, branch_1.setIsLoadingBranch)(false))];
                case 3:
                    // Indicate that the API is done being called.
                    _b.sent();
                    // Handle the response.
                    if (response.success) {
                        console.log("create_branch_thunk content : ", response.content);
                        dispatch((0, branch_1.setSelectedBranch)(response.content));
                        // get the client branches list
                        dispatch((0, branch_list_thunk_1.branch_list_thunk)(null));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
