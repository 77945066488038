"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParcelNinjaQuotesModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../../reducers/slices/main");
var create_optimise_custom_pickup_quotes_1 = require("../../../../../../../api/calls/integration/parcelNInjaOptimise/create_optimise_custom_pickup_quotes");
var QuoteBlockComponent = function (_a) {
    var quote = _a.quote, selected = _a.selected, index = _a.index, onClick = _a.onClick;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 rounded-md gap-4 p-4 border-2 ".concat(selected ? "border-blue-500" : "", " cursor-pointer"), onClick: function () {
            onClick(index);
        } }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4 items-center" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 items-start" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: quote.service_code + " - " + quote.reference, size: "small", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: quote.courier, size: "small", textColor: "text-black" })] })) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-4 items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Price", size: "small", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "R " + quote.price, size: "small", textColor: "text-black" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-4 items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Delivery Date", size: "small", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: new Date(quote.delivery_date).toLocaleString(), size: "small", textColor: "text-black" })] }))] }))] }), quote.quote_id));
};
var ParcelNinjaQuotesModal = function (_a) {
    var parcel_id = _a.parcel_id, onConfirm = _a.onConfirm;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)(null), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var _c = (0, react_1.useState)([]), quoteOptions = _c[0], setQuoteOptions = _c[1];
    var _d = (0, react_1.useState)(false), loading = _d[0], setLoading = _d[1];
    var _e = (0, react_1.useState)(null), enforcedQuote = _e[0], setEnforcedQuote = _e[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, main_1.setMessageModal)(null));
        setLoading(true);
        (0, create_optimise_custom_pickup_quotes_1.create_parcel_ninja_custom_pickup_quote)({ parcel_id: parcel_id })
            .then(function (response) {
            if (response.success) {
                var _a = response.content, quote = _a.quote, quotes = _a.quotes;
                if (quote) {
                    onConfirm(quote);
                    setEnforcedQuote(quote);
                }
                else {
                    setQuoteOptions(quotes);
                }
            }
            setLoading(false);
        })
            .catch(function (error) {
            dispatch((0, main_1.setMessageModal)({
                title: "Something went wrong!",
                messages: [error.message],
            }));
            setLoading(false);
        });
    }, []);
    if (loading) {
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "medium" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Fetching quotes...", size: "large", textColor: "text-black" })] })));
    }
    if (enforcedQuote) {
        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col min-w-sm max-w-md max-h-[80vh]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Using recommended quote", size: "large", textColor: "text-black", center: true }) })));
    }
    if (!(quoteOptions === null || quoteOptions === void 0 ? void 0 : quoteOptions.length)) {
        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "No couriers available", size: "large", textColor: "text-black", center: true }) })));
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col min-w-sm max-w-md max-h-[80vh]" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Select a courier", size: "large", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-2 flex-col overflow-y-auto" }, { children: quoteOptions === null || quoteOptions === void 0 ? void 0 : quoteOptions.map(function (deliveryCourier, index) {
                    return ((0, jsx_runtime_1.jsx)(QuoteBlockComponent, { quote: deliveryCourier, selected: selectedIndex === index, index: index, onClick: function (index) {
                            setSelectedIndex(index);
                        } }));
                }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Confirm", size: "small", textColor: "text-white", backgroundColor: "bg-secondary", onClick: function () {
                        var _a;
                        dispatch((0, main_1.setContentModal)({
                            title: "Confirm",
                            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex pt-4 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Delivery Courier", size: "small", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_a = quoteOptions[selectedIndex]) === null || _a === void 0 ? void 0 : _a.courier, size: "small", textColor: "text-black" })] }))),
                        }));
                        onConfirm(quoteOptions[selectedIndex]);
                    } }) }))] })));
};
exports.ParcelNinjaQuotesModal = ParcelNinjaQuotesModal;
