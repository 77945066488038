"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFrontContentConfiguration = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var store_front_1 = require("../../../../../reducers/slices/storeFront/store_front");
var richMarkdownEditor_1 = require("../../../../../components/richMarkdownEditor");
var AboutUsEditor = function (_a) {
    var _b;
    var className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var about_us = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.about_us; });
    var errors = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.errors; });
    var setAboutUsDescription = function (value) {
        dispatch((0, store_front_1.setAboutUs)(__assign(__assign({}, about_us), { description: value })));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    // useEffect(() => {
    //   return () => {
    //     dispatch(setAboutUs(""));
    //   }
    // }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: className }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "About Us", size: "medium", textColor: "text-inputText" }), (0, jsx_runtime_1.jsx)(richMarkdownEditor_1.RichMarkdownEditor, { markdown: about_us === null || about_us === void 0 ? void 0 : about_us.description, onChange: setAboutUsDescription, errorText: (_b = errors === null || errors === void 0 ? void 0 : errors.about_us) === null || _b === void 0 ? void 0 : _b.description, className: "max-h-[500px]" })] })));
};
var ReturnPolicyEditor = function (_a) {
    var _b;
    var className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var return_policy = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.return_policy; });
    var errors = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.errors; });
    var setReturnPolicyDescription = function (value) {
        dispatch((0, store_front_1.setReturnPolicy)(__assign(__assign({}, return_policy), { description: value })));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    (0, react_1.useEffect)(function () {
        return function () {
            dispatch((0, store_front_1.setReturnPolicy)(""));
        };
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: className }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Return Policy", size: "medium", textColor: "text-inputText" }), (0, jsx_runtime_1.jsx)(richMarkdownEditor_1.RichMarkdownEditor, { markdown: return_policy === null || return_policy === void 0 ? void 0 : return_policy.description, onChange: setReturnPolicyDescription, errorText: (_b = errors === null || errors === void 0 ? void 0 : errors.return_policy) === null || _b === void 0 ? void 0 : _b.description, className: "max-h-[500px]" })] })));
};
var TermsOfServiceEditor = function (_a) {
    var _b;
    var className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var terms_of_service = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.terms_of_service; });
    var errors = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.errors; });
    var setTermsOfServiceDescription = function (value) {
        dispatch((0, store_front_1.setTermsOfService)(__assign(__assign({}, terms_of_service), { description: value })));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    (0, react_1.useEffect)(function () {
        return function () {
            dispatch((0, store_front_1.setTermsOfService)(""));
        };
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: className }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Terms of Service", size: "medium", textColor: "text-inputText" }), (0, jsx_runtime_1.jsx)(richMarkdownEditor_1.RichMarkdownEditor, { markdown: terms_of_service === null || terms_of_service === void 0 ? void 0 : terms_of_service.description, onChange: setTermsOfServiceDescription, errorText: (_b = errors === null || errors === void 0 ? void 0 : errors.terms_of_service) === null || _b === void 0 ? void 0 : _b.description, className: "max-h-[500px]" })] })));
};
var PrivacyPolicyEditor = function (_a) {
    var _b;
    var className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var privacy_policy = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.privacy_policy; });
    var errors = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.errors; });
    var setPrivacyPolicyDescription = function (value) {
        dispatch((0, store_front_1.setPrivacyPolicy)(__assign(__assign({}, privacy_policy), { description: value })));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    (0, react_1.useEffect)(function () {
        return function () {
            dispatch((0, store_front_1.setPrivacyPolicy)(""));
        };
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: className }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Privacy Policy", size: "medium", textColor: "text-inputText" }), (0, jsx_runtime_1.jsx)(richMarkdownEditor_1.RichMarkdownEditor, { markdown: privacy_policy === null || privacy_policy === void 0 ? void 0 : privacy_policy.description, onChange: setPrivacyPolicyDescription, errorText: (_b = errors === null || errors === void 0 ? void 0 : errors.privacy_policy) === null || _b === void 0 ? void 0 : _b.description, className: "max-h-[500px]" })] })));
};
var NewsletterConfiguration = function (_a) {
    var _b, _c, _d, _e;
    var className = _a.className;
    var dispatch = (0, react_redux_1.useDispatch)();
    var newsletter = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.newsletter; });
    var errors = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.errors; });
    var setNewsletterDescription = function (value) {
        dispatch((0, store_front_1.setNewsletter)(__assign(__assign({}, newsletter), { description: value })));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    var setNewsletterSenderEmail = function (value) {
        dispatch((0, store_front_1.setNewsletter)(__assign(__assign({}, newsletter), { sender: __assign(__assign({}, newsletter.sender), { email: value }) })));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: className }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Insert newsletter prompt", size: "large", textColor: "text-labelText" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-500", value: newsletter === null || newsletter === void 0 ? void 0 : newsletter.description, errorText: (_b = errors === null || errors === void 0 ? void 0 : errors.newsletter) === null || _b === void 0 ? void 0 : _b.description, onChange: setNewsletterDescription }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Email", size: "large", textColor: "text-labelText pt-2" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Specify the email from which queries are sent", size: "xsmall", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-500", value: (_c = newsletter === null || newsletter === void 0 ? void 0 : newsletter.sender) === null || _c === void 0 ? void 0 : _c.email, errorText: (_e = (_d = errors === null || errors === void 0 ? void 0 : errors.newsletter) === null || _d === void 0 ? void 0 : _d.sender) === null || _e === void 0 ? void 0 : _e.email, onChange: setNewsletterSenderEmail })] })));
};
var StoreFrontContentConfiguration = function () {
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent" }, { children: [(0, jsx_runtime_1.jsx)(AboutUsEditor, {}), (0, jsx_runtime_1.jsx)(ReturnPolicyEditor, { className: "pt-2" }), (0, jsx_runtime_1.jsx)(TermsOfServiceEditor, { className: "pt-2" }), (0, jsx_runtime_1.jsx)(PrivacyPolicyEditor, { className: "pt-2" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Newsletter", size: "large", textColor: "text-black pt-4" }), (0, jsx_runtime_1.jsx)(NewsletterConfiguration, {})] })));
};
exports.StoreFrontContentConfiguration = StoreFrontContentConfiguration;
