"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockReplacementQuantities = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("./constants");
var modify_scheduled_stock_replacement_thunk_1 = require("../../../../../reducers/slices/stockReplacement/thunks/modify_scheduled_stock_replacement_thunk");
var cancel_scheduled_stock_replacement_thunk_1 = require("../../../../../reducers/slices/stockReplacement/thunks/cancel_scheduled_stock_replacement_thunk");
var StockReplacementQuantities = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedOrder = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }).selectedOrder;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.stockReplacementState; }), selectedScheduledWebhook = _a.selectedScheduledWebhook, isLoading = _a.isLoading;
    var replacementQuantities = selectedOrder.stock_replacement
        .map(function (replacementProduct) {
        var _product = selectedOrder.product_list.find(function (product) {
            return product._id.toString() === replacementProduct.product_id.toString();
        });
        if (!_product)
            return;
        return {
            _id: replacementProduct.product_id,
            title: _product.title,
            new_quantity: parseInt(replacementProduct.quantity_requested),
            quantity_requested: replacementProduct.quantity_requested,
            max_quantity: _product.quantity,
        };
    })
        .filter(function (item) { return item; });
    var _onSave = function () {
        var stock_replacement = replacementQuantities.map(function (replacement) {
            return {
                product_id: replacement._id,
                quantity_requested: replacement.new_quantity.toString(),
            };
        });
        var productsToReplace = stock_replacement.find(function (replacement) { return replacement.quantity_requested !== "0"; });
        if (productsToReplace) {
            dispatch((0, modify_scheduled_stock_replacement_thunk_1.modify_scheduled_stock_replacement_thunk)({
                _id: selectedScheduledWebhook._id,
                data: __assign(__assign({}, selectedOrder), { stock_replacement: stock_replacement }),
            }));
        }
        else {
            dispatch((0, cancel_scheduled_stock_replacement_thunk_1.cancel_scheduled_stock_replacement_thunk)(selectedScheduledWebhook._id));
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Set the quantity of products to be replaced for order ".concat(selectedOrder.code), size: "medium", center: true }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-3 lg:grid-cols-4 w-full gap-6 mt-3" }, { children: constants_1.stockReplacementQuantitiesColumns.map(function (_a, index) {
                    var label = _a.label, width = _a.width;
                    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, size: "small", textColor: "text-black", bold: true }) }), index));
                }) })), replacementQuantities === null || replacementQuantities === void 0 ? void 0 : replacementQuantities.map(function (_replacement, index) {
                var _a;
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col w-full" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 lg:grid-cols-4 w-full gap-6 items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-1 lg:col-span-2 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: _replacement.title, className: "overflow-anywhere", size: "small", textColor: "text-black" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex col-span-1 max-w-[100px] items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: _replacement.quantity_requested, size: "small", textColor: "text-black" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex col-span-1 max-w-[100px] items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IncrementControl, { value: (_a = _replacement === null || _replacement === void 0 ? void 0 : _replacement.new_quantity) !== null && _a !== void 0 ? _a : parseInt(_replacement.quantity_requested), size: "small", max: _replacement.max_quantity, min: 0, onChange: function (value) { return (_replacement.new_quantity = value); } }) }))] }), index), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end mr-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "max: ".concat(_replacement.max_quantity), size: "xsmall", textColor: "text-gray-500" }) }))] })));
            }).filter(function (p) { return p; }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4 flex-row justify-center m-4" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[75px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { loading: isLoading, label: "Confirm", size: "medium", onClick: function () { return _onSave(); } }) })) }))] })));
};
exports.StockReplacementQuantities = StockReplacementQuantities;
