"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSearch = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var get_products_shopify_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var main_1 = require("../../../../../../reducers/slices/main");
var ProductSearch = function (props) {
    var setBasketProducts = props.setBasketProducts, basketProducts = props.basketProducts;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), productSearch = _a[0], setProductSearch = _a[1];
    var _b = (0, react_1.useState)([]), selectedProducts = _b[0], setSelectedProducts = _b[1];
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _c.products, loading = _c.loading, pagination = _c.pagination;
    (0, react_1.useEffect)(function () {
        dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign(__assign({}, pagination), { search_string: productSearch !== null && productSearch !== void 0 ? productSearch : "" })));
    }, [productSearch]);
    (0, react_1.useEffect)(function () { }, [selectedProducts]);
    (0, react_1.useEffect)(function () {
        setSelectedProducts(__spreadArray(__spreadArray([], basketProducts, true), selectedProducts, true));
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full " }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Search", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", borderColor: "border-borders", backgroundColor: "bg-white", onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setProductSearch(e);
                            return [2 /*return*/];
                        });
                    }); } }) })), (0, jsx_runtime_1.jsx)("ul", __assign({ className: "w-full overflow-y-auto h-96" }, { children: products &&
                    (products === null || products === void 0 ? void 0 : products.map(function (product) {
                        var _a;
                        return ((0, jsx_runtime_1.jsxs)("li", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 hover:bg-borders p-2 border-borders border" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: product === null || product === void 0 ? void 0 : product.image_url }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { checkedColor: "text-black", uncheckedColor: "text-black", label: product === null || product === void 0 ? void 0 : product.title, value: selectedProducts.find(function (x) { return x._id === product._id; }), onChange: function (checked) {
                                                    if (checked) {
                                                        setSelectedProducts(__spreadArray(__spreadArray([], selectedProducts, true), [__assign(__assign({}, product), { quantity: 1 })], false));
                                                    }
                                                    else {
                                                        {
                                                            setSelectedProducts(selectedProducts === null || selectedProducts === void 0 ? void 0 : selectedProducts.filter(function (x) { return x._id !== product._id; }));
                                                        }
                                                    }
                                                } }) }))] })), selectedProducts.find(function (x) { return x._id === product._id; }) && ((0, jsx_runtime_1.jsx)("ul", __assign({ className: "pl-4" }, { children: ((_a = product === null || product === void 0 ? void 0 : product.variations) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                                        (product === null || product === void 0 ? void 0 : product.variations.map(function (variationType) {
                                            var _a;
                                            return ((0, jsx_runtime_1.jsxs)("li", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "hover:bg-borders p-2 border-borders border" }, { children: variationType.title })), ((_a = variationType === null || variationType === void 0 ? void 0 : variationType.variations) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((0, jsx_runtime_1.jsx)("ul", __assign({ className: "pl-4" }, { children: variationType === null || variationType === void 0 ? void 0 : variationType.variations.map(function (variation) {
                                                            var _a;
                                                            return ((0, jsx_runtime_1.jsx)("li", { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between hover:bg-borders p-2 border-borders border " }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { checkedColor: "text-black", uncheckedColor: "text-black", label: variation === null || variation === void 0 ? void 0 : variation.title, value: (product === null || product === void 0 ? void 0 : product.selectedVariations) && ((_a = product === null || product === void 0 ? void 0 : product.selectedVariations) === null || _a === void 0 ? void 0 : _a.filter(function (x) { return x.sku !== (variation === null || variation === void 0 ? void 0 : variation.sku); })), onChange: function (checked) {
                                                                                var _a;
                                                                                var _product = selectedProducts.find(function (x) { return x._id === product._id; });
                                                                                if (_product) {
                                                                                    var selectedVariations = (_a = _product === null || _product === void 0 ? void 0 : _product.selectedVariations) !== null && _a !== void 0 ? _a : [];
                                                                                    if (checked) {
                                                                                        _product.selectedVariations = __spreadArray(__spreadArray([], selectedVariations, true), [
                                                                                            __assign(__assign({}, variation), { title: "".concat(product.title, " - ").concat(variation.title) }),
                                                                                        ], false);
                                                                                    }
                                                                                    else {
                                                                                        selectedVariations = selectedVariations.filter(function (x) { return x.sku !== variation.sku; });
                                                                                        _product.selectedVariations = selectedVariations;
                                                                                    }
                                                                                    setSelectedProducts(selectedProducts.map(function (x) {
                                                                                        if (x._id === _product._id)
                                                                                            return _product;
                                                                                        return x;
                                                                                    }));
                                                                                }
                                                                            } }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between w-64" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(variation === null || variation === void 0 ? void 0 : variation.status) }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(variation === null || variation === void 0 ? void 0 : variation.price, " ZAR") })] }))] }), variation === null || variation === void 0 ? void 0 : variation.sku) }));
                                                        }) })))] }), variationType === null || variationType === void 0 ? void 0 : variationType._id));
                                        })) })))] }), product === null || product === void 0 ? void 0 : product._id));
                    })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end border-borders border-t-2 pt-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", backgroundColor: "bg-white", size: "small", textColor: "text-black", borderColor: "border-borders", onClick: function () {
                                dispatch((0, main_1.setContentModal)(null));
                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Add", backgroundColor: "bg-success", size: "small", textColor: "text-white", borderColor: "border-borders", onClick: function () {
                                setBasketProducts(__spreadArray(__spreadArray([], basketProducts, true), selectedProducts.map(function (x) {
                                    if ((x === null || x === void 0 ? void 0 : x.selectedVariations) && x.selectedVariations.length > 0) {
                                        var _variation = x.selectedVariations[0];
                                        return __assign(__assign({}, x), { title: "".concat(x.title, " - ").concat(_variation.title), price: _variation.price });
                                    }
                                    return x;
                                }), true));
                                dispatch((0, main_1.setContentModal)(null));
                            } })] })) }))] })));
};
exports.ProductSearch = ProductSearch;
