"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.branch_update_thunk = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var update_branch_1 = require("../../../../../api/calls/branch/update/update_branch");
var main_1 = require("../../../main");
var branch_1 = require("../../branch");
exports.branch_update_thunk = (0, toolkit_1.createAsyncThunk)("branch/branch_update_thunk", function (arg, _a) {
    var dispatch = _a.dispatch, getState = _a.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var state, branchState, _b, branch_id, __v, api_version, type, createdAt, updatedAt, client_id, coordinates, deleted, delivery, exclusion_areas, working_hours, payment_method, _c, _id, address, branch, response;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    state = getState();
                    branchState = state.branchState;
                    _b = branchState.selectedBranch, branch_id = _b._id, __v = _b.__v, api_version = _b.api_version, type = _b.type, createdAt = _b.createdAt, updatedAt = _b.updatedAt, client_id = _b.client_id, coordinates = _b.coordinates, deleted = _b.deleted, delivery = _b.delivery, exclusion_areas = _b.exclusion_areas, working_hours = _b.working_hours, payment_method = _b.payment_method, _c = _b.address, _id = _c._id, address = __rest(_c, ["_id"]), branch = __rest(_b, ["_id", "__v", "api_version", "type", "createdAt", "updatedAt", "client_id", "coordinates", "deleted", "delivery", "exclusion_areas", "working_hours", "payment_method", "address"]);
                    return [4 /*yield*/, dispatch((0, branch_1.setIsLoadingBranch)(true))];
                case 1:
                    _d.sent();
                    return [4 /*yield*/, (0, update_branch_1.branch_update)({
                            _id: branch_id,
                            branch: __assign(__assign({}, branch), { address: address, working_hours: working_hours.map(function (hour) {
                                    return {
                                        day: hour.day,
                                        from: hour.from,
                                        to: hour.to,
                                    };
                                }) }),
                        })];
                case 2:
                    response = _d.sent();
                    return [4 /*yield*/, dispatch((0, branch_1.setIsLoadingBranch)(false))];
                case 3:
                    _d.sent();
                    if (response.success) {
                        dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: [response.message] }));
                        dispatch((0, branch_1.setSelectedBranch)(response.content));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                    }
                    return [2 /*return*/, response];
            }
        });
    });
});
