"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentPageBranches = exports.getCategoriesPerPage = exports.getCurrentPageCategoryProducts = exports.getCurrentPageProducts = void 0;
// Function to calculate the products to display for the current page
var getCurrentPageProducts = function (currentPage, rowsPerPage, product_list) {
    var startIndex = (currentPage - 1) * rowsPerPage;
    var endIndex = startIndex + rowsPerPage;
    return product_list === null || product_list === void 0 ? void 0 : product_list.slice(startIndex, endIndex);
};
exports.getCurrentPageProducts = getCurrentPageProducts;
// Function to calculate the products to display for the current page
var getCurrentPageCategoryProducts = function (currentCategoryProductsPage, rowsPerPage, originalCategoryListLength, category_list, categoryProducts, product_list, deal_or_voucher_id) {
    if (!deal_or_voucher_id) {
        var startIndex = (currentCategoryProductsPage - 1) * rowsPerPage;
        var endIndex = startIndex + rowsPerPage;
        return categoryProducts === null || categoryProducts === void 0 ? void 0 : categoryProducts.slice(startIndex, endIndex);
    }
    else {
        var startIndex = (currentCategoryProductsPage - 1) * rowsPerPage;
        var endIndex = startIndex + rowsPerPage;
        if (originalCategoryListLength !== (category_list === null || category_list === void 0 ? void 0 : category_list.length)) {
            return categoryProducts === null || categoryProducts === void 0 ? void 0 : categoryProducts.slice(startIndex, endIndex);
        }
        else {
            return product_list === null || product_list === void 0 ? void 0 : product_list.slice(startIndex, endIndex);
        }
    }
};
exports.getCurrentPageCategoryProducts = getCurrentPageCategoryProducts;
var getCategoriesPerPage = function (currentPage, rowsPerPage, category_list) {
    var startIndex = (currentPage - 1) * rowsPerPage;
    var endIndex = startIndex + rowsPerPage;
    return category_list === null || category_list === void 0 ? void 0 : category_list.slice(startIndex, endIndex);
};
exports.getCategoriesPerPage = getCategoriesPerPage;
var getCurrentPageBranches = function (currentBranchesPage, rowsPerPage, branches) {
    var startIndex = (currentBranchesPage - 1) * rowsPerPage;
    var endIndex = startIndex + rowsPerPage;
    return branches === null || branches === void 0 ? void 0 : branches.slice(startIndex, endIndex);
};
exports.getCurrentPageBranches = getCurrentPageBranches;
