"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var api_1 = require("@react-google-maps/api");
var MapComponent = function (props) {
    var _a;
    var markers = props.markers, center = props.center, zoom = props.zoom, path = props.path, marker = props.marker, mapRef = props.mapRef;
    var _b = (0, react_1.useState)(null), activeMarker = _b[0], setActiveMarker = _b[1];
    var _c = (0, react_1.useState)(null), directionsResponse = _c[0], setDirectionsResponse = _c[1];
    var _d = (0, react_1.useState)(''), distance = _d[0], setDistance = _d[1];
    var _e = (0, react_1.useState)(''), duration = _e[0], setDuration = _e[1];
    var _f = (0, react_1.useState)({
        lat: -26.2142883,
        lng: 27.6841737,
    }), centerPosition = _f[0], setCenterPosition = _f[1];
    var isLoaded = (0, api_1.useJsApiLoader)({
        id: "google-map-script",
        googleMapsApiKey: process.env.GOOGLE_API_KEY,
    }).isLoaded;
    (0, react_1.useEffect)(function () {
        if (center) {
            setCenterPosition(center);
        }
    }, [center]);
    (0, react_1.useEffect)(function () {
        if (path && path.length > 0) {
            console.log("map path ===>", path);
            apiIsLoaded(mapRef.current);
        }
    }, [path]);
    var containerStyle = {
        width: "100%",
        height: "400px",
    };
    var _g = (0, react_1.useState)(null), map = _g[0], setMap = _g[1];
    var onUnmount = (0, react_1.useCallback)(function (map) {
        setMap(null);
    }, []);
    var handleActiveMarker = function (marker) {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };
    var onLoad = function (map) {
        var bounds = new google.maps.LatLngBounds();
        markers.forEach(function (_a) {
            var position = _a.position;
            return bounds.extend(position);
        });
        map.fitBounds(bounds);
        mapRef.current = map;
    };
    var apiIsLoaded = function (map) { return __awaiter(void 0, void 0, void 0, function () {
        var directionsService, directionsRenderer, origin, destination, points, waypoints, results;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    directionsService = new google.maps.DirectionsService();
                    directionsRenderer = new google.maps.DirectionsRenderer();
                    directionsRenderer.setMap(map);
                    origin = path[0];
                    destination = path[path.length - 1];
                    points = path;
                    points.shift();
                    points.pop();
                    waypoints = points.map(function (route) {
                        return {
                            location: { lat: route.lat, lng: route.lng },
                            stopover: true
                        };
                    });
                    return [4 /*yield*/, directionsService.route({
                            origin: origin,
                            destination: destination,
                            travelMode: google.maps.TravelMode.DRIVING,
                            waypoints: waypoints
                        })];
                case 1:
                    results = _a.sent();
                    setDirectionsResponse(results);
                    setDistance(results.routes[0].legs[0].distance.text);
                    setDuration(results.routes[0].legs[0].duration.text);
                    return [2 /*return*/];
            }
        });
    }); };
    return isLoaded ? ((0, jsx_runtime_1.jsxs)(api_1.GoogleMap, __assign({ mapContainerStyle: containerStyle, center: centerPosition, onClick: function () { return setActiveMarker(null); }, zoom: zoom, onLoad: onLoad, onUnmount: onUnmount, options: {
            gestureHandling: "greedy",
        } }, { children: [(marker === null || marker === void 0 ? void 0 : marker.markerRef) ?
                (0, jsx_runtime_1.jsx)(api_1.Marker, { ref: marker.markerRef, icon: (_a = marker.icon) !== null && _a !== void 0 ? _a : "http://labs.google.com/ridefinder/images/mm_20_red.png", position: marker.position }) :
                markers.map(function (_a, i) {
                    var node = _a.node, position = _a.position, icon = _a.icon;
                    return ((0, jsx_runtime_1.jsx)(api_1.Marker, __assign({ icon: icon !== null && icon !== void 0 ? icon : "http://labs.google.com/ridefinder/images/mm_20_red.png", position: position, onClick: function () { return handleActiveMarker(i); } }, { children: activeMarker === i ? ((0, jsx_runtime_1.jsx)(api_1.InfoWindow, __assign({ onCloseClick: function () { return setActiveMarker(null); } }, { children: (0, jsx_runtime_1.jsx)("div", { children: node }) }))) : null }), i));
                }), directionsResponse && ((0, jsx_runtime_1.jsx)(api_1.DirectionsRenderer, { directions: directionsResponse }))] }))) : ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}));
};
exports.default = MapComponent;
