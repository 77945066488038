"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableBottomContent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var tableBottomContent = function (_a) {
    var filterSelectedKeys = _a.filterSelectedKeys, page = _a.page, pages = _a.pages, filteredItems = _a.filteredItems, onPreviousPage = _a.onPreviousPage, onNextPage = _a.onNextPage, setPage = _a.setPage;
    return (0, react_1.useMemo)(function () {
        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-center justify-between sm:flex-row" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Pagination, { isCompact: true, showControls: true, showShadow: true, color: "primary", page: page, total: pages, onChange: setPage }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center justify-end gap-6" }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: "text-small text-default-400" }, { children: filterSelectedKeys === "all" ? "All items selected" : "".concat(filterSelectedKeys.size, " of ").concat(filteredItems.length, " selected") })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center gap-3" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isDisabled: page === 1, size: "sm", variant: "flat", onPress: onPreviousPage }, { children: "Previous" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isDisabled: page === pages, size: "sm", variant: "flat", onPress: onNextPage }, { children: "Next" }))] }))] }))] })));
    }, [filterSelectedKeys, page, pages, filteredItems, onPreviousPage, onNextPage, setPage]);
};
exports.tableBottomContent = tableBottomContent;
