"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntryPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../constants/routes");
var create_client_1 = require("./pages/create_client/create_client");
var select_subscription_1 = require("./pages/create_client/select_subscription");
var forgot_password_1 = require("./pages/forgot_password");
var log_in_1 = require("./pages/log_in");
var reset_password_1 = require("./pages/reset_password");
var react_redux_1 = require("react-redux");
var EntryPage = function () {
    var location = (0, react_router_dom_1.useLocation)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var entryRoutes = (0, react_router_dom_1.useRoutes)([
        { index: true, element: (0, jsx_runtime_1.jsx)(react_router_dom_1.Navigate, { to: routes_1.WELCOME_ROUTE + routes_1.LOG_IN_ROUTE }) },
        { path: routes_1.LOG_IN_ROUTE, element: (0, jsx_runtime_1.jsx)(log_in_1.LogInPage, {}) },
        { path: routes_1.FORGOT_PASSWORD_ROUTE, element: (0, jsx_runtime_1.jsx)(forgot_password_1.ForgotPasswordPage, {}) },
        { path: "".concat(routes_1.RESET_PASSWORD_ROUTE, "/:isNewAccount/:token"), element: (0, jsx_runtime_1.jsx)(reset_password_1.ResetPasswordPage, {}) },
        { path: routes_1.CREATE_CLIENT_ROUTE, element: (0, jsx_runtime_1.jsx)(create_client_1.CreateClientPage, {}) },
        { path: routes_1.SELECT_SUBSCRIPTION_ROUTE, element: (0, jsx_runtime_1.jsx)(select_subscription_1.SelectSubscriptionPage, {}) },
    ]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "h-full" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col lg:flex-row h-full" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col h-1/2 lg:h-auto flex-1 p-4 relative" }, { children: [(0, jsx_runtime_1.jsx)("img", { src: entryTheme.config.sideImage, className: "absolute top-0 left-0 object-cover object-center w-full h-full -z-10" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LogoControl, { image: entryTheme.config.logo.light, size: "medium" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center lg:pt-20" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4 drop-shadow-lg decoration-[#000000] text-7xl \n                  ".concat(entryTheme.config.colors.slogan.text, " font-bold") }, { children: entryTheme.config.slogan })) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "hidden lg:block absolute left-0 bottom-0 p-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CopyrightControl, { size: "small", company: "", backgroundColor: "bg-transparent", textColor: "text-white" }) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col justify-center items-center p-4" }, { children: [entryRoutes, (0, jsx_runtime_1.jsx)("div", __assign({ className: "lg:hidden w-full p-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CopyrightControl, { size: "small", position: "center", company: "", backgroundColor: "bg-transparent", textColor: "text-black" }) }))] }))] })) })));
};
exports.EntryPage = EntryPage;
