"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_redux_1 = require("react-redux");
var add_product_type_thunk_1 = require("../../../../../../reducers/slices/products/product_types/thunks/add_product_type_thunk");
var update_product_type_thunk_1 = require("../../../../../../reducers/slices/products/product_types/thunks/update_product_type_thunk");
var unit_constants_1 = require("../product_details/unit_constants");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var AddProductTypeComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var dispatch = (0, react_redux_1.useDispatch)();
    var productType = props.productType, isEditing = props.isEditing;
    var _j = (0, react_1.useState)((_b = (_a = productType === null || productType === void 0 ? void 0 : productType.delivery_time) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ""), value = _j[0], setValue = _j[1];
    var _k = (0, react_1.useState)((_d = (_c = productType === null || productType === void 0 ? void 0 : productType.delivery_time) === null || _c === void 0 ? void 0 : _c.unit) !== null && _d !== void 0 ? _d : ""), unit = _k[0], setUnit = _k[1];
    var _l = (0, react_1.useState)((_e = productType === null || productType === void 0 ? void 0 : productType.fees) !== null && _e !== void 0 ? _e : []), fees = _l[0], setFees = _l[1];
    var _m = (0, react_1.useState)(0), nextIndex = _m[0], setNextIndex = _m[1];
    var _o = (0, react_1.useState)({
        _id: "",
        name: "",
        delivery_time: {
            value: "",
            unit: "",
        },
    }), values = _o[0], setValues = _o[1];
    // Form validation section
    var _p = (0, react_1.useState)(false), isNameTouched = _p[0], setIsNameTouched = _p[1];
    var _q = (0, react_1.useState)(false), isValueTouched = _q[0], setIsValueTouched = _q[1];
    var _r = (0, react_1.useState)(false), isUnitTouched = _r[0], setIsUnitTouched = _r[1];
    var addFee = function () {
        var newIndex = calculateNextIndex();
        setFees(__spreadArray(__spreadArray([], fees, true), [
            {
                index: newIndex,
                fee_type: "",
                amount: 0,
            },
        ], false));
        setNextIndex(nextIndex + 1);
    };
    var calculateNextIndex = function () {
        var maxIndex = fees.reduce(function (max, fee) { return (fee.index > max ? fee.index : max); }, -1);
        return maxIndex + 1;
    };
    var removeFee = function (indexToRemove) {
        var updatedFees = fees.filter(function (fee) { return fee.index !== indexToRemove; });
        setFees(updatedFees);
    };
    var updateFee = function (index, field, value) {
        var updatedFees = __spreadArray([], fees, true);
        updatedFees[index][field] = value;
        setFees(updatedFees);
    };
    (0, react_1.useEffect)(function () {
        if (productType) {
            setValues(__assign({ _id: productType === null || productType === void 0 ? void 0 : productType._id }, productType));
        }
    }, []);
    var updateValues = function (prop, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = value, _a)));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4 mb-4 p-2 bg-borders rounded-md" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Product Type Name ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Product Delivery Value ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Product Delivery Unit ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Name", type: "text", borderColor: "border-borders", value: values.name, errorText: isNameTouched && ((_f = values === null || values === void 0 ? void 0 : values.name) === null || _f === void 0 ? void 0 : _f.length) >= 0 && ((_g = values === null || values === void 0 ? void 0 : values.name) === null || _g === void 0 ? void 0 : _g.length) < 3 ? (((_h = values === null || values === void 0 ? void 0 : values.name) === null || _h === void 0 ? void 0 : _h.length) > 2 ? "" : "must be 3 or more characters...") : null, onChange: function (v) {
                            updateValues("name", v);
                            setIsNameTouched(true);
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Value", type: "number", borderColor: "border-borders", value: value, errorText: isValueTouched && value.length === 0 && "value is required.", onChange: function (v) {
                            setValue(v);
                            setIsValueTouched(true);
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Unit", borderColor: "border-borders", options: unit_constants_1.deliveryUnitOptions, value: unit, errorText: isUnitTouched && !unit && "unit is required.", onChange: function (v) {
                            setUnit(v);
                            setIsUnitTouched(true);
                        } })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4 mb-4 p-2 bg-borders rounded-md" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: "Fee Type " })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: "Amount " })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: " " }))] })), fees === null || fees === void 0 ? void 0 : fees.map(function (fee, index) {
                        var _a;
                        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex space-x-2 pb-4 gap-7 items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-80" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Fee Type", borderColor: "border-borders", options: unit_constants_1.feeTypes, value: fee.fee_type, onChange: function (e) { return updateFee(index, "fee_type", e); } }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Amount", type: "number", borderColor: "border-borders", value: (_a = fee.amount) === null || _a === void 0 ? void 0 : _a.toString(), errorText: "", onChange: function (e) { return updateFee(index, "amount", e); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faTrashAlt, iconColor: "text-white", loading: false, backgroundColor: "bg-primary", size: "xsmall", onClick: function () { return removeFee(fee === null || fee === void 0 ? void 0 : fee.index); } })] }), fee === null || fee === void 0 ? void 0 : fee.index));
                    }), (fees === null || fees === void 0 ? void 0 : fees.length) < (unit_constants_1.feeTypes === null || unit_constants_1.feeTypes === void 0 ? void 0 : unit_constants_1.feeTypes.length) && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faPlus, loading: false, iconColor: "text-white", backgroundColor: "bg-primary", size: "xsmall", onClick: addFee }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { startIcon: !isEditing ? pro_light_svg_icons_1.faPlus : pro_solid_svg_icons_1.faSave, iconColor: "text-white", label: !isEditing ? "Add Product Type" : "Update Product Type", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var feeTypesArray, params;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    feeTypesArray = fees.length > 0 ? fees : [];
                                    params = __assign(__assign({}, values), { delivery_time: {
                                            value: value,
                                            unit: unit,
                                        }, fees: feeTypesArray });
                                    if (!!productType) return [3 /*break*/, 2];
                                    setIsNameTouched(true);
                                    setIsValueTouched(true);
                                    setIsUnitTouched(true);
                                    return [4 /*yield*/, dispatch((0, add_product_type_thunk_1.add_product_type_thunk)(params))];
                                case 1:
                                    _a.sent();
                                    return [3 /*break*/, 4];
                                case 2:
                                    setIsNameTouched(true);
                                    setIsValueTouched(true);
                                    setIsUnitTouched(true);
                                    return [4 /*yield*/, dispatch((0, update_product_type_thunk_1.update_product_type_thunk)(params))];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); } }) }))] })));
};
exports.default = AddProductTypeComponent;
