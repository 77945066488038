"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parcelProductTableColumns = exports.returnedProductTableColumns = exports.productTableColumns = void 0;
exports.productTableColumns = [
    {
        displayKey: "title",
        label: "Title",
        width: "col-span-2",
    },
    {
        displayKey: "category.name",
        label: "Category",
        width: "col-span-1",
    },
    {
        displayKey: "sku",
        label: "SKU",
        width: "col-span-1",
    },
    {
        displayKey: "quantity",
        label: "Quantity",
        width: "col-span-1",
    },
    {
        displayKey: "price",
        label: "Price",
        width: "col-span-1",
    },
    {
        displayKey: "variations",
        label: "Product Variations",
        width: "col-span-1",
    },
    {
        displayKey: "estimated_delivery_time",
        label: "Est Time",
        width: "col-span-1",
    },
    {
        displayKey: "type",
        label: "Type",
        width: "col-span-1",
    },
    {
        displayKey: "status",
        label: "Status",
        width: "col-span-1",
    },
    {
        displayKey: "discount",
        label: "Applied Discounts",
        width: "col-span-1",
    },
];
exports.returnedProductTableColumns = [
    {
        displayKey: "title",
        label: "Title",
        width: "col-span-2",
    },
    {
        displayKey: "sku",
        label: "SKU",
        width: "col-span-1",
    },
    {
        displayKey: "code",
        label: "Parcel",
        width: "col-span-1",
    },
    {
        displayKey: "quantity",
        label: "Quantity",
        width: "col-span-1",
    },
    {
        displayKey: "price",
        label: "Price",
        width: "col-span-1",
    },
    {
        displayKey: "status",
        label: "Status",
        width: "col-span-1",
    },
];
exports.parcelProductTableColumns = exports.returnedProductTableColumns.filter(function (product) { return product.displayKey !== "code"; });
