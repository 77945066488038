"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddThemeComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var upload_file_1 = require("../../../../../api/calls/media/upload_file");
var create_theme_thunk_1 = require("../../../../../reducers/slices/themes/thunks/create_theme_thunk");
var update_theme_thunk_1 = require("../../../../../reducers/slices/themes/thunks/update_theme_thunk");
var AddThemeComponent = function (props) {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var theme = props.theme, isEditing = props.isEditing; //
    var loading = (0, react_redux_1.useSelector)(function (state) { return state.themeState; }).loading;
    var _b = (0, react_1.useState)(false), isImageLoading = _b[0], setIsImageLoading = _b[1];
    var TAG = { _id: null, name: "", image: "", etag: "" };
    var _c = (0, react_1.useState)({
        _id: null,
        name: "",
        etag: "",
        image: "",
        tags: [],
    }), values = _c[0], setValues = _c[1];
    var _d = (0, react_1.useState)(false), showFileInputControl = _d[0], setShowFileInputControl = _d[1];
    (0, react_1.useEffect)(function () {
        if (theme) {
            console.log("Theme ==>", theme);
            setValues(__assign({}, theme));
        }
    }, []);
    var updateValues = function (prop, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = value, _a)));
    };
    var uploadImage = function (file, file_name, index) {
        setIsImageLoading(true);
        (0, upload_file_1.upload_file)({
            file: file,
            file_name: file_name,
            description: index ? "Letstrade product tag images" : "Letstrade product theme images",
            type: "IMAGE",
            category: index ? "PRODUCTS TAGS" : "PRODUCTS THEME",
        }).then(function (res) {
            console.log(res);
            if (res.success) {
                setIsImageLoading(false);
                if (index === null) {
                    setValues(__assign(__assign({}, values), { image: res.content.location, etag: res.content.etag }));
                }
                else {
                    values.tags[index].image = res.content.location;
                    values.tags[index].etag = res.content.etag;
                    setValues(__assign({}, values));
                }
            }
            else {
                setIsImageLoading(false);
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when trying to upload your Image.", messages: ["Please try again..."] }));
            }
        });
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 overflow-y-auto max-h-[600px]" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4 mb-4 p-2 bg-borders rounded-md" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Theme Name ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Theme Image ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] }))] })), isImageLoading && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "xsmall" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Name", type: "text", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.name, errorText: "", size: "medium", onChange: function (v) {
                            updateValues("name", v);
                        } }), (showFileInputControl || !(theme === null || theme === void 0 ? void 0 : theme.image)) && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.FileInputControl, { startIcon: pro_light_svg_icons_1.faFileArrowUp, previewSide: "right", colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, size: "medium", previewFile: true, label: "Upload Image", onChange: function (f) {
                            uploadImage(f, f.name, null);
                        } })), !showFileInputControl && (theme === null || theme === void 0 ? void 0 : theme._id) && (theme === null || theme === void 0 ? void 0 : theme.image) && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " }, { children: [(0, jsx_runtime_1.jsx)("img", { src: theme === null || theme === void 0 ? void 0 : theme.image, alt: "Theme Image", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "absolute right-3 bg-gray-600 rounded-full text-white" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faPencil, size: "xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                                        setShowFileInputControl(true);
                                    } }) }))] })))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex  justify-between gap-4 mb-4 p-2 mt-10 rounded-md" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Add Tag ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-l text-slate-600 font-bold" }, { children: ["Tag Image ", (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-rose-600" }, { children: "*" }))] }))] })), (_a = values === null || values === void 0 ? void 0 : values.tags) === null || _a === void 0 ? void 0 : _a.map(function (x, i) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-8 " }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Name", type: "text", borderColor: "border-borders", value: x.name, errorText: "", onChange: function (v) {
                            var _tags = values.tags.map(function (x, index) {
                                if (index === i) {
                                    return __assign(__assign({}, x), { name: v });
                                }
                                return x;
                            });
                            setValues(__assign(__assign({}, values), { tags: _tags }));
                        } }), !(x === null || x === void 0 ? void 0 : x.image) && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.FileInputControl, { startIcon: pro_light_svg_icons_1.faFileArrowUp, previewSide: "right", colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, previewFile: true, label: "Upload Image", size: "medium", onChange: function (f) {
                            uploadImage(f, f.name, i);
                        } })), (x === null || x === void 0 ? void 0 : x.image) && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " }, { children: (0, jsx_runtime_1.jsx)("img", { src: x === null || x === void 0 ? void 0 : x.image, alt: "Tag Image", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }) }))), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { iconColor: "text-black", backgroundColor: "bg-gray-100", size: "xsmall", onClick: function () {
                            var _a;
                            var _tag = (_a = values === null || values === void 0 ? void 0 : values.tags) === null || _a === void 0 ? void 0 : _a.filter(function (sub, subIndex) { return subIndex !== i; });
                            setValues(__assign(__assign({}, values), { tags: _tag }));
                        }, icon: pro_light_svg_icons_1.faMinus })] }), i)); }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-right" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { onClick: function () {
                        setValues(__assign(__assign({}, values), { tags: __spreadArray(__spreadArray([], values === null || values === void 0 ? void 0 : values.tags, true), [TAG], false) }));
                    }, icon: pro_light_svg_icons_1.faPlus }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: !isEditing ? "Add Theme" : "Update Theme", loading: loading, size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var callBack, _id, _data;
                        return __generator(this, function (_a) {
                            callBack = function () {
                                setValues({
                                    _id: null,
                                    name: "",
                                    etag: "",
                                    image: "",
                                    tags: [],
                                });
                            };
                            _id = values._id;
                            delete values._id;
                            _data = __assign(__assign({}, values), { tags: values.tags.map(function (x) {
                                    if (!x._id)
                                        delete x._id;
                                    return x;
                                }) });
                            if (!isEditing) {
                                dispatch((0, create_theme_thunk_1.create_theme_thunk)({
                                    payload: _data,
                                    callBack: callBack,
                                }));
                            }
                            else {
                                delete _data.client_id;
                                delete _data.createdAt;
                                delete _data.updatedAt;
                                delete _data.__v;
                                delete _data.deleted;
                                dispatch((0, update_theme_thunk_1.update_theme_thunk)({
                                    payload: {
                                        _id: _id,
                                        payload: _data,
                                    },
                                    callBack: callBack,
                                }));
                            }
                            return [2 /*return*/];
                        });
                    }); } }) }))] })));
};
exports.AddThemeComponent = AddThemeComponent;
