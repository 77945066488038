"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableTopBar = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@iconify/react");
var react_2 = require("@nextui-org/react");
var tableTopBar = function (_a) {
    var pageTitle = _a.pageTitle, recordCount = _a.recordCount, actionButtons = _a.actionButtons;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "mb-[18px] flex items-center justify-between" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex w-[400px] items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-2xl font-[700] leading-[32px]" }, { children: pageTitle })), (0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ className: "hidden items-center text-default-500 sm:flex", size: "sm", variant: "flat" }, { children: recordCount }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end gap-4" }, { children: actionButtons.map(function (button) { return ((0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onClick: button.onClick, endContent: (0, jsx_runtime_1.jsx)(react_1.Icon, { icon: button.iconClassname, width: 20 }) }, { children: button.label }))); }) }))] })));
};
exports.tableTopBar = tableTopBar;
