"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PimTablePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var remove_product_1 = require("../../../../../api/calls/product/remove_product");
var channelsCellComponent_1 = require("../../../../../components/channelsCellComponent");
var copy_text_1 = require("../../../../../components/copy_text");
var status_component_1 = require("../../../../../components/status_component");
var tabel_cell_1 = require("../../../../../components/tabel_cell");
var table_bottom_content_1 = require("../../../../../components/table_bottom_content");
var table_top_bar_1 = require("../../../../../components/table_top_bar");
var table_top_content_1 = require("../../../../../components/table_top_content");
var local_storage_names_1 = require("../../../../../constants/local_storage_names");
var pages_1 = require("../../../../../constants/pages");
var routes_1 = require("../../../../../constants/routes");
var useMemoizedCallback_1 = require("../../../../../helpers/useMemoizedCallback");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var branch_1 = require("../../../../../reducers/slices/branch/branch");
var main_1 = require("../../../../../reducers/slices/main");
var get_products_shopify_thunk_1 = require("../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var importCSV_1 = require("../components/product_details/importCSV");
var constants_1 = require("./constants");
var PimTablePage = function () {
    var _a, _b;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), search = _c[0], setSearch = _c[1];
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _d.products, pagination = _d.pagination, loading = _d.loading;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _e.client_token, user = _e.user;
    var pageTitle = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).pageTitle;
    var _f = (0, react_1.useState)(), selectedAvailabilityType = _f[0], setSelectedAvailabilityType = _f[1];
    var _g = (0, react_1.useState)(), selectedChannelsFilter = _g[0], setSelectedChannelsFilter = _g[1];
    var _h = (0, react_1.useState)(), selectedStatusFilter = _h[0], setSelectedStatusFilter = _h[1];
    var _j = (0, react_1.useState)(1), currentPage = _j[0], setCurrentPage = _j[1];
    var _k = (0, react_1.useState)(10), rowsPerPage = _k[0], setRowsPerPage = _k[1];
    var _l = (0, react_1.useState)(new Set([])), selectedKeys = _l[0], setSelectedKeys = _l[1];
    var _m = (0, react_1.useState)({
        column: "createdAt",
        direction: "ascending",
    }), sortDescriptor = _m[0], setSortDescriptor = _m[1];
    var filters = {
        status: selectedStatusFilter,
        channel: selectedChannelsFilter,
        availability: selectedAvailabilityType,
    };
    var tableColumnConfig = localStorage.getItem(local_storage_names_1.TABLE_COLUMN_CONFIG);
    var savedVisibleColumns = (_a = JSON.parse(tableColumnConfig)) !== null && _a !== void 0 ? _a : {};
    var initialVisibleColumns = (_b = savedVisibleColumns["products"]) !== null && _b !== void 0 ? _b : constants_1.INITIAL_VISIBLE_COLUMNS;
    var _o = (0, react_1.useState)(new Set(initialVisibleColumns)), visibleColumns = _o[0], setVisibleColumns = _o[1];
    var handleSetColumns = function (value) {
        savedVisibleColumns["products"] = Array.from(value);
        localStorage.setItem(local_storage_names_1.TABLE_COLUMN_CONFIG, JSON.stringify(savedVisibleColumns));
        setVisibleColumns(value);
    };
    var handleStatusFilterChange = function (value) {
        setSelectedStatusFilter(value);
        getProducts(__assign(__assign({}, pagination), filters));
    };
    var handleChannelsFilterChange = function (value) {
        setSelectedChannelsFilter(value);
        getProducts(__assign(__assign({}, pagination), filters));
    };
    var handleSortDescriptor = function (value) {
        var order_by = value.direction === "ascending" ? 1 : -1;
        setSortDescriptor(value);
        getProducts(__assign(__assign({}, pagination), { sort_by: value.column, order_by: order_by }));
    };
    var handleSetSelectedAvailabilityType = function (value) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedAvailabilityType(value);
            getProducts(__assign(__assign({}, pagination), filters));
            return [2 /*return*/];
        });
    }); };
    var headerColumns = (0, react_1.useMemo)(function () {
        if (visibleColumns === "all")
            return constants_1.columns;
        return constants_1.columns
            .map(function (item) {
            if (item.uid === sortDescriptor.column) {
                return __assign(__assign({}, item), { sortDirection: sortDescriptor.direction });
            }
            return item;
        })
            .filter(function (column) { return Array.from(visibleColumns).includes(column.uid); });
    }, [visibleColumns, sortDescriptor]);
    var onSelectionChange = (0, useMemoizedCallback_1.useMemoizedCallback)(function (keys) {
        if (keys === "all") {
            setSelectedKeys(keys);
        }
        else if (keys.size === 0) {
            setSelectedKeys(new Set());
        }
        else {
            var resultKeys_1 = new Set();
            keys.forEach(function (v) {
                resultKeys_1.add(v);
            });
            var selectedValue = selectedKeys === "all" ? new Set(products.map(function (item) { return String(item._id); })) : selectedKeys;
            selectedValue.forEach(function (v) {
                if (products.some(function (item) { return String(item._id) === v; })) {
                    return;
                }
                resultKeys_1.add(v);
            });
            setSelectedKeys(new Set(resultKeys_1));
        }
    });
    var filterSelectedKeys = (0, react_1.useMemo)(function () {
        var resultKeys = new Set();
        resultKeys = selectedKeys;
        return resultKeys;
    }, [selectedKeys, products]);
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getProducts(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPushToUber = function () {
        dispatch((0, main_1.setContentModal)(null));
        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CHANNELS_ROUTE, "/uber"));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (search === "") {
            setRowsPerPage(rowsPerPage);
            return getProducts(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getProducts(__assign(__assign({}, pagination), { page: page, search_string: search }));
        }
    };
    var onSearchChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (e === "")
                        setCurrentPage(1);
                    setSearch(e);
                    return [4 /*yield*/, dispatch((0, branch_1.setTablePagination)(pages_1.initialPaginationValues))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var renderCellViews = function (cellValue) {
        var _a, _b, _c;
        return [
            {
                key: "image_url",
                content: ((0, jsx_runtime_1.jsx)(react_2.User, { avatarProps: { radius: "lg", src: cellValue }, classNames: {
                        name: "text-default-foreground",
                        description: "text-default-500",
                    }, name: "" })),
            },
            {
                key: "category",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-small capitalize text-default-foreground" }, { children: (_a = cellValue === null || cellValue === void 0 ? void 0 : cellValue.name) !== null && _a !== void 0 ? _a : "N/A" })),
            },
            {
                key: "pid",
                content: (0, jsx_runtime_1.jsx)(copy_text_1.CopyText, { children: cellValue }),
            },
            {
                key: "on_sale_price",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "text-small capitalize text-default-foreground" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_b = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _b !== void 0 ? _b : "0",
                        symbolSeparation: true,
                    }) }))),
            },
            {
                key: "price",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_c = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _c !== void 0 ? _c : "0",
                        symbolSeparation: true,
                    }) }))),
            },
            {
                key: "on_sale",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue ? "Yes" : "No" })),
            },
            {
                key: "channels",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "float-start flex gap-1" }, { children: (0, channelsCellComponent_1.ChannelsCellComponent)({ channels: cellValue }) })),
            },
            {
                key: "status",
                content: (0, jsx_runtime_1.jsx)(status_component_1.Status, { status: cellValue }),
            },
        ];
    };
    var rowActions = function (cellValue) { return [
        {
            key: "view",
            onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_DETAILS_ROUTE, "/").concat(cellValue._id)); },
        },
        {
            key: "edit",
            onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_DETAILS_ROUTE, "/").concat(cellValue._id)); },
        },
        {
            key: "delete",
            onClick: function () {
                return dispatch((0, main_1.setContentModal)({
                    title: "Confirm deletion of ".concat(cellValue === null || cellValue === void 0 ? void 0 : cellValue.title, " product"),
                    content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col justify-start pt-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this product?", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 pt-4 justify-start" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "danger", variant: "light", onPress: function () { return dispatch((0, main_1.setContentModal)(null)); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onPress: function () {
                                            (0, remove_product_1.remove_product)({
                                                _id: cellValue === null || cellValue === void 0 ? void 0 : cellValue._id,
                                            }).then(function (response) {
                                                if (response.success) {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                    dispatch((0, main_1.setContentModal)(null));
                                                    dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign({}, pagination)));
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when deleting the product!", messages: [response.message] }));
                                                }
                                            });
                                        } }, { children: "Continue" }))] }))] }))),
                }));
            },
        },
    ]; };
    (0, react_1.useEffect)(function () {
        if (client_token && !loading) {
            dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "mb-4 relative overflow-auto rounded-large", style: { width: pages_1.scrollableTableWidth } }, { children: [(0, table_top_bar_1.tableTopBar)({
                pageTitle: pageTitle,
                recordCount: pagination.countTotal,
                actionButtons: [
                    {
                        label: "Types",
                        iconClassname: "iconamoon:link-thin",
                        onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_TYPES_ROUTE));
                        },
                    },
                    {
                        label: "Categories",
                        iconClassname: "iconamoon:link-thin",
                        onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_CATEGORIES_ROUTE));
                        },
                    },
                    (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && {
                        label: "Import CSV",
                        iconClassname: "material-symbols-light:csv-outline-rounded",
                        onClick: function () {
                            dispatch((0, main_1.setContentModal)({
                                title: "Import Products",
                                content: (0, jsx_runtime_1.jsx)(importCSV_1.ImportCSVComponent, {}),
                            }));
                        },
                    },
                    {
                        label: "Add Products",
                        iconClassname: "solar:add-circle-bold",
                        onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_DETAILS_ROUTE));
                        },
                    },
                ].filter(Boolean),
            }), (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ isHeaderSticky: true, "aria-label": "Products", bottomContent: (0, table_bottom_content_1.tableBottomContent)({
                    filterSelectedKeys: filterSelectedKeys,
                    page: currentPage,
                    pages: Math.round(pagination.countTotal / rowsPerPage),
                    filteredItems: products,
                    onPreviousPage: function () { return onPageChange(currentPage - 1); },
                    onNextPage: function () { return onPageChange(currentPage + 1); },
                    setPage: onPageChange,
                }), bottomContentPlacement: "outside", classNames: {
                    td: "before:bg-transparent",
                }, selectedKeys: filterSelectedKeys, selectionMode: "multiple", sortDescriptor: sortDescriptor, topContent: (0, table_top_content_1.tableTopContent)({
                    columns: constants_1.columns,
                    selectedActions: [
                        {
                            label: "Push to Uber",
                            key: "push_to_uber",
                            onClick: onPushToUber,
                        },
                    ],
                    filterActions: [
                        {
                            label: "Product Availability",
                            value: selectedAvailabilityType,
                            items: constants_1.PRODUCT_AVAILABILITY,
                            onChange: handleSetSelectedAvailabilityType,
                        },
                        {
                            label: "Status",
                            value: selectedStatusFilter,
                            items: constants_1.STATUS,
                            onChange: handleStatusFilterChange,
                        },
                        {
                            label: "Channels",
                            value: selectedChannelsFilter,
                            items: constants_1.CHANNELS,
                            onChange: handleChannelsFilterChange,
                        },
                    ],
                    excludeSortColumns: ["on_sale_price", "on_sale", "image_url", "channels", "category"],
                    visibleColumns: visibleColumns,
                    filterSelectedKeys: filterSelectedKeys,
                    headerColumns: headerColumns,
                    sortDescriptor: sortDescriptor,
                    onSearchChange: onSearchChange,
                    setVisibleColumns: handleSetColumns,
                    setSortDescriptor: handleSortDescriptor,
                }), topContentPlacement: "outside", onSelectionChange: onSelectionChange }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_2.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_2.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: loading, emptyContent: "This table is empty.", items: products, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) {
                            return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                        } }))] }))] })));
};
exports.PimTablePage = PimTablePage;
