"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Maps the fields from the source data to the target data based on the provided field mapping configuration.
 *
 * @param fields - An array of field mapping properties.
 * @param data - The source data to be mapped.
 * @returns The mapped data object.
 */
function mapToTarget(fields, data, root) {
    var _a;
    if (root === void 0) { root = null; }
    if (!fields || !fields.length) {
        return;
    }
    if (!root) {
        root = data;
    }
    var mappedData = {};
    for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
        var _b = fields_1[_i], localField = _b.localField, targetField = _b.targetField, targetType = _b.targetType, defaultValue = _b.defaultValue, nestedFields = _b.nestedFields;
        var value = (_a = getFieldFromPath(data, localField, root)) !== null && _a !== void 0 ? _a : defaultValue;
        var convertedValue = convertToType(value, targetType);
        setFieldWithPath(mappedData, targetField, convertedValue, nestedFields, root);
    }
    return mappedData;
}
exports.default = mapToTarget;
function getFieldFromPath(obj, path, root) {
    if (isRootPath(path)) {
        path = removeRootIndicator(path);
        obj = root;
    }
    var fields = path.split(".");
    var currentValue = obj;
    for (var _i = 0, fields_2 = fields; _i < fields_2.length; _i++) {
        var field = fields_2[_i];
        if (currentValue && typeof currentValue === "object") {
            currentValue = currentValue[field];
        }
        else {
            return;
        }
    }
    return currentValue;
}
function setFieldWithPath(obj, path, value, nestedFields, root) {
    if (isRootPath(path)) {
        path = removeRootIndicator(path);
        obj = root;
    }
    var fields = path.split(".");
    var currentObj = obj;
    fields.slice(0, -1).forEach(function (field) {
        if (!currentObj[field] || typeof currentObj[field] !== "object") {
            currentObj[field] = {};
        }
        currentObj = currentObj[field];
    });
    var finalField = fields[fields.length - 1];
    assignValueToField(currentObj, finalField, value, nestedFields, root);
}
function isRootPath(path) {
    return path.startsWith("$.");
}
function removeRootIndicator(path) {
    return path.substring(2);
}
function assignValueToField(obj, field, value, nestedFields, root) {
    if (Array.isArray(value)) {
        obj[field] = obj[field] || [];
        value.forEach(function (item) {
            var nestedObj = mapToTarget(nestedFields, item, root);
            obj[field].push(nestedObj);
        });
    }
    else {
        obj[field] = value;
    }
}
function convertToType(value, type) {
    var conversions = {
        String: String,
        Decimal: function (val) { return (typeof val === "string" ? parseFloat(val.replace(/[^0-9.-]+/g, "")) / 100 : Number(val).toFixed(2)); },
        Number: Number,
        Boolean: function (val) { return Boolean(val); },
        Date: function (val) { return new Date(val); },
        Array: function (val) { return (Array.isArray(val) ? val : [val]); },
        Integer: function (val) { return parseInt(val, 10); },
        Object: function (val) { return (typeof val === "object" ? val : {}); },
    };
    try {
        return conversions[type] ? conversions[type](value) : value;
    }
    catch (e) {
        console.error("Error converting value '".concat(value, "' to type '").concat(type, "':"), e);
        return value;
    }
}
