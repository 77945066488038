"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../constants/routes");
var validateForm_1 = require("../../../helpers/validateForm/validateForm");
var constants_1 = require("../../../constants/constants");
var main_1 = require("../../../reducers/slices/main");
var forgot_password_1 = require("../../../api/calls/user/forgot_password");
var ForgotPasswordPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)(); //<AppDispatch>
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _a.user, isLoading = _a.isLoading;
    var _b = (0, react_1.useState)({ email: "" }), credentials = _b[0], setCredentials = _b[1];
    var _c = (0, validateForm_1.validateForm)(credentials), errors = _c.errors, isValid = _c.isValid;
    var _setCredentials = function (value) {
        setCredentials(__assign(__assign({}, credentials), value));
    };
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var handleResponse = function (response) {
        if (response.success) {
            dispatch((0, main_1.setContentModal)({ title: "Success", content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
        }
        else
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
    };
    var _onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var email, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    email = credentials.email;
                    showLoader();
                    return [4 /*yield*/, (0, forgot_password_1.forgot_password)({
                            email: email,
                        })];
                case 1:
                    response = _a.sent();
                    handleResponse(response);
                    return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-y-4 h-full justify-center w-full lg:w-[700px] py-4 lg:py-0 px-4 lg:px-20" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Forgot password?", size: "3xlarge", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Enter the email address you used when you joined and we'll send you instructions to reset your password", textColor: "text-black", size: "small" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full flex flex-col gap-y-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Email", type: "text", value: credentials.email, errorText: errors.email, borderColor: "border-borders", onChange: function (email) {
                            _setCredentials({ email: email });
                        } }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Submit", enabled: isValid, loading: isLoading, backgroundColor: "bg-primary", onClick: _onSubmit }) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row justify-between items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-500", iconColor: "text-blue-500", size: "medium", icon: pro_solid_svg_icons_1.faChevronLeft, label: "Back to sign in", iconSide: "left", onClick: function () {
                        navigate("".concat(routes_1.WELCOME_ROUTE).concat(routes_1.LOG_IN_ROUTE));
                    } }) }))] })));
};
exports.ForgotPasswordPage = ForgotPasswordPage;
