"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExcludedProductListPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../../../reducers/slices/console");
var routes_1 = require("../../../../../../constants/routes");
var main_1 = require("../../../../../../reducers/slices/main");
var pages_1 = require("../../../../../../constants/pages");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var get_excluded_products_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_excluded_products_thunk");
var remove_excluded_product_1 = require("../../../../../../api/calls/product/remove_excluded_product");
var import_excluded_products_1 = require("./import_excluded_products");
var export_excluded_products_1 = require("../../../../../../api/calls/product/export_excluded_products");
var download_file_1 = require("../../../../../../api/calls/product/download_file");
var remove_all_excluded_products_1 = require("../../../../../../api/calls/product/remove_all_excluded_products");
var ExcludedProductListPage = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), search = _a[0], setSearch = _a[1];
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), excludedProducts = _b.excludedProducts, excludedProductsPagination = _b.excludedProductsPagination;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _c = (0, react_1.useState)(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = (0, react_1.useState)(10), rowsPerPage = _d[0], setRowsPerPage = _d[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Product Exclusion List"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_excluded_products_thunk_1.get_excluded_products_thunk)(__assign({}, excludedProductsPagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    var getExcludedProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_excluded_products_thunk_1.get_excluded_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getExcludedProducts(__assign(__assign({}, pages_1.initialPaginationValues), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCurrentPage(page);
                    setRowsPerPage(rowsPerPage);
                    return [4 /*yield*/, getExcludedProducts(__assign(__assign({}, excludedProductsPagination), { page: page }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var onRowsPerPageChange = function (size) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setRowsPerPage(size);
                    return [4 /*yield*/, getExcludedProducts(__assign(__assign({}, excludedProductsPagination), { size: size }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var csvData = [];
    if (excludedProducts === null || excludedProducts === void 0 ? void 0 : excludedProducts.length) {
        csvData = excludedProducts === null || excludedProducts === void 0 ? void 0 : excludedProducts.map(function (product) {
            return { title: product === null || product === void 0 ? void 0 : product.title, sku: product === null || product === void 0 ? void 0 : product.sku, pid: product === null || product === void 0 ? void 0 : product.pid, barcode: product === null || product === void 0 ? void 0 : product.barcode };
        });
    }
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-end items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-32 bg-white rounded-md p-2" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { label: "Export CSV", size: "medium", onClick: function () {
                                    (0, export_excluded_products_1.get_download_url)()
                                        .then(function (data) {
                                        (0, download_file_1.download_file)(data.content)
                                            .then(function (response) {
                                            var blob = new Blob([response.file_data], { type: "application/octet-stream" });
                                            var url = window.URL.createObjectURL(blob);
                                            var link = document.createElement("a");
                                            link.href = url;
                                            link.download = response.file_name;
                                            link.click();
                                            window.URL.revokeObjectURL(url);
                                        })
                                            .catch(function (e) {
                                            console.log("download_file: ", e);
                                        });
                                    })
                                        .catch(function (error) {
                                        console.log("get_download_url: ", error);
                                    });
                                } }) })) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Import CSV", endIcon: pro_light_svg_icons_1.faFileCsv, size: "small", backgroundColor: "bg-white", textColor: "text-black", onClick: function () {
                            dispatch((0, main_1.setContentModal)({
                                title: "Import Excluded Products",
                                content: (0, jsx_runtime_1.jsx)(import_excluded_products_1.ImportExcludedProductsComponent, {}),
                            }));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Delete All", size: "small", backgroundColor: "bg-red-600", textColor: "text-white", onClick: function () {
                            dispatch((0, main_1.setContentModal)({
                                title: "Confirm deletion of ALL products",
                                content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex pt-4 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete ALL these products?", textColor: "text-inputText" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () {
                                                (0, remove_all_excluded_products_1.remove_all_exluded_products)().then(function (response) {
                                                    if (response.success) {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                        dispatch((0, main_1.setContentModal)(null));
                                                        dispatch((0, get_excluded_products_thunk_1.get_excluded_products_thunk)(__assign({}, excludedProductsPagination)));
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when deleting ALL excluded products!", messages: [response.message] }));
                                                    }
                                                });
                                            } })] }))),
                            }));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Add Product", endIcon: pro_light_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.EXCLUDED_PRODUCT_DETAILS_ROUTE));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) {
                            setSearch(e);
                        } })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "title",
                        label: "Title",
                    },
                    {
                        displayKey: "sku",
                        label: "SKU",
                    },
                    {
                        displayKey: "pid",
                        label: "PID",
                    },
                    {
                        displayKey: "barcode",
                        label: "Barcode",
                    },
                ], data: excludedProducts, rowActions: [
                    {
                        label: "Delete Product",
                        onClick: function (product) {
                            dispatch((0, main_1.setContentModal)({
                                title: "Confirm deletion of ".concat(product === null || product === void 0 ? void 0 : product.title, " product"),
                                content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this product?", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () {
                                                        (0, remove_excluded_product_1.remove_excluded_product)({
                                                            _id: product === null || product === void 0 ? void 0 : product._id,
                                                            deletedBy: "".concat(user === null || user === void 0 ? void 0 : user.name, " ").concat(user === null || user === void 0 ? void 0 : user.surname),
                                                        }).then(function (response) {
                                                            if (response.success) {
                                                                dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                                dispatch((0, main_1.setContentModal)(null));
                                                                dispatch((0, get_excluded_products_thunk_1.get_excluded_products_thunk)(__assign({}, excludedProductsPagination)));
                                                            }
                                                            else {
                                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when deleting excluded product!", messages: [response.message] }));
                                                            }
                                                        });
                                                    } })] }))] }))),
                            }));
                        },
                    },
                ], hasShadow: true, pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: currentPage,
                    rowsPerPage: rowsPerPage,
                    totalRecords: excludedProductsPagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig })] })));
};
exports.ExcludedProductListPage = ExcludedProductListPage;
