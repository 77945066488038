"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectSubscriptionPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var payment_plan_1 = require("./tabs/payment_plan");
var SelectSubscriptionPage = function () {
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full flex flex-col h-full justify-center overflow-y-auto " }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "overflow-y-auto overflow-x-hidden  px-8" }, { children: (0, jsx_runtime_1.jsx)(payment_plan_1.PaymentPlanPage, { hasBackButton: false }) })) })));
};
exports.SelectSubscriptionPage = SelectSubscriptionPage;
