"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../reducers/slices/console");
var branch_configure_1 = require("./components/branch_configure");
var stock_configure_1 = require("./components/stock_configure");
var get_client_suppliers_thunk_1 = require("../../../../reducers/slices/client/thunks/get_client_suppliers_thunk");
var ProcurePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(0), selectedTabIndex = _a[0], setSelectedTabIndex = _a[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var pagination = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).pagination;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Procure"));
    }, []);
    var getSuppliers = function () {
        var _pagination = __assign({}, pagination);
        _pagination === null || _pagination === void 0 ? true : delete _pagination.countTotal;
        dispatch((0, get_client_suppliers_thunk_1.get_client_suppliers_thunk)({
            payload: __assign({}, _pagination),
            callBack: function () {
                setSelectedTabIndex(1);
            },
        }));
    };
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TabControl, { selectedTabIndex: selectedTabIndex, tabs: [
                {
                    title: "Branch",
                    content: (0, jsx_runtime_1.jsx)(branch_configure_1.BranchConfigure, { getSuppliers: getSuppliers }),
                },
                {
                    title: "Stock",
                    content: (0, jsx_runtime_1.jsx)(stock_configure_1.StockConfigure, {}),
                },
            ], colors: {
                borderColor: "border-borders",
                tabs: {
                    textColor: "text-gray-800",
                    indicatorColor: "bg-primary",
                    backgroundColor: "bg-borders",
                    unselectedBackgroundColor: "bg-white",
                    selectedBackgroundColor: "bg-white",
                },
            } }) })));
};
exports.ProcurePage = ProcurePage;
