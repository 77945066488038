"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderTimelineComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var add_order_notes_1 = require("../../../../../api/calls/order/update/add_order_notes");
var constants_1 = require("../../../../../constants/constants");
var main_1 = require("../../../../../reducers/slices/main");
var get_order_thunk_1 = require("../../../../../reducers/slices/orders/thunks/get_order_thunk");
var OrderTimelineComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), orderNotes = _a[0], setOrderNotes = _a[1];
    var selectedOrder = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }).selectedOrder;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var saveOrderNotes = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, add_order_notes_1.add_order_notes)({
                        order_id: selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder._id,
                        user: {
                            _id: user === null || user === void 0 ? void 0 : user._id,
                            display_name: "".concat(user.name, " ").concat(user.surname),
                        },
                        message: orderNotes,
                        date: "".concat(new Date()),
                    })];
                case 1:
                    response = _a.sent();
                    if (response.success)
                        dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id }));
                    else
                        dispatch((0, main_1.setMessageModal)({ title: constants_1.REQUEST_ERROR_TITLE, messages: [response.message] }));
                    return [2 /*return*/];
            }
        });
    }); };
    var formatOrderNotes = function (notes) {
        return notes === null || notes === void 0 ? void 0 : notes.map(function (note) {
            return __assign(__assign({}, note), { date: doshx_controls_web_1.Utilities.formatDateTime(note === null || note === void 0 ? void 0 : note.date, "Do MMMM YYYY"), time: doshx_controls_web_1.Utilities.formatDateTime(note === null || note === void 0 ? void 0 : note.date, "HH:mm") });
        });
    };
    var formattedNotes = selectedOrder ? formatOrderNotes(selectedOrder.notes) : [];
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center justify-center p-4 w-16 h-16 rounded-full border " }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faUser, onClick: function () { }, backgroundColor: "bg-gray-200", iconColor: "text-black", shape: "circle", size: "large" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full pl-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Notes/Comments", value: orderNotes, onChange: function (text) { return setOrderNotes(text); }, required: true, backgroundColor: "bg-gray-200", borderColor: "border-transparent", lines: 1, size: "medium" }) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Post", textColor: "text-white", backgroundColor: "bg-secondary", borderColor: "border-transparent", size: "small", onClick: saveOrderNotes }) })), formattedNotes && formattedNotes.length > 0 && ((0, jsx_runtime_1.jsx)("div", { children: formattedNotes === null || formattedNotes === void 0 ? void 0 : formattedNotes.map(function (note, index) {
                            var _a;
                            return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-6 h-6 border-1 border-white rounded-full flex items-center justify-center" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheckCircle, className: "text-blue-500" }) })), index < formattedNotes.length - 1 && (0, jsx_runtime_1.jsx)("div", { className: "w-1 h-20 bg-blue-500" })] })) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col pl-4 space-y-1" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-between items-start" }, { children: (0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-sm" }, { children: note === null || note === void 0 ? void 0 : note.date })) })), (note === null || note === void 0 ? void 0 : note.user) && (0, jsx_runtime_1.jsxs)("p", __assign({ className: "font-bold" }, { children: ["Note by: ", (_a = note === null || note === void 0 ? void 0 : note.user) === null || _a === void 0 ? void 0 : _a.display_name] })), (0, jsx_runtime_1.jsx)("p", __assign({ className: (note === null || note === void 0 ? void 0 : note.user) ? "text-md" : "font-bold" }, { children: note === null || note === void 0 ? void 0 : note.message }))] }))] })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-sm text-gray-500" }, { children: note === null || note === void 0 ? void 0 : note.time }))] }), index));
                        }) }))] })) })) }));
};
exports.OrderTimelineComponent = OrderTimelineComponent;
