"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var constants_1 = require("./constants");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
exports.default = (function () {
    var _a = (0, react_1.useState)(false), sidebarCollapsed = _a[0], setSidebarCollapsed = _a[1];
    var toggleCollapse = function () { return setSidebarCollapsed(!sidebarCollapsed); };
    var NodeComponents = function () { return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: Object.entries(constants_1.nodeConfigurations)
            .map(function (_a, index) {
            var category = _a[0], typeConfigs = _a[1];
            if (!typeConfigs) {
                return null;
            }
            var nodes = typeConfigs
                .sort(function (a, b) { return (a.data.name > b.data.name ? 1 : -1); })
                .map(function (value, subIndex) {
                var type = value.type, data = value.data;
                var NodeTypeComponent = constants_1.nodeTypes[type];
                if (!NodeTypeComponent) {
                    return null;
                }
                return (0, jsx_runtime_1.jsx)(NodeTypeComponent, { draggable: true, type: type, category: category, data: data }, "".concat(category, "-").concat(type, "-").concat(index * 100 + subIndex));
            })
                .filter(Boolean);
            if (nodes.length === 0) {
                return null;
            }
            return index === 0 ? nodes : __spreadArray([(0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { lineColor: "bg-borders" }, "divider-".concat(index))], nodes, true);
        })
            .flat() })); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-start gap-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex ".concat(sidebarCollapsed ? "flex-col gap-4" : "row justify-between min-w-[250px]", " max-w-[400px]") }, { children: [!sidebarCollapsed && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "You can drag these nodes into the editor on the left.", size: "medium", textColor: "text-gray-500" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: sidebarCollapsed ? pro_light_svg_icons_1.faPlus : pro_light_svg_icons_1.faMinus, iconColor: sidebarCollapsed ? "text-white" : "text-black", backgroundColor: sidebarCollapsed ? "bg-primary hover:bg-blue-500" : "bg-gray-100 hover:bg-blue-300", borderColor: "border-transparent", onClick: toggleCollapse, size: "2xsmall" })] })), !sidebarCollapsed && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-blue-50", borderColor: "border-transparent", className: "flex flex-col h-full w-full overflow-scroll shadow-inner rounded-lg p-2 gap-2", addPadding: false }, { children: (0, jsx_runtime_1.jsx)(NodeComponents, {}) })))] })));
});
