"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UberStoreListComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var main_1 = require("../../../../../../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var uber_integrated_1 = require("./uber_integrated");
var get_store_list_1 = require("../../../../../../api/calls/integration/uber/get_store_list");
var UberStoreListComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.channelState; }), loading = _a.loading, channels = _a.channels;
    var _b = (0, react_router_dom_1.useSearchParams)(), searchParams = _b[0], setSearchParams = _b[1];
    var _c = (0, react_1.useState)([]), stores = _c[0], setStores = _c[1];
    (0, react_1.useEffect)(function () {
        getStoreList();
        integrationRedirect();
    }, []);
    var integrationRedirect = function () {
        if (!searchParams.get("code"))
            return;
        dispatch((0, main_1.setContentModal)({
            title: "Creating a Uber integration...",
            content: (0, jsx_runtime_1.jsx)(uber_integrated_1.UberIntegrated, {}),
        }));
    };
    var getStoreList = function () {
        (0, get_store_list_1.get_store_list)().then(function (res) { });
    };
    return loading ? ((0, jsx_runtime_1.jsx)("div", __assign({ className: "grid justify-center p-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl //
        , { size: "xlarge" }) }))) : ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "xs:w-auto text-center" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex" }, { children: [(0, jsx_runtime_1.jsx)("div", { className: "mr-4" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 flex-col gap-1 text-start" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Connect Uber", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "The list of stores you have with uber will appear here." })] }))] })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-between" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-start" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "small", startIcon: pro_light_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                            navigate(-1);
                        } }) })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4" }, { children: stores.map(function (x) { return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex-1" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(x === null || x === void 0 ? void 0 : x.display_name) }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { disabled: false, value: true, onChange: function (v) {
                                            //   onChange("is_default", v);
                                        }, textColor: "text-primary", size: "large" }) }))] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "By connecting your uber account, you agree that your products, customers and/or orders will be visible and manageable on the Letstrade platform.", size: "xsmall", center: true })] }))); }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Push to uber", endIcon: pro_light_svg_icons_1.faPaperPlane, size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { } }) })) }))] })));
};
exports.UberStoreListComponent = UberStoreListComponent;
