"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sanitiseObjectRecursively = exports.sanitiseObject = void 0;
function sanitiseObject(obj) {
    for (var prop in obj) {
        if (typeof obj[prop] !== "boolean") {
            if (!obj[prop]) {
                delete obj[prop];
            }
        }
    }
    return obj;
}
exports.sanitiseObject = sanitiseObject;
function sanitiseObjectRecursively(obj) {
    var _loop_1 = function (prop) {
        if (typeof obj[prop] === "object") {
            if (Array.isArray(obj[prop])) {
                obj[prop].forEach(function (item, index) {
                    sanitiseObjectRecursively(item);
                    if (typeof item === "object" && !Array.isArray(item) && Object.keys(item).length === 0) {
                        obj[prop].splice(index, 1);
                    }
                });
                if (obj[prop].length === 0) {
                    delete obj[prop];
                }
            }
            else {
                sanitiseObjectRecursively(obj[prop]);
                if (Object.keys(obj[prop]).length === 0) {
                    delete obj[prop];
                }
            }
        }
        else if (typeof obj[prop] !== "boolean") {
            if (!obj[prop]) {
                delete obj[prop];
            }
        }
    };
    for (var prop in obj) {
        _loop_1(prop);
    }
    return obj;
}
exports.sanitiseObjectRecursively = sanitiseObjectRecursively;
