"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationConfig = exports.initialSubscriptionValues = exports.productTableColumns = exports.redemptionsTableColumns = exports.tableColumns = exports.productTypeOptions = exports.paymentFailedRetryOptions = exports.statusOptions = exports.onPaymentFailureOptions = exports.applyToOptions = exports.typeOptions = exports.periodOptions = exports.subscriptionTypeOptions = exports.paymentTypeOptions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var formatDate_1 = require("../../../../../helpers/formatDate");
var constants_1 = require("../../../constants");
exports.paymentTypeOptions = [
    {
        label: "Post Payment",
        value: "POST_PAYMENT",
        tooltip: "Cumulative total for redemptions will be charged in arrears as the subscription total.",
    },
    {
        label: "Pre Payment",
        value: "PRE_PAYMENT",
        tooltip: "Total redemption values are paid upfront as the subscription total, redemptions are limited to a specified amount.",
    },
];
exports.subscriptionTypeOptions = [
    { label: "Order Creation", value: "ORDER_CREATION", tooltip: "Order is created on activation of the subscription for the product in the basket." },
    { label: "Selective Usage", value: "SELECTIVE_USAGE", tooltip: "Subscription is activated prior to an order creation and will be redeemed on selection." },
];
exports.periodOptions = [
    {
        label: "Custom",
        value: "CUSTOM",
    },
    {
        label: "Weekly",
        value: "WEEKLY",
    },
    {
        label: "Monthly",
        value: "MONTHLY",
    },
    {
        label: "Annually",
        value: "ANNUALLY",
    },
    {
        label: "Once-off",
        value: "ONCE_OFF",
    },
];
exports.typeOptions = [
    {
        label: "Fixed Period",
        value: "FIXED_PERIOD",
    },
    {
        label: "Ongoing",
        value: "ONGOING",
    },
    {
        label: "Fixed End Date",
        value: "FIXED_END_DATE",
    },
];
exports.applyToOptions = [
    {
        label: "Product",
        value: "PRODUCT",
    },
    {
        label: "Category",
        value: "CATEGORY",
    },
    {
        label: "Basket",
        value: "BASKET",
    },
    {
        label: "Branch",
        value: "BRANCH",
    },
    {
        label: "Compound",
        value: "COMPOUND",
    },
];
exports.onPaymentFailureOptions = [
    {
        label: "Cancel",
        value: "CANCEL",
    },
    {
        label: "Retry",
        value: "RETRY",
    },
    {
        label: "Pause",
        value: "PAUSE",
    },
];
exports.statusOptions = [
    {
        label: "Draft",
        value: "DRAFT",
    },
    {
        label: "Active",
        value: "ACTIVE",
    },
    {
        label: "Coming Soon",
        value: "COMING_SOON",
    },
    {
        label: "Paused",
        value: "PAUSED",
    },
];
exports.paymentFailedRetryOptions = [
    { label: "Cancel", value: "CANCEL" },
    { label: "Pause", value: "PAUSE" },
    { label: "Retry", value: "RETRY" },
];
exports.productTypeOptions = [
    { label: "Primary", value: "PRODUCT" },
    { label: "Category", value: "CATEGORY" },
    { label: "Combination", value: "COMPOUND" },
];
exports.tableColumns = [
    {
        displayKey: "name",
        label: "Title",
    },
    {
        displayKey: "start_date",
        label: "Start Date",
        formatter: function (_) { return (0, formatDate_1.formatDate)(_.start_date); },
    },
    {
        displayKey: "last_purchase_date",
        label: "Last Purchase Date",
        formatter: function (_) { return (0, formatDate_1.formatDate)(_.last_purchase_date); },
    },
    {
        displayKey: "last_redemption_date",
        label: "Last Redemption Date",
        formatter: function (_) { return (0, formatDate_1.formatDate)(_.last_redemption_date); },
    },
    {
        displayKey: "subscribers",
        label: "Subscribers",
    },
    {
        displayKey: "redemptions",
        label: "Redemptions",
    },
    {
        displayKey: "status",
        label: "Status",
        formatter: function (x) {
            if (x) {
                return (0, constants_1.TABLE_STATUSES)(x.status);
            }
        },
    },
];
exports.redemptionsTableColumns = [
    {
        displayKey: "redeemed_subscriptions",
        label: "Branch",
        formatter: function (data) {
            var _a;
            if (data === null || data === void 0 ? void 0 : data.branch) {
                return (_a = data === null || data === void 0 ? void 0 : data.branch) === null || _a === void 0 ? void 0 : _a.branch_name;
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "price",
        label: "Amount",
    },
    {
        displayKey: "redemption_method",
        label: "Method",
    },
    {
        displayKey: "redemption_date",
        label: "Redemption Date",
        formatter: function (_) { return (0, formatDate_1.formatDate)(_.redemption_date); },
    },
];
exports.productTableColumns = [
    {
        displayKey: "image",
        label: "Image",
        formatter: function (data) {
            if (data === null || data === void 0 ? void 0 : data.image) {
                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: data === null || data === void 0 ? void 0 : data.image });
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "PID",
        label: "PID",
    },
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "price",
        label: "Price",
    },
    /* {
      displayKey: "status",
      label: "Status",
    },*/
];
var _devMode = process.env.NODE_ENV !== "production";
exports.initialSubscriptionValues = {
    image: null,
    notes: "",
    categories: [],
    trial_period: "",
    is_new: false,
    cheapest_only: false,
    primary_products: [],
    products: [],
    expires_in: _devMode ? new Date().toISOString() : "",
    billable: true,
    number_of_periods: _devMode ? "12" : "",
    payment_type: "POST_PAYMENT",
    subscription_usage_type: "ORDER_CREATION",
    name: _devMode ? "Subscription Test" : "",
    max_per_user: _devMode ? "5" : "",
    description: _devMode ? "Subscription A description" : "",
    sku: _devMode ? "SKU-001" : "",
    period: _devMode ? "MONTHLY" : "WEEKLY",
    type: _devMode ? "ONGOING" : "FIXED_PERIOD",
    apply_to: _devMode ? "CATEGORY" : "PRODUCT",
    start_date: _devMode ? new Date().toISOString() : "",
    price: _devMode ? "20.00" : "",
    discountable: _devMode ? true : false,
    free_trial: _devMode ? true : false,
    last_purchase_date: _devMode ? new Date().toISOString() : "",
    last_redemption_date: _devMode ? new Date().toISOString() : "",
    max_redemptions_per_day: _devMode ? "10" : "",
    max_redemptions_per_period: _devMode ? "50" : "",
    carry_over: _devMode ? true : false,
    on_payment_failure: _devMode ? "CANCEL" : "RETRY",
    pause_while_retrying: _devMode ? true : false,
    retry_days: _devMode ? "3" : "",
    retry_count: _devMode ? "5" : "",
    expires: _devMode ? true : false,
    status: _devMode ? "ACTIVE" : "DRAFT",
    trial_conversion: false,
    auto_renewal: false,
};
exports.validationConfig = {
    image: {
        required: false,
    },
    notes: { required: false },
    end_date: { required: false },
    categories: {
        dependOn: {
            key: "apply_to",
            value: "CATEGORY",
        },
    },
    retry_days: {
        dependOn: {
            key: "on_payment_failure",
            value: "RETRY",
        },
    },
    retry_count: {
        dependOn: {
            key: "on_payment_failure",
            value: "RETRY",
        },
    },
    trial_period: { required: false },
    expires_in: { required: true },
    custom_days: { required: false },
    primary_products: {
        dependOn: {
            key: "apply_to",
            value: "COMPOUND",
        },
    },
    products: {
        dependOn: {
            key: "apply_to",
            value: "COMPOUND",
        },
    },
};
