"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.editParcel = exports.updateParcelFloatingFleet = exports.updateBranchParcelStatuses = exports.setBranchParcels = exports.setTablePagination = exports.setIsLoadingParcel = exports.setParcel = exports.setIsLoadingParcels = exports.setParcels = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var _devMode = process.env.NODE_ENV !== "production";
var initialState = {
    parcels: [],
    branchParcels: [],
    isLoadingParcels: false,
    parcel: {},
    isLoadingParcel: false,
    pagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "parcels",
    initialState: initialState,
    reducers: {
        setParcels: function (state, action) {
            state.parcels = action.payload;
        },
        setBranchParcels: function (state, action) {
            state.branchParcels = action.payload;
        },
        updateBranchParcelStatuses: function (state, action) {
            state.branchParcels = state.branchParcels.map(function (x) {
                if (action.payload.parcel_id_list.includes(x._id)) {
                    return __assign(__assign({}, x), { status: action.payload.status });
                }
                return x;
            });
        },
        updateParcelFloatingFleet: function (state, action) {
            state.parcels = state.parcels.map(function (x) {
                if (x._id === action.payload._id) {
                    x.floating_fleet = action.payload.floating_fleet;
                }
                return x;
            });
        },
        editParcel: function (state, action) {
            state.parcels = state.parcels.map(function (x) {
                if (x._id === action.payload._id) {
                    return action.payload;
                }
                return x;
            });
        },
        setIsLoadingParcels: function (state, action) {
            state.isLoadingParcels = action.payload;
        },
        setParcel: function (state, action) {
            state.parcel = action.payload;
        },
        setIsLoadingParcel: function (state, action) {
            state.isLoadingParcel = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setParcels = (_a = slice.actions, _a.setParcels), exports.setIsLoadingParcels = _a.setIsLoadingParcels, exports.setParcel = _a.setParcel, exports.setIsLoadingParcel = _a.setIsLoadingParcel, exports.setTablePagination = _a.setTablePagination, exports.setBranchParcels = _a.setBranchParcels, exports.updateBranchParcelStatuses = _a.updateBranchParcelStatuses, exports.updateParcelFloatingFleet = _a.updateParcelFloatingFleet, exports.editParcel = _a.editParcel;
exports.default = slice.reducer;
