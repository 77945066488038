"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTotalProductPrice = void 0;
function sumVariations(variances) {
    var total = 0;
    if (Array.isArray(variances)) {
        for (var _i = 0, variances_1 = variances; _i < variances_1.length; _i++) {
            var item = variances_1[_i];
            if (item.price && !isNaN(item.price)) {
                total += parseFloat(item.price);
            }
            if (item.variations) {
                total += sumVariations(item.variations);
            }
        }
    }
    return total;
}
function calculateTotalProductPrice(productPrice, variances) {
    var totalVariancePrice = sumVariations(variances);
    return parseFloat(productPrice) + totalVariancePrice;
}
exports.calculateTotalProductPrice = calculateTotalProductPrice;
