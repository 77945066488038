"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("../constants");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var user_sign_up_1 = require("../../../../../api/calls/user/user_sign_up");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var get_client_user_branches_1 = require("../../../../../api/calls/client/get_client_user_branches");
var branch_list_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var add_user_address_1 = require("../../../../../api/calls/user/create/add_user_address");
var AddCustomerComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var addressTitle = props.addressTitle;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _o = (0, useSearchDebounce_1.useSearchDebounce)(900), searchDashboardBranches = _o[0], setSearchDashboardBranches = _o[1];
    var clientUsers = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).clientUsers;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _p = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), dashboardBranches = _p.dashboardBranches, dashboardBranchPagination = _p.dashboardBranchPagination;
    var query = __assign(__assign({}, dashboardBranchPagination), { is_dashboard_request: true });
    //clientUser branches
    var _q = (0, react_1.useState)([]), clientUserBranches = _q[0], setClientUserBranches = _q[1];
    var _r = (0, react_1.useState)(undefined), selectedBranchId = _r[0], setSelectedBranchId = _r[1];
    var _s = (0, react_1.useState)(""), branch = _s[0], setBranch = _s[1];
    var _t = (0, useSearchDebounce_1.useSearchDebounce)(), isValidEmailAddress = _t[0], setIsValidEmailAddress = _t[1];
    var _u = (0, useSearchDebounce_1.useSearchDebounce)(), isValidPhoneNumber = _u[0], setIsValidPhoneNumber = _u[1];
    var _v = (0, react_1.useState)(), selectedBirthDay = _v[0], setSelectedBirthDay = _v[1];
    var _w = (0, react_1.useState)(false), isSaving = _w[0], setIsSaving = _w[1];
    var _x = (0, react_1.useState)(false), isTouchedNameInput = _x[0], setIsTouchedNameInput = _x[1];
    var _y = (0, react_1.useState)(false), isTouchedSurnameInput = _y[0], setIsTouchedSurnameInput = _y[1];
    var _z = (0, react_1.useState)(false), isTouchedEmailInput = _z[0], setIsTouchedEmailInput = _z[1];
    var _0 = (0, react_1.useState)(false), isTouchedPhoneInput = _0[0], setIsTouchedPhoneInput = _0[1];
    var _1 = (0, react_1.useState)({
        full_address: "",
        longitude: "",
        latitude: "",
    }), selectedAddress = _1[0], setSelectedAddress = _1[1];
    var _2 = (0, react_1.useState)({
        name: "",
        surname: "",
        email: null,
        cell_number: null,
        gender: null,
        member_card_number: null,
        status: "AWAITING-SIGNUP",
        birthday: null,
        member_date: doshx_controls_web_1.Utilities.formatDateTime(new Date(), "YYYY-MM-DD"),
        loyalty_sign_up: true,
        get_communication: false,
        agrees_terms_and_conditions: false,
        communication_method: [],
        member_branch_id: null,
    }), params = _2[0], setParams = _2[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (user && ((_a = Object.keys(user)) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
            getLoggedInUserBranches();
        }
    }, [clientUsers]);
    (0, react_1.useEffect)(function () {
        onSearchBranches(searchDashboardBranches);
    }, [searchDashboardBranches]);
    (0, react_1.useEffect)(function () {
        console.log("selectedAddress ==> ", selectedAddress);
    }, [selectedAddress]);
    var updateValues = function (prop, value) {
        var _a;
        setParams(__assign(__assign({}, params), (_a = {}, _a[prop] = value, _a)));
    };
    var getBranches = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, branch_list_thunk_1.user_branch_list_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchBranches = function (search_string) {
        getBranches(__assign(__assign(__assign({}, dashboardBranchPagination), (search_string && { search_string: search_string })), { is_dashboard_request: true }));
    };
    function validateEmailAddress(email) {
        // email validation regex
        var regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
        return regex.test(email);
    }
    function validatePhoneNumber(phone) {
        // phone number validation regex
        var regex = /^(\+27|0)[6-8][0-9]{8}$/;
        return regex.test(phone);
    }
    var getLoggedInUserBranches = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!(user && (user === null || user === void 0 ? void 0 : user.role) === "AREA_MANAGER")) return [3 /*break*/, 1];
                    (0, get_client_user_branches_1.get_logged_in_client_user_branches)({
                        branch_id_list: user === null || user === void 0 ? void 0 : user.manager_of,
                    }).then(function (response) {
                        var _a;
                        if (response.success) {
                            setClientUserBranches((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.branch_list);
                        }
                    });
                    return [3 /*break*/, 3];
                case 1:
                    if (!((user === null || user === void 0 ? void 0 : user.role) === "ADMIN")) return [3 /*break*/, 3];
                    return [4 /*yield*/, dispatch((0, branch_list_thunk_1.user_branch_list_thunk)(__assign(__assign({}, dashboardBranchPagination), query)))];
                case 2:
                    response = _d.sent();
                    if ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.success) {
                        setClientUserBranches((_c = (_b = response === null || response === void 0 ? void 0 : response.payload) === null || _b === void 0 ? void 0 : _b.content) === null || _c === void 0 ? void 0 : _c.branch_list);
                    }
                    _d.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onAddCustomer = function () {
        var _a;
        setIsSaving(true);
        var name = params.name, surname = params.surname, email = params.email, cell_number = params.cell_number, gender = params.gender, member_card_number = params.member_card_number, status = params.status, birthday = params.birthday, member_date = params.member_date, loyalty_sign_up = params.loyalty_sign_up, get_communication = params.get_communication, agrees_terms_and_conditions = params.agrees_terms_and_conditions, communication_method = params.communication_method, member_branch_id = params.member_branch_id;
        // Create the payload dynamically
        var payload = {
            name: name,
            surname: surname,
            email: (email === null || email === void 0 ? void 0 : email.length) > 0 ? email : "guest@doshex.com",
            cell_number: cell_number,
            password: "123456",
            gender: gender,
            get_communication: get_communication,
            member_card_number: member_card_number,
            status: status,
            birthday: birthday,
            loyalty_sign_up: loyalty_sign_up,
            member_date: member_date,
            agrees_terms_and_conditions: agrees_terms_and_conditions,
            communication_method: communication_method,
            member_branch_id: (user === null || user === void 0 ? void 0 : user.role) === "BRANCH_MANAGER" ? user === null || user === void 0 ? void 0 : user.manager_of[0] : selectedBranchId,
        };
        if (((_a = payload === null || payload === void 0 ? void 0 : payload.member_card_number) === null || _a === void 0 ? void 0 : _a.length) === 0)
            delete payload.member_card_number;
        if ((payload === null || payload === void 0 ? void 0 : payload.gender) === "")
            payload.gender = "N/A";
        if ((user === null || user === void 0 ? void 0 : user.role) === "AREA_MANAGER" && !(payload === null || payload === void 0 ? void 0 : payload.member_branch_id)) {
            dispatch((0, main_1.setMessageModal)({ title: "Branch is required!", messages: ["Please select a branch"] }));
            setIsSaving(false);
            return;
        }
        (0, user_sign_up_1.user_sign_up)(payload).then(function (response) {
            if (response.success) {
                dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                setIsSaving(false);
                setParams({
                    name: "",
                    surname: "",
                    email: "",
                    cell_number: "",
                    gender: "",
                    member_card_number: "",
                    status: "AWAITING-SIGNUP",
                    birthday: null,
                    member_date: doshx_controls_web_1.Utilities.formatDateTime(new Date(), "YYYY-MM-DD"),
                    loyalty_sign_up: true,
                    get_communication: false,
                    agrees_terms_and_conditions: false,
                    communication_method: [],
                });
                addUserAddress(response.content._id);
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when adding a customer!", messages: [response.message] }));
                setIsSaving(false);
            }
        });
    };
    var addUserAddress = function (userID) {
        if (selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address) {
            setIsSaving(true);
            (0, add_user_address_1.add_user_address)({
                user_id: userID,
                address: {
                    label: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address,
                    full_address: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.full_address,
                    latitude: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.latitude,
                    longitude: selectedAddress === null || selectedAddress === void 0 ? void 0 : selectedAddress.longitude,
                    default: true,
                },
            }).then(function (response) {
                setIsSaving(false);
                if (response.success) {
                    setSelectedAddress({
                        full_address: "",
                        longitude: "",
                        latitude: "",
                    });
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                }
            });
        }
    };
    var isValidRequest = ((_a = params === null || params === void 0 ? void 0 : params.name) === null || _a === void 0 ? void 0 : _a.length) > 0 && ((_b = params === null || params === void 0 ? void 0 : params.surname) === null || _b === void 0 ? void 0 : _b.length) > 0 && ((_c = params === null || params === void 0 ? void 0 : params.cell_number) === null || _c === void 0 ? void 0 : _c.length) === 10 && isValidPhoneNumber;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-1 lg:gap-2 lg:h-full md:h-auto sm:h-auto" }, { children: [(user === null || user === void 0 ? void 0 : user.role) !== "BRANCH_MANAGER" && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (branch) { return branch === null || branch === void 0 ? void 0 : branch.label; }, label: "Select a branch...", options: (user === null || user === void 0 ? void 0 : user.role) === "ADMIN"
                    ? dashboardBranches === null || dashboardBranches === void 0 ? void 0 : dashboardBranches.map(function (branch) { return ({ label: branch === null || branch === void 0 ? void 0 : branch.display_name, value: branch === null || branch === void 0 ? void 0 : branch._id }); })
                    : (_d = clientUserBranches === null || clientUserBranches === void 0 ? void 0 : clientUserBranches.map(function (branch) { return ({ label: branch === null || branch === void 0 ? void 0 : branch.display_name, value: branch === null || branch === void 0 ? void 0 : branch._id }); })) !== null && _d !== void 0 ? _d : [], size: "small", value: branch, colors: {
                    borderColor: "border-borders",
                    iconColor: "text-primary",
                }, errorText: (user === null || user === void 0 ? void 0 : user.role) === "AREA_MANAGER" && !selectedBranchId ? "Branch is required" : "", onChange: function (branchObject) {
                    setBranch(branchObject);
                    setSelectedBranchId(branchObject === null || branchObject === void 0 ? void 0 : branchObject.value);
                    updateValues("member_branch_id", branchObject === null || branchObject === void 0 ? void 0 : branchObject.value);
                }, onSearchChanged: function (search_string) {
                    setSearchDashboardBranches(search_string);
                } })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Name", labelColor: "text-black", type: "text", borderColor: "border-borders", value: params === null || params === void 0 ? void 0 : params.name, errorText: isTouchedNameInput && ((_e = params === null || params === void 0 ? void 0 : params.name) === null || _e === void 0 ? void 0 : _e.length) === 0 ? "Name is required" : "", size: "small", onChange: function (v) {
                    setIsTouchedNameInput(true);
                    updateValues("name", v);
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Surname", labelColor: "text-black", type: "text", borderColor: "border-borders", value: params === null || params === void 0 ? void 0 : params.surname, errorText: isTouchedSurnameInput && ((_f = params === null || params === void 0 ? void 0 : params.surname) === null || _f === void 0 ? void 0 : _f.length) === 0 ? "Surname is required" : "", size: "small", onChange: function (v) {
                    setIsTouchedSurnameInput(true);
                    updateValues("surname", v);
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Email", type: "text", borderColor: "border-borders", value: params.email, errorText: ((_g = params === null || params === void 0 ? void 0 : params.email) === null || _g === void 0 ? void 0 : _g.length) > 0 && isTouchedEmailInput && !isValidEmailAddress ? "Invalid email address" : "", size: "small", onChange: function (v) {
                    setIsTouchedEmailInput(true);
                    updateValues("email", v);
                    setIsValidEmailAddress(validateEmailAddress(v));
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Cell Number", labelColor: ((_h = params === null || params === void 0 ? void 0 : params.cell_number) === null || _h === void 0 ? void 0 : _h.length) !== 10 ? "text-red-400" : "text-black", type: "number", borderColor: "border-borders", value: params === null || params === void 0 ? void 0 : params.cell_number, errorText: ((_j = params === null || params === void 0 ? void 0 : params.cell_number) === null || _j === void 0 ? void 0 : _j.length) > 0 && isTouchedPhoneInput && !isValidPhoneNumber ? "Invalid phone number" : "", size: "small", onChange: function (v) {
                    setIsTouchedPhoneInput(true);
                    updateValues("cell_number", v);
                    setIsValidPhoneNumber(validatePhoneNumber(v));
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Gender", borderColor: "border-borders", required: true, options: constants_1.genderOptions, iconColor: "text-primary", size: "small", value: params.gender, onChange: function (v) {
                    updateValues("gender", v);
                }, errorText: "" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Member Card Number", type: "number", borderColor: "border-borders", value: params === null || params === void 0 ? void 0 : params.member_card_number, errorText: "", size: "small", onChange: function (v) {
                    updateValues("member_card_number", v);
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { label: "Date of Birth", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-borders", value: selectedBirthDay, size: "small", onChange: function (v) {
                    setSelectedBirthDay(v);
                    updateValues("birthday", doshx_controls_web_1.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                } }), addressTitle && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.AddressSearchControl, { letsTradeAPIUrl: process.env.LETS_TRADE_CLIENT_URL, required: true, placeholder: addressTitle, errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-borders", size: "small", onSelectionChange: function (value) {
                    setSelectedAddress({
                        full_address: value.fullAddress,
                        longitude: value.coordinates.lng,
                        latitude: value.coordinates.lat,
                    });
                } })), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Get Communication?", size: "xsmall", value: params === null || params === void 0 ? void 0 : params.get_communication, onChange: function (v) {
                            updateValues("get_communication", v);
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Agrees To Terms and Conditions?", size: "xsmall", value: params === null || params === void 0 ? void 0 : params.agrees_terms_and_conditions, onChange: function (v) {
                            updateValues("agrees_terms_and_conditions", v);
                        } })] }), (0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-0" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Communication Preferences", bold: true, size: "xsmall" }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "SMS", size: "xsmall", value: (_k = params === null || params === void 0 ? void 0 : params.communication_method) === null || _k === void 0 ? void 0 : _k.includes("SMS"), onChange: function (v) {
                            var _a;
                            if (v) {
                                updateValues("communication_method", __spreadArray(__spreadArray([], params === null || params === void 0 ? void 0 : params.communication_method, true), ["SMS"], false));
                            }
                            else {
                                updateValues("communication_method", (_a = params === null || params === void 0 ? void 0 : params.communication_method) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item !== "SMS"; }));
                            }
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "EMAIL", size: "xsmall", value: (_l = params === null || params === void 0 ? void 0 : params.communication_method) === null || _l === void 0 ? void 0 : _l.includes("EMAIL"), onChange: function (v) {
                            var _a;
                            if (v) {
                                updateValues("communication_method", __spreadArray(__spreadArray([], params === null || params === void 0 ? void 0 : params.communication_method, true), ["EMAIL"], false));
                            }
                            else {
                                updateValues("communication_method", (_a = params === null || params === void 0 ? void 0 : params.communication_method) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item !== "EMAIL"; }));
                            }
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", label: "WHATSAPP", value: (_m = params === null || params === void 0 ? void 0 : params.communication_method) === null || _m === void 0 ? void 0 : _m.includes("WHATSAPP"), onChange: function (v) {
                            var _a;
                            if (v) {
                                updateValues("communication_method", __spreadArray(__spreadArray([], params === null || params === void 0 ? void 0 : params.communication_method, true), ["WHATSAPP"], false));
                            }
                            else {
                                updateValues("communication_method", (_a = params === null || params === void 0 ? void 0 : params.communication_method) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return item !== "WHATSAPP"; }));
                            }
                        } })] }), (0, jsx_runtime_1.jsx)("hr", {}), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", startIcon: pro_light_svg_icons_1.faCancel, label: "Cancel", size: "small", backgroundColor: "bg-borders", textColor: "text-black", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[90px] sm:block md:hidden" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", startIcon: pro_light_svg_icons_1.faSave, label: "Save", size: "small", enabled: isValidRequest, backgroundColor: "bg-primary", textColor: "text-white", loading: isSaving, onClick: onAddCustomer }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[168px] hidden md:block" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", startIcon: pro_light_svg_icons_1.faSave, label: "Add Customer", size: "small", enabled: isValidRequest, backgroundColor: "bg-primary", textColor: "text-white", loading: isSaving, onClick: onAddCustomer }) }))] }))] })));
};
exports.default = AddCustomerComponent;
