"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonFilterControl = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var ButtonFilterControl = function (props) {
    var title = props.title, value = props.value, onButtonClick = props.onButtonClick;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center items-center w-32 bg-white rounded-md" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: title, decorationColor: "decoration-borders", uncheckedColor: "text-textInput", checkedColor: "text-success", value: value, size: "xsmall", textColor: "text-textInput", onChange: function (v) { return onButtonClick(v); } }) })));
};
exports.ButtonFilterControl = ButtonFilterControl;
