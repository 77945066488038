"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var client_subscriptions_thunk_1 = require("../../../../../reducers/slices/subscriptions/thunks/client_subscriptions_thunk");
var subscriptions_1 = require("../../../../../reducers/slices/subscriptions/subscriptions");
var issue_subscription_1 = require("../../../../../api/calls/subscription/issue_subscription");
var get_user_subscription_1 = require("../../../../../api/calls/subscription/get_user_subscription");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var IssueSubscriptionComponent = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, useSearchDebounce_1.useSearchDebounce)(), searchSubscriptionList = _b[0], setSearchSubscriptionList = _b[1];
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _c.client_token, user = _c.user;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.subscriptionsState; }), subscriptions = _d.subscriptions, pagination = _d.pagination;
    var customer = (0, react_redux_1.useSelector)(function (state) { return state.customerState; }).customer;
    var _e = (0, react_1.useState)(""), subscription = _e[0], setSubscription = _e[1];
    var _f = (0, react_1.useState)(undefined), selectedSubscriptionId = _f[0], setSelectedSubscriptionId = _f[1];
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id)
            getClientSubscriptions(pagination);
    }, [user]);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, client_subscriptions_thunk_1.client_subscriptions_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearchSubscriptionList(searchSubscriptionList);
    }, [searchSubscriptionList]);
    var getClientSubscriptions = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, client_subscriptions_thunk_1.client_subscriptions_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchSubscriptionList = function (search_string) {
        getClientSubscriptions(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-1 lg:gap-2 lg:h-full md:h-auto sm:h-auto" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (subscription) { return subscription === null || subscription === void 0 ? void 0 : subscription.label; }, label: "Select a subscription...", options: (_a = subscriptions === null || subscriptions === void 0 ? void 0 : subscriptions.map(function (subscription) { return ({ label: subscription === null || subscription === void 0 ? void 0 : subscription.name, value: subscription === null || subscription === void 0 ? void 0 : subscription._id }); })) !== null && _a !== void 0 ? _a : [], size: "small", value: subscription, colors: {
                    borderColor: "border-borders",
                    iconColor: "text-primary",
                }, errorText: "", onChange: function (subscriptionObject) {
                    setSubscription(subscriptionObject);
                    setSelectedSubscriptionId(subscriptionObject === null || subscriptionObject === void 0 ? void 0 : subscriptionObject.value);
                }, onSearchChanged: function (search_string) {
                    setSearchSubscriptionList(search_string);
                } }), (0, jsx_runtime_1.jsx)("hr", {}), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", startIcon: pro_light_svg_icons_1.faCancel, label: "Cancel", size: "small", backgroundColor: "bg-borders", textColor: "text-black", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[85px] hidden md:block" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", startIcon: pro_light_svg_icons_1.faSave, label: "Issue", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var response, user_subscriptions;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, (0, issue_subscription_1.issue_subscription)({ user_id: customer === null || customer === void 0 ? void 0 : customer._id, subscription_id: selectedSubscriptionId })];
                                        case 1:
                                            response = _b.sent();
                                            if (!(response === null || response === void 0 ? void 0 : response.success)) return [3 /*break*/, 3];
                                            dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                            dispatch((0, main_1.setContentModal)(null));
                                            return [4 /*yield*/, (0, get_user_subscription_1.get_user_subscription)({ user_id: customer === null || customer === void 0 ? void 0 : customer._id })];
                                        case 2:
                                            user_subscriptions = _b.sent();
                                            dispatch((0, subscriptions_1.setUserSubscriptions)((_a = user_subscriptions === null || user_subscriptions === void 0 ? void 0 : user_subscriptions.content) === null || _a === void 0 ? void 0 : _a.subscription_list));
                                            return [3 /*break*/, 4];
                                        case 3:
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Something went wrong when issuing a subscription.",
                                                messages: [response.message],
                                            }));
                                            _b.label = 4;
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); } }) }))] }))] })));
};
exports.default = IssueSubscriptionComponent;
