"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var driver_sign_up_thunk_1 = require("../../../../../reducers/slices/driver/thunks/driver_sign_up_thunk");
var suspend_driver_thunk_1 = require("../../../../../reducers/slices/driver/thunks/suspend_driver_thunk");
var unsuspend_driver_thunk_1 = require("../../../../../reducers/slices/driver/thunks/unsuspend_driver_thunk");
var update_driver_thunk_1 = require("../../../../../reducers/slices/driver/thunks/update_driver_thunk");
var main_1 = require("../../../../../reducers/slices/main");
var constants_1 = require("./constants");
var AddDriverComponent = function (props) {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var modalLoading = (0, react_redux_1.useSelector)(function (state) { return state.driverState; }).modalLoading;
    var driver = props.driver;
    var _a = (0, react_1.useState)(""), status = _a[0], setStatus = _a[1];
    var _b = (0, react_1.useState)(""), email = _b[0], setEmail = _b[1];
    var _c = (0, react_1.useState)(null), birthday = _c[0], setBirthday = _c[1];
    var _d = (0, react_1.useState)({
        name: "",
        surname: "",
        cell_number: "",
        password: "123456",
        role: "DRIVER",
        email: "",
        birthday: "",
        get_communication: true,
        agrees_terms_and_conditions: false,
        floating_fleet: false,
        app_details: {
            app_link: "link",
            app_name: "Driver App",
        },
    }), values = _d[0], setValues = _d[1];
    (0, react_1.useEffect)(function () {
        console.log("driver ===>", driver);
        if (driver) {
            setEmail(driver.email);
            setBirthday(driver.birthday);
            setStatus(driver.status);
            setValues(__assign({}, driver));
        }
    }, []);
    var updateValues = function (prop, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = value, _a)));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Name", type: "text", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.name, 
                // errorText={validate().url}
                errorText: "", onChange: function (v) {
                    updateValues("name", v);
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Surname", type: "text", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.surname, 
                // errorText={validate().url}
                errorText: "", onChange: function (v) {
                    updateValues("surname", v);
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Cell Number", type: "number", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.cell_number, 
                // errorText={validate().url}
                errorText: "", onChange: function (v) {
                    updateValues("cell_number", v);
                } }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Email", type: "text", borderColor: "border-borders", value: email, 
                        // errorText={validate().url}
                        errorText: "", onChange: function (v) {
                            setEmail(v);
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DateTimeControl, { label: "Date of birth", errorText: "", borderColor: "border-borders", value: birthday, onChange: function (v) {
                            setBirthday(v);
                        } })] }), (driver === null || driver === void 0 ? void 0 : driver._id) && ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Status", borderColor: "border-borders", required: true, options: constants_1.DRIVER_STATUSES, iconColor: "text-primary", size: "small", value: status, onChange: function (v) {
                    setStatus(v);
                    if (v) {
                        if (v === "SUSPENDED") {
                            dispatch((0, suspend_driver_thunk_1.suspend_driver_thunk)({ _id: driver === null || driver === void 0 ? void 0 : driver._id }));
                        }
                        else if (v === "ACTIVE") {
                            dispatch((0, unsuspend_driver_thunk_1.unsuspend_driver_thunk)({ _id: driver === null || driver === void 0 ? void 0 : driver._id }));
                        }
                    }
                }, errorText: "" })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Get communication ?", value: values === null || values === void 0 ? void 0 : values.get_communication, onChange: function (v) {
                    updateValues("get_communication", v);
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Floating Fleet Diver ?", value: values === null || values === void 0 ? void 0 : values.floating_fleet, onChange: function (v) {
                    updateValues("floating_fleet", v);
                } }), (0, jsx_runtime_1.jsx)("hr", {}), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Note: Driver will be sent login\u2019s to access LMF app", center: true, size: "xsmall" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-evenly" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-white", textColor: "text-primary", borderColor: "border-primary", onClick: function () {
                            dispatch((0, main_1.setContentModal)(null));
                        } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { loading: modalLoading, label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                            if (driver === null || driver === void 0 ? void 0 : driver._id) {
                                dispatch((0, update_driver_thunk_1.update_driver_thunk)({
                                    _id: driver === null || driver === void 0 ? void 0 : driver._id,
                                    name: values.name,
                                    surname: values.surname,
                                    cell_number: values.cell_number,
                                    email: email,
                                    birthday: birthday,
                                    get_communication: values === null || values === void 0 ? void 0 : values.get_communication,
                                    floating_fleet: values.floating_fleet,
                                }));
                            }
                            else {
                                dispatch((0, driver_sign_up_thunk_1.driver_sign_up_thunk)(__assign(__assign({}, values), { birthday: birthday, email: email })));
                            }
                            console.log("values ==>", values);
                        } })] }))] })));
};
exports.default = AddDriverComponent;
