"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WAYBILL_TABLE_COLUMNS = exports.PARCEL_STATUSES = exports.DRIVER_STATUSES = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
exports.DRIVER_STATUSES = [
    {
        label: "Active",
        value: "ACTIVE",
    },
    {
        label: "Inactive",
        value: "INACTIVE",
    },
    {
        label: "Suspended",
        value: "SUSPENDED",
    },
    {
        label: "Offline",
        value: "OFFLINE",
    },
];
exports.PARCEL_STATUSES = {
    ready: "READY",
    accepted: "ACCEPTED",
    deliveryReady: "DELIVERY-READY",
    collectedByDriver: "COLLECTED-BY-DRIVER",
    inTransit: "IN-TRANSIT",
    arrived: "ARRIVED",
    delivered: "DELIVERED",
    abandoned: "ABANDONED",
};
exports.WAYBILL_TABLE_COLUMNS = [
    {
        displayKey: "waybill",
        label: "Waybill",
    },
    {
        displayKey: "createdAt",
        label: "Date",
        formatter: function (waybill) { return doshx_controls_web_1.Utilities.formatDateTime(waybill === null || waybill === void 0 ? void 0 : waybill.createdAt); },
    },
    {
        displayKey: "parcel_length",
        label: "Parcels",
    },
    /*
    {
      displayKey: "status",
      label: "Status",
    }, */
];
