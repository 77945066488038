"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RichMarkdownEditor = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var editor_1 = require("@mdxeditor/editor");
require("@mdxeditor/editor/style.css");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var RichMarkdownEditor = function (_a) {
    var markdown = _a.markdown, onChange = _a.onChange, className = _a.className, colours = _a.colours, errorText = _a.errorText;
    var ref = react_1.default.useRef(null);
    var _b = (0, react_1.useState)(Boolean(markdown)), isPopulated = _b[0], setIsPopulated = _b[1];
    var _c = (0, react_1.useState)(markdown !== null && markdown !== void 0 ? markdown : ""), value = _c[0], setValue = _c[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (!isPopulated && markdown) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setMarkdown(markdown);
        }
    }, [markdown]);
    var FallbackToolbar = function () { return (0, jsx_runtime_1.jsx)("span", __assign({ className: "w-full h-full" }, { children: "Loading toolbar..." })); };
    var borderColour = errorText ? "border-red-600" : (colours === null || colours === void 0 ? void 0 : colours.border) || "border-black-500";
    var errorColour = (colours === null || colours === void 0 ? void 0 : colours.error) || "text-red-500";
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex border rounded-md ".concat(borderColour, " ").concat(className) }, { children: (0, jsx_runtime_1.jsx)(editor_1.MDXEditor, { ref: ref, markdown: value, plugins: [
                        (0, editor_1.listsPlugin)(),
                        (0, editor_1.quotePlugin)(),
                        (0, editor_1.headingsPlugin)(),
                        (0, editor_1.toolbarPlugin)({
                            toolbarContents: function () { return ((0, jsx_runtime_1.jsx)(react_1.Suspense, __assign({ fallback: (0, jsx_runtime_1.jsx)(FallbackToolbar, {}) }, { children: (0, jsx_runtime_1.jsxs)(editor_1.DiffSourceToggleWrapper, { children: [(0, jsx_runtime_1.jsx)(editor_1.UndoRedo, {}), (0, jsx_runtime_1.jsx)(editor_1.Separator, {}), (0, jsx_runtime_1.jsx)(editor_1.BlockTypeSelect, {}), (0, jsx_runtime_1.jsx)(editor_1.Separator, {}), (0, jsx_runtime_1.jsx)(editor_1.BoldItalicUnderlineToggles, {}), (0, jsx_runtime_1.jsx)(editor_1.Separator, {})] }) }))); },
                        }),
                        (0, editor_1.markdownShortcutPlugin)(),
                        (0, editor_1.diffSourcePlugin)({
                            diffMarkdown: "Previous",
                            viewMode: "rich-text",
                        }),
                    ], contentEditableClassName: "prose", onChange: function (value) {
                        if (isPopulated || !markdown) {
                            onChange(value);
                        }
                        setIsPopulated(true);
                        setValue(value);
                    }, className: "overflow-auto grow" }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: errorText, size: "xsmall", textColor: errorColour, className: "text-ellipsis overflow-hidden hover:overflow-scroll px-2" })] }));
};
exports.RichMarkdownEditor = RichMarkdownEditor;
