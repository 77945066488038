"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setFieldErrors = exports.updateField = exports.removeField = exports.addField = exports.setFields = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    fields: [
        {
            localField: "",
            targetField: "",
            targetType: "String",
            defaultValue: "",
            nestedFields: [],
            errorText: {},
        },
    ],
};
var slice = (0, toolkit_1.createSlice)({
    name: "dataMapState",
    initialState: initialState,
    reducers: {
        setFields: function (state, action) {
            state.fields = action.payload;
        },
        addField: function (state, action) {
            var _a = action.payload, field = _a.field, path = _a.path;
            var updatedFields = state.fields;
            if (!field) {
                field = {
                    localField: "",
                    targetField: "",
                    targetType: "String",
                    defaultValue: "",
                    nestedFields: [],
                };
            }
            if (!path) {
                updatedFields.push(field);
                return;
            }
            var pathArray = path.split(".");
            var currentField = updatedFields;
            for (var i = 0; i < pathArray.length; i++) {
                var index = parseInt(pathArray[i]);
                if (Number.isNaN(index)) {
                    continue;
                }
                if (i === pathArray.length - 1) {
                    if (currentField[index].targetType !== "Array" && currentField[index].targetType !== "Object") {
                        currentField[index].targetType = "Array";
                    }
                    currentField[index].nestedFields.push(field);
                    return;
                }
                currentField = currentField[index].nestedFields;
            }
            currentField.push(field);
        },
        removeField: function (state, action) {
            var path = action.payload;
            if (!path) {
                return;
            }
            var pathArray = path.split(".");
            var currentField = state.fields;
            for (var i = 0; i < pathArray.length; i++) {
                var index = parseInt(pathArray[i]);
                if (Number.isNaN(index)) {
                    continue;
                }
                if (i === pathArray.length - 1) {
                    currentField.splice(index, 1);
                    return;
                }
                currentField = currentField[index].nestedFields;
            }
        },
        updateField: function (state, action) {
            var _a;
            var updatedFields = state.fields;
            var _b = action.payload, field = _b.field, path = _b.path;
            if (!path) {
                return;
            }
            if ((field.targetType === "Array" || field.targetType === "Object") && !((_a = field.nestedFields) === null || _a === void 0 ? void 0 : _a.length)) {
                field.nestedFields = [
                    {
                        localField: "",
                        targetField: "",
                        targetType: "String",
                        defaultValue: "",
                        nestedFields: [],
                    },
                ];
            }
            else if (field.targetType !== "Array" && field.targetType !== "Object") {
                field.nestedFields = [];
            }
            var pathArray = path.split(".");
            var currentField = updatedFields;
            for (var i = 0; i < pathArray.length; i++) {
                var index = parseInt(pathArray[i]);
                if (Number.isNaN(index)) {
                    continue;
                }
                if (i === pathArray.length - 1) {
                    currentField[index] = field;
                    return;
                }
                currentField = currentField[index].nestedFields;
            }
        },
        setFieldErrors: function (state, action) {
            var updatedFields = state.fields;
            var _a = action.payload, errors = _a.errors, path = _a.path;
            if (!path) {
                return;
            }
            var pathArray = path.split(".");
            var currentField = updatedFields;
            for (var i = 0; i < pathArray.length; i++) {
                var index = parseInt(pathArray[i]);
                if (Number.isNaN(index)) {
                    continue;
                }
                if (i === pathArray.length - 1) {
                    currentField[index].errorText = errors;
                    return;
                }
                currentField = currentField[index].nestedFields;
            }
        }
    },
});
exports.setFields = (_a = slice.actions, _a.setFields), exports.addField = _a.addField, exports.removeField = _a.removeField, exports.updateField = _a.updateField, exports.setFieldErrors = _a.setFieldErrors;
exports.default = slice.reducer;
