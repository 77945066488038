"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedemptionsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var RedemptionsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Redemptions"));
    }, []);
    return (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: "Redemptions" }));
};
exports.RedemptionsPage = RedemptionsPage;
