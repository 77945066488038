"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerSegmentDetails = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var react_1 = require("react");
var console_1 = require("../../../../../reducers/slices/console");
var add_customer_segment_thunk_1 = require("../../../../../reducers/slices/customer_segments/thunks/add_customer_segment_thunk");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_2 = require("@nextui-org/react");
var constants_1 = require("./constants");
var customers_1 = require("../../../../../reducers/slices/customers/customers");
var all_client_users_thunk_1 = require("../../../../../reducers/slices/customers/thunks/all_client_users_thunk");
var main_1 = require("../../../../../reducers/slices/main");
var get_customer_segment_1 = require("../../../../../api/calls/customer-segments/get/get_customer_segment");
var update_customer_segment_1 = require("../../../../../api/calls/customer-segments/update/update_customer_segment");
var get_client_customer_segments_thunk_1 = require("../../../../../reducers/slices/customer_segments/thunks/get_client_customer_segments_thunk");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../../constants/pages");
var CustomerSegmentDetails = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var segment = props.segment, isEditing = props.isEditing;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.customerState; }), customers = _a.customers, customersPagination = _a.pagination;
    var customerSegmentPagination = (0, react_redux_1.useSelector)(function (state) { return state.customerSegmentState; }).pagination;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _b = (0, react_1.useState)(null), selectedDiscountType = _b[0], setSelectedDiscountType = _b[1];
    var _c = (0, react_1.useState)(null), selectedRule = _c[0], setSelectedRule = _c[1];
    var _d = (0, react_1.useState)(1), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = (0, react_1.useState)(10), rowsPerPage = _e[0], setRowsPerPage = _e[1];
    var _f = (0, useSearchDebounce_1.useSearchDebounce)(), searchCustomers = _f[0], setSearchCustomers = _f[1];
    var _g = (0, react_1.useState)([]), selectedCustomers = _g[0], setSelectedCustomers = _g[1];
    var selectedCustomersIDs = (0, react_1.useMemo)(function () { return selectedCustomers.map(function (customer) { return customer._id; }); }, [selectedCustomers]);
    var handleSelectedDiscountType = function (discountType) {
        updateValues("discount_type", discountType.value);
        setSelectedDiscountType(discountType.label);
    };
    var handleSelectRule = function (rule) {
        updateValues("segment_rule", rule.value);
        setSelectedRule(rule.label);
    };
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, all_client_users_thunk_1.all_client_users_thunk)(__assign({}, customersPagination)));
        }
    }, [client_token, isEditing]);
    var _h = (0, react_1.useState)({
        title: null,
        description: null,
        discount_type: false,
        discount: null,
        customers: [],
        apply_to_products_on_promotion: false,
        segment_rule: null,
    }), values = _h[0], setValues = _h[1];
    var updateValues = function (prop, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = value, _a)));
    };
    (0, react_1.useEffect)(function () {
        if (client_token && isEditing) {
            getSelectedCustomerSegment();
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        if (isEditing) {
            setSelectedDiscountType(values === null || values === void 0 ? void 0 : values.discount_type);
        }
    }, [values === null || values === void 0 ? void 0 : values.discount_type]);
    (0, react_1.useEffect)(function () {
        if (isEditing) {
            setSelectedRule(values === null || values === void 0 ? void 0 : values.segment_rule);
        }
    }, [values === null || values === void 0 ? void 0 : values.segment_rule]);
    (0, react_1.useEffect)(function () {
        if (customers && isEditing) {
            var selectedCustomers_1 = segment === null || segment === void 0 ? void 0 : segment.customers.map(function (customer) { return customer._id; });
            var selected = customers.filter(function (customer) { return selectedCustomers_1.includes(customer._id); });
            updateValues("customers", selected);
        }
    }, [(customers === null || customers === void 0 ? void 0 : customers.length) > 0]);
    (0, react_1.useEffect)(function () {
        return function () {
            // This cleanup function will run when the component unmounts
            if (window.ResizeObserver) {
                // Disconnect any ResizeObserver instances if needed here
                // This prevents ResizeObserver notifications on unmounted components
            }
        };
    }, []);
    (0, react_1.useEffect)(function () {
        onSearch(searchCustomers);
    }, [searchCustomers]);
    var getSelectedCustomerSegment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, content;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, get_customer_segment_1.get_customer_segment)({ _id: segment === null || segment === void 0 ? void 0 : segment._id })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        content = response.content;
                        setSelectedCustomers(content.customers);
                        setValues({
                            title: content.title,
                            description: content.description,
                            discount_type: content.discount_type,
                            discount: content.discount,
                            customers: content.customers,
                            apply_to_products_on_promotion: content.apply_to_products_on_promotion,
                            segment_rule: content.segment_rule,
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var getCustomers = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, all_client_users_thunk_1.all_client_users_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getCustomers(__assign(__assign({}, customersPagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (searchCustomers === "") {
            setRowsPerPage(rowsPerPage);
            return getCustomers(__assign(__assign({}, customersPagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getCustomers(__assign(__assign({}, customersPagination), { page: page, search_string: searchCustomers }));
        }
    };
    var enableSaveButton = !values.title || !values.discount_type || !values.discount || !values.segment_rule || selectedCustomers.length === 0;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2 lg:gap-2 lg:h-full w-[600px] overflow-y-auto max-h-[600px] md:h-auto sm:h-auto" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col justify-center gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-2 bg-white" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Input, { label: "Title", value: values.title, onChange: function (e) {
                                            updateValues("title", e.target.value);
                                        }, size: "sm" }), (0, jsx_runtime_1.jsx)(react_2.Input, { label: "Description", value: values.description, onChange: function (e) {
                                            updateValues("description", e.target.value);
                                        }, size: "sm" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-2 bg-white items-center" }, { children: [(0, jsx_runtime_1.jsxs)(react_2.Dropdown, __assign({ size: "lg" }, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "flat" }, { children: selectedDiscountType ? "".concat(selectedDiscountType) : "Select Discount Type" })) }), (0, jsx_runtime_1.jsx)(react_2.DropdownMenu, __assign({ "aria-label": "Dynamic Actions", items: constants_1.DISCOUNT_TYPE, onAction: function (key) {
                                                    var selected = constants_1.DISCOUNT_TYPE.find(function (item) { return item.label === key; });
                                                    handleSelectedDiscountType(selected);
                                                } }, { children: function (item) { return (0, jsx_runtime_1.jsx)(react_2.DropdownItem, { children: item.label }, item.label); } }))] })), (0, jsx_runtime_1.jsx)(react_2.Input, { type: "number", label: "Discount", value: values.discount, onChange: function (e) {
                                            updateValues("discount", e.target.value);
                                        }, size: "sm" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-2 bg-white" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "gap-2 w-[280px] bg-white grid" }, { children: (0, jsx_runtime_1.jsxs)(react_2.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ className: "text-wrap h-auto" }, { children: selectedCustomers.length === 0
                                                            ? "Select Customers"
                                                            : selectedCustomers.length > 4
                                                                ? "".concat(selectedCustomers.length, " Customers Selected")
                                                                : selectedCustomers.map(function (customer) { return "".concat(customer.name, " ").concat(customer.surname); }).join(", ") })) }), (0, jsx_runtime_1.jsxs)(react_2.DropdownMenu, __assign({ selectedKeys: selectedCustomersIDs, closeOnSelect: false, onAction: function (key) {
                                                        var selectedCustomer = customers.find(function (customer) { return customer._id === key; });
                                                        if (selectedCustomers.find(function (customer) { return customer._id === (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer._id); })) {
                                                            setSelectedCustomers(selectedCustomers.filter(function (customer) { return customer._id !== (selectedCustomer === null || selectedCustomer === void 0 ? void 0 : selectedCustomer._id); }));
                                                        }
                                                        else {
                                                            setSelectedCustomers(__spreadArray(__spreadArray([], selectedCustomers, true), [
                                                                {
                                                                    _id: selectedCustomer._id,
                                                                    name: selectedCustomer.name,
                                                                    surname: selectedCustomer.surname,
                                                                },
                                                            ], false));
                                                        }
                                                    }, selectionMode: "multiple" }, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownSection, __assign({ hideSelectedIcon: true }, { children: (0, jsx_runtime_1.jsx)(react_2.DropdownItem, __assign({ isReadOnly: true, textValue: "SelectedItem" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { type: "text", labelPlacement: "outside", placeholder: "Search", onChange: function (event) {
                                                                        var search_string = event.target.value;
                                                                        dispatch((0, customers_1.setTablePagination)(pages_1.initialPaginationValues));
                                                                        setSearchCustomers(search_string);
                                                                    } }) }), "Search") })), (0, jsx_runtime_1.jsx)(react_2.DropdownSection, __assign({ items: customers }, { children: function (item) { return ((0, jsx_runtime_1.jsxs)(react_2.DropdownItem, __assign({ textValue: "Customer" }, { children: [item.name, " ", item.surname] }), item._id)); } })), (0, jsx_runtime_1.jsx)(react_2.DropdownSection, __assign({ hideSelectedIcon: true }, { children: (0, jsx_runtime_1.jsx)(react_2.DropdownItem, __assign({ isReadOnly: true, textValue: "Pagination" }, { children: (0, jsx_runtime_1.jsx)(react_2.Pagination, { total: customersPagination === null || customersPagination === void 0 ? void 0 : customersPagination.countTotal, initialPage: currentPage, onChange: function (page) { return onPageChange(page); } }) }), "move") }))] }))] }) })), (0, jsx_runtime_1.jsxs)(react_2.Dropdown, __assign({ size: "lg" }, { children: [(0, jsx_runtime_1.jsx)(react_2.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "flat" }, { children: selectedRule ? "".concat(selectedRule) : "Select Segment Rule" })) }), (0, jsx_runtime_1.jsx)(react_2.DropdownMenu, __assign({ "aria-label": "Dynamic Actions", items: constants_1.SEGMENT_RULE, onAction: function (key) {
                                                    var selected = constants_1.SEGMENT_RULE.find(function (item) { return item.label === key; });
                                                    handleSelectRule(selected);
                                                } }, { children: function (item) { return (0, jsx_runtime_1.jsx)(react_2.DropdownItem, { children: item.label }, item.label); } }))] }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-2 gap-2 bg-white" }, { children: (0, jsx_runtime_1.jsx)(react_2.Checkbox, __assign({ value: String(values.apply_to_products_on_promotion), onChange: function (event) {
                                        updateValues("apply_to_products_on_promotion", event.target.checked);
                                    } }, { children: "Apply to products on promotion?" })) }))] })) })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center items-center w-full" }, { children: (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isDisabled: enableSaveButton, color: "primary", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var payload, response, simplifiedCustomers, request, response_1;
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    if (!!isEditing) return [3 /*break*/, 2];
                                    payload = __assign(__assign({}, values), { customers: selectedCustomers });
                                    return [4 /*yield*/, dispatch((0, add_customer_segment_thunk_1.add_customer_segment_thunk)(payload))];
                                case 1:
                                    response = _b.sent();
                                    if ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.success) {
                                        setTimeout(function () {
                                            dispatch((0, main_1.setMessageModal)(null));
                                            dispatch((0, console_1.setPageTitle)("Customer Segments"));
                                            dispatch((0, main_1.setContentModal)(null));
                                            dispatch((0, get_client_customer_segments_thunk_1.get_customer_segments_thunk)(__assign({}, customerSegmentPagination)));
                                        }, 1500);
                                    }
                                    return [3 /*break*/, 4];
                                case 2:
                                    simplifiedCustomers = values === null || values === void 0 ? void 0 : values.customers.map(function (customer) { return ({
                                        _id: customer._id,
                                        name: customer.name,
                                        surname: customer.surname,
                                    }); });
                                    request = {
                                        _id: segment === null || segment === void 0 ? void 0 : segment._id,
                                        customer_segment: {
                                            title: values.title,
                                            description: values.description,
                                            discount_type: values.discount_type,
                                            discount: values.discount,
                                            customers: simplifiedCustomers,
                                            apply_to_products_on_promotion: values.apply_to_products_on_promotion,
                                            segment_rule: values.segment_rule,
                                        },
                                    };
                                    return [4 /*yield*/, (0, update_customer_segment_1.update_customer_segment)(request)];
                                case 3:
                                    response_1 = _b.sent();
                                    if (response_1.success) {
                                        setTimeout(function () {
                                            dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response_1.message] }));
                                            dispatch((0, main_1.setContentModal)(null));
                                            dispatch((0, get_client_customer_segments_thunk_1.get_customer_segments_thunk)(__assign({}, customerSegmentPagination)));
                                        }, 1500);
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when updating customer segment!", messages: [response_1.message] }));
                                    }
                                    _b.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); } }, { children: isEditing ? "Update Segment" : "Save Segment" })) }))] })));
};
exports.CustomerSegmentDetails = CustomerSegmentDetails;
