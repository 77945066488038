"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var OfflineDriverComponent = function () {
    var _a;
    var drivers = (0, react_redux_1.useSelector)(function (state) { return state.driverState; }).drivers;
    (0, react_1.useEffect)(function () { }, []);
    var offlineDrivers = drivers.filter(function (x) { return x.status === "OFFLINE" || x.status === "INACTIVE"; });
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)("hr", { className: "mt-4" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Total", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat((_a = offlineDrivers === null || offlineDrivers === void 0 ? void 0 : offlineDrivers.length) !== null && _a !== void 0 ? _a : 0) })] })), (0, jsx_runtime_1.jsx)("hr", {}), offlineDrivers.map(function (x) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Driver:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat(x.name, " ").concat(x.surname) })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Mobile:", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: x.cell_number })] }))] })) })), (0, jsx_runtime_1.jsx)("hr", {})] }), x._id)); })] })));
};
exports.default = OfflineDriverComponent;
