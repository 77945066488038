"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setModalLoading = exports.unPairDrivers = exports.pairDrivers = exports.removeFilterStatusDrivers = exports.setFilterStatusDrivers = exports.setDriverTablePagination = exports.updateDriverStatus = exports.setLoading = exports.deleteDrivers = exports.editDriver = exports.updateDrivers = exports.setDrivers = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    drivers: [],
    modalLoading: false,
    loading: false,
    driverPagination: pages_1.initialPaginationValues,
    filterStatuses: [],
};
var slice = (0, toolkit_1.createSlice)({
    name: "driver",
    initialState: initialState,
    reducers: {
        setDrivers: function (state, action) {
            state.drivers = action.payload;
        },
        updateDrivers: function (state, action) {
            state.drivers.push(action.payload);
            // state.loading = false;
        },
        editDriver: function (state, action) {
            state.drivers = state.drivers.map(function (x) {
                if (x._id === action.payload._id) {
                    return __assign(__assign({}, x), action.payload);
                }
                return x;
            });
        },
        deleteDrivers: function (state, action) {
            state.drivers.filter(function (x) { return x._id !== action.payload; });
        },
        pairDrivers: function (state, action) {
            state.drivers = state.drivers.map(function (x) {
                if (x._id === action.payload.user_id) {
                    x.pairing.push(action.payload);
                }
                return x;
            });
        },
        unPairDrivers: function (state, action) {
            state.drivers = state.drivers.map(function (x) {
                if (x._id === action.payload) {
                    return __assign(__assign({}, x), { pairing: [] });
                }
                return x;
            });
        },
        updateDriverStatus: function (state, action) {
            state.drivers = state.drivers.map(function (x) {
                if (x._id === action.payload._id) {
                    return __assign(__assign({}, x), { status: action.payload.status });
                }
                return x;
            });
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setModalLoading: function (state, action) {
            state.modalLoading = action.payload;
        },
        setDriverTablePagination: function (state, action) {
            state.driverPagination = action.payload;
        },
        setFilterStatusDrivers: function (state, action) {
            state.filterStatuses.push(action.payload);
        },
        removeFilterStatusDrivers: function (state, action) {
            state.filterStatuses.splice(state.filterStatuses.indexOf(action.payload), 1);
        },
    },
});
exports.setDrivers = (_a = slice.actions, _a.setDrivers), exports.updateDrivers = _a.updateDrivers, exports.editDriver = _a.editDriver, exports.deleteDrivers = _a.deleteDrivers, exports.setLoading = _a.setLoading, exports.updateDriverStatus = _a.updateDriverStatus, exports.setDriverTablePagination = _a.setDriverTablePagination, exports.setFilterStatusDrivers = _a.setFilterStatusDrivers, exports.removeFilterStatusDrivers = _a.removeFilterStatusDrivers, exports.pairDrivers = _a.pairDrivers, exports.unPairDrivers = _a.unPairDrivers, exports.setModalLoading = _a.setModalLoading;
exports.default = slice.reducer;
