"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementHistoryPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../../constants/pages");
var console_1 = require("../../../../../reducers/slices/console");
var routes_1 = require("../../../../../constants/routes");
var get_client_procurements_thunk_1 = require("../../../../../reducers/slices/products/thunks/get_client_procurements_thunk");
var ProcurementHistoryPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), clientProcurements = _a.clientProcurements, clientProcurementsPagination = _a.clientProcurementsPagination, loading = _a.loading;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _b = (0, useSearchDebounce_1.useSearchDebounce)(), search = _b[0], setSearch = _b[1];
    var _c = (0, react_1.useState)(null), filterType = _c[0], setFilterType = _c[1];
    var _d = (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Procurement History"));
        console.log("user ===>", user);
    }, []);
    var _e = (0, react_1.useEffect)(function () {
        getClientProcurements({});
        console.log("clientProcurements ==>", clientProcurements);
    }, []);
    var _f = (0, react_1.useEffect)(function () {
        console.log("clientProcurements ==>", clientProcurements);
    }, [clientProcurements]);
    var getClientProcurements = function (filter) {
        var _clientProcurementsPagination = __assign({}, clientProcurementsPagination);
        delete _clientProcurementsPagination.countTotal;
        dispatch((0, get_client_procurements_thunk_1.get_client_procurements_thunk)({
            payload: __assign(__assign({}, _clientProcurementsPagination), filter),
        }));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-end" }, { children: [(0, jsx_runtime_1.jsx)("div", {}), (0, jsx_runtime_1.jsx)("div", { className: "flex gap-2" })] })), loading ? ((0, jsx_runtime_1.jsx)("div", __assign({ className: "grid justify-center p-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "large" }) }))) : ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "branch.display_name",
                        label: "Branch Name",
                    },
                    {
                        displayKey: "status",
                        label: "Status",
                    },
                    {
                        displayKey: "createdAt",
                        label: "Created At",
                        formatter: function (basket) {
                            if (basket) {
                                return doshx_controls_web_1.Utilities.formatDateTime(basket === null || basket === void 0 ? void 0 : basket.createdAt, "YYYY-MM-DD");
                            }
                            else {
                                return "";
                            }
                        },
                    },
                ], onRowClicked: function (data) {
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PROCUREMENT_ROUTE).concat(routes_1.PROCUREMENT_PRODUCTS_ROUTE, "/").concat(data._id));
                }, data: clientProcurements, pagination: {
                    currentPage: clientProcurementsPagination.page,
                    rowsPerPage: clientProcurementsPagination.size,
                    totalRecords: clientProcurementsPagination.countTotal,
                    onPageChange: function (page) {
                        getClientProcurements({ page: page });
                    },
                    onRowsPerPageChange: function (size) {
                        getClientProcurements({ size: size });
                    },
                }, colors: pages_1.tableColorConfig }))] })));
};
exports.ProcurementHistoryPage = ProcurementHistoryPage;
