"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfilePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var sectioner_1 = require("../../../../controls/sectioner");
var pages_1 = require("../../../../constants/pages");
var ProfilePage = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    // const { isSideHeaderControlOpen } = props;
    var _a = (0, react_1.useState)(null), selectedPaymentMethod = _a[0], setSelectedPaymentMethod = _a[1];
    var _b = (0, react_1.useState)([
        { title: "Credit Card", type: "visa", maskedNumber: "**** **** **** 2345", selected: false },
        { title: "Cheque Card", type: "mastercard", maskedNumber: "**** **** **** 0987", selected: true },
    ]), paymentMethods = _b[0], setPaymentMethods = _b[1];
    var _c = (0, react_1.useState)(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = (0, react_1.useState)(10), rowsPerPage = _d[0], setRowsPerPage = _d[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Profile"));
    }, []);
    // may come from api call
    var userData = (0, react_1.useState)([
        {
            name: "Letstrade0008",
            label: "Username",
        },
        {
            name: "Bruno Silva",
            label: "Name",
        },
        {
            name: "BrunoSilva@Letstrade.com",
            label: "Email",
        },
    ])[0];
    // may come from api call
    var billingInfo = (0, react_1.useState)([
        {
            type: "Username",
            value: "Bruno Silva",
        },
        {
            type: "Company",
            value: "Let’sTrade",
        },
        {
            type: "Street",
            value: "100 Airdlin Rd, Sunninghill",
        },
        {
            type: "Surburb",
            value: "Johannesburg 2021",
        },
        {
            type: "Country",
            value: "South Africa",
        },
    ])[0];
    // may come from api call
    var taxInvoiceData = (0, react_1.useState)({
        columns: [
            {
                displayKey: "date",
                label: "Date",
            },
            {
                displayKey: "tax",
                label: "Tax Invoices",
            },
            {
                displayKey: "amount",
                label: "Total Amount",
            },
        ],
        content: [
            {
                _id: "1",
                date: "Jan 1, 2023",
                tax: 100765473,
                amount: "$150.00",
            },
            {
                _id: "2",
                date: "Jan 1, 2023",
                tax: 100765473,
                amount: "$150.00",
            },
        ],
    })[0];
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row justify-center lg:justify-start" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { textColor: "text-black", bold: true, size: "2xlarge", label: "Account Details", center: true }) })), (0, jsx_runtime_1.jsx)("div", { className: "h-8" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-1 \n                   lg:grid-cols-2 gap-4 items-start justify-start" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(sectioner_1.SectionerComponent, { buttonText: "Update Account Details", onButtonClick: function () {
                                    //
                                }, sections: [
                                    (0, jsx_runtime_1.jsx)("div", { children: userData.map(function (user, index) {
                                            return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { disabled: true, label: user.label, labelColor: "text-labelText", borderColor: "border-transparent", value: user.name }, index));
                                        }) }),
                                ] }), (0, jsx_runtime_1.jsx)(sectioner_1.SectionerComponent, { title: "Current Plan", buttonText: "Update Account Details", onButtonClick: function () {
                                    //
                                }, sections: [
                                    (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Plan Title", size: "small", textColor: "text-inputText", bold: true }) }),
                                    (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Plan details,", size: "small", textColor: "text-disabled" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "10 January 2023 (Monthly)", size: "small", textColor: "text-disabled" })] }),
                                    (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "What You'll be charged", size: "small", textColor: "text-disabled" }) }),
                                ] })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(sectioner_1.SectionerComponent, { title: "Upgrade your Let\u2019sTrade Subscription", buttonText: "Switch Plan", onButtonClick: function () {
                                    //
                                }, sections: [
                                    (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "We will adjust the amount we charge you according to the remaining days on your current subscriptions", size: "small", textColor: "text-inputText" }) }),
                                ] }), (0, jsx_runtime_1.jsx)(sectioner_1.SectionerComponent, { title: "Payment Method", buttonText: "Add payment Method", onButtonClick: function () {
                                    //
                                }, sections: [
                                    (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.PaymentMethodControl
                                            // title="Payment Method"
                                            // titleColor="text-inputText"
                                            , { 
                                                // title="Payment Method"
                                                // titleColor="text-inputText"
                                                options: paymentMethods, selectedValue: selectedPaymentMethod, onSelectedValueChanged: function (v) {
                                                    setSelectedPaymentMethod(v);
                                                } }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Update Payment method", size: "small", borderColor: "border-black", backgroundColor: "bg-transparent", textColor: "text-disabled" }) }))] })),
                                    (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Billing Information", size: "small", textColor: "text-inputText", bold: true }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col" }, { children: billingInfo.map(function (info, index) {
                                                    return (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: info.value, textColor: "text-inputText", size: "small" }, index);
                                                }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Update Billing information", size: "small", borderColor: "border-black", backgroundColor: "bg-transparent", textColor: "text-disabled" }) }))] })),
                                    (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Allow direct payments for clients or branches" }) }),
                                ] })] }))] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { className: "underline pt-4", textColor: "text-underlineColor", bold: true, label: "Cancel Subscription" }), (0, jsx_runtime_1.jsx)("div", { className: "h-8" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { title: "Tax Invoices", 
                // currentPage={currentPage}
                // rowsPerPage={rowsPerPage}
                // totalRecords={1000}
                columns: taxInvoiceData.columns, data: taxInvoiceData.content, rowActions: [
                    {
                        label: "Edit",
                        onClick: function (o) {
                            console.log("Edit", o);
                        },
                    },
                    {
                        label: "Delete",
                        onClick: function (data) {
                            console.log("Delete", data);
                        },
                    },
                ], onSearch: function (searchText) {
                    console.log("Search", searchText);
                }, 
                //
                hasShadow: true, pagination: {
                    currentPage: currentPage,
                    rowsPerPage: rowsPerPage,
                    totalRecords: 1000,
                    onPageChange: function (page) {
                        setCurrentPage(page);
                    },
                    onRowsPerPageChange: function (rows) {
                        setRowsPerPage(rows);
                    },
                }, colors: pages_1.tableColorConfig }), (0, jsx_runtime_1.jsx)("div", { className: "h-8" })] })));
};
exports.ProfilePage = ProfilePage;
