"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stockReplacementQuantitiesColumns = exports.stockReplacementTableColumns = void 0;
exports.stockReplacementTableColumns = [
    {
        displayKey: "parcel_code",
        label: "Parcel",
        width: "col-span-1",
    },
    {
        displayKey: "status",
        label: "Status",
        width: "col-span-1",
    },
    {
        displayKey: "title",
        label: "Title",
        width: "col-span-2",
    },
    {
        displayKey: "sku",
        label: "SKU",
        width: "col-span-1",
    },
    {
        displayKey: "quantity_requested",
        label: "Quantity Requested",
        width: "col-span-1",
    },
    {
        displayKey: "quantity_replaced",
        label: "Quantity Replaced",
        width: "col-span-1",
    },
    {
        displayKey: "replacement_status",
        label: "Replacement Status",
        width: "col-span-1",
    },
];
exports.stockReplacementQuantitiesColumns = [
    {
        displayKey: "title",
        label: "Product",
        width: "md:col-span-1 lg:col-span-2",
    },
    {
        displayKey: "quantity",
        label: "Quantity",
        width: "col-span-1",
    },
    {
        displayKey: "quantity_to_replace",
        label: "Quantity to Replace",
        width: "col-span-1",
    }
];
