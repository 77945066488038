"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarDropdownIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
function AvatarDropdownIcon(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({}, props, { fill: "none", height: "20", viewBox: "0 0 20 20", width: "20", xmlns: "http://www.w3.org/2000/svg" }, { children: [(0, jsx_runtime_1.jsxs)("g", __assign({ "clip-path": "url(#clip0_3076_10614)" }, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M6.6665 7.50008L9.99984 4.16675L13.3332 7.50008", stroke: "#A1A1AA", "stroke-linecap": "round", "stroke-linejoin": "round" }), (0, jsx_runtime_1.jsx)("path", { d: "M13.3332 12.5L9.99984 15.8333L6.6665 12.5", stroke: "#A1A1AA", "stroke-linecap": "round", "stroke-linejoin": "round" })] })), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("clipPath", __assign({ id: "clip0_3076_10614" }, { children: (0, jsx_runtime_1.jsx)("rect", { fill: "white", height: "20", width: "20" }) })) })] })));
}
exports.AvatarDropdownIcon = AvatarDropdownIcon;
