"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var branch_list_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var pair_driver_thunk_1 = require("../../../../../reducers/slices/driver/thunks/pair_driver_thunk");
var unpair_driver_thunk_1 = require("../../../../../reducers/slices/driver/thunks/unpair_driver_thunk");
var get_heroconnect_devices_thunk_1 = require("../../../../../reducers/slices/heroconnect/thunks/get_heroconnect_devices_thunk");
var PairDriverComponent = function (props) {
    var _a;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.driverState; }), modalLoading = _b.modalLoading, drivers = _b.drivers;
    var branches = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).branches;
    var vehicles = (0, react_redux_1.useSelector)(function (state) { return state.heroconnectState; }).vehicles;
    var driver = props.driver;
    var _c = (0, react_1.useState)("Pair"), pairingState = _c[0], setPairingState = _c[1];
    var _d = (0, react_1.useState)({
        driver: {
            _id: "",
        },
        branch: {
            _id: "",
        },
        vehicle: { _id: "" },
        tenant_id: "",
        smart_box_rider: false,
    }), values = _d[0], setValues = _d[1];
    (0, react_1.useEffect)(function () {
        console.log("driver ===>", driver);
        if (driver) {
            if (driver.vehicle_id) {
                setPairingState("Unpair");
            }
            if (driver.tenant_id) {
                dispatch((0, get_heroconnect_devices_thunk_1.get_heroconnect_devices_thunk)(driver.tenant_id));
            }
            setValues(__assign(__assign({}, values), { driver: {
                    _id: driver._id,
                }, branch: {
                    _id: driver.branch_id,
                }, vehicle: { _id: driver.vehicle_id }, tenant_id: driver.tenant_id, smart_box_rider: driver.smart_box_rider }));
        }
    }, []);
    (0, react_1.useEffect)(function () {
        if ((branches === null || branches === void 0 ? void 0 : branches.length) === 0) {
            dispatch((0, branch_list_thunk_1.branch_list_thunk)(null));
        }
    }, [branches]);
    var updateValues = function (prop, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = value, _a)));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Branches", borderColor: "border-borders", required: true, options: branches.map(function (x) {
                    return __assign({ label: x.display_name, value: x._id }, x);
                }), iconColor: "text-primary", size: "small", value: (_a = values.branch) === null || _a === void 0 ? void 0 : _a._id, onChange: function (v) {
                    var _branch = branches.find(function (x) { return x._id === v; });
                    if (_branch) {
                        var tenantId = _branch.extra_data.find(function (x) { return x.name === "tenant_id"; });
                        if (tenantId) {
                            console.log("tenantId.value ===>", tenantId.value);
                            updateValues("tenant_id", tenantId.value);
                            dispatch((0, get_heroconnect_devices_thunk_1.get_heroconnect_devices_thunk)(tenantId.value));
                        }
                        else {
                            updateValues("tenant_id", "");
                        }
                        updateValues("branch", {
                            _id: v,
                        });
                    }
                }, errorText: "" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { label: "Select * (Link driver to a vehicle)", colors: {
                    iconColor: "text-primary",
                    borderColor: "border-borders",
                }, required: true, options: vehicles.filter(function (x) {
                    var pairedDrivers = drivers.filter(function (d) { return d.pairing.length > 0; }).map(function (d) { return d.pairing[0].vehicle_registration; });
                    if (pairedDrivers && pairedDrivers.length > 0) {
                        if (pairedDrivers.includes(driver === null || driver === void 0 ? void 0 : driver.vehicle_registration) || !pairedDrivers.includes(x.givenName)) {
                            return x;
                        }
                        else {
                            return false;
                        }
                    }
                    return x;
                }), renderDisplay: function (v) {
                    return "".concat(v.givenName);
                }, size: "small", value: values.vehicle, onChange: function (v) {
                    updateValues("vehicle", v);
                }, errorText: "" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Smartbox rider?", value: values === null || values === void 0 ? void 0 : values.smart_box_rider, onChange: function (v) {
                    updateValues("smart_box_rider", v);
                } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { loading: modalLoading, label: pairingState, size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                    if (pairingState === "Pair") {
                        delete values.tenant_id;
                        dispatch((0, pair_driver_thunk_1.pair_driver_thunk)(values));
                    }
                    else {
                        dispatch((0, unpair_driver_thunk_1.unpair_driver_thunk)({
                            driver: {
                                _id: values.driver._id,
                            },
                            vehicle: null,
                        }));
                    }
                    setPairingState("Pair");
                    setValues({
                        driver: {
                            _id: "",
                        },
                        branch: {
                            _id: "",
                        },
                        vehicle: { _id: "" },
                        tenant_id: "",
                        smart_box_rider: false,
                    });
                    // dispatch(driver_sign_up_thunk(values));
                    console.log("values ==>", values);
                } })] })));
};
exports.default = PairDriverComponent;
