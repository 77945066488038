"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../reducers/slices/main");
var connected_channels_select_modal_1 = require("./connected_channels_select_modal");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var ConnectedChannelsBoxComponent = function (props) {
    var logo = props.logo, channel = props.channel, channels = props.channels, updateValues = props.updateValues;
    var dispatch = (0, react_redux_1.useDispatch)();
    console.log("channels", channels);
    var addProductToChannelModal = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Add Product to Channel",
            content: ((0, jsx_runtime_1.jsx)(connected_channels_select_modal_1.ConnectedChannelsSelectModal, { channel_type: channel, connectedChannels: channels, onConfirm: function (selected_channels) {
                    updateValues("channels", __spreadArray(__spreadArray([], channels, true), selected_channels, true));
                } })),
        }));
    };
    var removeProductFromChannelModal = function (channel_id) {
        dispatch((0, main_1.setContentModal)({
            title: "Remove channel link from product",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-6 justify-between text-center items-center max-w-md" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Removing the channel link from this product will only remove the link to the channel and not the product from the selected channel.", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-6 justify-center items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", onClick: function () { return (0, main_1.setContentModal)(null); }, borderColor: "border-primary", backgroundColor: "bg-transparent", textColor: "text-primary" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Confirm", onClick: function () {
                                        updateValues("channels", channels.filter(function (channel) { return channel.channel_id !== channel_id; }));
                                    }, backgroundColor: "bg-primary", textColor: "text-white" }) }))] }))] }))),
        }));
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "flex-1 flex-col gap-2 min-w-20 max-w-xs shadow-sm overflow-x-auto" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between items-center" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-2 items-center" }, { children: [(0, jsx_runtime_1.jsx)("img", { src: logo, className: "w-8 pb-2" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: channel.toLocaleUpperCase(), textColor: "text-black", size: "small" })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faPlus, onClick: addProductToChannelModal, backgroundColor: "bg-transparent", iconColor: "text-primary", borderColor: "border-transparent", size: "xsmall" })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "overflow-y-auto max-h-48" }, { children: channels === null || channels === void 0 ? void 0 : channels.map(function (channel) {
                    var _a;
                    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between hover:bg-gray-50 rounded-md p-1 items-center group" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_a = channel.name) !== null && _a !== void 0 ? _a : channel.channel_id, textColor: "text-black", size: "small" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "hidden group-hover:block" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faXmark, onClick: function () { return removeProductFromChannelModal(channel.channel_id); }, backgroundColor: "bg-transparent", iconColor: "text-primary bold", borderColor: "border-transparent", size: "xsmall" }) }))] })));
                }) }))] })));
};
exports.default = ConnectedChannelsBoxComponent;
