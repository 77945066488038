"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../constants/routes");
var suppliers_1 = require("./suppliers");
var procure_1 = require("./procure");
var supplier_details_1 = require("./supplier_details");
var procurement_history_1 = require("./components/procurement_history");
var procurement_products_1 = require("./components/procurement_products");
var ProcurementPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useEffect)(function () { }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full h-full" }, { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(procure_1.ProcurePage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.SUPPLIERS_ROUTE), element: (0, jsx_runtime_1.jsx)(suppliers_1.SupplierTable, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.SUPPLIERS_DETAILS_ROUTE, "/:client_id"), element: (0, jsx_runtime_1.jsx)(supplier_details_1.SupplierDetailsPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.PROCUREMENT_HISTORY_ROUTE), element: (0, jsx_runtime_1.jsx)(procurement_history_1.ProcurementHistoryPage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.PROCUREMENT_PRODUCTS_ROUTE, "/:procurement_id"), element: (0, jsx_runtime_1.jsx)(procurement_products_1.ProcurementProductsPage, {}) })] }) })));
};
exports.ProcurementPage = ProcurementPage;
