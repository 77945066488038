"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPaymentMethod = exports.setPaymentConfig = exports.setSelectedPaymentSettings = exports.setIsLoading = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var constant_1 = require("../../../screens/console/pages/configurations/paymentSettings/constant");
var initialState = {
    selectedPaymentSettings: constant_1.initialPaymentSettingsValues,
    isLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "paymentSettings",
    initialState: initialState,
    reducers: {
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setSelectedPaymentSettings: function (state, action) {
            state.selectedPaymentSettings = action.payload;
        },
        setPaymentMethod: function (state, action) {
            state.selectedPaymentSettings.payment_method = action.payload;
        },
        setPaymentConfig: function (state, action) {
            state.selectedPaymentSettings.payment_config = action.payload;
        },
    },
});
exports.setIsLoading = (_a = slice.actions, _a.setIsLoading), exports.setSelectedPaymentSettings = _a.setSelectedPaymentSettings, exports.setPaymentConfig = _a.setPaymentConfig, exports.setPaymentMethod = _a.setPaymentMethod;
exports.default = slice.reducer;
