"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productTableColumns = exports.condimentGroupProductTableColumns = exports.condimentsTableColumns = exports.feeTypes = exports.deliveryUnitOptions = void 0;
exports.deliveryUnitOptions = [
    { label: "Minute(s)", value: "minutes" },
    { label: "Hour(s)", value: "hours" },
    { label: "Day(s)", value: "days" },
    { label: "Week(s)", value: "weeks" },
    { label: "Month(s)", value: "months" },
    { label: "Year(s)", value: "years" },
];
exports.feeTypes = [
    { label: "Delivery", value: "delivery" },
    { label: "Handling", value: "handling" },
];
exports.condimentsTableColumns = [
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "required",
        label: "Required",
    },
    {
        displayKey: "multiple",
        label: "Multiple",
    },
    {
        displayKey: "minimum_quantity",
        label: "Min Quantity",
    },
    {
        displayKey: "maximum_quantity",
        label: "Max Quantity",
    },
];
exports.condimentGroupProductTableColumns = [
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "price",
        label: "Price",
    },
    {
        displayKey: "required",
        label: "Required",
    },
    {
        displayKey: "default",
        label: "Default",
    },
];
exports.productTableColumns = [
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "price",
        label: "Price",
    },
    {
        displayKey: "required",
        label: "Required",
    },
    {
        displayKey: "is_default",
        label: "Default",
    },
];
