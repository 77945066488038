"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_redux_1 = require("react-redux");
var constants_1 = require("../screens/console/pages/preferences/users/constants");
var usePermission = function (permissionKey) {
    var _a, _b, _c, _d, _e, _f;
    var _id = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user._id;
    var viewPermissions = {};
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var user = (_b = (_a = client === null || client === void 0 ? void 0 : client.users) === null || _a === void 0 ? void 0 : _a.find(function (user) { return (user === null || user === void 0 ? void 0 : user.user_id) === _id; })) !== null && _b !== void 0 ? _b : {};
    var user_permissions = user.user_permissions;
    if (!user_permissions) {
        user_permissions = (0, constants_1.initialPermissionsValues)();
    }
    if (!permissionKey)
        Object.keys(constants_1.featurePermissions).forEach(function (key) {
            var _a;
            viewPermissions[key] = (_a = user_permissions[key]) === null || _a === void 0 ? void 0 : _a.read;
        });
    var permission = permissionKey ? user_permissions[permissionKey] : null;
    return {
        canViewPermissions: viewPermissions,
        canView: (_c = permission === null || permission === void 0 ? void 0 : permission.read) !== null && _c !== void 0 ? _c : false,
        canUpdate: (_d = permission === null || permission === void 0 ? void 0 : permission.update) !== null && _d !== void 0 ? _d : false,
        canDelete: (_e = permission === null || permission === void 0 ? void 0 : permission.delete) !== null && _e !== void 0 ? _e : false,
        canCreate: (_f = permission === null || permission === void 0 ? void 0 : permission.create) !== null && _f !== void 0 ? _f : false,
    };
};
exports.default = usePermission;
