"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SectionerComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var SectionerComponent = function (props) {
    var title = props.title, sections = props.sections, buttonText = props.buttonText, onButtonClick = props.onButtonClick;
    (0, react_1.useEffect)(function () {
        //
    }, []);
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ variant: "outlined", backgroundColor: "bg-white", borderColor: "border-borders" }, { children: [title && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: title, textColor: "text-inputText", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-disabled" })] }))), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: sections === null || sections === void 0 ? void 0 : sections.map(function (section, index) {
                    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [section, sections.length - 1 != index && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-disabled" })] }), "section-".concat(index)));
                }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex pt-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: buttonText, size: "small", onClick: onButtonClick }) }))] })));
};
exports.SectionerComponent = SectionerComponent;
