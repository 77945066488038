"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSchemaOptions = exports.getSchemaKeys = exports.combineSchemas = exports.getFieldType = exports.getFlattenedSchema = exports.getSchemaAtPath = exports.fieldDataTypeOptions = void 0;
var models_1 = require("@letstrade/models");
exports.fieldDataTypeOptions = [
    { label: "String", value: "String" },
    { label: "Number", value: "Number" },
    { label: "Boolean", value: "Boolean" },
    { label: "Date", value: "Date" },
    { label: "Integer", value: "Integer" },
    { label: "Array", value: "Array" },
    { label: "Object", value: "Object" },
];
var getSchemaAtPath = function (schema, path) {
    var fields = path.split(".");
    var value = schema;
    for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
        var field = fields_1[_i];
        if (value && typeof value === "object") {
            value = value[field];
        }
        else {
            return undefined;
        }
    }
    if (Array.isArray(value)) {
        value = value[0];
    }
    if (value && value.tree) {
        return value.tree;
    }
    return value;
};
exports.getSchemaAtPath = getSchemaAtPath;
var getFlattenedSchema = function (schema, prefix) {
    if (prefix === void 0) { prefix = ""; }
    if (!schema || !schema.paths) {
        return {};
    }
    var flattened = {};
    Object.entries(schema.paths).forEach(function (_a) {
        var _b;
        var path = _a[0], value = _a[1];
        if ((_b = value.options) === null || _b === void 0 ? void 0 : _b.private) {
            return;
        }
        flattened["".concat(prefix).concat(path)] = value.instance;
        if (value.schema && value.instance !== "Array") {
            Object.assign(flattened, (0, exports.getFlattenedSchema)(value.schema, "".concat(prefix).concat(path, ".")));
        }
    });
    return flattened;
};
exports.getFlattenedSchema = getFlattenedSchema;
var getFieldType = function (value) {
    if (value === null) {
        return { type: "String" };
    }
    if (value === undefined) {
        return { type: "String" };
    }
    if (typeof value === "string") {
        return { type: "String" };
    }
    if (typeof value === "number") {
        return { type: "Number" };
    }
    if (typeof value === "boolean") {
        return { type: "Boolean" };
    }
    if (value instanceof Date) {
        return { type: "Date" };
    }
    if (Array.isArray(value)) {
        return { type: "Array" };
    }
    if (typeof value === "object") {
        return { type: "Object" };
    }
    return { type: "String" };
};
exports.getFieldType = getFieldType;
// traverse previous nodes and compound schemas
var combineSchemas = function (currentNode, nodes, edges) {
    var schema;
    var traverse = function (node) {
        var _a;
        edges.forEach(function (edge) {
            if (edge.target === node.id) {
                var previousNode = nodes.find(function (n) { return n.id === edge.source; });
                traverse(previousNode);
            }
        });
        if (!((_a = node.data) === null || _a === void 0 ? void 0 : _a.schema)) {
            return;
        }
        if (node.data.schema.path) {
            var path = node.data.schema.path.split(".");
            var current = {};
            for (var i = 0; i < path.length; i++) {
                if (i === path.length - 1) {
                    current[path[i]] = node.data.schema.ref.tree;
                }
                else {
                    current = current[path[i]] || {};
                }
            }
            if (!schema) {
                schema = new models_1.Schema();
            }
            schema.add(current);
        }
        else {
            if (schema) {
                schema.add(node.data.schema.ref);
            }
            else {
                schema = node.data.schema.ref;
            }
        }
    };
    traverse(currentNode);
    return schema;
};
exports.combineSchemas = combineSchemas;
var getSchemaKeys = function (schema) {
    if (!schema) {
        return [];
    }
    var keys = [];
    Object.keys(schema).forEach(function (key) {
        if (!schema[key].private) {
            keys.push(key);
        }
    });
    return keys;
};
exports.getSchemaKeys = getSchemaKeys;
var getSchemaOptions = function (schema) {
    if (!schema) {
        return [];
    }
    var schemaKeys = (0, exports.getSchemaKeys)(schema.tree);
    var schemaOptions = schemaKeys.map(function (key) { return ({ label: key, value: key }); });
    var flattenedSchema = (0, exports.getFlattenedSchema)(schema, "$.");
    if (Object.keys(flattenedSchema)) {
        schemaOptions.push({ label: "--- PATHS BELOW ---", value: "" });
        var pathOptions = Object.keys(flattenedSchema)
            .map(function (key) { return ({ label: key, value: key }); })
            .filter(function (option) { return !schemaKeys.includes(option.label); });
        schemaOptions.push.apply(schemaOptions, pathOptions);
    }
    return schemaOptions;
};
exports.getSchemaOptions = getSchemaOptions;
