"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoyaltyPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var loyalties_table_1 = require("./loyalties_table");
var LoyaltyPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)(); //<AppDispatch>
    (0, react_1.useEffect)(function () { }, []);
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Routes, { children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(loyalties_table_1.LoyaltiesTablePage, {}) }) }) }));
};
exports.LoyaltyPage = LoyaltyPage;
