"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFront = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var get_store_front_thunk_1 = require("../../../../reducers/slices/storeFront/thunks/get_store_front_thunk");
var store_front_colour_panel_1 = require("./storeFront/store_front_colour_panel");
var store_front_content_panel_1 = require("./storeFront/store_front_content_panel");
var store_front_media_socials_panel_1 = require("./storeFront/store_front_media_socials_panel");
var store_front_deployment_details_1 = require("./storeFront/store_front_deployment_details");
var StoreFront = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Store Front"));
        dispatch((0, get_store_front_thunk_1.get_store_front_thunk)());
    }, []);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-1 flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(store_front_deployment_details_1.StoreFrontDeploymentDetails, {}), (0, jsx_runtime_1.jsx)(store_front_content_panel_1.StoreFrontContentConfiguration, {})] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-1 flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(store_front_colour_panel_1.StoreFrontColourConfiguration, {}), (0, jsx_runtime_1.jsx)(store_front_media_socials_panel_1.StoreFrontMediaSocialsConfiguration, {})] }))] })));
};
exports.StoreFront = StoreFront;
