"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
function DocumentPreviewComponent(props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var selectedScanScript = props.selectedScanScript;
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-borders", variant: "filled", className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Document Preview", bold: true, textColor: "text-black" }), ((_b = (_a = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _a === void 0 ? void 0 : _a.type) === null || _b === void 0 ? void 0 : _b.includes("image")) &&
                (0, jsx_runtime_1.jsx)("picture", { children: (0, jsx_runtime_1.jsx)("img", { className: "w-full", src: (_c = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _c === void 0 ? void 0 : _c.location }) }), ((_e = (_d = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _d === void 0 ? void 0 : _d.type) === null || _e === void 0 ? void 0 : _e.includes("octet-stream")) &&
                (0, jsx_runtime_1.jsx)("picture", { children: (0, jsx_runtime_1.jsx)("img", { className: "w-full", src: (_f = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _f === void 0 ? void 0 : _f.location }) }), ((_h = (_g = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _g === void 0 ? void 0 : _g.type) === null || _h === void 0 ? void 0 : _h.includes("application")) && !((_k = (_j = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _j === void 0 ? void 0 : _j.type) === null || _k === void 0 ? void 0 : _k.includes("octet-stream")) &&
                (0, jsx_runtime_1.jsx)("object", __assign({ data: (_l = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _l === void 0 ? void 0 : _l.location, type: (_m = selectedScanScript === null || selectedScanScript === void 0 ? void 0 : selectedScanScript.file) === null || _m === void 0 ? void 0 : _m.type, width: "100%", height: "100%" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "We can't preview this file type" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { label: "Download", onClick: function () {
                                    var _a, _b;
                                    if ((_a = selectedScanScript.file) === null || _a === void 0 ? void 0 : _a.location)
                                        location.href = (_b = selectedScanScript.file) === null || _b === void 0 ? void 0 : _b.location;
                                }, textColor: "text-primary", size: "small" })] })) }))] })));
}
exports.default = DocumentPreviewComponent;
