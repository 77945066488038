"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSelectedScanScriptID = exports.setScanScriptTablePagination = exports.setAddCommentLoading = exports.setLoading = exports.updateScanScriptStatus = exports.deleteScanScript = exports.editScanScripts = exports.updateScanScripts = exports.setScanScripts = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    scanScripts: [],
    loading: false,
    pagination: pages_1.initialPaginationValues,
    selectedScanScriptID: "",
    addCommentLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "scanScript",
    initialState: initialState,
    reducers: {
        setScanScripts: function (state, action) {
            state.scanScripts = action.payload;
        },
        setSelectedScanScriptID: function (state, action) {
            state.selectedScanScriptID = action.payload;
        },
        updateScanScripts: function (state, action) {
            state.scanScripts.unshift(action.payload);
        },
        editScanScripts: function (state, action) {
            state.scanScripts = state.scanScripts.map(function (x) {
                if (x._id === action.payload._id) {
                    return action.payload;
                }
                return x;
            });
        },
        deleteScanScript: function (state, action) {
            state.scanScripts.filter(function (x) { return x._id !== action.payload; });
        },
        updateScanScriptStatus: function (state, action) {
            state.scanScripts = state.scanScripts.map(function (x) {
                if (x._id === action.payload._id) {
                    return __assign(__assign({}, x), { status: action.payload.status });
                }
                return x;
            });
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setAddCommentLoading: function (state, action) {
            state.loading = action.payload;
        },
        setScanScriptTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setScanScripts = (_a = slice.actions, _a.setScanScripts), exports.updateScanScripts = _a.updateScanScripts, exports.editScanScripts = _a.editScanScripts, exports.deleteScanScript = _a.deleteScanScript, exports.updateScanScriptStatus = _a.updateScanScriptStatus, exports.setLoading = _a.setLoading, exports.setAddCommentLoading = _a.setAddCommentLoading, exports.setScanScriptTablePagination = _a.setScanScriptTablePagination, exports.setSelectedScanScriptID = _a.setSelectedScanScriptID;
exports.default = slice.reducer;
