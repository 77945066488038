"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParcelsTablesComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var constants_1 = require("../../orders/components/parcels/constants");
var ParcelsTablesComponent = function (props) {
    var parcels = props.parcels;
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        console.log("parcels ===>", parcels);
    }, [parcels]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Parcels", size: "small", textColor: "text-black", bold: true }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "You\u2019ve added the following items into a parcel", size: "xsmall", textColor: "text-black" })] }), parcels === null || parcels === void 0 ? void 0 : parcels.map(function (parcel, index) {
                    var _a;
                    return ((0, jsx_runtime_1.jsx)("div", { children: parcel.product_list.length > 0 && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Parcel ".concat(parcel === null || parcel === void 0 ? void 0 : parcel.code), size: "small", textColor: "text-blue-700", bold: true }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-8 mt-3 gap-4 w-full" }, { children: [constants_1.parcelProductTableColumns.map(function (_a, index) {
                                                var label = _a.label, width = _a.width;
                                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, size: "small", textColor: "text-black", bold: true }) }), index));
                                            }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 flex items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Actions", size: "small", textColor: "text-black", bold: true }) }))] })), (_a = parcel === null || parcel === void 0 ? void 0 : parcel.product_list) === null || _a === void 0 ? void 0 : _a.map(function (product, index) {
                                        return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-8 mt-3 gap-4 w-full items-center" }, { children: constants_1.parcelProductTableColumns.map(function (_a, i) {
                                                var displayKey = _a.displayKey, width = _a.width;
                                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "".concat(width, " break-words items-center") }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product[displayKey], size: "xsmall", textColor: "text-black" }) }), i));
                                            }) })));
                                    })] }) })) }, index));
                })] })) }));
};
exports.ParcelsTablesComponent = ParcelsTablesComponent;
