"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGiftsTablePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../../constants/pages");
var routes_1 = require("../../../../../constants/routes");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var category_1 = require("../../../../../reducers/slices/categories/category");
var console_1 = require("../../../../../reducers/slices/console");
var gift_1 = require("../../../../../reducers/slices/gift/gift");
var get_user_gifts_thunk_1 = require("../../../../../reducers/slices/gift/thunks/get_user_gifts_thunk");
var constants_1 = require("../../../constants");
var UserGiftsTablePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), search = _a[0], setSearch = _a[1];
    var _b = (0, react_1.useState)(false), showFilters = _b[0], setShowFilters = _b[1];
    var _c = (0, react_1.useState)(), selectedType = _c[0], setSelectedType = _c[1];
    var _d = (0, react_1.useState)(""), fromDate = _d[0], setFromDate = _d[1];
    var _e = (0, react_1.useState)(""), toDate = _e[0], setToDate = _e[1];
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.giftState; }), userGifts = _f.userGifts, userGiftsPagination = _f.userGiftsPagination;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Sent Gifts"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, category_1.setLoaded)(true));
            dispatch((0, get_user_gifts_thunk_1.get_user_gifts_thunk)(__assign({}, userGiftsPagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    var getUserGifts = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_user_gifts_thunk_1.get_user_gifts_thunk)(query))];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSearch = function (search_string) {
        getUserGifts(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })));
    };
    var onPageChange = function (page) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_user_gifts_thunk_1.get_user_gifts_thunk)(__assign(__assign({}, userGiftsPagination), { page: page })))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    var onRowsPerPageChange = function (size) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_user_gifts_thunk_1.get_user_gifts_thunk)(__assign(__assign({}, userGiftsPagination), { size: size })))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faSlidersSimple, size: "small", backgroundColor: "bg-primary", iconColor: "text-white", onClick: function () { return setShowFilters(!showFilters); } }), showFilters && (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-32 bg-white rounded-md pt-4 text-center cursor-pointer" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.PopoverControl, __assign({ content: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 bg-white border-borders rounded-md" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { label: "From Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: fromDate, onChange: function (v) {
                                                    setFromDate(doshx_controls_web_1.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                                }, size: "small" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DatePickerControl, { label: "To Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: toDate, onChange: function (v) {
                                                    setToDate(doshx_controls_web_1.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                                }, size: "small" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { label: "apply", size: "xsmall", onClick: function () {
                                                        getUserGifts({
                                                            from_date: fromDate,
                                                            to_date: toDate,
                                                        });
                                                        setFromDate("");
                                                        setToDate("");
                                                    } }) }))] })), behavior: "click" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Date Filter" }) })) })), showFilters && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-start gap-4 px-4" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-32 bg-white rounded-lg p-2" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.NewDropdownControl, { label: "Status", options: [{ label: "Voucher", value: "VOUCHER" }, { label: "Wallet", value: "WALLET" }], colors: {
                                            textColor: "text-black",
                                            labelColor: "text-black",
                                            borderColor: "border-borders",
                                            iconColor: "text-primary",
                                        }, renderDisplay: function (item) {
                                            return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: item.label });
                                        }, size: "small", value: selectedType, errorText: "", onChange: function (item) {
                                            setSelectedType(item);
                                            getUserGifts({
                                                type: item === null || item === void 0 ? void 0 : item.value,
                                            });
                                        } }) })) })))] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) {
                            setSearch(e);
                        } })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "sender.cell_number",
                        label: "Sender"
                    },
                    {
                        displayKey: "recipient.cell_number",
                        label: "Recipient",
                    },
                    {
                        displayKey: "date",
                        label: "Date Sent", formatter: function (_) {
                            if (_) {
                                return doshx_controls_web_1.Utilities.formatDateTime(_ === null || _ === void 0 ? void 0 : _.date, "YYYY-MM-DD");
                            }
                            else {
                                return "";
                            }
                        },
                    }, {
                        displayKey: "amount",
                        label: "Amount", formatter: function (_) {
                            return doshx_controls_web_1.Utilities.formatMoney({
                                symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                value: _ === null || _ === void 0 ? void 0 : _.amount,
                                symbolSeparation: true,
                            });
                        },
                    }, {
                        displayKey: "status",
                        label: "Status", formatter: function (_) {
                            if (_) {
                                return (0, constants_1.TABLE_STATUSES)(_.status);
                            }
                        },
                    }, {
                        displayKey: "type",
                        label: "Gift type",
                    }
                ], data: userGifts, onRowClicked: function (data) {
                    dispatch((0, gift_1.setSelectedUserGift)(data));
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.USER_GIFTS_DETAILS_ROUTE, "/").concat(data._id));
                }, rowActions: [{
                        label: "View",
                        onClick: function (data) {
                            dispatch((0, gift_1.setSelectedUserGift)(data));
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.USER_GIFTS_DETAILS_ROUTE, "/").concat(data._id));
                        },
                    },
                ], hasShadow: true, pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: userGiftsPagination === null || userGiftsPagination === void 0 ? void 0 : userGiftsPagination.page,
                    rowsPerPage: userGiftsPagination === null || userGiftsPagination === void 0 ? void 0 : userGiftsPagination.size,
                    totalRecords: userGiftsPagination === null || userGiftsPagination === void 0 ? void 0 : userGiftsPagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig })] })));
};
exports.UserGiftsTablePage = UserGiftsTablePage;
