"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setVehiclesLoaded = exports.setLoading = exports.setVehicles = exports.setHeroConnectToken = exports.setHeroConnectUser = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    user: {},
    vehicles: [],
    token: {},
    vehiclesLoaded: false,
    loading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "heroconnect",
    initialState: initialState,
    reducers: {
        setHeroConnectUser: function (state, action) {
            state.user = __assign(__assign({}, state.user), action.payload);
        },
        setHeroConnectToken: function (state, action) {
            state.token = __assign(__assign({}, state.token), action.payload);
        },
        setVehicles: function (state, action) {
            state.vehicles = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setVehiclesLoaded: function (state, action) {
            state.vehiclesLoaded = action.payload;
        },
    },
});
exports.setHeroConnectUser = (_a = slice.actions, _a.setHeroConnectUser), exports.setHeroConnectToken = _a.setHeroConnectToken, exports.setVehicles = _a.setVehicles, exports.setLoading = _a.setLoading, exports.setVehiclesLoaded = _a.setVehiclesLoaded;
exports.default = slice.reducer;
