"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_spring_1 = require("react-spring");
var TextScroller = function (_a) {
    var text = _a.text;
    var _b = (0, react_1.useState)(1), key = _b[0], setKey = _b[1];
    var scrolling = (0, react_spring_1.useSpring)({
        from: { transform: "translate(0%,0)" },
        to: { transform: "translate(-20%,0)" },
        config: { duration: 2000 },
        reset: true,
        reverse: key % 2 == 0,
        onRest: function () {
            setKey(key + 1);
        },
    });
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(react_spring_1.animated.div, __assign({ style: scrolling, className: "text-green-400" }, { children: text })) }, key));
};
exports.default = TextScroller;
