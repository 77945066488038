"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsEdited = exports.setIsLoading = exports.setErrors = exports.setImages = exports.setContactUs = exports.setSelectedEnvironment = exports.setContactDetails = exports.setSocials = exports.setTermsOfService = exports.setStoreDomainUrl = exports.setReturnPolicy = exports.setPrivacyPolicy = exports.setNewsletter = exports.setAboutUs = exports.setColours = exports.setStoreFront = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    environments: {
        production: {
            url: "",
            status: "",
        },
        development: {
            url: "",
            status: "",
        },
    },
    about_us: {
        description: "",
    },
    return_policy: {
        description: "",
    },
    terms_of_service: {
        description: "",
    },
    privacy_policy: {
        description: "",
    },
    newsletter: {
        description: "",
        sender: { email: "" },
    },
    socials: {
        facebook: "",
        instagram: "",
        twitter: "",
        linkedin: "",
        whatsapp: "",
    },
    images: {
        header_logo: { file_name: "" },
        footer_logo: { file_name: "" },
        favicon: { file_name: "" },
    },
    colours: {
        primary: "#0000fc",
        secondary: "#000000",
        header: {
            background: "#000000",
            text: "#FFFFFF",
        },
        newsletter: {
            background: "#000000",
            text: "#FFFFFF",
        },
        menu: {
            background: "#0000fc",
            text: "#FFFFFF",
        },
        disabled: "#B0B0B0",
        success: "#00AA00",
        error: "#AA0000",
        borders: {
            light: "#FFFFFF",
            gray: "#888888",
            dark: "#000000",
        },
        shadows: {
            light: "#FFFFFF",
            dark: "#000000",
        },
        fromGradient: "#F1FCFF",
        toGradient: "#EEFCFF",
        stopGradient: "#EBF8FE",
    },
    contact_details: {
        email: "",
        phone: "",
    },
    contact_us: {
        recipient: {
            email: "",
        },
    },
    store_domain: {
        url: "",
    },
    selectedEnvironment: "development",
    errors: {},
    isLoading: false,
    isEdited: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "storeFront",
    initialState: initialState,
    reducers: {
        setStoreFront: function (state, action) {
            var _a = action.payload, colours = _a.colours, payload = __rest(_a, ["colours"]);
            Object.assign(state, payload, { isLoading: false, isEditing: false });
            Object.assign(state.colours, colours);
        },
        setColours: function (state, action) {
            state.colours = action.payload;
        },
        setAboutUs: function (state, action) {
            state.about_us = action.payload;
        },
        setReturnPolicy: function (state, action) {
            state.return_policy = action.payload;
        },
        setSelectedEnvironment: function (state, action) {
            state.selectedEnvironment = action.payload;
        },
        setTermsOfService: function (state, action) {
            state.terms_of_service = action.payload;
        },
        setPrivacyPolicy: function (state, action) {
            state.privacy_policy = action.payload;
        },
        setNewsletter: function (state, action) {
            state.newsletter = action.payload;
        },
        setSocials: function (state, action) {
            state.socials = action.payload;
        },
        setContactDetails: function (state, action) {
            state.contact_details = action.payload;
        },
        setContactUs: function (state, action) {
            state.contact_us = action.payload;
        },
        setImages: function (state, action) {
            state.images = action.payload;
        },
        setErrors: function (state, action) {
            state.errors = action.payload;
        },
        setStoreDomainUrl: function (state, action) {
            state.store_domain = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEdited: function (state, action) {
            state.isEdited = action.payload;
        },
    },
});
exports.setStoreFront = (_a = slice.actions, _a.setStoreFront), exports.setColours = _a.setColours, exports.setAboutUs = _a.setAboutUs, exports.setNewsletter = _a.setNewsletter, exports.setPrivacyPolicy = _a.setPrivacyPolicy, exports.setReturnPolicy = _a.setReturnPolicy, exports.setStoreDomainUrl = _a.setStoreDomainUrl, exports.setTermsOfService = _a.setTermsOfService, exports.setSocials = _a.setSocials, exports.setContactDetails = _a.setContactDetails, exports.setSelectedEnvironment = _a.setSelectedEnvironment, exports.setContactUs = _a.setContactUs, exports.setImages = _a.setImages, exports.setErrors = _a.setErrors, exports.setIsLoading = _a.setIsLoading, exports.setIsEdited = _a.setIsEdited;
exports.default = slice.reducer;
