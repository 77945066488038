"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.statuses = exports.tableColumns = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("../../orders/constants");
var constants_2 = require("../../../constants");
exports.tableColumns = [
    {
        displayKey: "code",
        label: "Code",
    },
    {
        displayKey: "method",
        label: "Method",
    },
    {
        displayKey: "boxes",
        label: "Boxes",
    },
    {
        displayKey: "owner",
        label: "Owner",
    },
    {
        displayKey: "status",
        label: "Status",
        formatter: function (x) {
            if (x) {
                return (0, constants_2.TABLE_STATUSES)(x.status);
            }
        },
    },
    {
        displayKey: "createdAt",
        label: "Created At",
        formatter: function (parcel) { return doshx_controls_web_1.Utilities.formatDateTime(parcel === null || parcel === void 0 ? void 0 : parcel.createdAt); },
    },
];
exports.statuses = [
    { value: constants_1.StatusEnum.READY, label: constants_1.StatusEnum.READY },
    { value: constants_1.StatusEnum.RETURNED, label: constants_1.StatusEnum.RETURNED },
    { value: constants_1.StatusEnum.COLLECTED, label: constants_1.StatusEnum.COLLECTED },
    { value: constants_1.StatusEnum.DELIVERED, label: constants_1.StatusEnum.DELIVERED },
    { value: constants_1.StatusEnum.COLLECTED_BY_DRIVER, label: constants_1.StatusEnum.COLLECTED_BY_DRIVER },
    { value: constants_1.StatusEnum.FULFILLED, label: constants_1.StatusEnum.FULFILLED },
];
