"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VOUCHER_TABLE_COLUMNS = exports.voucherCategoriesColumns = exports.voucherBranchColumns = exports.DAYS_APPLICABLE = exports.PERCENTAGE_VOUCHER_DISCOUNT_TYPE = exports.DISCOUNT_TYPE = exports.USAGE = exports.STATUSES = exports.APPLY_TO = exports.VOUCHER_TYPES = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var constants_1 = require("../../../constants");
exports.VOUCHER_TYPES = [
    { label: "Percentage Voucher", value: "PERCENTAGE" },
    { label: "Value Voucher", value: "VALUE" },
    { label: "Price-Cap Voucher", value: "PRICE-CAP" },
    { label: "Combo Voucher", value: "COMBO" },
];
exports.APPLY_TO = [
    { label: "Products", value: "PRODUCT" },
    // { label: "Category", value: "CATEGORY" },
    { label: "Basket", value: "BASKET" },
    // { label: "Branch", value: "BRANCH" },
    // { label: "Compound", value: "COMPOUND" },
    // { label: "Buy-Get", value: "BUY-GET" },
    { label: "Combo", value: "COMBO" },
    { label: "Service", value: "SERVICE" },
];
exports.STATUSES = [
    { label: "Active", value: "ACTIVE" },
    { label: "Draft", value: "DRAFT" },
    { label: "Inactive", value: "INACTIVE" },
];
exports.USAGE = [
    { label: "Unlimited", value: "UNLIMITED" },
    { label: "Fixed", value: "FIXED" },
];
exports.DISCOUNT_TYPE = [
    { label: "Percentage", value: "PERCENTAGE" },
    { label: "Value", value: "VALUE" },
];
exports.PERCENTAGE_VOUCHER_DISCOUNT_TYPE = [{ label: "Percentage", value: "PERCENTAGE" }];
exports.DAYS_APPLICABLE = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
exports.voucherBranchColumns = [
    {
        displayKey: "display_name",
        label: "Branch Name",
    },
    {
        displayKey: "branch_number",
        label: "Branch Number",
    },
];
exports.voucherCategoriesColumns = [
    {
        displayKey: "name",
        label: "Category Name",
    },
    {
        displayKey: "image_url",
        label: "Image",
        formatter: function (category) {
            if (category) {
                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: category === null || category === void 0 ? void 0 : category.image_url });
            }
            else {
                return "";
            }
        },
    },
];
exports.VOUCHER_TABLE_COLUMNS = [
    {
        displayKey: "Image",
        label: "Image",
        formatter: function (voucher) {
            if (voucher) {
                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: voucher === null || voucher === void 0 ? void 0 : voucher.image_url });
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "name",
        label: "Voucher Name",
    },
    {
        displayKey: "status",
        label: "Status",
        formatter: function (x) {
            if (x) {
                return (0, constants_1.TABLE_STATUSES)(x.status);
            }
        },
    },
    {
        displayKey: "usage",
        label: "Usage",
    },
    {
        displayKey: "date_range.from",
        label: "Start Date",
        formatter: function (voucher) {
            var _a;
            if (voucher) {
                return (_a = voucher === null || voucher === void 0 ? void 0 : voucher.date_range) === null || _a === void 0 ? void 0 : _a.from;
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "date_range.to",
        label: "End Date",
        formatter: function (voucher) {
            var _a;
            if (voucher) {
                return (_a = voucher === null || voucher === void 0 ? void 0 : voucher.date_range) === null || _a === void 0 ? void 0 : _a.to;
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "quantity",
        label: "Quantity",
    },
    {
        displayKey: "reedemed_voucher_count",
        label: "Redeemed",
    },
];
