"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaybillTimeline = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var update_waybill_comment_thunk_1 = require("../../../../../reducers/slices/waybill/thunks/update_waybill_comment_thunk");
var WaybillTimeline = function (props) {
    var waybillID = props.waybillID, comments = props.comments;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), comment = _a[0], setComment = _a[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var addCommentLoading = (0, react_redux_1.useSelector)(function (state) { return state.waybillState; }).addCommentLoading;
    var formatOrderNotes = function (notes) {
        return notes === null || notes === void 0 ? void 0 : notes.map(function (note) {
            return __assign(__assign({}, note), { date: doshx_controls_web_1.Utilities.formatDateTime(note === null || note === void 0 ? void 0 : note.date, "Do MMMM YYYY"), time: doshx_controls_web_1.Utilities.formatDateTime(note === null || note === void 0 ? void 0 : note.date, "HH:mm") });
        });
    };
    var formattedNotes = comments ? formatOrderNotes(comments) : [];
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center justify-center p-4 w-16 h-16 rounded-full border " }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faUser, onClick: function () { }, backgroundColor: "bg-gray-200", iconColor: "text-black", shape: "circle", size: "large" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-full pl-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Notes/Comments", value: comment, onChange: function (text) { return setComment(text); }, required: true, backgroundColor: "bg-gray-200", borderColor: "border-transparent", lines: 1, size: "medium" }) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Post", textColor: "text-white", loading: addCommentLoading, backgroundColor: "bg-primary", borderColor: "border-transparent", size: "small", onClick: function () {
                                if (comment) {
                                    dispatch((0, update_waybill_comment_thunk_1.update_waybill_comment_thunk)({
                                        comment: comment,
                                        waybill_id: waybillID,
                                        user: {
                                            _id: user._id,
                                            display_name: "".concat(user.name, " ").concat(user.surname)
                                        }
                                    }));
                                    setComment("");
                                }
                            } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "mt-5" }, { children: formattedNotes === null || formattedNotes === void 0 ? void 0 : formattedNotes.map(function (note, index) {
                            var _a;
                            return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-6 h-6 border-1 border-white rounded-full flex items-center justify-center" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheckCircle, className: "text-blue-500" }) })), index < formattedNotes.length - 1 && (0, jsx_runtime_1.jsx)("div", { className: "w-1 h-20 bg-blue-500" })] })) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col pl-4 space-y-1" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-between items-start" }, { children: (0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-sm" }, { children: note === null || note === void 0 ? void 0 : note.date })) })), (note === null || note === void 0 ? void 0 : note.user) && (0, jsx_runtime_1.jsxs)("p", __assign({ className: "font-bold" }, { children: ["Note by: ", (_a = note === null || note === void 0 ? void 0 : note.user) === null || _a === void 0 ? void 0 : _a.display_name] })), (0, jsx_runtime_1.jsx)("p", __assign({ className: (note === null || note === void 0 ? void 0 : note.user) ? "text-md" : "font-bold" }, { children: note === null || note === void 0 ? void 0 : note.comment }))] }))] })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-sm text-gray-500" }, { children: note === null || note === void 0 ? void 0 : note.time }))] }), index));
                        }) }))] })) })) }));
};
exports.WaybillTimeline = WaybillTimeline;
