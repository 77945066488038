"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateAddressComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var addressSearch_1 = require("../../../../../controls/addressSearch");
var UpdateAddressComponent = function (_a) {
    var initialAddress = _a.initialAddress, onAddressChange = _a.onAddressChange;
    var handleAddressSelection = function (value) {
        var newAddress = {
            full_address: value.fullAddress,
            lon: value.coordinates.lng,
            lat: value.coordinates.lat,
            line_one: value.street_number ? "".concat(value.street_number, " ").concat(value.street_name) : value.street_name,
            line_two: value.suburb || value.city,
            town: value.suburb || value.city,
            city: value.city,
            province: value.province,
            country: value.country,
            postal_code: value.postal_code,
        };
        onAddressChange(newAddress);
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-6 text-center" }, { children: [(0, jsx_runtime_1.jsx)("p", __assign({ className: "text-md" }, { children: "Oh no! It appears the provided address is missing or invalid." })), initialAddress && ((0, jsx_runtime_1.jsxs)("p", __assign({ className: "px-4 text-sm" }, { children: ["Existing Address: ", (0, jsx_runtime_1.jsx)("em", { children: initialAddress.full_address })] }))), (0, jsx_runtime_1.jsx)(addressSearch_1.AddressSearch, { required: true, size: "sm", onSelectionChange: handleAddressSelection })] })));
};
exports.UpdateAddressComponent = UpdateAddressComponent;
