"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CopyText = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = __importStar(require("react"));
var react_2 = require("@nextui-org/react");
var react_3 = require("@iconify/react");
var react_4 = require("@nextui-org/react");
exports.CopyText = (0, react_1.memo)((0, react_1.forwardRef)(function (props, forwardedRef) {
    var className = props.className, textClassName = props.textClassName, children = props.children, _a = props.copyText, copyText = _a === void 0 ? "Copy" : _a;
    var _b = react_1.default.useState(false), copied = _b[0], setCopied = _b[1];
    var _c = react_1.default.useState(null), copyTimeout = _c[0], setCopyTimeout = _c[1];
    var onClearTimeout = function () {
        if (copyTimeout) {
            clearTimeout(copyTimeout);
        }
    };
    var handleClick = function () {
        onClearTimeout();
        navigator.clipboard.writeText(children);
        setCopied(true);
        setCopyTimeout(setTimeout(function () {
            setCopied(false);
        }, 3000));
    };
    var content = (0, react_1.useMemo)(function () { return (copied ? "Copied" : copyText); }, [copied, copyText]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ ref: forwardedRef, className: (0, react_4.cn)("flex items-center gap-3 text-default-500 text-nowrap", className) }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: textClassName }, { children: children })), (0, jsx_runtime_1.jsx)(react_2.Tooltip, __assign({ className: "text-foreground", content: content }, { children: (0, jsx_runtime_1.jsxs)(react_2.Button, __assign({ isIconOnly: true, className: "h-7 w-7 min-w-7 text-default-400", size: "sm", variant: "light", onPress: handleClick }, { children: [!copied && (0, jsx_runtime_1.jsx)(react_3.Icon, { className: "h-[14px] w-[14px]", icon: "solar:copy-linear" }), copied && (0, jsx_runtime_1.jsx)(react_3.Icon, { className: "h-[14px] w-[14px]", icon: "solar:check-read-linear" })] })) }))] })));
}));
exports.CopyText.displayName = "CopyText";
