"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedChannelsSelectModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var get_channels_thunk_1 = require("../../../../../../reducers/slices/channels/thunks/get_channels_thunk");
var main_1 = require("../../../../../../reducers/slices/main");
var ConnectedChannelsSelectModal = function (_a) {
    var channel_type = _a.channel_type, connectedChannels = _a.connectedChannels, onConfirm = _a.onConfirm;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.channelState; }), channels = _b.channels, loading = _b.loading;
    var _c = (0, react_1.useState)([]), selectedChannels = _c[0], setSelectedChannels = _c[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, get_channels_thunk_1.get_channels_thunk)({ type: channel_type }));
    }, []);
    var filteredChannels = channels.filter(function (channel) { return !connectedChannels.find(function (connectedChannel) { return connectedChannel.channel_id === channel._id; }); });
    var allSelected = connectedChannels.length === (channels === null || channels === void 0 ? void 0 : channels.length) && filteredChannels.length === 0;
    var channelOptions = filteredChannels
        .sort(function (a, b) { return a.type.localeCompare(b.type); })
        .map(function (channel) { return ({ channel_id: channel._id, name: channel.name, channel: channel.type.toUpperCase() }); });
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-6 justify-between items-center max-w-md" }, { children: [allSelected && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "No more channels available to add to this product.", textColor: "text-inputText" }), loading ? ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : ((0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[250px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownMultiControl, { label: "Channel", options: channelOptions !== null && channelOptions !== void 0 ? channelOptions : [], renderDisplay: function (channel) { return channel === null || channel === void 0 ? void 0 : channel.name; }, 
                    // selectedValues={channelOptions?.filter((channel) => selectedChannels?.some((selectedChannel) => selectedChannel.channel_id === channel.channel_id)) ?? []}
                    selectedValues: selectedChannels, colors: {
                        borderColor: "border-borders",
                        textColor: "text-inputText",
                        iconColor: "text-inputText",
                    }, onSelectionChanged: setSelectedChannels }) }))), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Adding channels to this product will sync this product across the selected channels upon saving this product.", textColor: "text-inputText" }), channel_type === "shopify" && (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Due to constraints in Shopify, upon.", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-6 justify-center items-center" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", onClick: function () { return (0, main_1.setContentModal)(null); }, borderColor: "border-primary", backgroundColor: "bg-transparent", textColor: "text-primary" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[150px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Confirm", onClick: function () {
                                onConfirm(selectedChannels);
                                (0, main_1.setContentModal)(null);
                            }, backgroundColor: "bg-primary", textColor: "text-white" }) }))] }))] })));
};
exports.ConnectedChannelsSelectModal = ConnectedChannelsSelectModal;
