"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDealCategoriesPagination = exports.setDealCategories = exports.setPagination = exports.setLoaded = exports.deleteCategories = exports.editCategories = exports.updateCategories = exports.setCategories = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    categories: [],
    dealCategories: [],
    loading: false,
    loaded: false,
    pagination: pages_1.initialPaginationValues,
    dealCategoriesPagination: pages_1.dealsCategoriesInitialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "category",
    initialState: initialState,
    reducers: {
        setCategories: function (state, action) {
            state.categories = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setDealCategories: function (state, action) {
            state.dealCategories = action.payload;
        },
        setDealCategoriesPagination: function (state, action) {
            state.dealCategoriesPagination = action.payload;
        },
        updateCategories: function (state, action) {
            state.categories.push(action.payload);
            // state.loading = false;
        },
        editCategories: function (state, action) {
            state.categories = state.categories.map(function (x) {
                if (x._id === action.payload._id) {
                    return action.payload;
                }
                return x;
            });
        },
        deleteCategories: function (state, action) {
            state.categories.filter(function (x) { return x._id !== action.payload; });
        },
        setLoaded: function (state, action) {
            state.loaded = action.payload;
        },
    },
});
exports.setCategories = (_a = slice.actions, _a.setCategories), exports.updateCategories = _a.updateCategories, exports.editCategories = _a.editCategories, exports.deleteCategories = _a.deleteCategories, exports.setLoaded = _a.setLoaded, exports.setPagination = _a.setPagination, exports.setDealCategories = _a.setDealCategories, exports.setDealCategoriesPagination = _a.setDealCategoriesPagination;
exports.default = slice.reducer;
