"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyInformationPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var create_client_1 = require("../../../../../api/calls/client/create_client");
var pages_1 = require("../../../../../constants/pages");
var routes_1 = require("../../../../../constants/routes");
var entry_1 = require("../../../../../reducers/slices/entry");
var main_1 = require("../../../../../reducers/slices/main");
var registration_1 = require("../../../../../reducers/slices/registration");
var sign_in_thunk_1 = require("../../../../../reducers/slices/user/thunks/sign_in_thunk");
var user_1 = require("../../../../../reducers/slices/user/user");
var CompanyInformationPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _a.user, token = _a.token;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.registrationState; }), offerings = _b.offerings, companyDetails = _b.companyDetails, companyInformation = _b.companyInformation;
    var _c = (0, react_1.useState)(""), industryMessage = _c[0], setIndustryMessage = _c[1];
    var _d = (0, react_1.useState)(""), businessTypeMessage = _d[0], setBusinessTypeMessage = _d[1];
    var _e = (0, react_1.useState)(""), sellingProductsMessage = _e[0], setSellingProductsMessage = _e[1];
    var onControlChanged = function (name, value) {
        var _a;
        dispatch((0, registration_1.setCompanyInformation)(__assign(__assign({}, companyInformation), (_a = {}, _a[name] = value, _a))));
    };
    var validate = function () {
        var messageCount = 0;
        setIndustryMessage("");
        setBusinessTypeMessage("");
        setSellingProductsMessage("");
        if (!(companyInformation === null || companyInformation === void 0 ? void 0 : companyInformation.industry)) {
            messageCount++;
            setIndustryMessage("Please select an industry.");
        }
        if (!(companyInformation === null || companyInformation === void 0 ? void 0 : companyInformation.businessType)) {
            messageCount++;
            setBusinessTypeMessage("Business type is required.");
        }
        if (!(companyInformation === null || companyInformation === void 0 ? void 0 : companyInformation.sellingProducts)) {
            messageCount++;
            setSellingProductsMessage("Please select products type.");
        }
        return messageCount ? false : true;
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full  lg:w-[700px] pt-8" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "2xlarge", label: "Which of the following options best describes you?", bold: true }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-10" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-black", addPadding: true }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.RadioButtonsControl, { errorText: businessTypeMessage, required: true, title: "We'll help you set up based on your business needs", cols: "grid-cols-2", colors: __assign(__assign({}, pages_1.radioButtonColorConfig), { radio: __assign(__assign({}, pages_1.radioButtonColorConfig.radio), { iconColor: entryTheme.config.colors.radio.box }) }), options: offerings.experience.map(function (e, i) {
                        return { label: e, value: e };
                    }), selectedValue: companyInformation.businessType, onChange: function (v) { return onControlChanged("businessType", v); } }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-y-4 pt-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "medium", label: "What do you plan to sell?.", bold: true }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-y-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.RadioButtonsControl, { errorText: sellingProductsMessage, required: true, title: "Choose all that apply, and we'll make sure you're set up to sell.", cols: "grid-cols-2", colors: __assign(__assign({}, pages_1.radioButtonColorConfig), { radio: __assign(__assign({}, pages_1.radioButtonColorConfig.radio), { iconColor: entryTheme.config.colors.radio.box }) }), options: offerings.offering.map(function (e, i) {
                                return { label: e, value: e };
                            }), selectedValue: companyInformation === null || companyInformation === void 0 ? void 0 : companyInformation.sellingProducts, onChange: function (v) { return onControlChanged("sellingProducts", v); } }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { textColor: "text-black", size: "medium", label: "Please select your business industry.", bold: true }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-y-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) {
                                return v.label;
                                // return v.hello;
                            }, label: "Select Industry", required: true, options: offerings.industry.map(function (e, i) {
                                return { label: e, value: e };
                            }), colors: {
                                labelColor: "text-black",
                                textColor: "text-black",
                                borderColor: "border-black",
                                iconColor: "text-black",
                            }, size: "small", value: companyInformation.industry, errorText: industryMessage, onChange: function (value) {
                                onControlChanged("industry", value);
                            } }) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-4  pt-8" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[200px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Next", backgroundColor: entryTheme.config.colors.buttons.bgPrimary, loading: companyInformation === null || companyInformation === void 0 ? void 0 : companyInformation.isLoading, onClick: function () {
                                var _a, _b, _c, _d, _e, _f;
                                if (validate()) {
                                    var domain = window.location.host;
                                    (0, create_client_1.create_client)({
                                        alias: companyDetails.businessName,
                                        name: companyDetails.businessRegisteredName,
                                        id_number: companyDetails.businessRegisteredNumber,
                                        vat_number: companyDetails.vatNumber,
                                        domain: domain,
                                        type: "ENTITY",
                                        address: {
                                            full_address: companyDetails.address.fullAddress,
                                            coordinates: companyDetails.address.coordinates,
                                        },
                                        client_user: {
                                            name: (_a = user.name) !== null && _a !== void 0 ? _a : "",
                                            surname: (_b = user.surname) !== null && _b !== void 0 ? _b : "",
                                            email: user.email,
                                            password: user.password,
                                            cell_country_code: user.cell_number,
                                            cell_number: user.cell_number,
                                            birthday: (_d = (_c = user.birthday) === null || _c === void 0 ? void 0 : _c.toString()) !== null && _d !== void 0 ? _d : "",
                                            get_communication: (_e = user.get_communication) !== null && _e !== void 0 ? _e : false,
                                            agrees_terms_and_conditions: (_f = user.agrees_terms_and_conditions) !== null && _f !== void 0 ? _f : false,
                                        },
                                        offering: {
                                            experience: companyInformation.businessType,
                                            product_types: [companyInformation.sellingProducts],
                                            offering: ["I'm not sure"],
                                            industry: [companyInformation.industry.value],
                                        },
                                    }).then(function (response) {
                                        if (response.success) {
                                            var client_id = response.content._id;
                                            // const payment_engine_id = response.content.settings.payment_engine_id;
                                            var payment_engine_id = response.content.integration_settings.payment_engine_id;
                                            dispatch((0, user_1.setUser)(__assign(__assign({}, user), { remember_me: true, client_id: client_id, payment_engine_id: payment_engine_id })));
                                            dispatch((0, sign_in_thunk_1.sign_in_thunk)());
                                            navigate(routes_1.CONSOLE_ROUTE);
                                        }
                                        else {
                                            dispatch((0, main_1.setMessageModal)({
                                                title: "Oops!",
                                                messages: [response.message],
                                            }));
                                        }
                                    });
                                }
                            } }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[200px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Back", backgroundColor: "bg-black", loading: companyInformation === null || companyInformation === void 0 ? void 0 : companyInformation.isLoading, onClick: function () {
                                dispatch((0, entry_1.setCurrentStep)(1));
                            } }) }))] }))] })));
};
exports.CompanyInformationPage = CompanyInformationPage;
