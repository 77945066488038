"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = exports.INITIAL_VISIBLE_COLUMNS = exports.SEGMENT_RULE = exports.DISCOUNT_TYPE = void 0;
exports.DISCOUNT_TYPE = [
    {
        label: "Percentage",
        value: "PERCENTAGE",
    },
    {
        label: "Value",
        value: "VALUE",
    },
];
exports.SEGMENT_RULE = [
    {
        label: "Exclude Promotion",
        value: "EXCLUDE_PROMOTION",
    },
    {
        label: "Include Promotion",
        value: "INCLUDE_PROMOTION",
    },
    {
        label: "Lowest Price",
        value: "LOWEST_PRICE",
    },
];
exports.INITIAL_VISIBLE_COLUMNS = ["title", "discount_type", "discount", "apply_to_products_on_promotion", "segment_rule", "actions"];
exports.columns = [
    { name: "Title", uid: "title" },
    { name: "Discount Type", uid: "discount_type" },
    { name: "Discount", uid: "discount" },
    { name: "Apply On Promotion", uid: "apply_to_products_on_promotion" },
    { name: "Segment Rule", uid: "segment_rule" },
    { name: "Actions", uid: "actions" },
];
