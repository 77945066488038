"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setUserVoucherPagination = exports.setTablePagination = exports.setSelectedVoucher = exports.setPagination = exports.setLoading = exports.setUserVouchers = exports.setVouchers = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    vouchers: [],
    userVouchers: [],
    selectedVoucher: null,
    loading: false,
    pagination: pages_1.initialPaginationValues,
    userVoucherPagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "vouchers",
    initialState: initialState,
    reducers: {
        setVouchers: function (state, action) {
            state.vouchers = action.payload;
        },
        setUserVouchers: function (state, action) {
            state.userVouchers = action.payload;
        },
        setSelectedVoucher: function (state, action) {
            state.selectedVoucher = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
        setUserVoucherPagination: function (state, action) {
            state.userVoucherPagination = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
    },
});
exports.setVouchers = (_a = slice.actions, _a.setVouchers), exports.setUserVouchers = _a.setUserVouchers, exports.setLoading = _a.setLoading, exports.setPagination = _a.setPagination, exports.setSelectedVoucher = _a.setSelectedVoucher, exports.setTablePagination = _a.setTablePagination, exports.setUserVoucherPagination = _a.setUserVoucherPagination;
exports.default = slice.reducer;
