"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteFilledIcon = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var DeleteFilledIcon = function (props) { return ((0, jsx_runtime_1.jsxs)("svg", __assign({ "aria-hidden": "true", fill: "none", focusable: "false", height: "1em", role: "presentation", viewBox: "0 0 18 19", width: "1em" }, props, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M15.75 4.98487C13.2525 4.73737 10.74 4.60986 8.235 4.60986C6.75 4.60986 5.265 4.68486 3.78 4.83486L2.25 4.98487", stroke: "#71717A", "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "1.35" }), (0, jsx_runtime_1.jsx)("path", { d: "M6.375 4.2275L6.54001 3.245C6.66 2.53249 6.75 2 8.0175 2H9.98246C11.25 2 11.3475 2.5625 11.46 3.25249L11.625 4.2275", stroke: "#71717A", "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "1.35" }), (0, jsx_runtime_1.jsx)("path", { d: "M14.1376 7.35498L13.6501 14.9075C13.5675 16.0849 13.5 17 11.4075 17H6.59255C4.50005 17 4.43255 16.0849 4.35004 14.9075L3.86255 7.35498", stroke: "#71717A", "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "1.35" }), (0, jsx_runtime_1.jsx)("path", { d: "M7.74756 12.875H10.245", stroke: "#71717A", "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "1.35" }), (0, jsx_runtime_1.jsx)("path", { d: "M7.125 9.875H10.875", stroke: "#71717A", "stroke-linecap": "round", "stroke-linejoin": "round", "stroke-width": "1.35" })] }))); };
exports.DeleteFilledIcon = DeleteFilledIcon;
