"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setIsTriggered = exports.setIsEditing = exports.setIsLoading = exports.setSelectedScheduledWebhook = exports.setWebhookAuth = exports.setWebhook = exports.setSettings = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    settings: {
        enabled: false,
        method: "",
        branch_ids: [],
    },
    webhook: {
        url: "",
        topic: "",
        process: "Stock Replacement",
        delay_ms: "",
        data_map: [],
        auth: {
            method: "",
            config: {},
        },
        condition: "",
        statuses: []
    },
    selectedScheduledWebhook: {
        _id: "",
        client_id: "",
        webhook_id: "",
        status: "",
        data: {},
        scheduled_for: "",
    },
    isTriggered: false,
    isLoading: false,
    isEditing: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "stockReplacement",
    initialState: initialState,
    reducers: {
        setSettings: function (state, action) {
            state.settings = action.payload;
        },
        setWebhook: function (state, action) {
            state.webhook = action.payload;
        },
        setWebhookAuth: function (state, action) {
            state.webhook.auth = action.payload;
        },
        setSelectedScheduledWebhook: function (state, action) {
            var _a;
            state.selectedScheduledWebhook = (_a = action.payload) !== null && _a !== void 0 ? _a : {};
        },
        setIsTriggered: function (state, action) {
            state.isTriggered = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEditing: function (state, action) {
            state.isEditing = action.payload;
        },
    },
});
exports.setSettings = (_a = slice.actions, _a.setSettings), exports.setWebhook = _a.setWebhook, exports.setWebhookAuth = _a.setWebhookAuth, exports.setSelectedScheduledWebhook = _a.setSelectedScheduledWebhook, exports.setIsLoading = _a.setIsLoading, exports.setIsEditing = _a.setIsEditing, exports.setIsTriggered = _a.setIsTriggered;
exports.default = slice.reducer;
