"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setClientSuppliers = exports.setSuppliers = exports.setClient = exports.setClientUsers = exports.setSelectedClientUser = exports.setSelectedClientIntegration = exports.setTablePagination = exports.updateClientIntegrations = exports.setClientIntegrations = exports.setClientSubscription = exports.setIsLoading = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var constants_1 = require("../../../screens/console/pages/preferences/users/constants");
var initialState = {
    client: undefined,
    isLoading: false,
    pagination: pages_1.initialPaginationValues,
    selectedClientUser: constants_1.initialUserValues,
    selectedClientIntegration: {},
    clientIntegrations: [],
    clientUsers: [],
    clientSubscription: {},
    suppliers: [],
    clientSuppliers: [],
};
var slice = (0, toolkit_1.createSlice)({
    name: "client",
    initialState: initialState,
    reducers: {
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setClient: function (state, action) {
            state.client = action.payload;
        },
        setSuppliers: function (state, action) {
            state.suppliers = action.payload;
        },
        setClientSuppliers: function (state, action) {
            state.clientSuppliers = action.payload;
        },
        setSelectedClientUser: function (state, action) {
            state.selectedClientUser = action.payload;
        },
        setSelectedClientIntegration: function (state, action) {
            state.selectedClientIntegration = action.payload;
        },
        setClientIntegrations: function (state, action) {
            state.clientIntegrations = action.payload;
        },
        updateClientIntegrations: function (state, action) {
            state.clientIntegrations.push(action.payload);
        },
        setClientUsers: function (state, action) {
            state.clientUsers = action.payload;
        },
        setClientSubscription: function (state, action) {
            state.clientSubscription = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setIsLoading = (_a = slice.actions, _a.setIsLoading), exports.setClientSubscription = _a.setClientSubscription, exports.setClientIntegrations = _a.setClientIntegrations, exports.updateClientIntegrations = _a.updateClientIntegrations, exports.setTablePagination = _a.setTablePagination, exports.setSelectedClientIntegration = _a.setSelectedClientIntegration, exports.setSelectedClientUser = _a.setSelectedClientUser, exports.setClientUsers = _a.setClientUsers, exports.setClient = _a.setClient, exports.setSuppliers = _a.setSuppliers, exports.setClientSuppliers = _a.setClientSuppliers;
exports.default = slice.reducer;
