"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActionSelected = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = require("react");
var routes_1 = require("../../../../../../constants/routes");
var react_router_dom_1 = require("react-router-dom");
var main_1 = require("../../../../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var ActionSelected = function (props) {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedProducts = (0, react_redux_1.useSelector)(function (state) { return state.productState; }).selectedProducts;
    var _a = (0, react_1.useState)(0), selectedTabIndex = _a[0], setSelectedTabIndex = _a[1];
    (0, react_1.useEffect)(function () { }, []);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TabControl, { selectedTabIndex: selectedTabIndex, tabs: [
                {
                    title: "Action",
                    content: ((0, jsx_runtime_1.jsx)("ul", __assign({ className: "flex flex-col gap-4 max-h-96 overflow-auto p-4 mt-4" }, { children: (0, jsx_runtime_1.jsxs)("li", __assign({ className: "flex justify-between border-b border-borders pb-2 cursor-pointer", onClick: function () {
                                dispatch((0, main_1.setContentModal)(null));
                                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CHANNELS_ROUTE, "/uber"));
                            } }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Push to Uber" }), (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faArrowRight })] })) }))),
                },
                {
                    title: "View Selected",
                    content: ((0, jsx_runtime_1.jsx)("ul", __assign({ className: "flex flex-col gap-4 max-h-96 overflow-auto p-4 mt-4" }, { children: selectedProducts &&
                            selectedProducts.map(function (x) { return ((0, jsx_runtime_1.jsxs)("li", __assign({ className: "flex gap-8 border-b border-borders pb-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: x === null || x === void 0 ? void 0 : x.image_url }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: x === null || x === void 0 ? void 0 : x.title })] }))); }) }))),
                },
            ], colors: {
                tabs: {
                    textColor: "text-gray-800",
                    indicatorColor: "bg-primary",
                    backgroundColor: "bg-white",
                },
            } }) })));
};
exports.ActionSelected = ActionSelected;
