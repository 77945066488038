"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var main_1 = require("../../../../../reducers/slices/main");
var react_redux_1 = require("react-redux");
var styles_tw_1 = require("../../../../../constants/styles_tw");
var MainProductAddOnsComponent = function (_a) {
    var _b;
    var product = _a.product, params = _a.params, setParams = _a.setParams, updateParams = _a.updateParams, onSave = _a.onSave;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var dispatch = (0, react_redux_1.useDispatch)();
    // className="bg-black text-white bold gap-2 pl-2 pr-0 pt-0 pb-0"
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "rounded-md" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.AccordionControl, { addHeaderPadding: false, addContentPadding: false, arrowSide: "right", collapsePreviousItems: true, items: [
                {
                    header: (0, jsx_runtime_1.jsx)("div", __assign({ className: "bg-black text-white bold pl-2" }, { children: "Add Ons" })),
                    content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col divide-y" }, { children: (_b = product === null || product === void 0 ? void 0 : product.selected_add_ons) === null || _b === void 0 ? void 0 : _b.map(function (addon, idx) {
                            var _a, _b;
                            if ((addon === null || addon === void 0 ? void 0 : addon.selected) === true || (addon === null || addon === void 0 ? void 0 : addon.is_required) === true) {
                                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "".concat(styles_tw_1.styles_tw === null || styles_tw_1.styles_tw === void 0 ? void 0 : styles_tw_1.styles_tw.table, " items-center py-1") }, { children: [(0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: addon === null || addon === void 0 ? void 0 : addon.image_url }, idx), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: addon === null || addon === void 0 ? void 0 : addon.title, size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_a = addon === null || addon === void 0 ? void 0 : addon.category) === null || _a === void 0 ? void 0 : _a.name, size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: addon === null || addon === void 0 ? void 0 : addon.barcode, size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "YES", size: "xsmall", textColor: "text-black", className: "break-words" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-16" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { value: (_b = addon.quantity) !== null && _b !== void 0 ? _b : 1, size: "small", type: "number", onChange: function (v) {
                                                    var quantity = 1;
                                                    if (v === "") {
                                                        quantity = Math.max(parseInt(v), 1); // Ensure the quantity is at least 1
                                                    }
                                                    quantity = Math.max(parseInt(v), 1); // Ensure the quantity is at least 1
                                                    // update the quantity of the addon.
                                                    var updatedAddOn = __assign(__assign({}, addon), { quantity: String(quantity) });
                                                    var updatedAddOns = product.selected_add_ons.map(function (item) {
                                                        if (item._id === addon._id) {
                                                            return updatedAddOn;
                                                        }
                                                        return item;
                                                    });
                                                    var updatedProducts = params === null || params === void 0 ? void 0 : params.product_list.map(function (item) {
                                                        if (item._id === product._id) {
                                                            return __assign(__assign({}, item), { selected_add_ons: updatedAddOns });
                                                        }
                                                        return item;
                                                    });
                                                    setParams(__assign(__assign({}, params), { product_list: updatedProducts }));
                                                    onSave(updatedProducts);
                                                } }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { size: "xsmall", textColor: "text-black", className: "break-words", label: doshx_controls_web_1.Utilities.formatMoney({
                                                symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                                value: (addon === null || addon === void 0 ? void 0 : addon.quantity) ? (addon === null || addon === void 0 ? void 0 : addon.price) * (addon === null || addon === void 0 ? void 0 : addon.quantity) : addon === null || addon === void 0 ? void 0 : addon.price,
                                                symbolSeparation: true,
                                            }) }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTrash, className: "text-white border-transparent p-1 rounded-md", onClick: function () {
                                                    var updatedProducts = params === null || params === void 0 ? void 0 : params.product_list.map(function (product_item) {
                                                        if (product_item._id === product._id) {
                                                            var updatedAddOns = product_item.selected_add_ons.filter(function (item) {
                                                                if (item._id === addon._id) {
                                                                    if (item.is_required) {
                                                                        // Show the message if the add-on is required
                                                                        dispatch((0, main_1.setMessageModal)({
                                                                            title: "".concat(item === null || item === void 0 ? void 0 : item.title, " is a required addon."),
                                                                            messages: ["At least ".concat(item === null || item === void 0 ? void 0 : item.quantity, " ").concat(item === null || item === void 0 ? void 0 : item.title, " required on the ").concat(product === null || product === void 0 ? void 0 : product.title, " product.")],
                                                                        }));
                                                                        return true; // Keep the required item in the list
                                                                    }
                                                                    else {
                                                                        dispatch((0, main_1.setContentModal)({
                                                                            title: "Confirm",
                                                                            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to remove ".concat(item === null || item === void 0 ? void 0 : item.title, " add on ?"), textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () {
                                                                                                    var updatedAddOns = product_item.selected_add_ons.filter(function (addOn) { return addOn._id !== item._id; });
                                                                                                    updateParams("product_list", __spreadArray([], params.product_list.map(function (product) {
                                                                                                        if (product._id === product_item._id) {
                                                                                                            return __assign(__assign({}, product), { selected_add_ons: updatedAddOns });
                                                                                                        }
                                                                                                        return product;
                                                                                                    }), true));
                                                                                                    dispatch((0, main_1.setContentModal)(null));
                                                                                                } })] }))] }))),
                                                                        }));
                                                                    }
                                                                }
                                                                return true; // Keep other items in the list
                                                            });
                                                            return __assign(__assign({}, product_item), { selected_add_ons: updatedAddOns });
                                                        }
                                                        return product_item;
                                                    });
                                                    updateParams("product_list", __spreadArray([], updatedProducts, true));
                                                } }) }))] }), idx));
                            }
                        }) }))),
                    expanded: true,
                },
            ], colors: {
                borderColor: "border-transparent",
                titleTextColor: "text-red-500",
                titleBackgroundColor: "bg-white",
                contentTextColor: "text-orange-500",
                contentBackgroundColor: "bg-white",
            } }) })));
};
exports.default = MainProductAddOnsComponent;
