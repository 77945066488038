"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var console_1 = require("../../../../../reducers/slices/console");
var subscription_1 = require("./subscription");
var subscriptions_table_1 = require("./subscriptions_table");
var SubscriptionsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)(); //<AppDispatch>
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Subscriptions"));
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Routes, { children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(subscriptions_table_1.SubscriptionsTablePage, {}) }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "".concat(routes_1.SUBSCRIPTION_ROUTE, "/:subscriptionId?"), element: (0, jsx_runtime_1.jsx)(subscription_1.SubscriptionPage, {}) })] }) }));
};
exports.SubscriptionsPage = SubscriptionsPage;
