"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var add_client_integration_1 = require("../../../../../../api/calls/client/add_client_integration");
var constants_1 = require("../../../../../../constants/constants");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var client_1 = require("../../../../../../reducers/slices/client/client");
var main_1 = require("../../../../../../reducers/slices/main");
var get_client_thunk_1 = require("../../../../../../reducers/slices/client/thunks/get_client_thunk");
var react_router_dom_1 = require("react-router-dom");
var AddBreeze = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var selectedClientIntegration = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClientIntegration;
    var data = selectedClientIntegration.data, _id = selectedClientIntegration._id;
    var _b = (0, validateForm_1.validateForm)(data), errors = _b.errors, isValid = _b.isValid;
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var handleResponse = function (response) {
        if (response.success) {
            dispatch((0, main_1.setContentModal)({ title: "Success", content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
            dispatch((0, get_client_thunk_1.get_client_thunk)());
        }
        else
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
    };
    var _onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var type, data, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    type = selectedClientIntegration.type, data = selectedClientIntegration.data;
                    return [4 /*yield*/, (0, add_client_integration_1.add_client_integration)({ type: type, data: data })];
                case 1:
                    response = _a.sent();
                    handleResponse(response);
                    return [2 /*return*/];
            }
        });
    }); };
    var _setFormData = function (value) {
        dispatch((0, client_1.setSelectedClientIntegration)(__assign(__assign({}, selectedClientIntegration), { data: __assign(__assign({}, data), value) })));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-8" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "eg. ABC-123", size: "xsmall" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Api Key", type: "text", borderColor: "border-borders", value: (_a = data === null || data === void 0 ? void 0 : data.api_key) !== null && _a !== void 0 ? _a : "", errorText: errors.api_key, onChange: function (api_key) {
                                _setFormData({ api_key: api_key });
                            } }) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-40" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: _id ? "Update" : "Connect", size: "small", enabled: isValid, backgroundColor: _id ? "bg-green-300" : "bg-primary", textColor: "text-white", onClick: _onSubmit }) })) }))] })));
};
exports.default = AddBreeze;
