"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setLoading = exports.updateProductTypes = exports.setProductTypes = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var initialState = {
    productTypes: [],
    loading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "types",
    initialState: initialState,
    reducers: {
        setProductTypes: function (state, action) {
            state.productTypes = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        updateProductTypes: function (state, action) {
            state.productTypes = __spreadArray(__spreadArray([], state.productTypes, true), action.payload, true);
        },
    },
});
exports.setProductTypes = (_a = slice.actions, _a.setProductTypes), exports.updateProductTypes = _a.updateProductTypes, exports.setLoading = _a.setLoading;
exports.default = slice.reducer;
