"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setThemePagination = exports.setLoading = exports.deleteTheme = exports.editTheme = exports.updateThemes = exports.setTheme = exports.setClientThemes = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    client_themes: [],
    theme: null,
    loading: false,
    themePagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "theme",
    initialState: initialState,
    reducers: {
        setClientThemes: function (state, action) {
            state.client_themes = action.payload;
        },
        setTheme: function (state, action) {
            state.theme = action.payload;
        },
        updateThemes: function (state, action) {
            state.client_themes.unshift(action.payload);
        },
        editTheme: function (state, action) {
            state.client_themes = state.client_themes.map(function (x) {
                if (x._id === action.payload._id) {
                    return __assign(__assign({}, x), action.payload);
                }
                return x;
            });
        },
        deleteTheme: function (state, action) {
            state.client_themes = state.client_themes.filter(function (x) { return x._id !== action.payload; });
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setThemePagination: function (state, action) {
            state.themePagination = action.payload;
        },
    },
});
exports.setClientThemes = (_a = slice.actions, _a.setClientThemes), exports.setTheme = _a.setTheme, exports.updateThemes = _a.updateThemes, exports.editTheme = _a.editTheme, exports.deleteTheme = _a.deleteTheme, exports.setLoading = _a.setLoading, exports.setThemePagination = _a.setThemePagination;
exports.default = slice.reducer;
