"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setWaybillPagination = exports.setAddCommentLoading = exports.setLoading = exports.deleteWaybills = exports.editWaybills = exports.updateWaybills = exports.setSelectedWaybillID = exports.setWaybills = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    waybills: [],
    loading: false,
    pagination: pages_1.initialPaginationValues,
    selectedWaybillID: "",
    addCommentLoading: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "waybill",
    initialState: initialState,
    reducers: {
        setWaybills: function (state, action) {
            state.waybills = action.payload;
        },
        setSelectedWaybillID: function (state, action) {
            state.selectedWaybillID = action.payload;
        },
        updateWaybills: function (state, action) {
            state.waybills.unshift(action.payload);
        },
        editWaybills: function (state, action) {
            state.waybills = state.waybills.map(function (x) {
                if (x._id === action.payload._id) {
                    return action.payload;
                }
                return x;
            });
        },
        deleteWaybills: function (state, action) {
            state.waybills.filter(function (x) { return x._id !== action.payload; });
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
        setAddCommentLoading: function (state, action) {
            state.addCommentLoading = action.payload;
        },
        setWaybillPagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setWaybills = (_a = slice.actions, _a.setWaybills), exports.setSelectedWaybillID = _a.setSelectedWaybillID, exports.updateWaybills = _a.updateWaybills, exports.editWaybills = _a.editWaybills, exports.deleteWaybills = _a.deleteWaybills, exports.setLoading = _a.setLoading, exports.setAddCommentLoading = _a.setAddCommentLoading, exports.setWaybillPagination = _a.setWaybillPagination;
exports.default = slice.reducer;
