"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementProductsPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../../constants/pages");
var console_1 = require("../../../../../reducers/slices/console");
var get_procurement_products_thunk_1 = require("../../../../../reducers/slices/products/thunks/get_procurement_products_thunk");
var constants_1 = require("../../../constants");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var ProcurementProductsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var procurement_id = params.procurement_id;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), procurementProducts = _a.procurementProducts, procurementProductsPagination = _a.procurementProductsPagination, loading = _a.loading;
    var _b = (0, useSearchDebounce_1.useSearchDebounce)(), search = _b[0], setSearch = _b[1];
    var _c = (0, react_1.useState)(null), filterType = _c[0], setFilterType = _c[1];
    var _d = (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Procurement products"));
        getProcurementProducts({});
    }, []);
    var getProcurementProducts = function (filter) {
        var _procurementProductsPagination = __assign({}, procurementProductsPagination);
        delete _procurementProductsPagination.countTotal;
        dispatch((0, get_procurement_products_thunk_1.get_procurement_products_thunk)({
            payload: __assign(__assign(__assign({}, _procurementProductsPagination), { procurement_id: procurement_id }), filter),
        }));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "xsmall", startIcon: pro_solid_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                                navigate(-1);
                            } }) }), (0, jsx_runtime_1.jsx)("div", { className: "flex gap-2" })] })), loading ? ((0, jsx_runtime_1.jsx)("div", __assign({ className: "grid justify-center p-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "large" }) }))) : ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "title",
                        label: "Title",
                    },
                    {
                        displayKey: "sku",
                        label: "SKU",
                    },
                    {
                        displayKey: "price",
                        label: "Price",
                    },
                    {
                        displayKey: "branch.display_name",
                        label: "Branch name",
                    },
                    {
                        displayKey: "quantity",
                        label: "Quantity",
                    },
                    {
                        displayKey: "ordered_quantity",
                        label: "Ordered Quantity",
                    },
                    {
                        displayKey: "status",
                        label: "Status",
                        formatter: function (x) {
                            if (x) {
                                return (0, constants_1.TABLE_STATUSES)(x.status);
                            }
                        },
                    },
                ], data: procurementProducts, pagination: {
                    currentPage: procurementProductsPagination.page,
                    rowsPerPage: procurementProductsPagination.size,
                    totalRecords: procurementProductsPagination.countTotal,
                    onPageChange: function (page) {
                        getProcurementProducts({ page: page });
                    },
                    onRowsPerPageChange: function (size) {
                        getProcurementProducts({ size: size });
                    },
                }, colors: pages_1.tableColorConfig }))] })));
};
exports.ProcurementProductsPage = ProcurementProductsPage;
