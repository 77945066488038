"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var ParcelDimensionsComponent = function (props) {
    var parcel = props.parcel, onConfirm = props.onConfirm;
    var _a = (0, react_1.useState)({
        length: "",
        width: "",
        height: "",
        weight: "",
    }), dimensions = _a[0], setDimensions = _a[1];
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Set Parcel Dimensions: ", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: parcel === null || parcel === void 0 ? void 0 : parcel.code, bold: true })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Length CM", type: "number", borderColor: "border-borders", value: dimensions.length, onChange: function (length) { return setDimensions(__assign(__assign({}, dimensions), { length: length })); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Width CM", type: "number", borderColor: "border-borders", value: dimensions.width, onChange: function (width) { return setDimensions(__assign(__assign({}, dimensions), { width: width })); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Height CM", type: "number", borderColor: "border-borders", value: dimensions.height, onChange: function (height) { return setDimensions(__assign(__assign({}, dimensions), { height: height })); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Weight KG", type: "number", borderColor: "border-borders", value: dimensions.weight, onChange: function (weight) { return setDimensions(__assign(__assign({}, dimensions), { weight: weight })); } })] }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "mt-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Confirm", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return onConfirm(dimensions); } }) }))] })));
};
exports.default = ParcelDimensionsComponent;
