"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var ChannelsAndAppsConfigComponent = function (props) {
    var values = props.values, setValues = props.setValues, updateValues = props.updateValues;
    var STATUSES = [
        { label: "Available", value: "AVAILABLE" },
        { label: "Out of stock", value: "OUT-OF-STOCK" },
        { label: "Draft", value: "DRAFT" },
    ];
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col lg:flex-row gap-4 justify-between" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4 w-64" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Status", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownControl, { label: "Status", borderColor: "border-borders", required: true, options: STATUSES, iconColor: "text-primary", size: "medium", value: values === null || values === void 0 ? void 0 : values.status, onChange: function (v) {
                            updateValues("status", v);
                        }, errorText: !(values === null || values === void 0 ? void 0 : values.status) ? "Please select a status" : "" })] })) })) })));
};
exports.default = ChannelsAndAppsConfigComponent;
