"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var constants_1 = require("../../../../constants/constants");
var get_client_thunk_1 = require("../../../../reducers/slices/client/thunks/get_client_thunk");
var main_1 = require("../../../../reducers/slices/main");
var update_client_1 = require("../../../../api/calls/client/update_client");
var SupplierComponent = function () {
    var _a, _b, _c;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _d = (0, react_1.useState)(false), isEditing = _d[0], setIsEditing = _d[1];
    var _e = (0, react_1.useState)((_a = client === null || client === void 0 ? void 0 : client.supplier) !== null && _a !== void 0 ? _a : false), isChecked = _e[0], setIsChecked = _e[1];
    var _f = (0, react_1.useState)((_c = (_b = client === null || client === void 0 ? void 0 : client.stock_manager) === null || _b === void 0 ? void 0 : _b.method) !== null && _c !== void 0 ? _c : constants_1.CLIENT), isBranchManaged = _f[0], setIsBranchManaged = _f[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (!(client === null || client === void 0 ? void 0 : client._id)) {
            dispatch((0, get_client_thunk_1.get_client_thunk)());
        }
        setIsChecked((_a = client === null || client === void 0 ? void 0 : client.supplier) !== null && _a !== void 0 ? _a : false);
    }, [client]);
    (0, react_1.useEffect)(function () {
        _onChange();
    }, [isChecked]);
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var _onChange = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, update_client_1.update_client)({ _id: client._id, client: { supplier: isChecked } })];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.success) {
                        dispatch((0, get_client_thunk_1.get_client_thunk)());
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
                    return [2 /*return*/];
            }
        });
    }); };
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ className: " bg-white", borderColor: "border-transparent" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "fle flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col lg:flex-row" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Enable Supplier Status", textColor: "text-black", size: "medium" }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.SwitchControl, { disabled: false, required: true, label: isChecked ? "Enabled" : "Disabled", checked: isChecked, onChange: function (checked) {
                                    showLoader();
                                    setIsChecked(checked);
                                }, colors: {
                                    thumbColor: isChecked ? "bg-blue-500" : "bg-gray-500",
                                    titleColor: "text-blue-500",
                                    labelColor: "text-black",
                                    errorTextColor: "text-red-500",
                                } }) }))] })) })) })) })));
};
exports.SupplierComponent = SupplierComponent;
