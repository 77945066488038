"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var product_1 = require("../../../../../reducers/slices/products/product");
var pages_1 = require("../../../../../constants/pages");
var get_products_shopify_thunk_1 = require("../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_2 = require("doshx_controls_web");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_2 = require("@fortawesome/pro-solid-svg-icons");
var ProductsComponent = function (_a) {
    var onSave = _a.onSave, onSelectAllProductsChange = _a.onSelectAllProductsChange;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), dealProductsPagination = _b.dealProductsPagination, dealProducts = _b.dealProducts;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), searchDealProducts = _c[0], setSearchDealProducts = _c[1];
    var _d = (0, react_1.useState)([]), selectedRows = _d[0], setSelectedRows = _d[1];
    var _e = (0, react_1.useState)(false), selectedAllProducts = _e[0], setSelectedAllProducts = _e[1];
    var selected_all_products = false;
    var handleSave = function () {
        onSave(selectedRows);
        dispatch((0, main_1.setContentModal)(null));
    };
    (0, react_1.useEffect)(function () {
        onSearch(searchDealProducts);
    }, [searchDealProducts]);
    (0, react_1.useEffect)(function () {
        onSelectAllProductsChange(selectedAllProducts);
    }, [selectedAllProducts, onSelectAllProductsChange]);
    (0, react_1.useEffect)(function () {
        setSelectedRows(selectedRows);
    }, [selectedRows, selectedRows.length]);
    var getDealProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_deal_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        var _a;
        return getDealProducts(__assign(__assign({}, pages_1.dealsInitialPaginationValues), { search_string: search_string !== null && search_string !== void 0 ? search_string : "", is_add_ons_request: true, branch_id_list: (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" ? (_a = user === null || user === void 0 ? void 0 : user.manager_of) === null || _a === void 0 ? void 0 : _a.join(",") : null }));
    };
    var onPageChange = function (page) {
        var _a, _b;
        if (searchDealProducts === "") {
            return getDealProducts(__assign(__assign({}, pages_1.dealsInitialPaginationValues), { is_add_ons_request: true, branch_id_list: (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" ? (_a = user === null || user === void 0 ? void 0 : user.manager_of) === null || _a === void 0 ? void 0 : _a.join(",") : null, page: page }));
        }
        else {
            return getDealProducts(__assign(__assign({}, pages_1.dealsInitialPaginationValues), { is_add_ons_request: true, branch_id_list: (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" ? (_b = user === null || user === void 0 ? void 0 : user.manager_of) === null || _b === void 0 ? void 0 : _b.join(",") : null, page: page, search_string: searchDealProducts }));
        }
    };
    var onRowsPerPageChange = function (size) {
        return getDealProducts(__assign(__assign({}, pages_1.dealsInitialPaginationValues), { is_add_ons_request: true, size: size }));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "pl-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Select All", size: "xsmall", value: selected_all_products, onChange: function (v) {
                                if (v) {
                                    setSelectedAllProducts(true);
                                }
                                else {
                                    setSelectedAllProducts(false);
                                }
                            } }) })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Search Products", endIcon: pro_solid_svg_icons_1.faSearch, borderColor: "border-borders", size: "small", onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, dispatch((0, product_1.setDealProductsPagination)(pages_1.dealsInitialPaginationValues))];
                                    case 1:
                                        _a.sent();
                                        setSearchDealProducts(e);
                                        return [2 /*return*/];
                                }
                            });
                        }); } })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "image_url",
                        label: "Image",
                        formatter: function (product) {
                            if (product) {
                                return (0, jsx_runtime_1.jsx)("img", { className: "w-10 aspect-square rounded-lg", src: product === null || product === void 0 ? void 0 : product.image_url });
                            }
                            else {
                                return "";
                            }
                        },
                    },
                    {
                        displayKey: "sku",
                        label: "SKU",
                    },
                    {
                        displayKey: "pid",
                        label: "PID",
                    },
                    {
                        displayKey: "title",
                        label: "Title",
                    },
                    {
                        displayKey: "price",
                        label: "Price",
                        formatter: function (product) {
                            var _a, _b;
                            if (product) {
                                return doshx_controls_web_2.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_b = (_a = product === null || product === void 0 ? void 0 : product.price) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "0",
                                    symbolSeparation: true,
                                });
                            }
                        },
                    },
                    {
                        displayKey: "availability",
                        label: "Online",
                        formatter: function (product) {
                            if ((product === null || product === void 0 ? void 0 : product.availability) === "ONLINE" || !(product === null || product === void 0 ? void 0 : product.availability)) {
                                return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { className: "text-success", icon: pro_solid_svg_icons_2.faCircleCheck });
                            }
                            else {
                                return (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { className: "text-disabled", icon: pro_solid_svg_icons_2.faCircleMinus });
                            }
                        },
                    },
                ], rowSelections: selectedRows, onSelectionChange: function (selectedRows) {
                    setSelectedAllProducts(false);
                    setSelectedRows(selectedRows);
                }, data: dealProducts, hasShadow: true, pagination: {
                    currentPage: dealProductsPagination.page,
                    rowsPerPage: dealProductsPagination.size,
                    totalRecords: dealProductsPagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center items-center gap-2" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Search", onClick: handleSave, size: "small", shape: "square", backgroundColor: "bg-success" }) }))] })));
};
exports.default = ProductsComponent;
