"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var get_product_1 = require("../../../../../../api/calls/product/get_product");
var add_product_stock_1 = require("../../../../../../api/calls/product/product_stock/add_product_stock");
var get_product_stock_1 = require("../../../../../../api/calls/product/product_stock/get_product_stock");
var update_stock_list_1 = require("../../../../../../api/calls/product/product_stock/update_stock_list");
var constants_1 = require("../../../../../../constants/constants");
var main_1 = require("../../../../../../reducers/slices/main");
var VarianceConfigComponent = function (props) {
    var values = props.values, setValues = props.setValues, updateValues = props.updateValues;
    var pid = values.pid, sku = values.sku, product_id = values._id, variationList = values.variations;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)([]), productStock = _a[0], setProductStock = _a[1];
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var variations = variationList.map(function (pVariance) {
        var _a;
        return (__assign(__assign({}, pVariance), { variations: (_a = pVariance === null || pVariance === void 0 ? void 0 : pVariance.variations) === null || _a === void 0 ? void 0 : _a.map(function (cVariance) {
                var _a;
                var foundStock = (productStock === null || productStock === void 0 ? void 0 : productStock.find(function (stock) { return (stock === null || stock === void 0 ? void 0 : stock.variation_id) === (cVariance === null || cVariance === void 0 ? void 0 : cVariance._id); })) || {};
                var stock = (foundStock === null || foundStock === void 0 ? void 0 : foundStock.stock_list) ? (_a = foundStock === null || foundStock === void 0 ? void 0 : foundStock.stock_list) === null || _a === void 0 ? void 0 : _a.find(function (stock) { return stock.type === constants_1.CLIENT; }) : {};
                var _b = cVariance || {}, _c = _b.stock_level, stock_level = _c === void 0 ? stock === null || stock === void 0 ? void 0 : stock.stock_level : _c, _d = _b.minimum_stock_level, minimum_stock_level = _d === void 0 ? stock === null || stock === void 0 ? void 0 : stock.minimum_stock_level : _d;
                return __assign(__assign({}, cVariance), { stock_level: stock_level, minimum_stock_level: minimum_stock_level });
            }) }));
    });
    var getProduct = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, get_product_1.get_product)({ _id: product_id })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        setValues(response.content);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when retrieving the selected product.", messages: [response.message] }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var updateVarianceValues = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var variationIndex, varianceValueIndex, value, key, variance, stock, hasStock, response;
        var _a;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    variationIndex = data.variationIndex, varianceValueIndex = data.varianceValueIndex, value = data.value, key = data.key;
                    values.variations[variationIndex].variations[varianceValueIndex][key] = value;
                    setValues(__assign({}, values));
                    variance = variations[variationIndex].variations[varianceValueIndex];
                    stock = productStock.find(function (_) { return _.variation_id === variance._id; });
                    hasStock = !!stock;
                    if (!!hasStock) return [3 /*break*/, 2];
                    return [4 /*yield*/, createProductStock(data)];
                case 1: return [2 /*return*/, _c.sent()];
                case 2: return [4 /*yield*/, (0, update_stock_list_1.update_stock_list)({
                        _id: stock === null || stock === void 0 ? void 0 : stock._id,
                        pid: pid,
                        sku: sku,
                        product_id: product_id,
                        variation_id: variance === null || variance === void 0 ? void 0 : variance._id,
                        stock_list: [
                            (_a = {
                                    branch_id: null,
                                    notes: null,
                                    stock_level: String(variance.stock_level),
                                    minimum_stock_level: String(variance.minimum_stock_level),
                                    type: (_b = client === null || client === void 0 ? void 0 : client.stock_manager) === null || _b === void 0 ? void 0 : _b.method
                                },
                                _a[key] = value,
                                _a),
                        ],
                    })];
                case 3:
                    response = _c.sent();
                    if (response.success) {
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
                    return [2 /*return*/];
            }
        });
    }); };
    var createProductStock = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var variationIndex, varianceValueIndex, value, key, variance, response;
        var _a;
        var _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    variationIndex = data.variationIndex, varianceValueIndex = data.varianceValueIndex, value = data.value, key = data.key;
                    variance = (_b = variations[variationIndex]) === null || _b === void 0 ? void 0 : _b.variations[varianceValueIndex];
                    return [4 /*yield*/, (0, add_product_stock_1.add_product_stock)({
                            product_id: product_id,
                            pid: pid,
                            sku: sku,
                            variation_id: variance._id,
                            stock_list: [
                                (_a = {
                                        branch_id: null,
                                        notes: null,
                                        stock_level: String((_c = variance.stock_level) !== null && _c !== void 0 ? _c : 0),
                                        minimum_stock_level: String((_d = variance.minimum_stock_level) !== null && _d !== void 0 ? _d : 0),
                                        type: (_e = client === null || client === void 0 ? void 0 : client.stock_manager) === null || _e === void 0 ? void 0 : _e.method
                                    },
                                    _a[key] = value,
                                    _a),
                            ],
                        })];
                case 1:
                    response = _f.sent();
                    if (response.success) {
                        dispatch((0, main_1.setContentModal)(null));
                        getProduct();
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
                    return [2 /*return*/];
            }
        });
    }); };
    var getProductStock = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, get_product_stock_1.get_product_stock)({
                        product_id: product_id,
                    })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        setProductStock(response.content);
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: response.message }) }));
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (values === null || values === void 0 ? void 0 : values._id)
            getProductStock();
    }, [values]);
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Stock", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Track Quantity", value: values.track_quantity, onChange: function (v) {
                                updateValues("track_quantity", v);
                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Notify when the stock level falls below the minimum level.", value: values.notify_when_stock_low, onChange: function (v) {
                                updateValues("notify_when_stock_low", v);
                            } })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Added Variants", textColor: "text-black", className: "mt-2" }), (0, jsx_runtime_1.jsx)("hr", {}), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Product", bold: true, textColor: "text-black" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Stock Level", bold: true, textColor: "text-black" }) }))] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: values.title }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "", type: "text", borderColor: "border-borders", value: values.quantity, errorText: "", onChange: function (v) {
                                updateValues("quantity", v);
                            } })] })), variations === null || variations === void 0 ? void 0 : variations.map(function (x, variationIndex) {
                    var _a;
                    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: x.title, bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "(Variant to the type)", size: "xsmall" })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Stock Level", bold: true, textColor: "text-black" }) }))] })), (_a = x.variations) === null || _a === void 0 ? void 0 : _a.map(function (varianceValue, varianceValueIndex) {
                                var _a, _b, _c, _d;
                                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: varianceValue.title }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Stock Level", type: "number", borderColor: "border-borders", value: (_b = (_a = variations[variationIndex]) === null || _a === void 0 ? void 0 : _a.variations[varianceValueIndex]) === null || _b === void 0 ? void 0 : _b.stock_level, errorText: "", onChange: function (v) {
                                                        updateVarianceValues({
                                                            variationIndex: variationIndex,
                                                            varianceValueIndex: varianceValueIndex,
                                                            value: v,
                                                            key: "stock_level",
                                                        });
                                                    } })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-between" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Set Minimum stock level" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Minimum stock level", type: "number", borderColor: "border-borders", value: (_d = (_c = variations[variationIndex]) === null || _c === void 0 ? void 0 : _c.variations[varianceValueIndex]) === null || _d === void 0 ? void 0 : _d.minimum_stock_level, errorText: "", onChange: function (v) {
                                                        updateVarianceValues({
                                                            variationIndex: variationIndex,
                                                            varianceValueIndex: varianceValueIndex,
                                                            value: v,
                                                            key: "minimum_stock_level",
                                                        });
                                                    } })] }), varianceValueIndex), (0, jsx_runtime_1.jsx)("hr", {})] }), varianceValueIndex));
                            })] }), variationIndex));
                }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Continue selling when out of stock", value: values === null || values === void 0 ? void 0 : values.continue_selling_when_out_of_stock, onChange: function (v) {
                        updateValues("continue_selling_when_out_of_stock", v);
                    } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Note: If not ticked, Out of stock will be still displayed on shop with the \u201Cout of stock image tag\u201D, and product can\u2019t checked out", size: "xsmall" }), (0, jsx_runtime_1.jsx)("hr", {}), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "mark if recommended", value: values === null || values === void 0 ? void 0 : values.recommended, onChange: function (v) {
                        updateValues("recommended", v);
                    } })] })) })));
};
exports.default = VarianceConfigComponent;
