"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerSegmentPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../../reducers/slices/console");
var get_client_customer_segments_thunk_1 = require("../../../../../reducers/slices/customer_segments/thunks/get_client_customer_segments_thunk");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var main_1 = require("../../../../../reducers/slices/main");
var add_customer_segment_1 = require("./add_customer_segment");
var doshx_controls_web_1 = require("doshx_controls_web");
var remove_customer_segment_1 = require("../../../../../api/calls/customer-segments/remove/remove_customer_segment");
var table_bottom_content_1 = require("../../../../../components/table_bottom_content");
var local_storage_names_1 = require("../../../../../constants/local_storage_names");
var constants_1 = require("./constants");
var tabel_cell_1 = require("../../../../../components/tabel_cell");
var copy_text_1 = require("../../../../../components/copy_text");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var table_top_bar_1 = require("../../../../../components/table_top_bar");
var CustomerSegmentPage = function () {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), searchSegmentList = _c[0], setSearchSegmentList = _c[1];
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.customerSegmentState; }), customer_segments = _d.customer_segments, pagination = _d.pagination, isLoading = _d.isLoading;
    var pageTitle = (0, react_redux_1.useSelector)(function (state) { return state.consoleState; }).pageTitle;
    var _e = (0, react_1.useState)(10), rowsPerPage = _e[0], setRowsPerPage = _e[1];
    var _f = (0, react_1.useState)(1), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = (0, react_1.useState)(new Set([])), selectedKeys = _g[0], setSelectedKeys = _g[1];
    var _h = (0, react_1.useState)({
        column: "createdAt",
        direction: "ascending",
    }), sortDescriptor = _h[0], setSortDescriptor = _h[1];
    var tableColumnConfig = localStorage.getItem(local_storage_names_1.TABLE_COLUMN_CONFIG);
    var savedVisibleColumns = (_a = JSON.parse(tableColumnConfig)) !== null && _a !== void 0 ? _a : {};
    var initialVisibleColumns = (_b = savedVisibleColumns["customer_segments"]) !== null && _b !== void 0 ? _b : constants_1.INITIAL_VISIBLE_COLUMNS;
    var _j = (0, react_1.useState)(new Set(initialVisibleColumns)), visibleColumns = _j[0], setVisibleColumns = _j[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Customer Segments"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_client_customer_segments_thunk_1.get_customer_segments_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearchSegmentList(searchSegmentList);
    }, [searchSegmentList]);
    var getCustomerSegments = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_client_customer_segments_thunk_1.get_customer_segments_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchSegmentList = function (search_string) {
        getCustomerSegments(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (searchSegmentList === "") {
            setRowsPerPage(rowsPerPage);
            return getCustomerSegments(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getCustomerSegments(__assign(__assign({}, pagination), { page: page, search_string: searchSegmentList }));
        }
    };
    var filterSelectedKeys = (0, react_1.useMemo)(function () {
        var resultKeys = new Set();
        resultKeys = selectedKeys;
        return resultKeys;
    }, [selectedKeys, customer_segments]);
    var handleDelete = function (segment) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch((0, main_1.setContentModal)({
                title: "Confirm deletion of ".concat(segment === null || segment === void 0 ? void 0 : segment.title, " segment"),
                content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col items-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this segment?", textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ className: "bg-primary text-white", onClick: function () {
                                        (0, remove_customer_segment_1.remove_customer_segment)({
                                            _id: segment === null || segment === void 0 ? void 0 : segment._id,
                                        }).then(function (response) { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!response.success) return [3 /*break*/, 2];
                                                        dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                        dispatch((0, main_1.setContentModal)(null));
                                                        return [4 /*yield*/, getCustomerSegments(__assign({}, pagination))];
                                                    case 1:
                                                        _a.sent();
                                                        return [3 /*break*/, 3];
                                                    case 2:
                                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when deleting the segment?", messages: [response.message] }));
                                                        _a.label = 3;
                                                    case 3: return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    } }, { children: "Continue" }))] }))] }))),
            }));
            return [2 /*return*/];
        });
    }); };
    var handleEdit = function (segment) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch((0, main_1.setContentModal)({
                title: "Edit Customer Segment",
                content: (0, jsx_runtime_1.jsx)(add_customer_segment_1.CustomerSegmentDetails, { segment: segment, isEditing: true }),
            }));
            return [2 /*return*/];
        });
    }); };
    var headerColumns = (0, react_1.useMemo)(function () {
        if (visibleColumns === "all")
            return constants_1.columns;
        return constants_1.columns
            .map(function (item) {
            if (item.uid === sortDescriptor.column) {
                return __assign(__assign({}, item), { sortDirection: sortDescriptor.direction });
            }
            return item;
        })
            .filter(function (column) { return Array.from(visibleColumns).includes(column.uid); });
    }, [visibleColumns, sortDescriptor]);
    var renderCellViews = function (cellValue) { return [
        {
            key: "title",
            content: (0, jsx_runtime_1.jsx)(copy_text_1.CopyText, { children: cellValue }),
        },
        {
            key: "discount_type",
            content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue === "PERCENTAGE" ? "Percentage" : "Value" })),
        },
        {
            key: "discount",
            content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })),
        },
        {
            key: "apply_to_products_on_promotion",
            content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue ? "Yes" : "No" })),
        },
        {
            key: "segment_rule",
            content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue === "EXCLUDE_PROMOTION"
                    ? "Exclude Promotion"
                    : cellValue === "INCLUDE_PROMOTION"
                        ? "Include Promotion"
                        : cellValue === "LOWEST_PRICE"
                            ? "Lowest Price"
                            : cellValue }))),
        },
        {
            key: "actions",
            content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })),
        },
    ]; };
    var rowActions = function (cellValue) { return [
        {
            key: "view",
            onClick: function () { return handleEdit(cellValue); },
        },
        {
            key: "edit",
            onClick: function () { return handleEdit(cellValue); },
        },
        {
            key: "delete",
            onClick: function () { return handleDelete(cellValue); },
        },
    ]; };
    var totalPages = (0, react_1.useMemo)(function () {
        return (pagination === null || pagination === void 0 ? void 0 : pagination.countTotal) ? Math.ceil((pagination === null || pagination === void 0 ? void 0 : pagination.countTotal) / rowsPerPage) : 0;
    }, [pagination === null || pagination === void 0 ? void 0 : pagination.countTotal, rowsPerPage]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "!overflow-x-hidden !overflow-y-hidden" }, { children: [(0, table_top_bar_1.tableTopBar)({
                pageTitle: pageTitle,
                recordCount: pagination.countTotal,
                actionButtons: [
                    {
                        label: "Add Segment",
                        iconClassname: "solar:add-circle-bold",
                        onClick: function () {
                            dispatch((0, main_1.setContentModal)({
                                title: "Add Customer Segment",
                                content: (0, jsx_runtime_1.jsx)(add_customer_segment_1.CustomerSegmentDetails, { segment: null, isEditing: false }),
                            }));
                        },
                    },
                ].filter(Boolean),
            }), (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ isHeaderSticky: true, "aria-label": "Customer Segments", bottomContent: (0, table_bottom_content_1.tableBottomContent)({
                    filterSelectedKeys: filterSelectedKeys,
                    page: currentPage,
                    pages: totalPages,
                    filteredItems: customer_segments,
                    onPreviousPage: function () { return onPageChange(currentPage - 1); },
                    onNextPage: function () { return onPageChange(currentPage + 1); },
                    setPage: onPageChange,
                }), bottomContentPlacement: "outside", classNames: {
                    td: "before:bg-transparent",
                }, sortDescriptor: sortDescriptor, topContent: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[250px]" }, { children: (0, jsx_runtime_1.jsx)(react_2.Input, { className: "min-w-[200px]", endContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faSearch, className: "text-default-400", width: 16 }), placeholder: "Search", size: "sm", onValueChange: function (search_string) {
                            setSearchSegmentList(search_string);
                        } }) })), topContentPlacement: "outside" }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_2.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_2.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: isLoading, emptyContent: "This table is empty.", items: customer_segments, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) {
                            return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                        } }))] }))] })));
};
exports.CustomerSegmentPage = CustomerSegmentPage;
