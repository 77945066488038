"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Variations = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var Variations = function (_a) {
    var product = _a.product;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var VariationsTree = function (_a) {
        var variations = _a.variations, _b = _a.level, level = _b === void 0 ? 0 : _b;
        if (!variations)
            return null;
        return ((0, jsx_runtime_1.jsx)("div", { children: variations.map(function (variance, index) {
                var _a;
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-3" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-10 justify-between", style: { textWrap: "nowrap" } }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "".concat("-".repeat(level), " ").concat(variance.title), size: "small", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_a = variance === null || variance === void 0 ? void 0 : variance.price) !== null && _a !== void 0 ? _a : "0",
                                        symbolSeparation: true,
                                    }), size: "small", textColor: "text-black" })] })), variance.variations && (0, jsx_runtime_1.jsx)(VariationsTree, { variations: variance.variations, level: level + 1 })] }), index));
            }) }));
    };
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ className: "w-[600px] gap-2" }, { children: (0, jsx_runtime_1.jsx)(VariationsTree, { variations: product === null || product === void 0 ? void 0 : product.variations }) })));
};
exports.Variations = Variations;
