"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_redux_1 = require("react-redux");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../../../constants/pages");
var get_client_tags_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_client_tags_thunk");
var AddTagsComponent = function (props) {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var values = props.values, setValues = props.setValues;
    var _b = (0, react_1.useState)(""), tag = _b[0], setTag = _b[1];
    var _c = (0, react_1.useState)([]), selectedTag = _c[0], setSelectedTag = _c[1];
    var _d = (0, react_1.useState)(""), addTagErrorText = _d[0], setAddTagErrorText = _d[1];
    var _e = (0, react_1.useState)("FIND"), tagView = _e[0], setTagView = _e[1];
    var _f = (0, useSearchDebounce_1.useSearchDebounce)(), searchAddTags = _f[0], setSearchAddTags = _f[1];
    var clientTags = (0, react_redux_1.useSelector)(function (state) { return state.productState; }).clientTags;
    (0, react_1.useEffect)(function () { }, [values]);
    (0, react_1.useEffect)(function () {
        onSearch(searchAddTags);
    }, [searchAddTags]);
    var getTags = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_client_tags_thunk_1.get_client_tags_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getTags(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })));
    };
    return ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Add tag", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-80" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex justify-end gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { label: "Find Tag", size: "xsmall", onClick: function () {
                                                setTagView("FIND");
                                                setTag("");
                                                setSelectedTag([]);
                                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LinkButtonControl, { label: "Create Tag", size: "xsmall", onClick: function () {
                                                setTagView("CREATE");
                                                setTag("");
                                                setSelectedTag([]);
                                            } })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: tagView === "CREATE" ? ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Create Tag", type: "text", size: "medium", borderColor: "border-borders", value: tag, errorText: addTagErrorText, onChange: function (v) {
                                            setTag(v);
                                        } })) : ((0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownMultiControl, { label: "Products", renderDisplay: function (obj) {
                                            console.log("renderDisplay", obj);
                                            return obj.name;
                                        }, canSearch: true, selectedValues: selectedTag, 
                                        // disableFilterOnSearch={true}
                                        onSearchChanged: function (v) {
                                            setSearchAddTags(v);
                                        }, onSelectionChanged: function (selectedObject) {
                                            console.log("story onSelectionChanged", selectedObject);
                                            setSelectedTag(selectedObject);
                                        }, options: clientTags, colors: {
                                            option: {
                                                selectedColor: "bg-blue-200",
                                            },
                                        } })
                                    /* <DropdownSearchControl
                                      renderDisplay={(v) => v.label}
                                      label="Find Tag"
                                      options={
                                        clientTags?.map((x: any, i: number) => {
                                          return { label: x.name, value: x?.x };
                                        }) ?? []
                                      }
                                      labelColor={"text-black"}
                                      textColor={"text-black"}
                                      borderColor={"border-black"}
                                      iconColor={"text-black"}
                                      size={"medium"}
                                      value={tag}
                                      errorText={""}
                                      onChange={(v) => {
                                        console.log("v ===>", v);
                                        setTag(v);
                                      }}
                                      onSearchChanged={(search_string) => {
                                        console.log("search_string ==>", search_string);
                    
                                        setSearchAddTags(search_string);
                                      }}
                                    /> */
                                    ) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[100px]" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Save", size: "medium", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                                    if (tag.length) {
                                        setAddTagErrorText("");
                                        setValues(__assign(__assign({}, values), { tags: __spreadArray(__spreadArray([], values.tags, true), [tag], false) }));
                                    }
                                    else if (selectedTag.length > 0) {
                                        setValues(__assign(__assign({}, values), { tags: __spreadArray(__spreadArray([], values.tags, true), selectedTag.map(function (x) { return x.name; }), true) }));
                                    }
                                    else {
                                        setAddTagErrorText("Can not save an empty tag.");
                                    }
                                    setTag("");
                                    setSelectedTag([]);
                                } }) }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.PillsControl, { title: "", pills: (_a = values === null || values === void 0 ? void 0 : values.tags) === null || _a === void 0 ? void 0 : _a.map(function (x, i) {
                            return { label: x, id: i };
                        }), removeIcon: pro_light_svg_icons_1.faTimesCircle, onPillClick: function () {
                            console.log("Pill clicked");
                        }, onPillRemoveClick: function (v) {
                            var _a;
                            setValues(__assign(__assign({}, values), { tags: (_a = values === null || values === void 0 ? void 0 : values.tags) === null || _a === void 0 ? void 0 : _a.filter(function (x) { return x !== v.label; }) }));
                        }, colors: {
                            pills: {
                                shadowColor: "shadow-gray-200",
                                backgroundColor: "bg-white",
                            },
                        } }) }))] })) })));
};
exports.default = AddTagsComponent;
function dispatch(arg0) {
    throw new Error("Function not implemented.");
}
