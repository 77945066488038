"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliedDiscounts = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var AppliedDiscounts = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var product = _a.product;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ className: "w-[600px] gap-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row mb-4 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Product name: ", size: "small", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product === null || product === void 0 ? void 0 : product.title, size: "small", textColor: "text-black" })] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Discounts", size: "xsmall", textColor: "text-black", className: "mb-1" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-1 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Promotional price", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_b = product === null || product === void 0 ? void 0 : product.promotion_price) !== null && _b !== void 0 ? _b : 0,
                                    symbolSeparation: true,
                                }), size: "xsmall", textColor: "text-black" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-1" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Discount", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_d = (_c = product === null || product === void 0 ? void 0 : product.deal) === null || _c === void 0 ? void 0 : _c.discounted_price) !== null && _d !== void 0 ? _d : "0",
                                    symbolSeparation: true,
                                }), size: "xsmall", textColor: "text-black" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-1 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Voucher", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: "0",
                                    symbolSeparation: true,
                                }), size: "xsmall", textColor: "text-black" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-1 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Subscription", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: "0",
                                    symbolSeparation: true,
                                }), size: "xsmall", textColor: "text-black" })] }))] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-borders" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Deal", size: "xsmall", textColor: "text-black", className: "mb-1" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 gap-4" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-1 gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Deal name", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_f = (_e = product === null || product === void 0 ? void 0 : product.deal) === null || _e === void 0 ? void 0 : _e.deal_name) !== null && _f !== void 0 ? _f : "N/A", size: "xsmall", textColor: "text-black" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-1  gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Deal discount", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_h = (_g = product === null || product === void 0 ? void 0 : product.deal) === null || _g === void 0 ? void 0 : _g.discount) !== null && _h !== void 0 ? _h : "0",
                                    symbolSeparation: true,
                                }), size: "xsmall", textColor: "text-black" })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "col-span-1  gap-4" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Discounted price", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_k = (_j = product === null || product === void 0 ? void 0 : product.deal) === null || _j === void 0 ? void 0 : _j.discounted_price) !== null && _k !== void 0 ? _k : "0",
                                    symbolSeparation: true,
                                }), size: "xsmall", textColor: "text-black" })] }))] })), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-borders" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Eligible Vouchers", size: "xsmall", textColor: "text-black", className: "mb-1" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Voucher name", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Voucher discount", size: "xsmall", bold: true, textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Discounted price", size: "xsmall", bold: true, textColor: "text-black" })] })), (_l = product === null || product === void 0 ? void 0 : product.vouchers) === null || _l === void 0 ? void 0 : _l.map(function (voucher) {
                var _a, _b, _c;
                return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-3 gap-2" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: (_a = voucher === null || voucher === void 0 ? void 0 : voucher.voucher_name) !== null && _a !== void 0 ? _a : "N/A", size: "xsmall", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                value: (_b = voucher === null || voucher === void 0 ? void 0 : voucher.discount) !== null && _b !== void 0 ? _b : "0",
                                symbolSeparation: true,
                            }), size: "xsmall", textColor: "text-black" }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatMoney({
                                symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                value: (_c = voucher === null || voucher === void 0 ? void 0 : voucher.discounted_price) !== null && _c !== void 0 ? _c : "0",
                                symbolSeparation: true,
                            }), size: "xsmall", textColor: "text-black" })] })));
            })] })));
};
exports.AppliedDiscounts = AppliedDiscounts;
