"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoughnutComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var chart_js_1 = require("chart.js");
var react_chartjs_2_1 = require("react-chartjs-2");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
chart_js_1.Chart.register(chart_js_1.ArcElement, chart_js_1.Tooltip, chart_js_1.Legend);
var DoughnutComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var title = props.title, dataSet = props.dataSet;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _j = (0, react_1.useState)(), ordered = _j[0], setOrdered = _j[1];
    var _k = (0, react_1.useState)(), processing = _k[0], setProcessing = _k[1];
    var _l = (0, react_1.useState)(), partiallyFulfilled = _l[0], setPartiallyFulfilled = _l[1];
    var _m = (0, react_1.useState)(), fulfilled = _m[0], setFulfilled = _m[1];
    var _o = (0, react_1.useState)(), cancelled = _o[0], setCancelled = _o[1];
    var _p = (0, react_1.useState)(), abondoned = _p[0], setAbondoned = _p[1];
    var _q = (0, react_1.useState)(), checkedOut = _q[0], setCheckedOut = _q[1];
    var _r = (0, react_1.useState)(), active = _r[0], setActive = _r[1];
    (0, react_1.useEffect)(function () {
        if (dataSet) {
            if (title === "Orders") {
                var orderedObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "ORDERED"; });
                setOrdered(orderedObject);
                var processingObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "PROCESSING"; });
                setProcessing(processingObject);
                var partiallyFulfilledObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "PARTIALLY-FULFILLED"; });
                setPartiallyFulfilled(partiallyFulfilledObject);
                var fulfilledObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "FULFILLED"; });
                setFulfilled(fulfilledObject);
            }
            if (title === "Baskets") {
                var activeObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "ACTIVE"; });
                setActive(activeObject);
                var cancelledObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "CANCELLED"; });
                setCancelled(cancelledObject);
                var abondonedObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "ABANDONED"; });
                setAbondoned(abondonedObject);
                var checkedOutObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "CHECKED-OUT"; });
                setCheckedOut(checkedOutObject);
            }
        }
    }, [dataSet]);
    var options = {
        responsive: false,
        maintainAspectRatio: false,
        cutout: "65%",
        spacing: 0,
        borderJoinStyle: "round",
        plugins: {
            legend: {
                position: "right",
                labels: {
                    usePointStyle: true,
                },
            },
        },
        animation: {
            animateRotate: false,
            animateScale: true,
        },
    };
    var data = {
        labels: dataSet === null || dataSet === void 0 ? void 0 : dataSet.map(function (row) { return row.status; }),
        datasets: [
            {
                data: dataSet === null || dataSet === void 0 ? void 0 : dataSet.map(function (row) { return row.count; }),
                backgroundColor: ["rgb(8,144,0)", "rgb(127, 0, 255)", "rgb(255, 194, 0)", "rgb(186,196,200, 0.5)"],
                hoverOffset: 4,
                borderRadius: 8,
            },
        ],
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ variant: "filled", backgroundColor: "bg-white", className: "flex flex-col border-transparent rounded-lg" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)("h1", __assign({ className: "font-bold pb-2" }, { children: title })) })), title === "Orders" && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row gap-4 items-center mb-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-2 text-xs w-full" }, { children: [ordered && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_a = ordered === null || ordered === void 0 ? void 0 : ordered.total) !== null && _a !== void 0 ? _a : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Ordered" }))] }))), processing && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_b = processing === null || processing === void 0 ? void 0 : processing.total) !== null && _b !== void 0 ? _b : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Processing" }))] }))), partiallyFulfilled && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_c = partiallyFulfilled === null || partiallyFulfilled === void 0 ? void 0 : partiallyFulfilled.total) !== null && _c !== void 0 ? _c : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Partially-Fulfilled" }))] }))), fulfilled && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_d = fulfilled === null || fulfilled === void 0 ? void 0 : fulfilled.total) !== null && _d !== void 0 ? _d : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Fulfilled" }))] })))] })) }))), title === "Baskets" && ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-row gap-4 items-center mb-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row gap-2 text-xs w-full" }, { children: [active && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_e = active === null || active === void 0 ? void 0 : active.total) !== null && _e !== void 0 ? _e : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Active" }))] }))), checkedOut && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_f = checkedOut === null || checkedOut === void 0 ? void 0 : checkedOut.total) !== null && _f !== void 0 ? _f : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Checked Out" }))] }))), abondoned && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_g = abondoned === null || abondoned === void 0 ? void 0 : abondoned.total) !== null && _g !== void 0 ? _g : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Abondoned" }))] }))), cancelled && ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-black font-semibold" }, { children: doshx_controls_web_1.Utilities.formatMoney({
                                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                        value: (_h = cancelled === null || cancelled === void 0 ? void 0 : cancelled.total) !== null && _h !== void 0 ? _h : "0",
                                        symbolSeparation: true,
                                    }) })), (0, jsx_runtime_1.jsx)("span", __assign({ className: "text-zinc-400" }, { children: "Cancelled" }))] })))] })) }))), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col justify-center items-center" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: !(dataSet === null || dataSet === void 0 ? void 0 : dataSet.length) ? (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CircularLoaderControl, { size: "medium" }) : (0, jsx_runtime_1.jsx)(react_chartjs_2_1.Doughnut, { data: data, options: options }) })) }))] })));
};
exports.DoughnutComponent = DoughnutComponent;
