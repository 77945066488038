"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setProductSelectionEdited = exports.setTablePagination = exports.setIsEdited = exports.setIsLoading = exports.setErrors = exports.setMenuConcepts = exports.setSelectedMenuConcept = exports.initialMenuConceptState = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
exports.initialMenuConceptState = {
    selectedMenuConcept: {
        _id: "",
        name: "",
    },
    menuConcepts: [],
    pagination: pages_1.initialPaginationValues,
    errors: {},
    isLoading: false,
    isEdited: false,
    productSelectionEdited: false,
};
var slice = (0, toolkit_1.createSlice)({
    name: "menuConcepts",
    initialState: exports.initialMenuConceptState,
    reducers: {
        setSelectedMenuConcept: function (state, action) {
            state.selectedMenuConcept = action.payload;
        },
        setMenuConcepts: function (state, action) {
            state.menuConcepts = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
        setErrors: function (state, action) {
            state.errors = action.payload;
        },
        setIsLoading: function (state, action) {
            state.isLoading = action.payload;
        },
        setIsEdited: function (state, action) {
            state.isEdited = action.payload;
        },
        setProductSelectionEdited: function (state, action) {
            state.productSelectionEdited = action.payload;
        },
    },
});
exports.setSelectedMenuConcept = (_a = slice.actions, _a.setSelectedMenuConcept), exports.setMenuConcepts = _a.setMenuConcepts, exports.setErrors = _a.setErrors, exports.setIsLoading = _a.setIsLoading, exports.setIsEdited = _a.setIsEdited, exports.setTablePagination = _a.setTablePagination, exports.setProductSelectionEdited = _a.setProductSelectionEdited;
exports.default = slice.reducer;
