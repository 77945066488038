"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../reducers/slices/main");
var console_1 = require("../../../../../../reducers/slices/console");
var product_1 = require("../../../../../../reducers/slices/products/product");
var get_products_shopify_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var add_condiment_group_thunk_1 = require("../../../../../../reducers/slices/condiments/thunks/add_condiment_group_thunk");
var get_selected_condiment_1 = require("../../../../../../api/calls/product/condiments/get_selected_condiment");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../../../constants/pages");
var update_condiment_1 = require("../../../../../../api/calls/product/condiments/update_condiment");
var unit_constants_1 = require("./unit_constants");
var CondimentDetailsComponent = function (props) {
    var _a, _b;
    var dispatch = (0, react_redux_1.useDispatch)();
    var condiment = props.condiment, isEditing = props.isEditing;
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), search = _c[0], setSearch = _c[1];
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _d.products, pagination = _d.pagination, loading = _d.loading;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _e = (0, react_1.useState)(10), rowsPerPage = _e[0], setRowsPerPage = _e[1];
    var _f = (0, react_1.useState)(1), currentPage = _f[0], setCurrentPage = _f[1];
    var _g = (0, react_1.useState)(false), showSaveChangesLabel = _g[0], setShowSaveChangesLabel = _g[1];
    var _h = (0, react_1.useState)(""), selectedProduct = _h[0], setSelectedProduct = _h[1];
    var _j = (0, react_1.useState)({
        title: "",
        type: "",
        required: false,
        multiple: false,
        minimum_quantity: 0,
        maximum_quantity: 0,
        product_list: [],
    }), values = _j[0], setValues = _j[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Condiment Details"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token && !loading) {
            dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        if (client_token && isEditing) {
            getSelectedCondiment();
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    var getSelectedCondiment = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, content;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, get_selected_condiment_1.get_selected_condiment)({ _id: condiment === null || condiment === void 0 ? void 0 : condiment._id })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        content = response.content;
                        setValues({
                            title: content.title,
                            type: content.type,
                            required: content.required,
                            multiple: content.multiple,
                            minimum_quantity: content.minimum_quantity,
                            maximum_quantity: content.maximum_quantity,
                            product_list: content.product_list,
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getProducts(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var updateValues = function (prop, value) {
        var _a;
        setValues(__assign(__assign({}, values), (_a = {}, _a[prop] = value, _a)));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (search === "") {
            return getProducts(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            setCurrentPage(page);
            return getProducts(__assign(__assign({}, pagination), { page: page, search_string: search }));
        }
    };
    var validateCondiment = function (values, dispatch) {
        var _a;
        if (values.required && (values.minimum_quantity == 0 || values.minimum_quantity === null)) {
            dispatch((0, main_1.setMessageModal)({ title: "Minimum quantity error!", messages: ["If required is selected, minimum quantity cannot be zero"] }));
            return false;
        }
        if (values.multiple && ((_a = values === null || values === void 0 ? void 0 : values.product_list) === null || _a === void 0 ? void 0 : _a.length) < 2) {
            dispatch((0, main_1.setMessageModal)({ title: "Products error!", messages: ["If multiple is selected, you need to select at least 2 or more products"] }));
            return false;
        }
        if ((values === null || values === void 0 ? void 0 : values.multiple) && (values.maximum_quantity === null || values.maximum_quantity == 0)) {
            dispatch((0, main_1.setMessageModal)({ title: "Maximum quantity error!", messages: ["maximum quantity cannot be zero"] }));
            return false;
        }
        if (((values === null || values === void 0 ? void 0 : values.multiple) && values.maximum_quantity < values.minimum_quantity) || values.minimum_quantity > values.maximum_quantity) {
            dispatch((0, main_1.setMessageModal)({ title: "Maximum quantity error!", messages: ["maximum quantity cannot be less than minimum quantity"] }));
            return false;
        }
        if (values.maximum_quantity < values.minimum_quantity || values.minimum_quantity > values.maximum_quantity) {
            dispatch((0, main_1.setMessageModal)({ title: "Maximum quantity error!", messages: ["maximum quantity cannot be less than minimum quantity"] }));
            return false;
        }
        if ((values === null || values === void 0 ? void 0 : values.multiple) && values.maximum_quantity < 2) {
            dispatch((0, main_1.setMessageModal)({ title: "Maximum quantity error!", messages: ["If multiple is selected, maximum quantity cannot be less than 2"] }));
            return false;
        }
        if (values.minimum_quantity > 0 && !values.required) {
            dispatch((0, main_1.setMessageModal)({ title: "Required error!", messages: ["If minimum quantity is greater than zero, required cannot be false"] }));
            return false;
        }
        return true;
    };
    var enableSaveButton = (values === null || values === void 0 ? void 0 : values.title) && ((_a = values === null || values === void 0 ? void 0 : values.product_list) === null || _a === void 0 ? void 0 : _a.length) > 0;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2 lg:gap-2 lg:h-full overflow-y-auto max-h-[600px] md:h-auto sm:h-auto" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col justify-center gap-4 " }, { children: (0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-2 bg-white" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Title", borderColor: "border-borders", backgroundColor: "bg-white", size: "small", value: String(values.title), onChange: function (e) {
                                                updateValues("title", e);
                                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Type", borderColor: "border-borders", backgroundColor: "bg-white", size: "small", value: String(values.type), onChange: function (e) {
                                                updateValues("type", e);
                                            } })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-2 bg-white" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Minimum Quantity", borderColor: "border-borders", type: "number", backgroundColor: "bg-white", size: "small", value: String(values.minimum_quantity), onChange: function (e) {
                                                updateValues("minimum_quantity", e);
                                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.TextInputControl, { label: "Maximum Quantity", borderColor: "border-borders", type: "number", backgroundColor: "bg-white", size: "small", value: String(values.maximum_quantity), onChange: function (e) {
                                                updateValues("maximum_quantity", e);
                                            } })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-2 gap-2 bg-white" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Required", decorationColor: "decoration-borders", uncheckedColor: "text-textInput", checkedColor: "text-success", value: values === null || values === void 0 ? void 0 : values.required, size: "xsmall", textColor: "text-textInput", onChange: function (v) {
                                                updateValues("required", v);
                                            } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { label: "Multiple", decorationColor: "decoration-borders", uncheckedColor: "text-textInput", checkedColor: "text-success", value: values === null || values === void 0 ? void 0 : values.multiple, size: "xsmall", textColor: "text-textInput", onChange: function (v) {
                                                updateValues("multiple", v);
                                            } })] }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col gap-2 pt-2 pb-4" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col gap-2 pt-2" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-80" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) { return "".concat(v.label, " SKU: ").concat(v.sku); }, label: "Select Product", required: true, options: (_b = products === null || products === void 0 ? void 0 : products.map(function (product, index) {
                                                return { label: product.title, sku: product === null || product === void 0 ? void 0 : product.sku, value: product === null || product === void 0 ? void 0 : product._id };
                                            })) !== null && _b !== void 0 ? _b : [], colors: {
                                                labelColor: "text-black",
                                                textColor: "text-black",
                                                borderColor: "border-borders",
                                                iconColor: "text-black",
                                            }, size: "small", value: selectedProduct, errorText: "", onChange: function (v) {
                                                var _a;
                                                setSelectedProduct(v);
                                                var product = products === null || products === void 0 ? void 0 : products.find(function (selectedProduct) { return (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct._id) === (v === null || v === void 0 ? void 0 : v.value); });
                                                var productExists = (_a = values === null || values === void 0 ? void 0 : values.product_list) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item._id === (product === null || product === void 0 ? void 0 : product._id); });
                                                if (!productExists) {
                                                    setValues(__assign(__assign({}, values), { product_list: __spreadArray(__spreadArray([], values === null || values === void 0 ? void 0 : values.product_list, true), [product], false) }));
                                                    setShowSaveChangesLabel(true);
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Product already exists!", messages: ["Please select a different product."] }));
                                                }
                                            }, onSearchChanged: function (search_string) {
                                                dispatch((0, product_1.setTablePagination)(pages_1.initialPaginationValues));
                                                setSearch(search_string);
                                            }, pagination: {
                                                currentPage: currentPage,
                                                recordsPerPage: rowsPerPage,
                                                totalRecords: pagination === null || pagination === void 0 ? void 0 : pagination.countTotal,
                                                position: "center",
                                                onClick: function (page) {
                                                    return onPageChange(page);
                                                },
                                            } }) })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-11 gap-1 w-[600px] p-2 bg-black rounded-lg text-white" }, { children: [unit_constants_1.productTableColumns.map(function (_a, index) {
                                                var label = _a.label;
                                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 flex items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere text-white", size: "small", textColor: "text-black", bold: true }) }), index));
                                            }), (0, jsx_runtime_1.jsx)("div", { className: "col-span-1" }), " "] })), values === null || values === void 0 ? void 0 : values.product_list.map(function (product, index) {
                                        return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "grid grid-cols-11 gap-1 w-[600px] items-center" }, { children: [unit_constants_1.productTableColumns.map(function (_a, i) {
                                                    var displayKey = _a.displayKey;
                                                    switch (displayKey) {
                                                        case "required":
                                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: product === null || product === void 0 ? void 0 : product.is_required, onChange: function (v) {
                                                                        var updatedProduct = __assign(__assign({}, product), { is_required: v });
                                                                        var updatedAddOns = values === null || values === void 0 ? void 0 : values.product_list.map(function (item) {
                                                                            if (item._id === product._id) {
                                                                                return updatedProduct;
                                                                            }
                                                                            return item;
                                                                        });
                                                                        setValues(__assign(__assign({}, values), { product_list: updatedAddOns }));
                                                                        setShowSaveChangesLabel(true);
                                                                    } }) }), i));
                                                        case "is_default":
                                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: product === null || product === void 0 ? void 0 : product.is_default, onChange: function (v) {
                                                                        var updatedProduct = __assign(__assign({}, product), { is_default: v });
                                                                        var updatedAddOns = values === null || values === void 0 ? void 0 : values.product_list.map(function (item) {
                                                                            if (item._id === product._id) {
                                                                                return updatedProduct;
                                                                            }
                                                                            return item;
                                                                        });
                                                                        setValues(__assign(__assign({}, values), { product_list: updatedAddOns }));
                                                                        setShowSaveChangesLabel(true);
                                                                    } }) }), i));
                                                        default:
                                                            return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-2 break-words" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product[displayKey], size: "xsmall", textColor: "text-black" }) }), i));
                                                    }
                                                }), (0, jsx_runtime_1.jsx)("div", __assign({ className: "" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.IconButtonControl, { size: "2xsmall", icon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-red-400", iconColor: "text-white", borderColor: "border-transparent", onClick: function (v) {
                                                            var _a;
                                                            var filteredProducts = (_a = values === null || values === void 0 ? void 0 : values.product_list) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return (item === null || item === void 0 ? void 0 : item._id) !== (product === null || product === void 0 ? void 0 : product._id); });
                                                            setValues(__assign(__assign({}, values), { product_list: __spreadArray([], filteredProducts, true) }));
                                                            setShowSaveChangesLabel(true);
                                                        } }) }))] }), index));
                                    })] })) }))] })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-center w-full" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", enabled: enableSaveButton, label: isEditing ? "Update Condiment" : "Save Condiment", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var product_ids, updatedValues, response, product_ids, updatedCondiment, updatedPayload;
                        var _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    if (!!isEditing) return [3 /*break*/, 2];
                                    if (!validateCondiment(values, dispatch)) {
                                        return [2 /*return*/];
                                    }
                                    product_ids = values === null || values === void 0 ? void 0 : values.product_list.map(function (product) { return product === null || product === void 0 ? void 0 : product._id; });
                                    updatedValues = __assign(__assign({}, values), { product_list: product_ids });
                                    return [4 /*yield*/, dispatch((0, add_condiment_group_thunk_1.add_condiment_thunk)(updatedValues))];
                                case 1:
                                    response = _c.sent();
                                    if ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.success) {
                                        setTimeout(function () {
                                            dispatch((0, main_1.setMessageModal)(null));
                                            dispatch((0, console_1.setPageTitle)("Condiments"));
                                            dispatch((0, main_1.setContentModal)(null));
                                        }, 1500);
                                    }
                                    else {
                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong adding a condiment group!", messages: [(_b = response.payload) === null || _b === void 0 ? void 0 : _b.message] }));
                                    }
                                    return [3 /*break*/, 3];
                                case 2:
                                    if (!validateCondiment(values, dispatch)) {
                                        return [2 /*return*/];
                                    }
                                    product_ids = values === null || values === void 0 ? void 0 : values.product_list.map(function (product) { return product === null || product === void 0 ? void 0 : product._id; });
                                    updatedCondiment = __assign(__assign({}, values), { product_list: product_ids });
                                    updatedPayload = {
                                        _id: condiment === null || condiment === void 0 ? void 0 : condiment._id,
                                        condiment: updatedCondiment,
                                    };
                                    (0, update_condiment_1.update_condiment_details)(updatedPayload).then(function (response) {
                                        if (response.success) {
                                            setShowSaveChangesLabel(false);
                                            dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                            setTimeout(function () {
                                                dispatch((0, main_1.setMessageModal)(null));
                                                dispatch((0, console_1.setPageTitle)("Condiments"));
                                                dispatch((0, main_1.setContentModal)(null));
                                            }, 1500);
                                        }
                                        else {
                                            setShowSaveChangesLabel(false);
                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong updating a condiment!", messages: [response.message] }));
                                        }
                                    });
                                    _c.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); } }) }))] })));
};
exports.default = CondimentDetailsComponent;
