"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DROPDOWN_STATUSES = exports.ALL = exports.DOCUMENTS_STATUSES = exports.DOCUMENT_TABLE_COLUMNS = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("../../../constants");
exports.DOCUMENT_TABLE_COLUMNS = [
    {
        displayKey: "name",
        label: "Name",
    },
    {
        displayKey: "createdAt",
        label: "Date",
        formatter: function (parcel) { return doshx_controls_web_1.Utilities.formatDateTime(parcel === null || parcel === void 0 ? void 0 : parcel.createdAt); },
    },
    {
        displayKey: "description",
        label: "Description",
    },
    /* {
      displayKey: "owner",
      label: "Customer",
    }, */
    {
        displayKey: "branch_name",
        label: "Branch",
    },
    {
        displayKey: "status",
        label: "Status",
        formatter: function (x) {
            if (x) {
                return (0, constants_1.TABLE_STATUSES)(x.status);
            }
        },
    },
];
exports.DOCUMENTS_STATUSES = {
    uploaded: "UPLOADED",
    viewed: "VIEWED",
    processed: "PROCESSED",
};
exports.ALL = {
    value: "",
    label: "ALL",
};
exports.DROPDOWN_STATUSES = [
    {
        value: exports.DOCUMENTS_STATUSES.uploaded,
        label: exports.DOCUMENTS_STATUSES.uploaded,
    },
    {
        value: exports.DOCUMENTS_STATUSES.viewed,
        label: exports.DOCUMENTS_STATUSES.viewed,
    },
    {
        value: exports.DOCUMENTS_STATUSES.processed,
        label: exports.DOCUMENTS_STATUSES.processed,
    },
    exports.ALL,
];
