"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.productAddOnsTableColumns = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var doshx_controls_web_1 = require("doshx_controls_web");
var get_products_shopify_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var react_redux_1 = require("react-redux");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../../../constants/pages");
var main_1 = require("../../../../../../reducers/slices/main");
var add_product_variances_1 = require("../../../../../../api/calls/product/variances/add_product_variances");
exports.productAddOnsTableColumns = [
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "pid",
        label: "PID",
    },
    {
        displayKey: "price",
        label: "Price",
    },
    {
        displayKey: "status",
        label: "Status",
    },
    {
        displayKey: "is_default",
        label: "Default",
    },
];
var defaultImageUrl = "https://doshex-test-kyc.s3.eu-west-1.amazonaws.com/PRODUCTS/IMAGE/6514cf51e09a134858d60a1c9a0c5a8dc7bbb09d";
var SelectProductVariant = function (props) {
    var _a;
    var product_id = props.product_id, onTaskComplete = props.onTaskComplete;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, useSearchDebounce_1.useSearchDebounce)(), searchAddOnProducts = _b[0], setSearchAddOnProducts = _b[1];
    var _c = (0, react_1.useState)(""), variation = _c[0], setVariation = _c[1];
    var _d = (0, react_1.useState)(false), showSaveChangesLabel = _d[0], setShowSaveChangesLabel = _d[1];
    var _e = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), productAddOnsPagination = _e.productAddOnsPagination, productAddOns = _e.productAddOns;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _f = (0, react_1.useState)(false), refreshTreeItemsData = _f[0], setRefreshTreeItemsData = _f[1];
    var _g = (0, react_1.useState)([]), treeItems = _g[0], setTreeItems = _g[1];
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign(__assign({}, productAddOnsPagination), { is_add_ons_request: true })));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        if (refreshTreeItemsData) {
            setRefreshTreeItemsData(false);
        }
    }, [refreshTreeItemsData]);
    (0, react_1.useEffect)(function () {
        setTreeItems(props.treeItems);
    }, [props.treeItems]);
    (0, react_1.useEffect)(function () {
        onSearch(searchAddOnProducts);
    }, [searchAddOnProducts]);
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getProducts(__assign(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })), { is_add_ons_request: true }));
    };
    var sanitiseVariances = function (items) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
        var variances = [];
        for (var _i = 0, items_1 = items; _i < items_1.length; _i++) {
            var item = items_1[_i];
            var obj = {
                ref: (_a = item._id) !== null && _a !== void 0 ? _a : item.ref,
                product_id: product_id,
                variations: [],
                add_ons: [],
                status: (_c = (_b = item.data) === null || _b === void 0 ? void 0 : _b.status) !== null && _c !== void 0 ? _c : item.status,
                type: (_e = (_d = item.data) === null || _d === void 0 ? void 0 : _d.type) !== null && _e !== void 0 ? _e : item.type,
                title: (_g = (_f = item.data) === null || _f === void 0 ? void 0 : _f.title) !== null && _g !== void 0 ? _g : item.title,
                price: (_j = (_h = item.data) === null || _h === void 0 ? void 0 : _h.price) !== null && _j !== void 0 ? _j : item.price,
                sku: (_l = (_k = item.data) === null || _k === void 0 ? void 0 : _k.sku) !== null && _l !== void 0 ? _l : item.sku,
                pid: (_o = (_m = item.data) === null || _m === void 0 ? void 0 : _m.pid) !== null && _o !== void 0 ? _o : item.pid,
                image_url: (_q = (_p = item.data) === null || _p === void 0 ? void 0 : _p.image_url) !== null && _q !== void 0 ? _q : item.image_url,
                on_promotion: (_s = (_r = item.data) === null || _r === void 0 ? void 0 : _r.on_promotion) !== null && _s !== void 0 ? _s : item.on_promotion,
                promotion_price: (_u = (_t = item.data) === null || _t === void 0 ? void 0 : _t.promotion_price) !== null && _u !== void 0 ? _u : item.promotion_price,
                is_default: (_w = (_v = item.data) === null || _v === void 0 ? void 0 : _v.is_default) !== null && _w !== void 0 ? _w : item.is_default,
            };
            if (item.items && item.items.length > 0) {
                obj.variations = sanitiseVariances(item.items);
            }
            variances.push(obj);
        }
        return variances;
    };
    return ((0, jsx_runtime_1.jsxs)(doshx_controls_web_1.BoxControl, __assign({ backgroundColor: "bg-white", variant: "filled", className: "" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "mb-4" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Select Product(s) To Add as Variant(s)", bold: true, textColor: "text-black" }) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex gap-4 mb-2" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "w-80" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) { return v.label; }, label: "Find product to place as a variant", required: true, options: (_a = productAddOns === null || productAddOns === void 0 ? void 0 : productAddOns.map(function (product, i) {
                            return { label: product.title, value: product === null || product === void 0 ? void 0 : product._id };
                        })) !== null && _a !== void 0 ? _a : [], colors: {
                            labelColor: "text-black",
                            textColor: "text-black",
                            borderColor: "border-borders",
                            iconColor: "text-black",
                        }, size: "medium", value: variation, errorText: "", onChange: function (v) {
                            setVariation(v);
                            var product = productAddOns === null || productAddOns === void 0 ? void 0 : productAddOns.find(function (selectedProduct) { return (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct._id) === (v === null || v === void 0 ? void 0 : v.value); });
                            var prod = {
                                _id: doshx_controls_web_1.Utilities.randomAlphanumeric(10),
                                title: product.title,
                                data: {
                                    title: product.title,
                                    sku: product.sku,
                                    price: product.price,
                                    image_url: product.image_url ? product.image_url : defaultImageUrl,
                                    is_default: (product === null || product === void 0 ? void 0 : product.is_default) ? product === null || product === void 0 ? void 0 : product.is_default : false,
                                    pid: product.pid,
                                    on_promotion: product.on_promotion,
                                    promotion_price: product.promotion_price,
                                    quantity: product.quantity ? product.quantity : 1,
                                    status: product.status,
                                    type: "VARIANT",
                                },
                            };
                            var productExists = treeItems.find(function (item) { var _a; return item.data.pid === ((_a = prod.data) === null || _a === void 0 ? void 0 : _a.pid); });
                            if (!productExists) {
                                setTreeItems(__spreadArray(__spreadArray([], treeItems, true), [prod], false));
                                setShowSaveChangesLabel(true);
                            }
                            else {
                                dispatch((0, main_1.setMessageModal)({ title: "Product already exists!", messages: ["Please select a different product."] }));
                            }
                        }, onSearchChanged: function (search_string) {
                            setTimeout(function () {
                                setSearchAddOnProducts(search_string);
                            }, 2000);
                        } }) })) })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-6 gap-2 bg-black w-auto rounded p-2 mb-2" }, { children: exports.productAddOnsTableColumns.map(function (_a, index) {
                    var label = _a.label;
                    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-1 flex items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere text-white", size: "small", textColor: "text-black", bold: true }) }), index));
                }) })), treeItems === null || treeItems === void 0 ? void 0 : treeItems.map(function (product, index) {
                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-6 w-full gap-2 items-center pb-2" }, { children: exports.productAddOnsTableColumns.map(function (_a, i) {
                        var _b;
                        var displayKey = _a.displayKey;
                        switch (displayKey) {
                            case "is_default":
                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-1 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: (_b = product === null || product === void 0 ? void 0 : product.data) === null || _b === void 0 ? void 0 : _b.is_default, onChange: function (v) {
                                            var updatedProduct = __assign(__assign({}, product), { data: __assign(__assign({}, product.data), { is_default: v }) });
                                            var modifiedProductList = treeItems.map(function (item) {
                                                if (v && item._id !== product._id) {
                                                    return __assign(__assign({}, item), { data: __assign(__assign({}, item.data), { is_default: false }) });
                                                }
                                                if (item._id === product._id) {
                                                    return updatedProduct;
                                                }
                                                return item;
                                            });
                                            setTreeItems(modifiedProductList);
                                        } }) }), i));
                            default:
                                return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "col-span-1 break-words items-center" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: product === null || product === void 0 ? void 0 : product.data[displayKey], size: "xsmall", textColor: "text-black" }) }), i));
                        }
                    }) }), index));
            }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-end" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "w-[200px] mt-2 pb-1" }, { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Save product variations", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                var treeItemsWithoutContent, sanitisedTreeItems, response;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            treeItemsWithoutContent = treeItems === null || treeItems === void 0 ? void 0 : treeItems.filter(function (treeItem) { return !treeItem.hasOwnProperty("content"); });
                                            sanitisedTreeItems = sanitiseVariances(treeItemsWithoutContent);
                                            return [4 /*yield*/, (0, add_product_variances_1.add_product_variances)(sanitisedTreeItems)];
                                        case 1:
                                            response = _a.sent();
                                            if (response.success) {
                                                onTaskComplete();
                                                setRefreshTreeItemsData(true);
                                                dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                setTreeItems([]);
                                                setShowSaveChangesLabel(false);
                                            }
                                            else {
                                                onTaskComplete();
                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                                setShowSaveChangesLabel(false);
                                            }
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }) })), showSaveChangesLabel && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Save Changes...", size: "xsmall", textColor: "text-red-600" }) }))] }))] })));
};
exports.default = SelectProductVariant;
